import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  GameExpectedGoals,
  GameExpectedWin,
  GameResult,
  GameShots,
  GameTimeline,
  GameTimelineLegend,
  HeatmapGames,
  Loading,
  ShotFlowGraph,
} from '../../../../components';
import {
  filteredMainFilterDataSelector,
  getGamePreview,
  selectGames,
  selectPlayers,
  selectTeams,
  selectedGameSelector,
} from '../../../../features';
import { useHandleOnSubmit } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IMainFilterForm,
  INavigationContentKeys,
  INavigationPageKeys,
  ITeamsMetricValues,
  IUrlFilterParams,
} from '../../../../types';
import {
  createClassNames,
  getTeamsMetricValues,
  normalizeUrlWithFilterParams,
} from '../../../../utils';
import { useGetGraphicOverview } from '../shared';
import './GraphicOverviewContent.styles.scss';

export const useFetchGraphicOverview = () => {
  const { filteredParts, selectedGame } = useAppSelector(filteredMainFilterDataSelector);
  const { games } = useAppSelector(selectGames);
  const dispatch = useAppDispatch();
  const { byId } = games;

  const fetchGraphicOverview = () => {
    const matchId = selectedGame?.id;

    if (matchId) {
      const gameById = byId[matchId];
      if (gameById) {
        dispatch(
          getGamePreview({
            competitionsUuids: filteredParts[0].id,
            matchUuid: gameById.id,
            teamUuid: gameById.homeTeamId,
            gameInfo: gameById,
          }),
        );
      }
    }
  };

  return fetchGraphicOverview;
};

const classNames = createClassNames('graphic-overview-content');

export const GraphicOverviewContent = () => {
  const selectedGame = useAppSelector(selectedGameSelector);
  const {
    games: { isLoading, isAllGamesLoaded, graphicOverview, byId },
  } = useAppSelector(selectGames);
  const players = useAppSelector(selectPlayers);
  const teams = useAppSelector(selectTeams);

  const isInitialMountRef = useRef(true);
  const { formState } = useFormContext<IMainFilterForm>();

  const { t } = useTranslation();

  const { getGraphicOverviewConfigs } = useGetGraphicOverview();

  const fetchGraphicOverview = useFetchGraphicOverview();
  const handleOnSubmit = useHandleOnSubmit(() => {
    fetchGraphicOverview();
  }, isInitialMountRef);

  useEffect(() => {
    if (selectedGame) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGame]);

  const handleNavigateButtons = (teamId: string, selectedGameId: string) => {
    const filterParams: IUrlFilterParams[] = [
      { name: 'selectedTeam', value: teamId },
      { name: 'selectedGames', value: selectedGameId },
      { name: 'countOfPlayers', value: 'all' },
    ];
    const pagesUrl = `/${INavigationPageKeys.videomaps}/${INavigationContentKeys.shots}`;
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, filterParams);
    window.open(urlToOpen, '_blank');
  };

  const handleHeadToHeadClick = (selectedGameId: string) => {
    const filterParams: IUrlFilterParams[] = [{ name: 'selectedGame', value: selectedGameId }];
    const pagesUrl = `/${INavigationPageKeys.games}/${INavigationContentKeys.headToHead}`;
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, filterParams);
    window.open(urlToOpen, '_blank');
  };

  const handlePlayersClick = (selectedGameId: string) => {
    const filterParams: IUrlFilterParams[] = [{ name: 'selectedGame', value: selectedGameId }];
    const pagesUrl = `/${INavigationPageKeys.games}/${INavigationContentKeys.players}`;
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, filterParams);
    window.open(urlToOpen, '_blank');
  };

  const handleFaceoffsClick = (teamId: string, selectedGameId: string) => {
    const filterParams: IUrlFilterParams[] = [
      { name: 'selectedTeam', value: teamId },
      { name: 'selectedGames', value: selectedGameId },
      { name: 'countOfPlayers', value: 'all' },
    ];
    const pagesUrl = `/${INavigationPageKeys.videomaps}/${INavigationContentKeys.faceoffs}`;
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, filterParams);
    window.open(urlToOpen, '_blank');
  };

  if (isLoading || !isAllGamesLoaded || !players.byId) return <Loading />;

  if (!selectedGame) {
    return (
      <div className='content-info-box'>
        {Object.values(formState.errors).map((error, index) => (
          <div key={`${error.message}_${index}`}>
            {error.message ? t(error.message.toString()) : error.message}
          </div>
        ))}
      </div>
    );
  }

  if (!graphicOverview) {
    return <Loading />;
  }

  const { gameExpectedGoals, gameResult, gameShots, gameShotsFlowGraph, gameCourseExpectedGoals } =
    getGraphicOverviewConfigs(graphicOverview);

  const homeTeam = teams.byId[selectedGame.homeTeamId];
  const awayTeam = teams.byId[selectedGame.awayTeamId];

  const scoreState = byId[graphicOverview.matchId].score.state;
  const { series, yMaxValue, maxTime, teamGoalsCoordinates } = gameCourseExpectedGoals;

  const { homeTeamPeriodMetricValues, awayTeamPeriodMetricValues } = getTeamsMetricValues(
    graphicOverview.homeTeam.shots,
    graphicOverview.awayTeam.shots,
  );

  const teamsMetricValues: ITeamsMetricValues = {
    homeTeam: {
      shortcut: homeTeam.shortcut,
      metricValues: homeTeamPeriodMetricValues,
    },
    awayTeam: {
      shortcut: awayTeam.shortcut,
      metricValues: awayTeamPeriodMetricValues,
    },
  };

  return (
    <div className={classNames()}>
      <GameResult {...gameResult} />
      <GameExpectedWin
        homePercent={graphicOverview.homeTeam.expectedWin}
        awayPercent={graphicOverview.awayTeam.expectedWin}
      />
      <GameExpectedGoals {...gameExpectedGoals} />
      <hr />
      <GameShots {...gameShots} />
      <hr />
      <div>
        <h3 className={classNames('heatmap-title')}>{t(ITranslationKeys.shotsHeatmap)}</h3>
        <HeatmapGames data={graphicOverview.heatmap} />
      </div>
      <div className={classNames('buttons-container')}>
        <Button
          label={`${t(ITranslationKeys.shotmap)} ${homeTeam.shortcut}`}
          onClick={() => handleNavigateButtons(selectedGame.homeTeamId, selectedGame.id)}
        />
        <Button
          label={`${t(ITranslationKeys.shotmap)} ${awayTeam.shortcut}`}
          onClick={() => handleNavigateButtons(selectedGame.awayTeamId, selectedGame.id)}
        />
      </div>
      <hr />
      <div className={classNames('goals-story')}>
        <h3 className={classNames('goals-story__title')}>
          {t(ITranslationKeys.gameCourseExpectedGoals)}
        </h3>
        <div>
          <GameTimeline
            {...{ series, yMaxValue, maxTime, scoreState, teamGoalsCoordinates }}
            width={1320}
          />
          <GameTimelineLegend metricName='xG' {...{ maxTime, teamsMetricValues }} />
        </div>
      </div>
      <hr />
      <div className={classNames('shot-flow-graph')}>
        <div className={classNames('shot-flow-graph__maps')}>
          <ShotFlowGraph {...gameShotsFlowGraph.home} />
          <h3>{t(ITranslationKeys.shootingsSuperiority)}</h3>
          <ShotFlowGraph {...gameShotsFlowGraph.away} />
        </div>
        <div className={classNames('shot-flow-graph__buttons')}>
          <Button
            label={ITranslationKeys.headToHead}
            onClick={() => handleHeadToHeadClick(selectedGame.id)}
          />
          <Button
            label={ITranslationKeys.players}
            onClick={() => handlePlayersClick(selectedGame.id)}
          />
          <Button
            label={ITranslationKeys.faceoffs}
            onClick={() => handleFaceoffsClick(selectedGame.homeTeamId, selectedGame.id)}
          />
        </div>
      </div>
    </div>
  );
};
