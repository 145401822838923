import { useAppDispatch } from '../../../../app/hooks';
import {
  getDashboardFormations,
  getGoalkeeperTeamStats,
  setIsShortTableLoading,
} from '../../../../features';
import { IGoalkeeperGoalStatsRequestBody, ITeamsFormationRequestBody } from '../../../../types';

export const useFetchMetrics = () => {
  const dispatch = useAppDispatch();

  const fetchTeamMetrics = (competitionIds: string[], teamId: string) => {
    const requestBodyNormal: ITeamsFormationRequestBody = {
      gameState: '5:5',
      metrics: ['gf60', 'ga60'],
    };
    const requestBodyPP: ITeamsFormationRequestBody = {
      gameState: 'PP',
      metrics: ['gf60'],
    };
    const requestBodySH: ITeamsFormationRequestBody = {
      gameState: 'SH',
      metrics: ['ga60'],
    };

    const teamMetricPromises = Array.from({ length: 2 }).reduce<Promise<any>[]>((acc, _, i) => {
      acc.push(
        dispatch(
          getDashboardFormations({
            competitionsUuids: competitionIds,
            body: requestBodyNormal,
            teamUuid: teamId,
            isLeagueRanking: i === 1,
          }),
        ),
      );
      acc.push(
        dispatch(
          getDashboardFormations({
            competitionsUuids: competitionIds,
            body: requestBodyPP,
            teamUuid: teamId,
            isLeagueRanking: i === 1,
          }),
        ),
      );
      acc.push(
        dispatch(
          getDashboardFormations({
            competitionsUuids: competitionIds,
            body: requestBodySH,
            teamUuid: teamId,
            isLeagueRanking: i === 1,
          }),
        ),
      );
      return acc;
    }, []);

    Promise.allSettled(teamMetricPromises).then(() => dispatch(setIsShortTableLoading(false)));
  };

  const fetchGoalkeeperMetrics = (competitionIds: string[], teamId: string) => {
    const requestBodyGoakeeperTeamStats: IGoalkeeperGoalStatsRequestBody = {
      metrics: ['gsax'],
    };

    for (let i = 0; i < 2; i++) {
      dispatch(
        getGoalkeeperTeamStats({
          competitionsUuids: competitionIds.join(','),
          teamUuid: teamId,
          body: requestBodyGoakeeperTeamStats,
          isLeagueRanking: i === 1,
        }),
      );
    }
  };

  return { fetchTeamMetrics, fetchGoalkeeperMetrics };
};
