import {
  enterExitLocationOptions,
  entryTypeOptions,
  exitTypeOptions,
  netZoneOptions,
  puckGainTypeOptions,
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationExtendedOptions,
  shotTypeOptions,
} from '../../../../../constants';
import {
  setDataSettingsEnterExitLocation,
  setDataSettingsEntryType,
  setDataSettingsExitType,
  setDataSettingsGameActionType,
  setDataSettingsNetZone,
  setDataSettingsPuckGainType,
  setDataSettingsShotDanger,
  setDataSettingsShotLocation,
  setDataSettingsShotType,
} from '../../../../../features';
import { ITranslationKeys } from '../../../../../i18n/types';
import {
  IDataTypeParameters,
  IDatasetMetric,
  IDatasetRecord,
  IDefaultDatasetRecord,
  IDefaultDefinedDatasets,
  IDefinedDatasets,
  IDefinedTemplatesCheckboxSectionOption,
  IDefinedTemplatesCheckboxSectionProps,
  IEntity,
  ILanguageType,
  IMetric,
  IMetricCategory,
  IMetrics,
  INetZoneUnion,
  ISelectOption,
  ISelectOptionWithParam,
  ISubgroupsMetricsRecord,
} from '../../../../../types';
import { getMetricsGroupOptions, getMetricsGroupsBase } from '../../../../../utils';

export const getMetricsByActiveSection = (activeSection: IEntity, metrics?: IMetrics) => {
  if (!metrics) return;
  if (activeSection === IEntity.players) return metrics.players;
  if (activeSection === IEntity.goalkeepers) return metrics.goalkeepers;
  return metrics.teams;
};

export const transformSelectedMetricsToSelectOptions = (
  datasetRecord: IDatasetRecord | IDefaultDatasetRecord,
  metricsBySection: IMetricCategory,
  activeTemplate: ISelectOption | undefined,
) => {
  if (!activeTemplate) return [];
  const definedDataset = datasetRecord[activeTemplate.value];
  if (!definedDataset) return [];

  return Object.values(definedDataset.datasetMetrics).reduce<
    ISelectOptionWithParam<IDatasetMetric>[]
  >((acc, metric) => {
    const metricBySection = Object.values(metricsBySection.byId).find(
      metricBySection => metricBySection.id === metric.origin,
    );
    if (!metricBySection) return acc;

    const datasetMetric: ISelectOptionWithParam<IDatasetMetric> = {
      label: metricBySection.metricInfo.name,
      value: metric.code,
      additionalInfo: {
        code: metric.code,
        origin: metric.origin,
        parameter: metric.parameter,
        value: metric.value,
      },
    };
    acc.push(datasetMetric);
    return acc;
  }, []);
};

export const transformDataSettingsToSelectOptions = (
  gameActionType: ISelectOption,
  shotType: ISelectOption,
  shotDanger: ISelectOption,
  shotLocation: ISelectOption,
  netZone: INetZoneUnion | undefined,
  puckGainType: ISelectOption,
  entryType: ISelectOption,
  exitType: ISelectOption,
  enterExitLocation: ISelectOption,
): IDataTypeParameters[] => [
  {
    label: ITranslationKeys.attackType,
    parameter: 'action_type',
    options: shotGameActionTypeOptions,
    selectedOption: gameActionType,
    setFunction: setDataSettingsGameActionType,
  },
  {
    label: ITranslationKeys.shotTypes,
    parameter: 'shot_type',
    options: shotTypeOptions,
    selectedOption: shotType,
    setFunction: setDataSettingsShotType,
  },
  {
    label: ITranslationKeys.shotsDanger,
    parameter: 'shot_danger',
    options: shotDangerOptions,
    selectedOption: shotDanger,
    setFunction: setDataSettingsShotDanger,
  },
  {
    label: ITranslationKeys.shotLocation,
    parameter: 'shot_location',
    options: shotLocationExtendedOptions,
    selectedOption: shotLocation,
    setFunction: setDataSettingsShotLocation,
  },
  {
    label: ITranslationKeys.netZones,
    parameter: 'net_zones',
    options: netZoneOptions,
    selectedOption: netZone,
    setFunction: setDataSettingsNetZone,
  },
  {
    label: ITranslationKeys.puckGainType,
    parameter: 'puck_gain_type',
    options: puckGainTypeOptions,
    selectedOption: puckGainType,
    setFunction: setDataSettingsPuckGainType,
  },
  {
    label: ITranslationKeys.entryType,
    parameter: 'entry_type',
    options: entryTypeOptions,
    selectedOption: entryType,
    setFunction: setDataSettingsEntryType,
  },
  {
    label: ITranslationKeys.exitType,
    parameter: 'exit_type',
    options: exitTypeOptions,
    selectedOption: exitType,
    setFunction: setDataSettingsExitType,
  },
  {
    label: ITranslationKeys.enterExitLocation,
    parameter: 'enter_exit_location',
    options: enterExitLocationOptions,
    selectedOption: enterExitLocation,
    setFunction: setDataSettingsEnterExitLocation,
  },
];

export const mapDatasetGroupOptions = (
  activeLanguage: string,
  activeSection: IEntity,
  definedDatasets?: IDefinedDatasets | IDefaultDefinedDatasets,
  metrics?: IMetrics,
): ISelectOption[] => {
  if (!definedDatasets || !metrics) return [];

  const metricsBySection = getMetricsByActiveSection(activeSection, metrics);
  if (!metricsBySection) return [];

  const datasetsGroupsBase = getMetricsGroupsBase(metricsBySection);
  const datasetsGroupOptions = getMetricsGroupOptions(datasetsGroupsBase, false, activeLanguage);
  return datasetsGroupOptions;
};

const getLabelByActiveLanguage = (
  label: ILanguageType<string> | undefined,
  activeLanguage: string,
) => {
  if (!label) return '';
  return activeLanguage === 'cz' ? label.cz : label.en;
};

const mapMetricToSubOption = (metric: IMetric): ISelectOption => {
  const { metricInfo } = metric;
  return {
    value: metricInfo.code,
    label: metricInfo.name,
  };
};

export const mapSectionedSubgroupMetrics = (
  activeLanguage: string,
  subgroupMetrics: ISubgroupsMetricsRecord,
  filteredMetricsOptions: ISelectOptionWithParam<IDatasetMetric>[],
  selectedMetrics: ISelectOptionWithParam<IDatasetMetric>[],
  individualName: ITranslationKeys,
  onIceDataName: ITranslationKeys,
): IDefinedTemplatesCheckboxSectionProps[] =>
  Object.entries(subgroupMetrics).map<IDefinedTemplatesCheckboxSectionProps>(
    ([sectionName, sectionMetrics]) => ({
      label: sectionName === 'individual' ? individualName : onIceDataName,
      filteredMetricsOptions,
      selectedMetrics,
      options: Object.values(sectionMetrics).map<IDefinedTemplatesCheckboxSectionOption>(
        subgroup => ({
          label: getLabelByActiveLanguage(subgroup.label, activeLanguage) as ITranslationKeys,
          options: Object.values(subgroup.metrics).map(metric => mapMetricToSubOption(metric)),
        }),
      ),
    }),
  );
