import { FC } from 'react';

import { IAlertColors, IDashboardGame, IDashboardTeam } from '../../types';
import {
  createClassNames,
  formatDateByLanguage,
  transformObjectKeysToKebabCase,
} from '../../utils';
import './DashboardGameButton.styles.scss';

export interface IDashboardGameButtonProps extends IDashboardGame {
  /** Displays content relative to the team id */
  displayForTeamId: string;
  /** Is game selected? */
  isActive?: boolean;
  /** Click callback */
  onClick?: () => void;
}

const classNames = createClassNames('dashboard-game-button');

/** UI component used in the dashboard to display game options. */
export const DashboardGameButton: FC<IDashboardGameButtonProps> = ({
  date,
  score,
  displayForTeamId,
  awayTeam,
  homeTeam,
  isActive,
  onClick,
}) => {
  const actualTeam = homeTeam.logo === displayForTeamId ? homeTeam : awayTeam;
  const opponentTeam = actualTeam.logo === homeTeam.logo ? awayTeam : homeTeam;
  const scoreState =
    score.state === 'overtime' ? 'p' : score.state === 'shooting' ? 'sn' : undefined;

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          positive: actualTeam.score > opponentTeam.score,
          isActive,
        }),
      })}
      onClick={onClick}
    >
      <p className={classNames('date')}>{formatDateByLanguage(new Date(date), 'P')}</p>
      <p className={classNames('teams')}>
        {homeTeam.shortcut} - {awayTeam.shortcut}
      </p>
      <p className={classNames('score', { positive: actualTeam.score > opponentTeam.score })}>
        {homeTeam.score}:{awayTeam.score}
        {scoreState}
      </p>
      <div className={classNames('bar')}>
        <div
          className={classNames('bar__inner', { color: getBarColor(actualTeam) })}
          style={{ width: `${actualTeam.xW}%` }}
        ></div>
      </div>
      <p className={classNames('bar__label')}>
        xW: <span>{Math.round(actualTeam.xW)}%</span>
      </p>
    </div>
  );
};

const getBarColor = (actualTeam: IDashboardTeam) => {
  const xw = Math.round(actualTeam.xW);
  if (xw < 55 && xw > 45) return IAlertColors.default;
  if (xw > 54) return IAlertColors.success;
  return IAlertColors.danger;
};
