import { createSelector } from '@reduxjs/toolkit';

import { ISelectOption } from '../../types';
import {
  createGameEntity,
  filterByEntity,
  filterBySelectedGames,
  filterBySelectedPlayerItems,
  filterShotsByCategory,
  filterShotsByDanger,
  filterShotsByGameActionType,
  filterShotsByNetZones,
  filterShotsByType,
} from '../../utils';
import {
  selectMainFilter,
  selectMetricParamsFilter,
  selectShots,
  selectVideoCenter,
  selectVideomapsFilter,
} from '../selectors';

export const filteredShotsByEntitySelector = createSelector(
  [selectVideomapsFilter, selectShots, selectVideoCenter],
  ({ entity }, { shots, shotsAgainst }, { formationsConfig }) =>
    filterByEntity(entity, shots, shotsAgainst, formationsConfig?.isOpponent),
);

export const filteredShotsBySelectedPlayerItemsSelector = createSelector(
  [selectVideomapsFilter, selectMainFilter, filteredShotsByEntitySelector],
  ({ entity }, { selectedPlayerItems }, filteredShotsByEntity) =>
    filterBySelectedPlayerItems(entity, filteredShotsByEntity, selectedPlayerItems),
);

export const filteredShotsByGamesSelector = createSelector(
  [
    selectMainFilter,
    filteredShotsByEntitySelector,
    (_, customGames?: ISelectOption[]) => customGames,
  ],
  ({ selectedGames }, filteredShotsByEntity, customGames) =>
    filterBySelectedGames(filteredShotsByEntity, selectedGames, customGames),
);

export const filteredShotsByCategorySelector = createSelector(
  [selectVideomapsFilter, filteredShotsByEntitySelector],
  (videomapsFilterState, filteredShotsByEntity) =>
    filterShotsByCategory(videomapsFilterState.shotCategory, filteredShotsByEntity),
);

export const filteredShotsByLocationSelector = createSelector(
  [selectMetricParamsFilter, filteredShotsByEntitySelector],
  ({ shotLocation }, filteredShotsByEntity) => {
    if (shotLocation.value === 'slot') {
      return filteredShotsByEntity.filter(shot => shot.inSlot);
    }
    if (shotLocation.value === 'sslot') {
      return filteredShotsByEntity.filter(shot => shot.innerSlot);
    }

    return filteredShotsByEntity;
  },
);

export const filteredShotsByTypeSelector = createSelector(
  [selectMetricParamsFilter, filteredShotsByEntitySelector],
  (metricParamsFilterState, filteredShotsByEntity) =>
    filterShotsByType(filteredShotsByEntity, metricParamsFilterState.shotType),
);

export const filteredShotsByDangerSelector = createSelector(
  [selectMetricParamsFilter, filteredShotsByEntitySelector],
  (metricParamsFilterState, filteredShotsByEntity) =>
    filterShotsByDanger(metricParamsFilterState.shotDanger, filteredShotsByEntity),
);

export const filteredShotsByGameActionTypeSelector = createSelector(
  [selectMetricParamsFilter, filteredShotsByEntitySelector],
  (metricParamsFilterState, filteredShotsByEntity) =>
    filterShotsByGameActionType(metricParamsFilterState.gameActionType, filteredShotsByEntity),
);

export const filteredShotsByNetZoneSelector = createSelector(
  [selectMetricParamsFilter, filteredShotsByEntitySelector],
  (metricParamsFilterState, filteredShotsByEntity) =>
    filterShotsByNetZones(filteredShotsByEntity, metricParamsFilterState.netZones),
);

export const filteredShotsExceptNetZonesSelector = createSelector(
  [
    filteredShotsByGamesSelector,
    filteredShotsBySelectedPlayerItemsSelector,
    filteredShotsByCategorySelector,
    filteredShotsByLocationSelector,
    filteredShotsByTypeSelector,
    filteredShotsByDangerSelector,
    filteredShotsByGameActionTypeSelector,
  ],
  (
    filteredShotsByGames,
    filteredShotsBySelectedPlayerItems,
    filteredShotsByCategory,
    filteredShotsByLocation,
    filteredShotsByType,
    filteredShotsByDanger,
    filteredShotsByGameActionType,
  ) => {
    const filteredShotsByAll = filteredShotsByGames.filter(shot => {
      return (
        filteredShotsBySelectedPlayerItems.includes(shot) &&
        filteredShotsByCategory.includes(shot) &&
        filteredShotsByLocation.includes(shot) &&
        filteredShotsByType.includes(shot) &&
        filteredShotsByDanger.includes(shot) &&
        filteredShotsByGameActionType.includes(shot)
      );
    });

    return filteredShotsByAll;
  },
);

export const filteredShotsSelector = createSelector(
  [filteredShotsExceptNetZonesSelector, filteredShotsByNetZoneSelector],
  (filteredShotsExceptNetZones, filteredShotsByNetZone) => {
    const filteredShotsByAll = filteredShotsExceptNetZones.filter(shot => {
      return filteredShotsByNetZone.includes(shot);
    });

    return filteredShotsByAll;
  },
);

export const filteredShotsGameEntitySelector = createSelector(
  [selectMainFilter, filteredShotsSelector],
  ({ selectedGames }, filteredShots) => createGameEntity(selectedGames, filteredShots),
);

export const filteredShotsExceptGameActionSelector = createSelector(
  [
    filteredShotsByGamesSelector,
    filteredShotsByCategorySelector,
    filteredShotsByLocationSelector,
    filteredShotsByTypeSelector,
    filteredShotsByDangerSelector,
    filteredShotsByNetZoneSelector,
  ],
  (
    filteredShotsByGames,
    filteredShotsByCategory,
    filteredShotsByLocation,
    filteredShotsByType,
    filteredShotsByDanger,
    filteredShotsByNetZone,
  ) => {
    const filteredShotsByAll = filteredShotsByGames.filter(shot => {
      return (
        filteredShotsByCategory.includes(shot) &&
        filteredShotsByLocation.includes(shot) &&
        filteredShotsByType.includes(shot) &&
        filteredShotsByDanger.includes(shot) &&
        filteredShotsByNetZone.includes(shot)
      );
    });

    return filteredShotsByAll;
  },
);

export const filteredShotsExceptDangerSelector = createSelector(
  [
    filteredShotsByGamesSelector,
    filteredShotsByCategorySelector,
    filteredShotsByLocationSelector,
    filteredShotsByTypeSelector,
    filteredShotsByNetZoneSelector,
    filteredShotsByGameActionTypeSelector,
  ],
  (
    filteredShotsByGames,
    filteredShotsByCategory,
    filteredShotsByLocation,
    filteredShotsByType,
    filteredShotsByNetZone,
    filteredShotsByActionType,
  ) => {
    const filteredShotsByAll = filteredShotsByGames.filter(shot => {
      return (
        filteredShotsByCategory.includes(shot) &&
        filteredShotsByLocation.includes(shot) &&
        filteredShotsByType.includes(shot) &&
        filteredShotsByActionType.includes(shot) &&
        filteredShotsByNetZone.includes(shot)
      );
    });

    return filteredShotsByAll;
  },
);

export const filteredShotsVideoMetricSelector = createSelector(
  [
    filteredShotsByCategorySelector,
    filteredShotsByLocationSelector,
    filteredShotsByTypeSelector,
    filteredShotsByDangerSelector,
    filteredShotsByGameActionTypeSelector,
    filteredShotsByNetZoneSelector,
  ],
  (
    filteredShotsByCategory,
    filteredShotsByLocation,
    filteredShotsByType,
    filteredShotsByDanger,
    filteredShotsByGameActionType,
    filteredShotsByNetZone,
  ) => {
    const filteredShotsByAll = filteredShotsByCategory.filter(shot => {
      return (
        filteredShotsByLocation.includes(shot) &&
        filteredShotsByType.includes(shot) &&
        filteredShotsByDanger.includes(shot) &&
        filteredShotsByGameActionType.includes(shot) &&
        filteredShotsByNetZone.includes(shot)
      );
    });

    return filteredShotsByAll;
  },
);

export const filteredShotsDataSelector = createSelector(
  [
    filteredShotsSelector,
    filteredShotsGameEntitySelector,
    filteredShotsByGameActionTypeSelector,
    filteredShotsExceptGameActionSelector,
    filteredShotsExceptDangerSelector,
    filteredShotsExceptNetZonesSelector,
  ],
  (
    filteredShots,
    filteredShotsGameEntity,
    filteredShotsByGameActionType,
    filteredShotsExceptGameAction,
    filteredShotsExceptDanger,
    filteredShotsExceptNetZones,
  ) => {
    return {
      filteredShots,
      filteredShotsGameEntity,
      filteredShotsByGameActionType,
      filteredShotsExceptGameAction,
      filteredShotsExceptDanger,
      filteredShotsExceptNetZones,
    };
  },
);
