import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IGame, IStandingsTableData } from '../../../types';
import { createClassNames } from '../../../utils';
import './FormColumn.styles.scss';

export interface IFormColumnRowProps extends CellContext<IStandingsTableData, any> {
  games: IGame[];
  teamId: string;
}

const classNames = createClassNames('form-column-cell');

export const FormColumnCell: FC<IFormColumnRowProps> = ({ games, teamId }, props) => {
  const { t } = useTranslation();

  return (
    <div className={classNames()}>
      {games.map(game => {
        const isHomeTeam = game.homeTeamId === teamId;
        const isPositive = isHomeTeam
          ? game.score.home > game.score.away
          : game.score.away > game.score.home;

        return (
          <div key={game.id} className={classNames('box', { positive: isPositive })}>
            {t(isPositive ? ITranslationKeys.winShortcut : ITranslationKeys.lossShortcut)}
          </div>
        );
      })}
    </div>
  );
};
