import { CSSProperties, FC } from 'react';

import { useSimpleHeatmap } from '../../../hooks';
import { ICoordinates, IHeatmapGradient } from '../../../types';
import { calculateHeatmapPointPositionToPercent, createClassNames } from '../../../utils';
import './Heatmap.styles.scss';

export interface IHeatmapProps {
  data: ICoordinates[];
  width?: number;
  height?: number;
  style?: CSSProperties;
  radius?: number;
  blur?: number;
  gradient?: IHeatmapGradient;
}

const classNames = createClassNames('heatmap-component');

export const Heatmap: FC<IHeatmapProps> = ({
  data,
  width = 760,
  height = 582,
  style,
  radius,
  blur = 33,
  gradient,
}) => {
  const heatData: [number, number, number][] = data.map(item => {
    const { x, y } = calculateHeatmapPointPositionToPercent(
      { x: item.x, y: item.y },
      height,
      width,
    );
    return [y, x, 1];
  });

  const r =
    radius ??
    Math.exp(2.7995655 + 0.0000000715628 * Math.pow(data.length, 2) - 0.000545733 * data.length);

  const canvasRef = useSimpleHeatmap(heatData, r, blur, gradient);

  return (
    <canvas
      className={classNames()}
      data-testid='heatmap-component'
      style={style}
      ref={canvasRef}
      {...{ width, height }}
    />
  );
};
