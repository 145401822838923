import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../../../services/axiosInstance';
import {
  ICategoryDataset,
  IDataset,
  IDatasetMetricRecord,
  IDatasetRecord,
  IDefinedDatasetsWithDefault,
  IEntity,
} from '../../../../types';
import { defaultCatchErrorCallback } from '../../../../utils';
import { putDefinedDatasetsRequest } from '../utils';
import { IRemoveAfterAPIChange } from './types';

interface ICategoryAndDefinedDatasets extends IRemoveAfterAPIChange {
  category: IEntity;
}

export interface ICreateDataset extends ICategoryAndDefinedDatasets {
  cz: string;
  en: string;
}

export interface IUpdateDataset extends ICreateDataset {
  id: string;
}

export interface IUpdateDatasetDisable extends ICategoryAndDefinedDatasets {
  id: string;
  disabled: boolean;
}

export interface IUpdateDatasetOrder extends ICategoryAndDefinedDatasets {
  newAllIds: string[];
}

export interface IUpdateDatasetMetrics extends ICategoryAndDefinedDatasets {
  id: string;
  metricsRecord: IDatasetMetricRecord;
}

export const getDefinedDatasets = createAsyncThunk<IDefinedDatasetsWithDefault>(
  'settings/definedDatasets/getDefinedDatasets',
  async () => {
    const definedDatasets: IDefinedDatasetsWithDefault = await axiosInstance
      .get(`/setting/template`)
      .then(response => response.data)
      .catch(defaultCatchErrorCallback);

    return definedDatasets;
  },
);

export const putDefinedDatasets = createAsyncThunk<
  IDefinedDatasetsWithDefault | undefined,
  IRemoveAfterAPIChange
>(
  'settings/definedDatasets/putDefinedDatasets',
  async ({ definedDatasets, defaultTemplates }) =>
    await putDefinedDatasetsRequest({ ...definedDatasets, defaultTemplates }),
);

export const createDataset = createAsyncThunk<
  IDefinedDatasetsWithDefault | undefined,
  ICreateDataset
>(
  'settings/definedDatasets/createDataset',
  async ({ category, cz, en, definedDatasets, defaultTemplates }) => {
    const uniqueId = Math.floor(Date.now() * Math.random());
    const id = `${uniqueId}-${Date.now()}`;

    const newDataset: IDataset = {
      id,
      name: { cz, en },
      datasetMetrics: {},
      disabled: true,
    };
    const newCategoryDataset: ICategoryDataset = {
      byId: {
        ...definedDatasets[category].byId,
        [id]: newDataset,
      },
      allIds: [...definedDatasets[category].allIds, id],
    };

    return await putDefinedDatasetsRequest({
      ...definedDatasets,
      defaultTemplates,
      [category]: newCategoryDataset,
    });
  },
);

export const updateDataset = createAsyncThunk<
  IDefinedDatasetsWithDefault | undefined,
  IUpdateDataset
>(
  'settings/definedDatasets/updateDataset',
  async ({ id, category, cz, en, definedDatasets, defaultTemplates }) => {
    const updatedDataset: IDataset = {
      ...definedDatasets[category].byId[id],
      name: { cz, en },
    };
    const updatedCategoryDataset: ICategoryDataset = {
      byId: {
        ...definedDatasets[category].byId,
        [id]: updatedDataset,
      },
      allIds: definedDatasets[category].allIds,
    };

    return await putDefinedDatasetsRequest({
      ...definedDatasets,
      defaultTemplates,
      [category]: updatedCategoryDataset,
    });
  },
);

export const deleteDataset = createAsyncThunk<
  IDefinedDatasetsWithDefault | undefined,
  Omit<IUpdateDatasetDisable, 'disabled'>
>(
  'settings/definedDatasets/deleteDataset',
  async ({ id, category, definedDatasets, defaultTemplates }) => {
    const updatedDatasetRecord = Object.values(definedDatasets[category].byId)
      .filter(dataset => dataset.id !== id)
      .reduce<IDatasetRecord>((acc, dataset) => {
        acc[dataset.id] = dataset;
        return acc;
      }, {});

    const updatedCategoryDataset: ICategoryDataset = {
      byId: updatedDatasetRecord,
      allIds: definedDatasets[category].allIds.filter(datasetId => datasetId !== id),
    };

    return await putDefinedDatasetsRequest({
      ...definedDatasets,
      defaultTemplates,
      [category]: updatedCategoryDataset,
    });
  },
);

export const updateDatasetDisable = createAsyncThunk<
  IDefinedDatasetsWithDefault | undefined,
  IUpdateDatasetDisable
>(
  'settings/definedDatasets/updateDatasetDisable',
  async ({ id, category, disabled, definedDatasets, defaultTemplates }) => {
    const updatedDataset: IDataset = {
      ...definedDatasets[category].byId[id],
      disabled,
    };
    const updatedCategoryDataset: ICategoryDataset = {
      byId: {
        ...definedDatasets[category].byId,
        [id]: updatedDataset,
      },
      allIds: definedDatasets[category].allIds,
    };

    return await putDefinedDatasetsRequest({
      ...definedDatasets,
      defaultTemplates,
      [category]: updatedCategoryDataset,
    });
  },
);

export const updateDatasetOrder = createAsyncThunk<
  IDefinedDatasetsWithDefault | undefined,
  IUpdateDatasetOrder
>(
  'settings/definedDatasets/updateDatasetOrder',
  async ({ newAllIds, category, definedDatasets, defaultTemplates }) => {
    const updatedCategoryDataset: ICategoryDataset = {
      byId: definedDatasets[category].byId,
      allIds: newAllIds,
    };

    return await putDefinedDatasetsRequest({
      ...definedDatasets,
      defaultTemplates,
      [category]: updatedCategoryDataset,
    });
  },
);

export const updateDatasetMetrics = createAsyncThunk<
  IDefinedDatasetsWithDefault | undefined,
  IUpdateDatasetMetrics
>(
  'settings/definedDatasets/updateDatasetMetrics',
  async ({ id, category, metricsRecord, definedDatasets, defaultTemplates }) => {
    const updatedDataset: IDataset = {
      ...definedDatasets[category].byId[id],
      datasetMetrics: metricsRecord,
    };
    const updatedCategoryDataset: ICategoryDataset = {
      byId: {
        ...definedDatasets[category].byId,
        [id]: updatedDataset,
      },
      allIds: definedDatasets[category].allIds,
    };

    return await putDefinedDatasetsRequest({
      ...definedDatasets,
      defaultTemplates,
      [category]: updatedCategoryDataset,
    });
  },
);
