import {
  IDumpInOutDTO,
  IDumpInOutEntityDTO,
  IZoneEntity,
  IZoneEntityDTO,
  IZoneLocation,
  IZones,
  IZonesDTO,
} from '../../types';

export const parseZoneData = (data: IZonesDTO): IZones => ({
  for: [
    ...parseZoneEntityData(data.for.left, 'left'),
    ...parseZoneEntityData(data.for.center, 'center'),
    ...parseZoneEntityData(data.for.right, 'right'),
  ],
  against: [
    ...parseZoneEntityData(data.against.left, 'left'),
    ...parseZoneEntityData(data.against.center, 'center'),
    ...parseZoneEntityData(data.against.right, 'right'),
  ],
});

export const parseDumpInOutData = (data: IDumpInOutDTO): IZones => ({
  for: [
    ...parseDumpInOutEntityData(data.for.left, 'left'),
    ...parseDumpInOutEntityData(data.for.center, 'center'),
    ...parseDumpInOutEntityData(data.for.right, 'right'),
  ],
  against: [
    ...parseDumpInOutEntityData(data.against.left, 'left'),
    ...parseDumpInOutEntityData(data.against.center, 'center'),
    ...parseDumpInOutEntityData(data.against.right, 'right'),
  ],
});

const parseZoneEntityData = (data: IZoneEntityDTO[], location: IZoneLocation): IZoneEntity[] =>
  data.map((entity, index) => ({
    id: index,
    playerId: entity.player,
    matchId: entity.match,
    matchDate: entity.matchDate,
    homeTeamId: entity.homeTeam,
    awayTeamId: entity.awayTeam,
    videoId: entity.videoId,
    videoTime: entity.videoTime,
    time: entity.time,
    realTime: entity.realTime,
    gameState: entity.gameState,
    score: entity.score,
    isDumpoutOrDumpin: false,
    location,
    finish: getFinish(entity),
    xG: entity.shot?.xG,
    shotInSlot: entity.shot?.inSlot,
    shotDanger: entity.shot?.shotDanger,
    shot: entity.shot,
    followingEvent: entity.followingEvent,
    blocker: entity.blocker,
    successful: entity.successful,
    type: entity.type,
    passingPlayer: entity.passingPlayer ?? undefined,
    x: entity.x,
    y: entity.y,
    zoneEntry: entity.zoneEntry
      ? {
          playerId: entity.zoneEntry.player,
          teamId: entity.zoneEntry.team,
        }
      : undefined,
  }));

const parseDumpInOutEntityData = (
  data: IDumpInOutEntityDTO[],
  location: IZoneLocation,
): IZoneEntity[] =>
  data.map((entity, index) => ({
    id: index,
    playerId: entity.player,
    matchId: entity.match,
    matchDate: entity.matchDate,
    homeTeamId: entity.homeTeam,
    awayTeamId: entity.awayTeam,
    videoId: entity.videoId,
    videoTime: entity.videoTime,
    time: entity.time,
    realTime: entity.realTime,
    gameState: entity.gameState,
    score: entity.score,
    isDumpoutOrDumpin: true,
    location,
    finish: entity.finish,
    xG: entity.xG,
    shotInSlot: entity.shotInSlot,
    shotDanger: entity.shotDanger,
    passingPlayer: entity.passingPlayer ?? undefined,
    battle: entity.battle,
    battleWon: entity.battleWon,
    wonTeam: entity.wonTeam,
  }));

const getFinish = (entity: IZoneEntityDTO) => {
  if (entity.shot) {
    if (entity.shot.type === 'G') {
      return 'goal';
    }

    if (entity.shot.inSlot) {
      return 'slotShot';
    }

    return 'shot';
  }

  return undefined;
};
