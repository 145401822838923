import { useAppDispatch } from '../../../app/hooks';
import {
  faceoffDefensiveZoneOption,
  faceoffNeutralZoneOption,
  faceoffOffensiveZoneOption,
  faceoffResultLostOption,
  faceoffResultWonOption,
  goalFollowedOption,
  shotAttemptFollowedOption,
} from '../../../constants';
import { setFaceoffFollowedBy, setFaceoffResult, setFaceoffZone } from '../../../features';
import { IMetricWithDatasetMetric } from '../../../types';

export const useSetFaceoffsFilter = () => {
  const dispatch = useAppDispatch();

  const setFaceoffsFilterSetter = (stat: IMetricWithDatasetMetric) => {
    const isParameter = stat.metric.id.includes('.');
    const [parameter, origin] = stat.metric.id.split('.');

    const mainMetric = isParameter ? origin : stat.metric.id;

    if (isParameter) {
      const param =
        parameter.startsWith('xgf_') || parameter.startsWith('xga_')
          ? parameter.replace(/xgf_|xga_/, '')
          : parameter;

      if (param === 'dz') {
        dispatch(setFaceoffZone(faceoffDefensiveZoneOption));
      } else if (param === 'nz') {
        dispatch(setFaceoffZone(faceoffNeutralZoneOption));
      } else if (param === 'oz') {
        dispatch(setFaceoffZone(faceoffOffensiveZoneOption));
      }
    }

    if (mainMetric.startsWith('fow')) {
      dispatch(setFaceoffResult(faceoffResultWonOption));

      if (mainMetric.includes('cf')) {
        dispatch(setFaceoffFollowedBy(shotAttemptFollowedOption));
      }

      if (mainMetric.includes('gf')) {
        dispatch(setFaceoffFollowedBy(goalFollowedOption));
      }
    }

    if (mainMetric.startsWith('fol')) {
      dispatch(setFaceoffResult(faceoffResultLostOption));

      if (mainMetric.includes('ca')) {
        dispatch(setFaceoffFollowedBy(shotAttemptFollowedOption));
      }

      if (mainMetric.includes('ga')) {
        dispatch(setFaceoffFollowedBy(goalFollowedOption));
      }
    }
  };

  return { setFaceoffsFilterSetter };
};
