import { useAppDispatch } from '../../../../app/hooks';
import {
  getDashboardGoalkeepersStats,
  getDashboardTeamsFormation,
  getFormations,
  getGameTeams,
  getGamesTablesPlayers,
} from '../../../../features';
import {
  IDashboardGame,
  IGameReportTeamsRequestBody,
  IGamesTablesStatsRequestBody,
  IGoalkeeperGoalStatsRequestBody,
} from '../../../../types';
import {
  dashboardGoalkeeperTableMetrics,
  dashboardSpecialTableMetrics,
  dashboardTeamFormationTableMetrics,
} from '../../constants';

export const useFetchTable = () => {
  const dispatch = useAppDispatch();

  const fetchTable = (competitionIds: string[], teamId: string, selectedGame: IDashboardGame) => {
    const requestBodyGoakeeperTeamStats: IGoalkeeperGoalStatsRequestBody = {
      metrics: dashboardGoalkeeperTableMetrics,
    };
    const requestBodyTeamFormationFOStats: IGamesTablesStatsRequestBody = {
      gameState: '5:5-FO',
      metrics: dashboardTeamFormationTableMetrics,
    };
    const requestBodyTeamFormationDEStats: IGamesTablesStatsRequestBody = {
      gameState: '5:5-DE',
      metrics: dashboardTeamFormationTableMetrics,
    };
    const requestBodySpecialPP: IGameReportTeamsRequestBody = {
      metrics: dashboardSpecialTableMetrics,
      gameState: 'PP',
    };
    const requestBodySpecialSH: IGameReportTeamsRequestBody = {
      metrics: dashboardSpecialTableMetrics,
      gameState: 'SH',
    };

    // Team formation stats
    dispatch(
      getDashboardTeamsFormation({
        competitionsUuids: competitionIds,
        teamUuid: teamId,
        matchUuid: selectedGame.id,
        body: requestBodyTeamFormationFOStats,
      }),
    );
    dispatch(
      getDashboardTeamsFormation({
        competitionsUuids: competitionIds,
        teamUuid: teamId,
        matchUuid: selectedGame.id,
        body: requestBodyTeamFormationDEStats,
      }),
    );
    // Team formations player formation info
    dispatch(
      getGamesTablesPlayers({
        competitionsUuids: competitionIds,
        matchUuid: selectedGame.id,
        teamUuid: teamId,
        body: requestBodyTeamFormationFOStats,
        isFromDashboard: true,
      }),
    );
    dispatch(
      getGamesTablesPlayers({
        competitionsUuids: competitionIds,
        matchUuid: selectedGame.id,
        teamUuid: teamId,
        body: requestBodyTeamFormationDEStats,
        isFromDashboard: true,
      }),
    );

    // Goalkeeper stats
    dispatch(
      getDashboardGoalkeepersStats({
        competitionsUuids: competitionIds,
        teamUuid: teamId,
        matchUuid: selectedGame.id,
        body: requestBodyGoakeeperTeamStats,
      }),
    );

    // Special stats of match
    dispatch(
      getGameTeams({
        competitionsUuids: competitionIds.join(','),
        matchUuid: selectedGame.id,
        teamUuid: teamId,
        body: requestBodySpecialPP,
      }),
    );
    dispatch(
      getGameTeams({
        competitionsUuids: competitionIds.join(','),
        matchUuid: selectedGame.id,
        teamUuid: teamId,
        body: requestBodySpecialSH,
      }),
    );
    // Special stats of whole seazon
    dispatch(
      getFormations({
        competitionsUuids: competitionIds,
        teamUuid: teamId,
        body: requestBodySpecialPP,
      }),
    );
    dispatch(
      getFormations({
        competitionsUuids: competitionIds,
        teamUuid: teamId,
        body: requestBodySpecialSH,
      }),
    );
  };

  return fetchTable;
};
