import {
  ICompetitionTable,
  ICompetitionTableDTO,
  IFormationDTO,
  IGameReportTeamDTO,
  IMinigraphBatteryTeam,
  IMinigraphBatteryTeamMetric,
  IScheduledMatch,
  IScheduledMatchDTO,
  IShortTable,
  IShortTableDTO,
} from '../../types';

export const parseScheduledMatchesData = (data: IScheduledMatchDTO[]): IScheduledMatch[] =>
  data.map<IScheduledMatch>(match => ({
    id: match.id,
    date: match.schedule.date,
    name: match.schedule.name,
  }));

export const parseShortTableData = (data: IShortTableDTO[]): IShortTable[] =>
  data.map<IShortTable>(row => ({
    teamId: row.team,
    order: row.order,
    GP: row.GP,
    PTS: row.PTS,
    'PTS%': row['PTS%'],
    W: row.W,
    WOT: row.WOT,
    LOT: row.LOT,
    L: row.L,
    'GF:GA': row['GF:GA'],
    xGF: row.xGF,
    xGA: row.xGA,
    xPTS: row.xPTS,
    'xPTS%': row['xPTS%'],
  }));

export const parseCompetitionTableData = (data: ICompetitionTableDTO[]): ICompetitionTable[] =>
  data.map<ICompetitionTable>(row => ({
    teamId: row.team,
    order: row.order,
    GP: row.GP,
    PTS: row.PTS,
    'PTS%': row['PTS%'],
    W: row.W,
    WOT: row.WOT,
    LOT: row.LOT,
    L: row.L,
    'GF:GA': row['GF:GA'],
    xGF: row.xGF,
    xGA: row.xGA,
    xPTS: row.xPTS,
    'xPTS%': row['xPTS%'],
  }));

export const parseGoalkeepersTeamData = (
  data: IGameReportTeamDTO,
): IMinigraphBatteryTeamMetric => ({
  metricValue: data.stats['gsax'],
  order: 0,
});

export const parseDashboardFormationsData = (
  data: IFormationDTO,
  teamId: string,
): IMinigraphBatteryTeam => ({
  teamId,
  metricRecord: Object.entries(data).reduce(
    (acc, [key, value]) => {
      acc[key] = {
        metricValue: value,
        order: 0,
      };
      return acc;
    },
    {} as Record<string, IMinigraphBatteryTeamMetric>,
  ),
});
