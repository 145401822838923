import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  selectDashboard,
  selectGames,
  selectMainFilter,
  selectPlayers,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IEntity,
  IGamesTableWithLineupsRecord,
  IGamesTablesFormations,
  IGamesTablesFormationsTableData,
  IMetricWithDatasetMetric,
  IPlayer,
  IPlayerRecord,
  ISelectOption,
  ISimilarPlayerNameRecord,
} from '../../../../types';
import {
  createDatasetMetricFromMetricCode,
  getMetricsWithValue,
  getPlayersTwoRows,
} from '../../../../utils';
import { dashboardTeamFormationTableMetrics } from '../../constants';

const getTableData = (
  formations: IGamesTablesFormations[],
  playerRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  similarPlayerNames: ISimilarPlayerNameRecord,
  formationType?: ISelectOption,
  playersById?: IGamesTableWithLineupsRecord,
  isOffense?: boolean,
) => {
  if (!playersById || !formations) {
    return [];
  }

  const tableData = formations
    .reduce<IGamesTablesFormationsTableData[]>((acc, formation) => {
      const players = formation.playerIds.map<IPlayer>(playerId => playerRecord[playerId]);
      const { firstRow, secondRow } = getPlayersTwoRows(players, similarPlayerNames, formationType);
      const isFormationValid =
        formation.playerIds.reduce((acc, player) => {
          const playerInfo = playersById[player];
          if (!playerInfo) {
            console.warn('Player not found:', player, playerRecord[player]);
            return -1;
          }

          if (acc === 0) {
            acc = playerInfo.line;
          }

          if (acc !== playerInfo.line) {
            return -1;
          }

          return acc;
        }, 0) !== -1;
      if (!isFormationValid) {
        return acc;
      }

      const stats = getMetricsWithValue(metrics, formation.stats);

      acc.push({
        teamId: formation.teamId,
        players: {
          firstRow,
          secondRow,
        },
        gp: formation.stats.gp,
        toi: formation.stats.toi,
        stats,
      });

      return acc;
    }, [])
    .sort((a, b) => {
      const playerA = isOffense
        ? playersById[a.players.firstRow[0].id]
        : playersById[a.players.secondRow[0].id];
      const playerB = isOffense
        ? playersById[b.players.firstRow[0].id]
        : playersById[b.players.secondRow[0].id];
      return playerA.line - playerB.line;
    });

  return tableData;
};

export const useOffenseDataForTable = (teamId: string) => {
  const { formationType } = useAppSelector(selectMainFilter);
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);
  const { table } = useAppSelector(selectDashboard);
  const { gamesTables } = useAppSelector(selectGames);
  const { playersById } = gamesTables;

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IGamesTablesFormationsTableData[] = useMemo(() => {
    if (!table?.[teamId]?.offenseFormation) {
      return [];
    }

    const { offenseFormation } = table[teamId];
    const filteredMetrics = getFilteredMetrics(
      IEntity.players,
      dashboardTeamFormationTableMetrics.map(metric => createDatasetMetricFromMetricCode(metric)),
    );

    if (filteredMetrics) {
      return getTableData(
        offenseFormation,
        byId,
        filteredMetrics,
        similarPlayerNames,
        formationType,
        playersById,
        true,
      );
    }

    return [];
  }, [table, teamId, byId, similarPlayerNames, formationType, playersById, getFilteredMetrics]);

  return data;
};

export const useDefenseDataForTable = (teamId: string) => {
  const { formationType } = useAppSelector(selectMainFilter);
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);
  const { table } = useAppSelector(selectDashboard);
  const { gamesTables } = useAppSelector(selectGames);
  const { playersById } = gamesTables;

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IGamesTablesFormationsTableData[] = useMemo(() => {
    if (!table?.[teamId]?.offenseFormation) {
      return [];
    }

    const { defenseFormation } = table[teamId];
    const filteredMetrics = getFilteredMetrics(
      IEntity.players,
      dashboardTeamFormationTableMetrics.map(metric => createDatasetMetricFromMetricCode(metric)),
    );

    if (filteredMetrics) {
      return getTableData(
        defenseFormation,
        byId,
        filteredMetrics,
        similarPlayerNames,
        formationType,
        playersById,
      );
    }

    return [];
  }, [table, teamId, byId, similarPlayerNames, formationType, playersById, getFilteredMetrics]);

  return data;
};
