import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IDashboardState, IDashboardTeamTable, IHomeAwayTeams } from '../../types';
import { getFormations } from '../formations';
import { getGameTeams } from '../games';
import {
  getCompetitionTable,
  getDashboardFormations,
  getDashboardGamePreview,
  getDashboardGoalkeepersStats,
  getDashboardTeamsFormation,
  getGoalkeeperTeamStats,
  getScheduledMatches,
  getShortTable,
} from './dashboardActions';

const initialState: IDashboardState = {
  // Other
  selectedTeamIds: {} as IHomeAwayTeams<string>,
  // Data
  scheduledMatches: [],
  competitionTable: [],
  shortTable: [],
  gamePreviewsById: {},
  teamBatteryStatsById: {},
  table: {},
  // Data Ids
  gamePreviewsAllIds: [],
  teamStatsAllIds: [],
  // Loading
  areScheduledMatchesLoading: false,
  isCompetitionTableLoading: false,
  isShortTableLoading: false,
  areTeamStatsLoading: false,
  areGamePreviewsLoading: false,
  // Errors
  scheduledMatchesError: undefined,
  competitionTableError: undefined,
  shortTableError: undefined,
  teamStatsError: undefined,
  gamePreviewsError: undefined,
  tableError: undefined,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setIsShortTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isShortTableLoading = action.payload;
    },
    setSelectedHomeTeam: (state, action: PayloadAction<string>) => {
      state.selectedTeamIds.homeTeam = action.payload;
    },
    setSelectedAwayTeam: (state, action: PayloadAction<string>) => {
      state.selectedTeamIds.awayTeam = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getScheduledMatches.pending, state => {
        state.areScheduledMatchesLoading = true;
        state.scheduledMatchesError = undefined;
      })
      .addCase(getScheduledMatches.fulfilled, (state, action) => {
        state.scheduledMatches = action.payload;

        if (!action.payload.length) {
          state.scheduledMatchesError = 'No matches found';
        }

        state.areScheduledMatchesLoading = false;
      })
      .addCase(getScheduledMatches.rejected, (state, action) => {
        state.areScheduledMatchesLoading = false;
        state.scheduledMatchesError = action.error.message;
      })
      // Next case
      .addCase(getShortTable.pending, state => {
        state.isShortTableLoading = true;
        state.shortTableError = undefined;
      })
      .addCase(getShortTable.fulfilled, (state, action) => {
        state.shortTable = action.payload;

        if (!action.payload.length) {
          state.shortTableError = 'No data found';
        }
        state.isShortTableLoading = false;
      })
      .addCase(getShortTable.rejected, (state, action) => {
        state.isShortTableLoading = false;
        state.shortTableError = action.error.message;
      })
      // Next case
      .addCase(getCompetitionTable.pending, state => {
        state.isCompetitionTableLoading = true;
        state.shortTableError = undefined;
      })
      .addCase(getCompetitionTable.fulfilled, (state, action) => {
        state.competitionTable = action.payload;

        if (!action.payload.length) {
          state.competitionTableError = 'No data found';
        }

        state.competitionTable = action.payload;

        state.isCompetitionTableLoading = false;
      })
      .addCase(getCompetitionTable.rejected, (state, action) => {
        state.isCompetitionTableLoading = false;
        state.competitionTableError = action.error.message;
      })
      // Next case
      .addCase(getDashboardGamePreview.pending, state => {
        state.areGamePreviewsLoading = true;
        state.gamePreviewsError = undefined;
      })
      .addCase(getDashboardGamePreview.fulfilled, (state, action) => {
        const { meta, payload } = action;
        const teamId = meta.arg.teamUuid;

        if (!Object.keys(payload).length) {
          state.gamePreviewsError = 'No data found';
          return;
        }

        if (!state.gamePreviewsAllIds.includes(teamId)) {
          state.gamePreviewsAllIds.push(teamId);
        }
        if (!state.gamePreviewsById[teamId]) {
          state.gamePreviewsById[teamId] = {};
        }

        state.gamePreviewsById[teamId][payload.matchId] = payload;
        state.areGamePreviewsLoading = false;
      })
      .addCase(getDashboardGamePreview.rejected, (state, action) => {
        state.isShortTableLoading = false;
        state.gamePreviewsError = action.error.message;
      })
      // Next case
      .addCase(getDashboardFormations.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const metrics = meta.arg.body?.metrics;
        if (!metrics) {
          return;
        }

        metrics.forEach(metric => {
          const key =
            meta.arg.body?.gameState && meta.arg.body.gameState !== '5:5'
              ? meta.arg.body.gameState.toLowerCase() + '_' + metric
              : metric;

          if (!Object.keys(payload).length) {
            state.teamStatsError = 'No data found';
            return;
          }

          if (!state.teamBatteryStatsById[payload.teamId]) {
            state.teamStatsAllIds.push(payload.teamId);
            state.teamBatteryStatsById[payload.teamId] = {
              teamId: payload.teamId,
              metricRecord: {},
            };
          }

          if (!state.teamBatteryStatsById[payload.teamId].metricRecord[key]) {
            if (meta.arg.isLeagueRanking) {
              state.teamBatteryStatsById[payload.teamId].metricRecord[key] = {
                metricValue: -1,
                order: payload.metricRecord[metric].metricValue,
              };
            } else {
              state.teamBatteryStatsById[payload.teamId].metricRecord[key] = {
                metricValue: payload.metricRecord[metric].metricValue,
                order: -1,
              };
            }
          } else {
            state.teamBatteryStatsById[payload.teamId].metricRecord[key][
              meta.arg.isLeagueRanking ? 'order' : 'metricValue'
            ] = payload.metricRecord[metric].metricValue;
          }
        });
      })
      // Next case
      .addCase(getGoalkeeperTeamStats.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const teamId = meta.arg.teamUuid;

        if (!Object.keys(payload).length) {
          state.teamStatsError = 'No data found';
          return;
        }

        if (!state.teamBatteryStatsById[teamId]) {
          state.teamStatsAllIds.push(teamId);
          state.teamBatteryStatsById[teamId] = {
            teamId: teamId,
            metricRecord: {},
          };
        }

        if (!state.teamBatteryStatsById[teamId].metricRecord['gsax']) {
          if (meta.arg.isLeagueRanking) {
            state.teamBatteryStatsById[teamId].metricRecord['gsax'] = {
              metricValue: -1,
              order: payload.metricValue,
            };
          } else {
            state.teamBatteryStatsById[teamId].metricRecord['gsax'] = {
              metricValue: payload.metricValue,
              order: -1,
            };
          }
        } else {
          state.teamBatteryStatsById[teamId].metricRecord['gsax'][
            meta.arg.isLeagueRanking ? 'order' : 'metricValue'
          ] = payload.metricValue;
        }

        state.areTeamStatsLoading = false;
      })
      // Next case
      .addCase(getDashboardTeamsFormation.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const gameState = meta.arg.body?.gameState;
        const teamId = meta.arg.teamUuid;

        if (gameState !== '5:5-FO' && gameState !== '5:5-DE') {
          return;
        }

        if (!Object.keys(payload).length) {
          state.tableError = 'No data found';
          return;
        }

        if (!state.table[teamId]) {
          state.table[teamId] = {} as IDashboardTeamTable;
        }

        const formation = payload.filter(team => team.teamId === teamId);
        if (gameState === '5:5-FO') {
          state.table[teamId].offenseFormation = formation;
        }
        if (gameState === '5:5-DE') {
          state.table[teamId].defenseFormation = formation;
        }
      })
      // Next case
      .addCase(getGameTeams.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const gameState = meta.arg.body?.gameState;
        const teamId = meta.arg.teamUuid;

        if (!teamId) {
          return;
        }

        if (!Object.keys(payload).length) {
          state.tableError = 'No data found';
          return;
        }

        if (!state.table[teamId]) {
          state.table[teamId] = {} as IDashboardTeamTable;
        }

        if (gameState === 'PP') {
          state.table[teamId].powerplay = payload;
        }
        if (gameState === 'SH') {
          state.table[teamId].shorthanded = payload;
        }
      })
      // Next case
      .addCase(getDashboardGoalkeepersStats.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const teamId = meta.arg.teamUuid;

        if (!teamId) {
          return;
        }

        if (!Object.keys(payload).length) {
          state.tableError = 'No data found';
          return;
        }

        if (!state.table[teamId]) {
          state.table[teamId] = {} as IDashboardTeamTable;
        }

        state.table[teamId].goalkeepers = payload;
      })
      // Next case
      .addCase(getFormations.fulfilled, (state, action) => {
        const { payload, meta } = action;
        const teamId = meta.arg.teamUuid;
        const gameState = meta.arg.body?.gameState;

        if (!teamId) {
          return;
        }

        if (!Object.keys(payload).length) {
          state.tableError = 'No data found';
          return;
        }

        if (!state.table[teamId]) {
          state.table[teamId] = {} as IDashboardTeamTable;
        }

        if (gameState === 'PP') {
          state.table[teamId].teamStatsPP = payload;
        }
        if (gameState === 'SH') {
          state.table[teamId].teamStatsSH = payload;
        }
      })
      // Matchers
      .addMatcher(
        isAnyOf(getDashboardFormations.pending, getGoalkeeperTeamStats.pending),
        state => {
          state.areTeamStatsLoading = true;
          state.teamStatsError = undefined;
        },
      )
      .addMatcher(
        isAnyOf(getDashboardFormations.rejected, getGoalkeeperTeamStats.rejected),
        (state, action) => {
          state.areTeamStatsLoading = false;
          state.teamStatsError = action.error.message;
        },
      );
  },
});

export const { setIsShortTableLoading, setSelectedAwayTeam, setSelectedHomeTeam } =
  dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
