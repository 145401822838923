import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { RightArrowIcon } from '../../icons';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { Skeleton } from '../Skeleton';
import { Tooltip } from '../Tooltip';
import './Minigraph.styles.scss';

export interface IMinigraphProps {
  /** Label of the minigraph */
  label: ITranslationKeys;
  /** Metric name */
  metricName?: string;
  /** Metric value */
  metricValue: number;
  /** Order of the team in the short table */
  order: number;
  /** Label shown in the tooltip */
  tooltipLabel: ITranslationKeys;
  /** Total number of teams in the short table */
  total: number;
  /** Callback function when the minigraph is clicked */
  onClick: () => void;
}

const classNames = createClassNames('minigraph');

export const Minigraph: FC<IMinigraphProps> = ({
  label,
  metricName,
  metricValue,
  order,
  tooltipLabel,
  total,
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [batteryWidth, setBatteryWidth] = useState(0);
  const [orderNum, setOrderNum] = useState(order > 3 ? order - 3 : 1);
  const { t } = useTranslation();

  const tooltipAdditionalInfo = metricName
    ? ' (' + metricName + ' ' + metricValue + ')'
    : ': ' + metricValue;

  useEffect(() => {
    if (order === undefined || order < 1) {
      return;
    }

    // setting timeout for the animation (css width transition) to start after the component is rendered
    setTimeout(() => {
      setBatteryWidth(
        total === order
          ? getBatteryWidth(total, order, false) / 2
          : getBatteryWidth(total, order, true),
      );
    }, 100);

    // Number counter
    const timerInterval = setInterval(() => {
      setOrderNum(prevTime => {
        if (prevTime < order) {
          return prevTime + 1;
        }

        clearInterval(timerInterval);
        return order;
      });
    }, 200);

    return () => clearInterval(timerInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, total]);

  if (order < 1 || order === undefined) {
    return (
      <div className={classNames()} data-testid='minigraph'>
        <div className={classNames('label')}>
          <Skeleton height={22} borderRadius={8} />
        </div>
        <div className={classNames('battery')}>
          <Skeleton height={40} borderRadius={8} />
        </div>
        <div className={classNames('order')}>?.</div>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          color: getColor(order, total),
          isBatteryFull: order === 1,
          isLoaded: true,
        }),
      })}
      onClick={() => onClick()}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      data-testid='minigraph'
    >
      <div className={classNames('label')}>
        {t(label)} <RightArrowIcon />
      </div>
      <div className={classNames('battery')}>
        <Tooltip
          centerXAxis
          effect='fade'
          children={
            <div
              className={classNames('battery__tooltip', {
                big: label === ITranslationKeys.form || label === ITranslationKeys.goalkeepers,
              })}
            >
              <strong>{t(tooltipLabel)}</strong>
              {tooltipAdditionalInfo}
            </div>
          }
          isShown={showTooltip}
        />
        <div
          className={classNames('battery__battery-value')}
          style={{ width: batteryWidth + '%' }}
        ></div>
      </div>
      <div className={classNames('order')}>{orderNum}.</div>
    </div>
  );
};

/**
 * Returns a color of the minigraph based on the order and the total number of teams with 25% offset.
 */
const getColor = (value: number, total: number) => {
  const offset = Math.floor((total / 100) * 25);
  if (value > total - offset) return 'red';
  if (value < offset + 1) return 'green';
  return 'gray';
};

/**
 * Returns the width of the battery based on the total number of teams and the order
 * @param total - total number of teams
 * @param order - order of the team
 * @param withEmptyBattery - PLAYS ROLE ONLY WHEN total === order
 *  - if true, then the battery will be empty
 *  - else the battery will show one bar
 */
const getBatteryWidth = (total: number, order: number, withEmptyBattery: boolean) => {
  // For some reason when user relogins, total is 0. Should be fixed when new EP is released.
  if (total === 0) {
    return 0;
  }

  return (
    (100 / (total - (withEmptyBattery ? 1 : 0))) * (total - (withEmptyBattery ? 1 : 0) - order + 1)
  );
};
