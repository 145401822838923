import { FC } from 'react';

import { DashboardTable, Skeleton } from '../../../../../../components';
import {
  IDashboardGame,
  IDashboardGoalkeeperTableData,
  IDashboardTeamTableData,
  IGameTeamsTableData,
} from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import {
  useDefenseDataForTable,
  useFormationColumnsConfig,
  useGoalkeeperColumnsConfig,
  useGoalkeeperDataForTable,
  useOffenseDataForTable,
  useSpecialColumnsConfig,
  useSpecialDataForTable,
} from '../../../../hooks';
import './DashboardTeamTables.styles.scss';

interface IDashboardTeamTablesProps {
  selectedDashboardGame: IDashboardGame | undefined;
  teamId: string;
}

const className = createClassNames('dashboard-team-tables');

export const DashboardTeamTables: FC<IDashboardTeamTablesProps> = ({
  teamId,
  selectedDashboardGame,
}) => {
  const dataOffense = useOffenseDataForTable(teamId);
  const dataDefense = useDefenseDataForTable(teamId);
  const dataGoalkeepers = useGoalkeeperDataForTable(teamId);
  const dataSpecial = useSpecialDataForTable(teamId);
  const {
    columns: columnsOffense,
    columnPinning: columnPinningOffense,
    initialSorting,
  } = useFormationColumnsConfig(true);
  const { columns: columnsDefense, columnPinning: columnPinningDefense } =
    useFormationColumnsConfig(false);
  const { columns: columnsGoalkeepers, columnPinning: columnPinningGoalkeepers } =
    useGoalkeeperColumnsConfig(teamId, selectedDashboardGame);
  const { columns: columnsSpecial, columnPinning: columnPinningSpecial } =
    useSpecialColumnsConfig();

  return (
    <div className={className()}>
      <div className={className('table')}>
        <DashboardTable<IDashboardTeamTableData>
          data={dataOffense}
          columns={columnsOffense}
          columnPinning={columnPinningOffense}
          disableBottomBorder
          disableIsSmall
          {...{ initialSorting }}
        />
        {!dataOffense.length &&
          Array.from(Array(4)).map((_, index) => (
            <Skeleton key={'formations-offense-' + index} height={40} />
          ))}
      </div>
      <div className={className('table')}>
        <DashboardTable<IDashboardTeamTableData>
          data={dataDefense}
          columns={columnsDefense}
          columnPinning={columnPinningDefense}
          disableBottomBorder
          disableIsSmall
          {...{ initialSorting }}
        />
        {!dataDefense.length &&
          Array.from(Array(3)).map((_, index) => (
            <Skeleton key={'formations-defense-' + index} height={40} />
          ))}
      </div>
      <div className={className('table')}>
        <DashboardTable<IDashboardGoalkeeperTableData>
          data={dataGoalkeepers}
          columns={columnsGoalkeepers}
          columnPinning={columnPinningGoalkeepers}
          disableBottomBorder
          disableIsSmall
          {...{ initialSorting }}
        />
        {!dataGoalkeepers.length && <Skeleton height={40} />}
      </div>
      <div className={className('table')}>
        <DashboardTable<IGameTeamsTableData>
          data={dataSpecial}
          columns={columnsSpecial}
          columnPinning={columnPinningSpecial}
          disableIsSmall
          {...{ initialSorting }}
        />
        {!dataSpecial.length &&
          Array.from(Array(2)).map((_, index) => <Skeleton key={'special-' + index} height={40} />)}
      </div>
    </div>
  );
};
