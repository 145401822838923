import { FC } from 'react';

import { IDashboardGame, IMultiParamsVoid } from '../../types';
import { createClassNames } from '../../utils';
import { DashboardGameButton } from '../DashboardGameButton';
import './DashboardGameList.styles.scss';

export interface IDashboardGameListProps {
  /** displays content relative to team id */
  displayForTeamId: string;
  /** list of dashboard games */
  games: IDashboardGame[];
  /** currently active game */
  selectedGameId: string | undefined;
  /** callback for game selection */
  onChange: IMultiParamsVoid;
}

const classNames = createClassNames('dashboard-game-list');

/** UI component used for listing dashboard game buttons.*/
export const DashboardGameList: FC<IDashboardGameListProps> = ({
  games,
  selectedGameId,
  displayForTeamId,
  onChange,
}) => (
  <div className={classNames()}>
    {games.map(game => (
      <DashboardGameButton
        key={game.id}
        isActive={game.id === selectedGameId}
        onClick={game.id !== selectedGameId ? () => onChange(game.id) : undefined}
        {...{ displayForTeamId, ...game }}
      />
    ))}
  </div>
);
