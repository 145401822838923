import { usePrepareBaseRequestBody } from '..';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DISABLED_TABS_ON_OFF } from '../../constants';
import {
  filteredMainFilterDataSelector,
  getFaceoffs,
  getFormationsPlayersStats,
  resetFaceoffs,
  selectMainFilter,
} from '../../features';
import {
  IBaseFormationParams,
  IFaceoffRequestBody,
  IFetchDataConfig,
  IMainFilterForm,
  INavigationContentKeys,
  ITeamsFormationRequestBody,
} from '../../types';
import {
  createFormationFromSelectedPlayers,
  isSelectedPlayerSelected,
  normalizeDateFromTo,
} from '../../utils';

const disabledOnOff = DISABLED_TABS_ON_OFF.includes(INavigationContentKeys.faceoffs);

export const useFetchFaceoffs = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam, selectedPlayerItems } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const competitionsUuids = filteredParts.map(part => part.id);
  const filteredSelected = Object.values(selectedPlayerItems).filter(item =>
    isSelectedPlayerSelected(item, disabledOnOff),
  );
  const selectedTeamUuid = selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : '';

  const fetchFaceoffs = (data: Partial<IMainFilterForm>, config?: IFetchDataConfig) => {
    const selectedPlayers = config?.customSelectedPlayerItems || filteredSelected;
    const teamUuid = config?.customSelectedTeamId || selectedTeamUuid;
    const dateRange = config?.customDateFromTo
      ? normalizeDateFromTo(config?.customDateFromTo)
      : undefined;

    const formation = createFormationFromSelectedPlayers(selectedPlayers);
    const isValidFormation = formation.length > 0;

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestFaceoffsBody: IFaceoffRequestBody = {
      ...requestBodyBase,
      stick: null,
      dateFrom: dateRange?.from || requestBodyBase.dateFrom,
      dateTo: dateRange?.to || requestBodyBase.dateTo,
    };
    dispatch(resetFaceoffs());

    dispatch(
      getFaceoffs({
        competitionsUuids,
        body: requestFaceoffsBody,
        teamUuid,
      }),
    );

    if (isValidFormation) {
      selectedPlayers.forEach(player => {
        if (player.selectedPlayer) {
          dispatch(
            getFaceoffs({
              competitionsUuids,
              body: requestFaceoffsBody,
              teamUuid,
              playerUuid: player.selectedPlayer.value,
            }),
          );
        }
      });
    }
  };

  const fetchFaceoffStatsOfPlayers = (data: Partial<IMainFilterForm>) => {
    const { requestBodyBase } = prepareBaseRequestBody(data);

    const requestBody: ITeamsFormationRequestBody = {
      ...requestBodyBase,
      formation: [],
      metrics: ['fo', 'fow', 'fow_percent'],
    };

    const actionBaseParams: IBaseFormationParams = {
      competitionsUuids,
      teamUuid: selectedTeamUuid,
      body: requestBody,
    };
    dispatch(
      getFormationsPlayersStats({
        ...actionBaseParams,
        isTeammate: true,
      }),
    );
  };

  return {
    fetchFaceoffs,
    fetchFaceoffStatsOfPlayers,
  };
};
