import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../../services/axiosInstance';
import {
  IGameBlock,
  IGameBlockDTO,
  IGameReportTeamDTO,
  IGameReportTeams,
  IGameReportTeamsRequestBody,
  IGameSummary,
  IGameSummaryDTO,
  IGamesReportBlocksRequestBody,
  IGetGamePreviewAndSummaryParams,
} from '../../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../../utils';
import {
  parseGameSummaryBlockData,
  parseGameSummaryData,
  parseGameTeamsData,
} from './parseGamesReport';

interface IGetGameSummaryBlockParams extends IGetGamePreviewAndSummaryParams {
  body?: IGamesReportBlocksRequestBody;
  isSecondBlock?: boolean;
}

interface IGetGameTeamsParams {
  competitionsUuids: string;
  matchUuid: string;
  teamUuid: string;
  body?: IGameReportTeamsRequestBody;
}

export const getGameSummary = createAsyncThunk<IGameSummary, IGetGamePreviewAndSummaryParams>(
  'games/report/getGameSummary',
  async ({ competitionsUuids, matchUuid, teamUuid, gameInfo }) => {
    const goals: IGameSummary = await axiosInstance
      .get<IGameSummaryDTO>(`/match/${competitionsUuids}/${matchUuid}/${teamUuid}/summary`)
      .then(response => parseGameSummaryData(response.data, gameInfo))
      .catch(defaultCatchErrorCallback);

    return goals;
  },
);

export const getGameSummaryBlock = createAsyncThunk<IGameBlock, IGetGameSummaryBlockParams>(
  'games/report/getGameSummaryBlock',
  async ({ competitionsUuids, matchUuid, teamUuid, gameInfo, isSecondBlock, body }) => {
    const base = `/match/${competitionsUuids}/${matchUuid}/${teamUuid}/summary`;
    const requestPath = buildPath(base, [
      urlBooleanParamResolver(!isSecondBlock, 'block1'),
      urlBooleanParamResolver(isSecondBlock, 'block2'),
    ]);

    const gameSummary: IGameBlock = await axiosInstance
      .post<IGameBlockDTO>(requestPath, body ?? {})
      .then(response => parseGameSummaryBlockData(response.data, gameInfo))
      .catch(defaultCatchErrorCallback);

    return gameSummary;
  },
);

export const getGameTeams = createAsyncThunk<IGameReportTeams, IGetGameTeamsParams>(
  'games/report/getGameTeams',
  async ({ competitionsUuids, matchUuid, teamUuid, body }) => {
    const requestPath = `/match/${competitionsUuids}/${matchUuid}/${teamUuid}/team`;

    const gameTeams: IGameReportTeams = await axiosInstance
      .post<IGameReportTeamDTO[]>(requestPath, body ?? {})
      .then(response => parseGameTeamsData(response.data))
      .catch(defaultCatchErrorCallback);

    return gameTeams;
  },
);
