import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { Sticker } from '../../Sticker';
import './TeamColumn.styles.scss';

export const TeamColumnHeader = () => {
  const { t } = useTranslation();
  return <span className='team-column'>{t(ITranslationKeys.team)}</span>;
};

export interface ITeamColumnRowProps<T> extends CellContext<T, any> {}

export const TeamColumnCell = <T extends {}>({ getValue }: ITeamColumnRowProps<T>) => (
  <span className='team-column'>
    <Sticker label={getValue()} size='small' />
  </span>
);
