import { useAppSelector } from '../app/hooks';
import { selectDefinedDatasets, selectTableFilter, selectUserDatasets } from '../features';
import { IEntity } from '../types';
import { getMetricsFromDataTemplate } from '../utils';
import { useLocationPaths } from './useLocationPaths';

export const useNormalizeMetrics = () => {
  const { definedDatasets, defaultTemplates } = useAppSelector(selectDefinedDatasets);
  const userDatasets = useAppSelector(selectUserDatasets);
  const { dataTemplate, customMetrics } = useAppSelector(selectTableFilter);
  const { activePage, activeTab } = useLocationPaths();

  const normalizeMetrics = (entity: IEntity = IEntity.players) => {
    const customMetricsIds = customMetrics?.map(metric => metric.additionalInfo.code);
    if (definedDatasets && defaultTemplates) {
      const screenId = `${activePage}_${activeTab}`;

      return (
        customMetricsIds ??
        getMetricsFromDataTemplate(
          definedDatasets,
          defaultTemplates,
          userDatasets.byId,
          dataTemplate,
          screenId,
          entity,
        ).map(metric => metric.code)
      );
    }

    return undefined;
  };

  return normalizeMetrics;
};
