import { FC, useState } from 'react';

import { Caption, Input, TcmsModal } from '../../../../../../components';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { AddItemIcon } from '../../../../../../icons';
import { ILanguageType } from '../../../../../../types';
import { createClassNames, transformStringToLanguageType } from '../../../../../../utils';
import './EditTemplateModal.styles.scss';

export interface IEditTemplateModalProps {
  openModal: boolean;
  onClose: () => void;
  onSave: (templateName: ILanguageType<string>) => void;
  initialTemplateName?: ILanguageType<string>;
}

const classNames = createClassNames('edit-template-modal');

export const EditTemplateModal: FC<IEditTemplateModalProps> = ({
  openModal,
  initialTemplateName,
  onClose,
  onSave,
}) => {
  const [templateName, setTemplateName] = useState<ILanguageType<string>>(
    initialTemplateName ?? transformStringToLanguageType(''),
  );

  return (
    <TcmsModal
      open={openModal}
      icon={<AddItemIcon />}
      title={ITranslationKeys.enterNameOfTemplate}
      closeButtonConfig={{
        label: ITranslationKeys.close,
        onClick: () => onClose(),
      }}
      confirmButtonConfig={{
        label: ITranslationKeys.save,
        onClick: () => onSave(templateName),
      }}
    >
      <div className={classNames()}>
        <div className={classNames('input-group')}>
          <div>
            <Caption label='Česky' />
            <Input
              onChange={e => setTemplateName({ ...templateName, cz: e.currentTarget.value })}
              value={templateName.cz}
              variant='tcms'
            />
          </div>
          <div>
            <Caption label='Anglicky' />
            <Input
              onChange={e => setTemplateName({ ...templateName, en: e.currentTarget.value })}
              value={templateName.en}
              variant='tcms'
            />
          </div>
        </div>
      </div>
    </TcmsModal>
  );
};
