import { useAppDispatch } from '../../../app/hooks';
import {
  opponentEntityOption,
  opponentTeamEntityOption,
  playerEntityOption,
  teamEntityOption,
} from '../../../constants';
import { setEntity } from '../../../features';
import { IEntity, IMetricSectionName, IMetricWithDatasetMetric } from '../../../types';

export const useSetEntityFilter = () => {
  const dispatch = useAppDispatch();

  const setEntityFilterSetter = (
    stat: IMetricWithDatasetMetric,
    isTeam: boolean = false,
    entity?: IEntity,
  ) => {
    if (stat.metric.metricSectionId === IMetricSectionName.individual && entity !== IEntity.teams) {
      if (stat.metric.metricInfo.color === 'green') {
        dispatch(setEntity(playerEntityOption));
      }

      if (stat.metric.metricInfo.color === 'red') {
        dispatch(setEntity(opponentEntityOption));
      }

      if (stat.metric.metricInfo.color === 'white') {
        dispatch(setEntity(playerEntityOption));
      }
    }

    if (stat.metric.metricSectionId === IMetricSectionName.onIce || entity === IEntity.teams) {
      if (stat.metric.metricInfo.color === 'green') {
        dispatch(setEntity(teamEntityOption));
      }

      if (stat.metric.metricInfo.color === 'red') {
        if (isTeam) {
          dispatch(setEntity(opponentTeamEntityOption));
        } else {
          dispatch(setEntity(opponentEntityOption));
        }
      }
    }
  };

  return { setEntityFilterSetter };
};
