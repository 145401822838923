import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IDashboardTableProps } from '../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../utils';
import { TableHead } from '../../TableHead';
import { TableRow } from '../../TableRow';
import { useTableResize } from '../useTableResize';
import './DashboardTable.styles.scss';

const classNames = createClassNames('dashboard-table-component');

export const DashboardTable = memo(
  <T extends {}>({
    data,
    columns,
    columnPinning,
    initialSorting,
    centerPartRef,
    cellsHeight,
    disableIsSmall = false,
    disableBottomBorder = false,
    showRightPartLeftBorder = false,
    centerPartTitle,
    rightPartTitle,
    isStandings = false,
    extraRowHeaderRender,
  }: IDashboardTableProps<T>) => {
    const { rightPartRef, rightPartWidth } = useTableResize();
    const [sorting, setSorting] = useState<SortingState>(initialSorting || []);
    const { t } = useTranslation();

    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
        columnPinning,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
    });

    return (
      <div className={classNames({ ...transformObjectKeysToKebabCase({ isStandings }) })}>
        <div
          ref={centerPartRef}
          className={classNames('center-part', {
            ...transformObjectKeysToKebabCase({
              isSmall: !disableIsSmall && table.getRowModel().rows.length < 4,
            }),
          })}
          style={{
            paddingRight: rightPartWidth,
          }}
        >
          {centerPartTitle && (
            <span className={classNames('center-part__title')}>{t(centerPartTitle)}</span>
          )}
          <table className={classNames('center-part__table')}>
            <TableHead
              headerGroup={table.getCenterHeaderGroups()}
              tablePart={'center'}
              extraHeaderRow={extraRowHeaderRender?.('center')}
            />
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  row={row}
                  cells={row.getCenterVisibleCells()}
                  cellsHeight={cellsHeight}
                  disableBottomBorder={
                    disableBottomBorder && index === table.getRowModel().rows.length - 1
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
        <div
          className={classNames('right-part', {
            ...transformObjectKeysToKebabCase({
              showRightPartLeftBorder,
            }),
          })}
        >
          {rightPartTitle && (
            <span className={classNames('right-part__title')}>{t(rightPartTitle)}</span>
          )}
          <table
            ref={rightPartRef}
            className={classNames('right-part__table', {
              ...transformObjectKeysToKebabCase({
                showRightPartLeftBorder,
              }),
            })}
          >
            <TableHead
              headerGroup={table.getRightHeaderGroups()}
              tablePart={'right'}
              extraHeaderRow={extraRowHeaderRender?.('right')}
            />
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={row.id}
                  row={row}
                  cells={row.getRightVisibleCells()}
                  cellsHeight={cellsHeight}
                  disableBottomBorder={
                    disableBottomBorder && index === table.getRowModel().rows.length - 1
                  }
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  },
) as <T extends {}>(props: IDashboardTableProps<T>) => JSX.Element;
