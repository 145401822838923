import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  getGameSummary,
  getGameSummaryBlock,
  getGameTeams,
  selectGames,
  selectTableFilter,
  selectedGameSelector,
} from '../../features';
import {
  IEntity,
  IGameReportTeamsRequestBody,
  IGamesReportBlocksRequestBody,
  IGamesReportForm,
  IMainFilterForm,
} from '../../types';
import { getTimeFromGamePart, normalizeSelectWithAllValues } from '../../utils';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchReport = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const selectedGame = useAppSelector(selectedGameSelector);
  const { games } = useAppSelector(selectGames);
  const dispatch = useAppDispatch();
  const { byId } = games;
  const { gamePart, gameCountOfPlayers } = useAppSelector(selectTableFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);

  const normalizeMetrics = useNormalizeMetrics();

  const fetchGameSummary = () => {
    if (selectedGame) {
      const game = byId[selectedGame.id];

      dispatch(
        getGameSummary({
          competitionsUuids: game.competitionId ?? filteredParts.map(part => part.id),
          matchUuid: game.id,
          teamUuid: game.homeTeamId,
          gameInfo: game,
        }),
      );
    }
  };

  const fetchGameBlockOne = (data: Partial<IGamesReportForm>) => {
    if (selectedGame) {
      const { countOfPlayers, gamePart } = data;

      const game = byId[selectedGame.id];

      const body: IGamesReportBlocksRequestBody = {
        gamePart: normalizeSelectWithAllValues(gamePart),
        gameState: normalizeSelectWithAllValues(countOfPlayers),
      };

      dispatch(
        getGameSummaryBlock({
          competitionsUuids: game.competitionId ?? filteredParts.map(part => part.id),
          matchUuid: game.id,
          teamUuid: game.homeTeamId,
          gameInfo: game,
          body,
        }),
      );
    }
  };

  const fetchGameBlockTwo = (data: Partial<IGamesReportForm>) => {
    if (selectedGame) {
      const { countOfPlayers, gamePart } = data;

      const game = byId[selectedGame.id];

      const body: IGamesReportBlocksRequestBody = {
        gamePart: normalizeSelectWithAllValues(gamePart),
        gameState: normalizeSelectWithAllValues(countOfPlayers),
      };

      dispatch(
        getGameSummaryBlock({
          competitionsUuids: game.competitionId ?? filteredParts.map(part => part.id),
          matchUuid: game.id,
          teamUuid: game.homeTeamId,
          gameInfo: game,
          body,
          isSecondBlock: true,
        }),
      );
    }
  };

  const fetchGameTeams = (data: Partial<IMainFilterForm>) => {
    const { requestBodyBase } = prepareBaseRequestBody(data);

    const timeFromTo = getTimeFromGamePart(gamePart);

    const requestBody: IGameReportTeamsRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(IEntity.teams),
      gameState: normalizeSelectWithAllValues(gameCountOfPlayers),
      timeFrom: timeFromTo?.timeFrom,
      timeTo: timeFromTo?.timeTo,
    };

    if (selectedGame) {
      const game = byId[selectedGame.id];

      dispatch(
        getGameTeams({
          competitionsUuids: game.competitionId ?? filteredParts.map(part => part.id),
          matchUuid: game.id,
          teamUuid: game.homeTeamId,
          body: requestBody,
        }),
      );
    }
  };

  return {
    fetchGameSummary,
    fetchGameBlockOne,
    fetchGameBlockTwo,
    fetchGameTeams,
  };
};
