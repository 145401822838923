import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { filteredMainFilterDataSelector, getPasses, getShots } from '../../features';
import {
  IBaseRequestBody,
  IDateRangeString,
  IFetchDataConfig,
  IFormation,
  IMainFilterForm,
  IMetricVideoIds,
  IMetricVideoLinkKeys,
  IZonesFetchDataMode,
} from '../../types';
import { normalizeDateFromTo } from '../../utils';
import {
  useFetchFaceoffs,
  useFetchShootouts,
  useFetchShotsOrPasses,
  useFetchZones,
} from '../fetch';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchDataByMetricLinkId = () => {
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const { fetchShots, fetchPasses, fetchGamesAndToiForShotsOrPasses } = useFetchShotsOrPasses();
  const { fetchFaceoffs } = useFetchFaceoffs();
  const { fetchZoneExits, fetchZoneEntries } = useFetchZones();
  const { fetchShootouts } = useFetchShootouts();

  const prepareBaseRequestBody = usePrepareBaseRequestBody();

  const fetchDataByMetricLinkId = (
    values: IMainFilterForm,
    metricIds: IMetricVideoIds,
    playerId?: string,
    teamId?: string,
    formations?: IFormation[],
    dateFromTo?: IDateRangeString,
  ) => {
    const { metricLinkId, metricId, metricSubgroupId } = metricIds;
    const config: IFetchDataConfig = {
      customSelectedPlayerItems: createCustomSelectedPlayerItems(playerId, formations),
      customSelectedTeamId: teamId,
      customDateFromTo: dateFromTo,
    };

    if (metricLinkId === IMetricVideoLinkKeys.shots) {
      fetchShots(values, config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.passes) {
      fetchPasses(values, config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.faceoffs) {
      fetchFaceoffs(values, config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.entries) {
      const isDumpin =
        metricSubgroupId === 'nahozeni' ||
        metricSubgroupId === 'nahozeni_vedouci_ke_strele' ||
        metricId?.startsWith('dibaw');

      const fetchBoth = metricId?.includes('r_percent') || metricId?.includes('rf_percent');

      const mode = fetchBoth
        ? IZonesFetchDataMode.both
        : isDumpin
        ? IZonesFetchDataMode.dumpInOut
        : IZonesFetchDataMode.entry;

      fetchGamesAndToiForShotsOrPasses(values, {
        ...config,
        onGamesLoad: games => fetchZoneEntries(values, { ...config, games, mode }),
      });
    }

    if (metricLinkId === IMetricVideoLinkKeys.exits) {
      const isDumpout =
        metricSubgroupId === 'vyhozeni' || metricSubgroupId === 'vyhozeni_vedouci_ke_strele';

      const fetchBoth =
        metricSubgroupId === 'kontrolovane_vystupy' && metricId?.includes('r_percent');

      const mode = fetchBoth
        ? IZonesFetchDataMode.both
        : isDumpout
        ? IZonesFetchDataMode.dumpInOut
        : IZonesFetchDataMode.entry;

      fetchGamesAndToiForShotsOrPasses(values, {
        ...config,
        onGamesLoad: games => fetchZoneExits(values, { ...config, games, mode }),
      });
    }

    if (metricLinkId === IMetricVideoLinkKeys.shootouts) {
      fetchShootouts(values, 'videomaps', config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.goalkeepersShootouts) {
      fetchShootouts(values, 'goalkeepers', config);
    }

    if (metricLinkId === IMetricVideoLinkKeys.goalkeepersShots) {
      const competitionsUuids = filteredParts.map(part => part.id);
      const dateRange = config?.customDateFromTo
        ? normalizeDateFromTo(config?.customDateFromTo)
        : undefined;

      const { requestBodyBase } = prepareBaseRequestBody(values);
      const requestBody: IBaseRequestBody = {
        ...requestBodyBase,
        dateFrom: dateRange?.from || requestBodyBase.dateFrom,
        dateTo: dateRange?.to || requestBodyBase.dateTo,
      };

      dispatch(
        getShots({
          competitionsUuids,
          body: requestBody,
          teamUuid: teamId || '',
          playerUuid: playerId,
        }),
      );

      if (metricId?.startsWith('cr.') || metricId?.startsWith('bn.')) {
        dispatch(
          getPasses({
            competitionsUuids,
            body: requestBody,
            teamUuid: teamId || '',
            isAgainst: true,
          }),
        );
      }
    }
  };

  return {
    fetchDataByMetricLinkId,
  };
};

const createCustomSelectedPlayerItems = (playerId?: string, formations?: IFormation[]) => {
  if (formations) {
    return formations.map(({ player, on }, index) => ({
      id: index.toString(),
      selectedPlayer: { value: player, label: player },
      isActive: on,
    }));
  }

  if (playerId) {
    return [
      {
        id: '0',
        selectedPlayer: { value: playerId, label: playerId },
        isActive: true,
      },
    ];
  }

  return [];
};
