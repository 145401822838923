import { useAppSelector } from '../../app/hooks';
import {
  selectFaceoffsFilter,
  selectGoalkeepersFilter,
  selectMainFilter,
  selectMetricParamsFilter,
  selectShootoutFilter,
  selectVideoCenter,
  selectVideomapsFilter,
  selectZonesFilter,
} from '../../features';
import { INavigationContentKeys, IUrlFilterParams, IUrlParam } from '../../types';
import { normalizeUrlSelectOptionParamValue } from '../../utils';

/** Hook for normalizing url filter params from store. */
export const useNormalizeUrlFilterParams = () => {
  const { metricVideoGameIds } = useAppSelector(selectVideoCenter);
  const { playerId, teamId, entity, shotCategory, passType, formations } =
    useAppSelector(selectVideomapsFilter);
  const { gameActionType, shotLocation, shotDanger, shotType, netZones, entryExitType } =
    useAppSelector(selectMetricParamsFilter);
  const { zoneCategory, zoneSuccess, zoneFinish, zoneExitResult, dumpInOutResult } =
    useAppSelector(selectZonesFilter);
  const { formationType } = useAppSelector(selectMainFilter);
  const { faceoffResult, faceoffFollowedBy } = useAppSelector(selectFaceoffsFilter);
  const { endResult, endType } = useAppSelector(selectShootoutFilter);
  const { saves } = useAppSelector(selectGoalkeepersFilter);

  const normalizeUrlFilterParams = (
    isGoalkeeper?: boolean,
    contentUrl?: INavigationContentKeys,
  ): IUrlFilterParams[] => {
    const playerParameterName = isGoalkeeper
      ? IUrlParam.selectedGoalkeeper
      : IUrlParam.selectedPlayerItems;
    const entityValue = entity.value === 'opponentTeam' ? 'opponent' : entity.value;

    const sharedShotsParams: IUrlFilterParams[] = [
      normalizeUrlSelectOptionParamValue(
        IUrlParam.shotDanger,
        shotDanger.map(shotDanger => shotDanger.value).join(','),
      ),
    ].filter((item): item is IUrlFilterParams => !!item);

    const filterParams: IUrlFilterParams[] = [
      normalizeUrlSelectOptionParamValue(IUrlParam.selectedTeam, teamId, null),
      normalizeUrlSelectOptionParamValue(playerParameterName, playerId, null),
      normalizeUrlSelectOptionParamValue(
        IUrlParam.selectedGames,
        metricVideoGameIds?.join(','),
        null,
      ),
      normalizeUrlSelectOptionParamValue(IUrlParam.entity, isGoalkeeper ? '' : entityValue, 'team'),
      normalizeUrlSelectOptionParamValue(IUrlParam.shotCategory, shotCategory.value),
      normalizeUrlSelectOptionParamValue(
        IUrlParam.countOfPlayers,
        formationType?.value === '5:5-FO' || formationType?.value === '5:5-DE'
          ? '5:5'
          : formationType?.value,
      ),
      normalizeUrlSelectOptionParamValue(
        IUrlParam.selectedPlayerItems,
        formations?.map(({ player, on }) => `${player}_${!!on}`).join(','),
      ),
    ].filter((item): item is IUrlFilterParams => !!item);

    if (
      contentUrl === INavigationContentKeys.shots ||
      contentUrl === INavigationContentKeys.passes ||
      contentUrl === INavigationContentKeys.shotmap
    ) {
      const shotsParams: IUrlFilterParams[] = [
        ...sharedShotsParams,
        normalizeUrlSelectOptionParamValue(IUrlParam.shotLocation, shotLocation.value),
        normalizeUrlSelectOptionParamValue(IUrlParam.shotType, shotType?.value),
        normalizeUrlSelectOptionParamValue(IUrlParam.netZones, netZones?.join(',')),
        normalizeUrlSelectOptionParamValue(IUrlParam.gameActionType, gameActionType.value),
      ].filter((item): item is IUrlFilterParams => !!item);

      filterParams.push(...shotsParams);
    }

    if (contentUrl === INavigationContentKeys.passes) {
      const passTypeParam = normalizeUrlSelectOptionParamValue(IUrlParam.passType, passType?.value);
      if (passTypeParam) {
        filterParams.push(passTypeParam);
      }
    }

    if (
      contentUrl === INavigationContentKeys.zoneEntries ||
      contentUrl === INavigationContentKeys.zoneExits
    ) {
      const zoneFinishValue =
        (shotLocation.value === 'slot' || shotLocation.value === 'sslot') &&
        zoneFinish.value !== 'goal'
          ? 'shotFromSlot'
          : zoneFinish.value;

      const zonesParams: IUrlFilterParams[] = [
        normalizeUrlSelectOptionParamValue(IUrlParam.zoneCategory, zoneCategory.value),
        normalizeUrlSelectOptionParamValue(IUrlParam.zoneSuccess, zoneSuccess.value),
        normalizeUrlSelectOptionParamValue(IUrlParam.zoneFinish, zoneFinishValue),
        normalizeUrlSelectOptionParamValue(IUrlParam.zoneExitResult, zoneExitResult.value),
        normalizeUrlSelectOptionParamValue(
          IUrlParam.entryExitType,
          entryExitType.map(item => item.value).join(','),
        ),
        normalizeUrlSelectOptionParamValue(IUrlParam.dumpInOutResult, dumpInOutResult.value),
      ].filter((item): item is IUrlFilterParams => !!item);

      filterParams.push(...zonesParams);
      filterParams.push(...sharedShotsParams);
    }

    if (contentUrl === INavigationContentKeys.faceoffs) {
      const faceoffsParams: IUrlFilterParams[] = [
        normalizeUrlSelectOptionParamValue(IUrlParam.faceoffResult, faceoffResult.value),
        normalizeUrlSelectOptionParamValue(IUrlParam.faceoffFollowedBy, faceoffFollowedBy.value),
      ].filter((item): item is IUrlFilterParams => !!item);

      filterParams.push(...faceoffsParams);
    }

    if (contentUrl === INavigationContentKeys.shootouts) {
      const shootoutsParams: IUrlFilterParams[] = [
        normalizeUrlSelectOptionParamValue(IUrlParam.shootoutEndResult, endResult.value),
        normalizeUrlSelectOptionParamValue(IUrlParam.shootoutEndType, endType.value),
      ].filter((item): item is IUrlFilterParams => !!item);

      filterParams.push(...shootoutsParams);
    }

    if (contentUrl === INavigationContentKeys.shotmap) {
      const goalkeepersParams: IUrlFilterParams[] = [
        normalizeUrlSelectOptionParamValue(IUrlParam.goalkeeperSaves, saves.value),
      ].filter((item): item is IUrlFilterParams => !!item);

      filterParams.push(...goalkeepersParams);
    }

    return filterParams;
  };

  return normalizeUrlFilterParams;
};
