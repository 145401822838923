import {
  ColumnDef,
  ColumnHelper,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnCell,
  BasicColumnHeader,
  DashboardColumnCell,
  Sticker,
  TranslatedColumnHeader,
} from '../../../../components';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IAlertColors,
  IColumnsConfig,
  IDashboardGame,
  IDashboardMinigameLabelColor,
  IGoalkeeperStatsTableData,
} from '../../../../types';
import { createClassNames, createIndexColumn } from '../../../../utils';
import './columns.styles.scss';

const columnHelper = createColumnHelper<IGoalkeeperStatsTableData>();
const initialSorting: SortingState = [];
const columnPinning: ColumnPinningState = {
  right: ['gsax', 'minigame'],
};

const classNames = createClassNames('dashboard-content-columns');

const fixedColumns: ColumnDef<IGoalkeeperStatsTableData, any>[] = [
  columnHelper.display({
    id: 'index',
    header: props => (
      <span className={classNames('index-cell', { header: true })}>
        <TranslatedColumnHeader {...props} label={ITranslationKeys.goalkeepers} />
      </span>
    ),
    cell: props => <strong className={classNames('index-cell')}>{createIndexColumn(props)}</strong>,
  }),
  columnHelper.accessor('player', {
    header: '',
    cell: props => (
      <div className={classNames('formation-cell')}>
        <BasicColumnCell {...props}>
          <Sticker label={props.getValue().displayName} size='small' />
        </BasicColumnCell>
      </div>
    ),
  }),
];

const rightColumn: ColumnDef<IGoalkeeperStatsTableData, any> = columnHelper.display({
  id: 'gsax',
  header: props => (
    <BasicColumnHeader {...props}>
      <span className={classNames('gsax-cell')}>GSAx</span>
    </BasicColumnHeader>
  ),
  cell: props => (
    <span className={classNames('gsax-cell')}>{props.row.original.stats['gsax']?.value}</span>
  ),
});

export const useGoalkeeperColumnsConfig = (
  teamId: string,
  game: IDashboardGame | undefined,
): IColumnsConfig<IGoalkeeperStatsTableData> => {
  const columns = useMemo(() => {
    if (!game) {
      return [];
    }

    const scoreDifference = Math.abs(game.homeTeam.score - game.awayTeam.score);
    const isWonGame =
      game.homeTeam.logo === teamId
        ? game.homeTeam.score > game.awayTeam.score
        : game.homeTeam.score < game.awayTeam.score;
    const dynamicColumns = createDashboardMinigameColumnsDefGoalkeepers(
      columnHelper,
      scoreDifference,
      isWonGame,
    );
    return fixedColumns.concat(rightColumn).concat(dynamicColumns);
  }, [teamId, game]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const createDashboardMinigameColumnsDefGoalkeepers = <T extends IGoalkeeperStatsTableData>(
  columnHelper: ColumnHelper<T>,
  scoreDifference: number,
  isWonGame: boolean,
) =>
  columnHelper.display({
    id: 'minigame',
    header: props => (
      <span className={classNames('minigame-cell')}>
        <TranslatedColumnHeader {...props} label={ITranslationKeys.minigame} />
      </span>
    ),
    cell: props => {
      const gsax = props.row.original.stats['gsax'].value;
      const ga = props.row.original.stats['ga'].value;
      const minigameConfig = getGoalkeepersMinigameConfig(gsax, ga, scoreDifference, isWonGame);

      return (
        <div className={classNames('minigame-cell')}>
          {minigameConfig && (
            <DashboardColumnCell
              {...props}
              color={minigameConfig.color}
              label={minigameConfig.label}
            />
          )}
        </div>
      );
    },
  });

const getGoalkeepersMinigameConfig = (
  gsax: number,
  ga: number,
  scoreDifference: number,
  isWonGame: boolean,
): IDashboardMinigameLabelColor | undefined => {
  if (gsax > scoreDifference && isWonGame) {
    return {
      color: IAlertColors.success,
      label: ITranslationKeys.stolenGame,
    };
  }

  if (gsax < 0 && Math.abs(gsax) > scoreDifference && !isWonGame) {
    return {
      color: IAlertColors.danger,
      label: ITranslationKeys.poorGame,
    };
  }

  if (gsax < -1.5) {
    return {
      color: IAlertColors.danger,
      label: ITranslationKeys.unreliableGame,
    };
  }

  if (ga === 0) {
    return {
      color: IAlertColors.success,
      label: ITranslationKeys.shutout,
    };
  }
};
