import { createSelector } from '@reduxjs/toolkit';

import { ISelectOption, ISelectOptionBase, ISelectedPlayerItem, IZoneEntity } from '../../types';
import {
  createZoneGameEntities,
  filterByEntity,
  filterBySelectedGames,
  filterBySelectedPlayerItems,
} from '../../utils';
import {
  selectMainFilter,
  selectMetricParamsFilter,
  selectPlayers,
  selectVideoCenter,
  selectVideomapsFilter,
  selectZones,
  selectZonesFilter,
} from '../selectors';

const filterZonesByPass = (entryExitType: ISelectOption[], zoneEntities: IZoneEntity[]) => {
  if (!zoneEntities) return [];
  if (entryExitType.some(option => option.value === 'all')) {
    return zoneEntities;
  }

  const filteredZoneEntities = entryExitType.reduce<IZoneEntity[]>((acc, option) => {
    const filteredEntities = zoneEntities.filter(zoneEntity => {
      if (option.value === 'c') {
        return zoneEntity.type === 'carry' && !zoneEntity.passingPlayer;
      }
      if (option.value === 'p') {
        return zoneEntity.type === 'carry' && zoneEntity.passingPlayer;
      }
      if (option.value === 'enp' || option.value === 'exp') {
        return zoneEntity.type === 'pass';
      }
      if (option.value === 'stp') {
        return zoneEntity.type === 'longPass';
      }
      return false;
    });
    return filteredEntities.length ? [...acc, ...filteredEntities] : acc;
  }, []);

  return filteredZoneEntities;
};

const isDumpPlayerSatisfied = (
  dump: IZoneEntity,
  entity: ISelectOptionBase,
  filteredPlayers: ISelectedPlayerItem[],
) => {
  const dumpBelongsToSelectedPlayer = filteredPlayers.find(
    player => player.selectedPlayer?.value === dump.playerId,
  );
  return dumpBelongsToSelectedPlayer || entity.value !== 'player';
};

const filterZonesBySuccess = (zoneEntities: IZoneEntity[], zoneSuccessValue: string) => {
  if (zoneSuccessValue === 'successfully') {
    return zoneEntities.filter(zoneEntity => zoneEntity.successful);
  }

  if (zoneSuccessValue === 'blocked') {
    return zoneEntities.filter(zoneEntity => !!zoneEntity.blocker);
  }

  return zoneEntities.filter(zoneEntity => !zoneEntity.successful);
};

const filterZoneEntitiesByDanger = (
  zoneEntities: IZoneEntity[],
  zoneEntityDanger: ISelectOption[],
) => {
  const zoneEntityDangerValue = zoneEntityDanger.map(entityDanger =>
    entityDanger.value.toUpperCase(),
  );
  return zoneEntities.filter(zoneEntity => {
    if (!zoneEntity.shotDanger) return false;
    return zoneEntityDangerValue.includes(zoneEntity.shotDanger);
  });
};

const filterZonesByFinish = (zoneEntities: IZoneEntity[], zoneFinishValue: string) => {
  if (!zoneEntities) return [];

  if (zoneFinishValue === 'shot') {
    return zoneEntities.filter(zoneEntity => zoneEntity.finish && zoneEntity.finish !== 'none');
  }
  if (zoneFinishValue === 'shotFromSlot') {
    return zoneEntities.filter(zoneEntity => zoneEntity.shotInSlot);
  }
  if (zoneFinishValue === 'goal') {
    return zoneEntities.filter(zoneEntity => zoneEntity.finish === 'goal');
  }

  return zoneEntities;
};

const filterZoneExitsByResult = (zoneEntities: IZoneEntity[], zoneResultValue: string) => {
  if (!zoneEntities) return [];

  if (zoneResultValue === 'offensiveZoneGain') {
    return zoneEntities.filter(zoneEntity => zoneEntity.followingEvent === 'gain');
  }
  if (zoneResultValue === 'losingPuckInMiddleZone') {
    return zoneEntities.filter(zoneEntity => zoneEntity.followingEvent === 'denial');
  }

  return zoneEntities;
};

const filterDumpInOutsByResultSelector = (
  dumpEntities: IZoneEntity[],
  dumpResultValue: string,
  entity: ISelectOptionBase,
  filteredPlayers: ISelectedPlayerItem[],
  selectedTeam: ISelectOptionBase | null | undefined,
) => {
  if (dumpResultValue === 'battle') {
    return dumpEntities.filter(dump => dump.battle);
  }

  if (dumpResultValue === 'battleWon') {
    return dumpEntities.filter(dump => dump.battleWon);
  }

  if (dumpResultValue === 'battleLost') {
    return dumpEntities.filter(
      dump =>
        dump.battle && !dump.battleWon && isDumpPlayerSatisfied(dump, entity, filteredPlayers),
    );
  }

  if (dumpResultValue === 'battleWonWithoutFight') {
    return dumpEntities.filter(dump => !dump.battle && dump.wonTeam === selectedTeam?.value);
  }

  return dumpEntities;
};

// Start of shared selectors
export const filteredZonesByEntitySelector = createSelector(
  [selectVideomapsFilter, selectZones, selectVideoCenter],
  ({ entity }, { zones }, { formationsConfig }) =>
    filterByEntity(entity, zones.for, zones.against, formationsConfig?.isOpponent),
);

export const filteredZonesBySelectedPlayerItemsSelector = createSelector(
  [selectVideomapsFilter, selectMainFilter, filteredZonesByEntitySelector],
  ({ entity, playerId }, { selectedPlayerItems }, filteredZonesByEntity) => {
    if (playerId) {
      return filteredZonesByEntity;
    }

    return filterBySelectedPlayerItems(entity, filteredZonesByEntity, selectedPlayerItems);
  },
);

export const filteredZonesByGamesSelector = createSelector(
  [
    selectVideomapsFilter,
    selectMainFilter,
    filteredZonesByEntitySelector,
    (_, customGames?: ISelectOption[]) => customGames,
  ],
  ({ playerId }, { selectedGames }, filteredZonesByEntity, customGames) => {
    if (playerId) {
      return filteredZonesByEntity;
    }

    return filterBySelectedGames(filteredZonesByEntity, selectedGames, customGames);
  },
);

export const filteredZonesBySuccessSelector = createSelector(
  [selectZonesFilter, filteredZonesByEntitySelector],
  ({ zoneSuccess }, filteredZonesByEntity) => {
    if (zoneSuccess.value === 'all') return filteredZonesByEntity;

    return filterZonesBySuccess(filteredZonesByEntity, zoneSuccess.value);
  },
);

export const filteredZonesByDangerSelector = createSelector(
  [selectMetricParamsFilter, filteredZonesByEntitySelector],
  ({ shotDanger }, filteredZonesByEntity) => {
    if (shotDanger.some(shotDanger => shotDanger.value === 'all')) return filteredZonesByEntity;

    return filterZoneEntitiesByDanger(filteredZonesByEntity, shotDanger);
  },
);

export const filteredZonesByLocationSelector = createSelector(
  [selectMetricParamsFilter, filteredZonesByEntitySelector],
  ({ enterExitLocation }, filteredZonesByEntity) => {
    if (enterExitLocation.value === 'all') return filteredZonesByEntity;

    if (enterExitLocation.value === 'le') {
      return filteredZonesByEntity.filter(zone => zone.location === 'left');
    }
    if (enterExitLocation.value === 'mi') {
      return filteredZonesByEntity.filter(zone => zone.location === 'center');
    }
    if (enterExitLocation.value === 'ri') {
      return filteredZonesByEntity.filter(zone => zone.location === 'right');
    }

    return filteredZonesByEntity;
  },
);

export const filteredZonesByShotLocationSelector = createSelector(
  [selectMetricParamsFilter, filteredZonesByEntitySelector],
  ({ shotLocation }, filteredZonesByEntity) => {
    if (shotLocation.value === 'all') return filteredZonesByEntity;

    if (shotLocation.value === 'slot' || shotLocation.value === 'sslot') {
      return filteredZonesByEntity.filter(zone => zone.shotInSlot);
    }

    return filteredZonesByEntity;
  },
);

export const filteredZonesByFinishSelector = createSelector(
  [selectZonesFilter, filteredZonesByEntitySelector],
  ({ zoneFinish }, filteredZonesByEntity) => {
    if (zoneFinish.value === 'all') return filteredZonesByEntity;

    return filterZonesByFinish(filteredZonesByEntity, zoneFinish.value);
  },
);

export const filteredZonesByPassSelector = createSelector(
  [selectMetricParamsFilter, filteredZonesByEntitySelector],
  ({ entryExitType }, filteredZonesByEntity) =>
    filterZonesByPass(entryExitType, filteredZonesByEntity),
);

export const filteredZonesByShotPlayerIdSelector = createSelector(
  [selectZonesFilter, filteredZonesByEntitySelector],
  ({ shotPlayerId }, filteredZonesByEntity) => {
    if (!shotPlayerId) return filteredZonesByEntity;

    return filteredZonesByEntity.filter(zoneEntity => zoneEntity.shot?.player === shotPlayerId);
  },
);

export const filteredZonesByZoneExitEntryEntitySelector = createSelector(
  [selectVideomapsFilter, selectZonesFilter, filteredZonesByEntitySelector],
  ({ playerId, teamId }, { zoneExitEntryEntity }, filteredZonesByEntity) => {
    if (!zoneExitEntryEntity) return filteredZonesByEntity;

    if (zoneExitEntryEntity.value === 'player') {
      return filteredZonesByEntity.filter(
        zoneEntity => zoneEntity.zoneEntry && zoneEntity.zoneEntry.playerId === playerId,
      );
    }

    if (zoneExitEntryEntity.value === 'opponent') {
      return filteredZonesByEntity.filter(
        zoneEntity => zoneEntity.zoneEntry && zoneEntity.zoneEntry.teamId !== teamId,
      );
    }

    return filteredZonesByEntity.filter(
      zoneEntity => zoneEntity.zoneEntry && zoneEntity.zoneEntry.teamId === teamId,
    );
  },
);

export const filteredZonesSelector = createSelector(
  [
    filteredZonesByGamesSelector,
    filteredZonesBySelectedPlayerItemsSelector,
    filteredZonesBySuccessSelector,
    filteredZonesByDangerSelector,
    filteredZonesByLocationSelector,
    filteredZonesByShotLocationSelector,
    filteredZonesByFinishSelector,
    filteredZonesByPassSelector,
    filteredZonesByShotPlayerIdSelector,
    filteredZonesByZoneExitEntryEntitySelector,
  ],
  (
    filteredZonesByGames,
    filteredZonesBySelectedPlayerItems,
    filteredZonesBySuccess,
    filteredZonesByDanger,
    filteredZonesByLocation,
    filteredZonesByShotLocation,
    filteredZonesByFinish,
    filteredZonesByPass,
    filteredZonesByShotPlayerId,
    filteredZonesByZoneExitEntryEntity,
  ) => {
    const filteredZonesByShared = filteredZonesByGames.filter(zone => {
      return (
        filteredZonesBySelectedPlayerItems.includes(zone) &&
        filteredZonesBySuccess.includes(zone) &&
        filteredZonesByDanger.includes(zone) &&
        filteredZonesByLocation.includes(zone) &&
        filteredZonesByShotLocation.includes(zone) &&
        filteredZonesByFinish.includes(zone) &&
        filteredZonesByPass.includes(zone) &&
        filteredZonesByShotPlayerId.includes(zone) &&
        filteredZonesByZoneExitEntryEntity.includes(zone)
      );
    });

    return filteredZonesByShared;
  },
);

export const filteredZoneGameEntitiesSelector = createSelector(
  [selectMainFilter, selectPlayers, (_, filteredZones: IZoneEntity[]) => filteredZones],
  ({ selectedGames }, playerState, filteredZones) => {
    if (!filteredZones) return [];

    return createZoneGameEntities(selectedGames, filteredZones, playerState.byId);
  },
);
// End of shared selectors
// Start of exits selectors
export const filteredZoneExitsByResultSelector = createSelector(
  [selectZonesFilter, filteredZonesByEntitySelector],
  ({ zoneExitResult }, filteredZonesByEntity) => {
    if (zoneExitResult.value === 'all') return filteredZonesByEntity;

    return filterZoneExitsByResult(filteredZonesByEntity, zoneExitResult.value);
  },
);
// End of exits selectors
// Beginning of the dumpin selectors
export const filteredZoneDumpInOutsByResultSelector = createSelector(
  [selectVideomapsFilter, selectMainFilter, selectZonesFilter, filteredZonesByEntitySelector],
  ({ entity }, { selectedPlayerItems, selectedTeam }, { dumpInOutResult }, zoneDumpInOuts) => {
    if (dumpInOutResult.value === 'all') return zoneDumpInOuts;

    const filteredPlayers = selectedPlayerItems.filter(item => item.selectedPlayer);

    return filterDumpInOutsByResultSelector(
      zoneDumpInOuts,
      dumpInOutResult.value,
      entity,
      filteredPlayers,
      selectedTeam,
    );
  },
);
// End of the zone dumpin selectors
// Start of shared summary selectors
export const filteredUncontrolledZonesDataSelector = createSelector(
  [
    filteredZonesByDangerSelector,
    filteredZoneDumpInOutsByResultSelector,
    filteredZonesByFinishSelector,
  ],
  (filteredDumpInOutsByDanger, filteredZoneDumpInOutsByResult, filteredZonesByFinish) =>
    filteredDumpInOutsByDanger.filter(
      zone => filteredZoneDumpInOutsByResult.includes(zone) && filteredZonesByFinish.includes(zone),
    ),
);
// End of shared summary selectors
// Start of entries summary selectors
export const filteredZoneEntriesFilterDataSelector = createSelector(
  [selectZonesFilter, filteredZonesSelector, filteredUncontrolledZonesDataSelector],
  ({ zoneCategory }, filteredControlledZoneEntries, filteredUncontrolledZones) => {
    if (zoneCategory.value === 'controlled') {
      return filteredControlledZoneEntries;
    }

    if (zoneCategory.value === 'all') {
      return filteredControlledZoneEntries.filter(zone => filteredUncontrolledZones.includes(zone));
    }

    return filteredUncontrolledZones;
  },
);
// End of entries summary selectors
// Start of exits summary selectors
export const filteredControlledZoneExitsDataSelector = createSelector(
  [filteredZonesSelector, filteredZoneExitsByResultSelector],
  (filteredZones, filteredZoneExitsByResult) =>
    filteredZones.filter(zone => filteredZoneExitsByResult.includes(zone)),
);

export const filteredZoneExitsFilterDataSelector = createSelector(
  [
    selectZonesFilter,
    filteredControlledZoneExitsDataSelector,
    filteredUncontrolledZonesDataSelector,
  ],
  ({ zoneCategory }, filteredControlledZoneExits, filteredUncontrolledZoneExits) => {
    if (zoneCategory.value === 'controlled') {
      return filteredControlledZoneExits;
    }

    if (zoneCategory.value === 'all') {
      return filteredControlledZoneExits.filter(zone =>
        filteredUncontrolledZoneExits.includes(zone),
      );
    }

    return filteredUncontrolledZoneExits;
  },
);
