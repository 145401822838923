import { CSSProperties, FC } from 'react';

import { COLORS } from '../../../constants';
import { ICoordinates, ICoordinatesWithPlayerId, ISelectedPlayerItem } from '../../../types';
import { createClassNames, isSelectedPlayerSelected } from '../../../utils';
import { Heatmap } from '../Heatmap';
import './HeatmapPlayers.styles.scss';

interface IPlayerHeatData {
  id: string;
  heatData: ICoordinates[];
}

interface IHeatmapPlayersProps {
  data: ICoordinatesWithPlayerId[];
  selectedPlayerItems: ISelectedPlayerItem[];
  width?: number;
  height?: number;
  style?: CSSProperties;
}

const classNames = createClassNames('heatmap-players');

export const HeatmapPlayers: FC<IHeatmapPlayersProps> = ({
  data,
  selectedPlayerItems,
  width = 760,
  height = 582,
  style,
}) => {
  const playersHeatData = selectedPlayerItems.reduce<IPlayerHeatData[]>((acc, player) => {
    const isPlayerSelected = isSelectedPlayerSelected(player, false);

    if (!isPlayerSelected) return acc;

    const heatData: ICoordinates[] = data.filter(
      item => item.playerId === player.selectedPlayer?.value,
    );

    acc.push({
      id: player.id,
      heatData,
    });

    return acc;
  }, []);

  return (
    <div className={classNames()} data-testid='heatmap-players'>
      {playersHeatData.map(player => {
        const gradient = getGradientById(player.id);

        return (
          <Heatmap key={player.id} data={player.heatData} {...{ width, height, gradient, style }} />
        );
      })}
    </div>
  );
};

const getGradientById = (id: string) => {
  if (id === '0') {
    return gradientRed;
  }

  if (id === '1') {
    return gradientPurple;
  }

  if (id === '2') {
    return gradientCyan;
  }

  if (id === '3') {
    return gradientTeal;
  }

  return gradientOrange;
};

const gradientRed = {
  0.2: COLORS.red[50],
  0.4: COLORS.red[60],
  0.6: COLORS.red[70],
  0.8: COLORS.red[80],
  1.0: COLORS.red[90],
};

const gradientPurple = {
  0.2: COLORS.purple[40],
  0.4: COLORS.purple[50],
  0.6: COLORS.purple[60],
  0.8: COLORS.purple[70],
  1.0: COLORS.purple[80],
};

const gradientCyan = {
  0.2: COLORS.cyan[40],
  0.4: COLORS.cyan[50],
  0.6: COLORS.cyan[60],
  0.8: COLORS.cyan[70],
  1.0: COLORS.cyan[80],
};

const gradientTeal = {
  0.2: COLORS.teal[40],
  0.4: COLORS.teal[50],
  0.6: COLORS.teal[60],
  0.8: COLORS.teal[70],
  1.0: COLORS.teal[80],
};

const gradientOrange = {
  0.2: COLORS.orange[30],
  0.4: COLORS.orange[40],
  0.6: COLORS.orange[50],
  0.8: COLORS.orange[60],
  1.0: COLORS.orange[70],
};
