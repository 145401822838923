import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DISABLED_TABS_ON_OFF } from '../../constants';
import {
  filteredMainFilterDataSelector,
  getFormations,
  getFormationsPlayersStats,
  selectMainFilter,
} from '../../features';
import {
  IBaseFormationParams,
  IMainFilterForm,
  INavigationContentKeys,
  ITeamsFormationRequestBody,
} from '../../types';
import { createFormationFromSelectedPlayers, isAtLeastOnePlayerOn } from '../../utils';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

const disabledOnOff = DISABLED_TABS_ON_OFF.includes(INavigationContentKeys.playerCombinations);

export const useFetchPlayerCombinations = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam, selectedPlayerItems } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const competitionsUuids = filteredParts.map(part => part.id);
  const filteredSelected = Object.values(selectedPlayerItems).filter(item => item.selectedPlayer);
  const formation = createFormationFromSelectedPlayers(filteredSelected);
  const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);
  const isValidFormation = formation.length > 0 && isSomePlayerOn;
  const teamUuid = selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : '';

  const fetchPlayerCombinations = (data: Partial<IMainFilterForm>) => {
    const { requestBodyBase } = prepareBaseRequestBody(data);

    const requestBody: ITeamsFormationRequestBody = {
      ...requestBodyBase,
      formation: isValidFormation ? formation : undefined,
      metrics: normalizeMetrics(),
    };

    const actionBaseParams: IBaseFormationParams = {
      competitionsUuids,
      teamUuid,
      body: requestBody,
    };

    dispatch(getFormations(actionBaseParams));
    dispatch(
      getFormationsPlayersStats({
        ...actionBaseParams,
        isTeammate: true,
      }),
    );
    dispatch(
      getFormationsPlayersStats({
        ...actionBaseParams,
        isOpponent: true,
      }),
    );
  };

  return fetchPlayerCombinations;
};
