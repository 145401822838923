import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  comparisonTypeOptions,
  entityOptions,
  minimapComparisonOptions,
  passTypeOptions,
  shotCategoryOptions,
} from '../../../constants';
import { IFormation, ISelectOption, IVideomapsFilterState } from '../../../types';
import { getDefaultPredefinedOptionByValue } from '../../../utils';

const initialState: IVideomapsFilterState = {
  entity: getDefaultPredefinedOptionByValue(entityOptions, 'team'),
  shotCategory: getDefaultPredefinedOptionByValue(shotCategoryOptions),
  passType: getDefaultPredefinedOptionByValue(passTypeOptions),
  drawAllPassesTrajectories: true,
  compareBy: getDefaultPredefinedOptionByValue(minimapComparisonOptions, 'attackType'),
  comparisonType: getDefaultPredefinedOptionByValue(comparisonTypeOptions, 'shotsProduction'),
  playerId: undefined,
  teamId: undefined,
  formations: undefined,
  filterByProp: 'playerId',
};

export const videomapsFilterSlice = createSlice({
  name: 'videomapsFilter',
  initialState,
  reducers: {
    setEntity: (state, action: PayloadAction<ISelectOption>) => {
      state.entity = action.payload;
    },
    setShotCategory: (state, action: PayloadAction<ISelectOption>) => {
      state.shotCategory = action.payload;
    },
    setPassType: (state, action: PayloadAction<ISelectOption>) => {
      state.passType = action.payload;
    },
    setDrawAllPassesTrajectories: (state, action: PayloadAction<boolean | undefined>) => {
      state.drawAllPassesTrajectories = action.payload;
    },
    setCompareBy: (state, action: PayloadAction<ISelectOption>) => {
      state.compareBy = action.payload;
    },
    setComparisonType: (state, action: PayloadAction<ISelectOption>) => {
      state.comparisonType = action.payload;
    },
    setPlayerId: (state, action: PayloadAction<string | undefined>) => {
      state.playerId = action.payload;
    },
    setTeamId: (state, action: PayloadAction<string | undefined>) => {
      state.teamId = action.payload;
    },
    setFormations: (state, action: PayloadAction<IFormation[] | undefined>) => {
      state.formations = action.payload;
    },
    setFilterByProp: (state, action: PayloadAction<string | undefined>) => {
      state.filterByProp = action.payload;
    },
    resetVideomapsFilter: (
      _,
      action: PayloadAction<Partial<IVideomapsFilterState> | undefined>,
    ) => ({
      ...initialState,
      ...action.payload,
    }),
  },
});

export const {
  setEntity,
  setShotCategory,
  setPassType,
  setDrawAllPassesTrajectories,
  setCompareBy,
  setComparisonType,
  setPlayerId,
  setTeamId,
  setFormations,
  setFilterByProp,
  resetVideomapsFilter,
} = videomapsFilterSlice.actions;
