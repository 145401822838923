import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  enterExitLocationOptions,
  entryTypeOptions,
  exitTypeOptions,
  puckGainTypeOptions,
  shotDangerOptions,
  shotGameActionTypeOptions,
  shotLocationExtendedOptions,
  shotTypeOptions,
} from '../../../constants';
import {
  dataSettingsFilterAllDataSelector,
  deleteUserDatasetById,
  selectDataSettingsFilter,
  selectDefinedDatasets,
  selectUserDatasets,
  setDataSettingsEnterExitLocation,
  setDataSettingsEntryType,
  setDataSettingsExitType,
  setDataSettingsGameActionType,
  setDataSettingsNetZone,
  setDataSettingsPuckGainType,
  setDataSettingsSelectedGroup,
  setDataSettingsSelectedMetrics,
  setDataSettingsShotDanger,
  setDataSettingsShotLocation,
  setDataSettingsShotType,
  setShowHelpSidebar,
} from '../../../features';
import { useDataTemplateOptions, useLocationPaths } from '../../../hooks';
import { ITranslationKeys } from '../../../i18n/types';
import { HelpIcon } from '../../../icons';
import {
  IDatasetMetric,
  IEntity,
  IMetricCategory,
  INetZoneUnion,
  ISectionNames,
  ISelectOption,
} from '../../../types';
import {
  createClassNames,
  getMetricLabel,
  getMetricsFromDataTemplate,
  getMetricsGroupOptions,
  getMetricsGroupsBase,
  getMetricsOptions,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import { GridLayout } from '../../GridLayout';
import { NavigationBarButton } from '../../NavigationBarButton';
import { GoalNet } from '../../goalNetComponents';
import { MetricButton } from '../../metricComponents';
import { ConfirmDialog, Modal } from '../../modalComponents';
import { ChangeDataset } from '../ChangeDataset';
import { DataSettingsSections } from '../DataSettingsSections';
import { MetricParameterToggle } from '../MetricParameterToggle';
import { NewDataset } from '../NewDataset';
import { SelectionSidebar } from '../SelectionSidebar';
import './DataSettingsModal.styles.scss';

export interface IDataSettingsModalProps extends ISectionNames {
  /** Is the modal opened? */
  open: boolean;
  /** Metrics of one category (players, goalkeepers or teams). */
  metrics: IMetricCategory;
  /** Players, teams or goalkeepers? */
  entity?: IEntity;
  /** Callback function for close modal. */
  onClose: () => void;
}

const classNames = createClassNames('data-settings-modal');

/**
 * Modal component for display own data selection and dataset settings.
 */
export const DataSettingsModal: FC<IDataSettingsModalProps> = ({
  open,
  metrics,
  entity = IEntity.players,
  onClose,
  individualName,
  onIceName,
}) => {
  const { definedDatasets, defaultTemplates } = useAppSelector(selectDefinedDatasets);
  const {
    dataSettingsDataTemplate,
    selectedGroup,
    selectedMetrics,
    gameActionType,
    shotType,
    shotDanger,
    shotLocation,
    netZone,
    puckGainType,
    entryType,
    exitType,
    enterExitLocation,
  } = useAppSelector(selectDataSettingsFilter);
  const userDatasets = useAppSelector(selectUserDatasets);
  const { filteredByParameters, filteredMetricsSectionsToDisplay, metricsPrefix } = useAppSelector(
    state => dataSettingsFilterAllDataSelector(state, metrics),
  );
  const dispatch = useAppDispatch();

  const { activePage, activeTab } = useLocationPaths();
  const dataTemplateOptions = useDataTemplateOptions(entity);
  const { t } = useTranslation();

  const [saveDatasetOpen, setSaveDatasetOpen] = useState(false);
  const [editDatasetOpen, setEditDatasetOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const datasetsGroupsBase = getMetricsGroupsBase(metrics);
    const datasetsGroupOptions = getMetricsGroupOptions(datasetsGroupsBase);

    dispatch(setDataSettingsSelectedGroup(datasetsGroupOptions[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics]);

  useEffect(() => {
    if (dataSettingsDataTemplate && definedDatasets && defaultTemplates) {
      const screenId = `${activePage}_${activeTab}`;
      const dataTemplateMetrics = getMetricsFromDataTemplate(
        definedDatasets,
        defaultTemplates,
        userDatasets.byId,
        dataSettingsDataTemplate,
        screenId,
        entity,
      );

      const predefinedMetrics: ISelectOption<IDatasetMetric>[] = dataTemplateMetrics?.map(
        datasetMetric => {
          const metric = datasetMetric.parameter
            ? metrics.byId[datasetMetric.origin]
            : metrics.byId[datasetMetric.code];

          const metricLabel = getMetricLabel(metric, datasetMetric);

          return {
            value: datasetMetric.code,
            label: metricLabel,
            additionalInfo: datasetMetric,
          };
        },
      );

      dispatch(setDataSettingsSelectedMetrics(predefinedMetrics));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSettingsDataTemplate, definedDatasets, userDatasets.byId]);

  const displayNetZones =
    selectedGroup &&
    filteredByParameters.some(
      metric => metric.metricGroups[selectedGroup.value].metricParameters.net_zones,
    );

  useEffect(() => {
    if (!displayNetZones && netZone) {
      dispatch(setDataSettingsNetZone(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNetZones]);

  const handleChangeSelectedGroup = (
    value: ISelectOption,
    prevValue: ISelectOption | undefined,
  ) => {
    if (!prevValue || value.value !== prevValue.value) {
      dispatch(setDataSettingsSelectedGroup(value));
    }
  };

  const handleChangeNetZone = (value: INetZoneUnion, prevValue: INetZoneUnion | undefined) => {
    if (value === prevValue) {
      dispatch(setDataSettingsNetZone(undefined));
    } else {
      dispatch(setDataSettingsNetZone(value));
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDeleteDialog = () => {
    if (dataSettingsDataTemplate) {
      dispatch(deleteUserDatasetById(dataSettingsDataTemplate.value))
        .unwrap()
        .then(() => {
          toast.success(t(ITranslationKeys.datasetDeletedSuccessfully), {
            toastId: ITranslationKeys.datasetDeletedSuccessfully,
          });
          handleCloseDeleteDialog();
        })
        .catch(error => {
          toast.error(t(ITranslationKeys.datasetDeleteFailed), {
            toastId: ITranslationKeys.datasetDeleteFailed,
          });
          handleCloseDeleteDialog();
          console.error('[DataSettingsModal]: Delete user dataset error:', error);
        });
    }
  };

  const datasetsGroupsBase = getMetricsGroupsBase(metrics);
  const datasetsGroupOptions = getMetricsGroupOptions(datasetsGroupsBase);
  const filteredMetricsOptions = useMemo(
    () => getMetricsOptions(filteredByParameters, metricsPrefix, null),
    [filteredByParameters, metricsPrefix],
  );

  if (!open) {
    return null;
  }

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({}),
      })}
      data-testid='data-settings-modal'
    >
      <div className={classNames('data-select')}>
        <div>
          <div className={classNames('help-button')}>
            <NavigationBarButton
              icon={<HelpIcon />}
              label={ITranslationKeys.help}
              onClick={() => dispatch(setShowHelpSidebar(true))}
            />
          </div>
          <div className={classNames('header')}>{t(ITranslationKeys.dataGroups)}</div>
          <hr className={classNames('divider')} />
          <GridLayout columns={5} gap={'0 3.2rem'}>
            {datasetsGroupOptions.map(option => (
              <MetricButton
                key={option.value}
                label={option.label}
                onClick={() => handleChangeSelectedGroup(option, selectedGroup)}
                isActive={selectedGroup?.value === option.value}
              />
            ))}
          </GridLayout>
        </div>
        <div>
          <div className={classNames('header')}>{t(ITranslationKeys.eventParameters)}</div>
          <div className={classNames('divider')}>
            <hr />
          </div>
          <GridLayout columns={5} gap={'3.2rem'}>
            <MetricParameterToggle
              selected={gameActionType}
              options={shotGameActionTypeOptions}
              setFunction={setDataSettingsGameActionType}
              parameterName='action_type'
              availableMetrics={filteredByParameters}
              captionTranslationKey={ITranslationKeys.attackType}
              hasOnlyOneColumn
              hasSmallGap
            />
            <MetricParameterToggle
              selected={shotType}
              options={shotTypeOptions}
              setFunction={setDataSettingsShotType}
              parameterName='shot_type'
              availableMetrics={filteredByParameters}
              captionTranslationKey={ITranslationKeys.shotTypes}
              hasOnlyOneColumn
              hasSmallGap
            />
            <MetricParameterToggle
              selected={shotDanger}
              options={shotDangerOptions}
              setFunction={setDataSettingsShotDanger}
              parameterName='shot_danger'
              availableMetrics={filteredByParameters}
              captionTranslationKey={ITranslationKeys.shotsDanger}
              hasOnlyOneColumn
              hasSmallGap
            />
            <MetricParameterToggle
              selected={shotLocation}
              options={shotLocationExtendedOptions}
              setFunction={setDataSettingsShotLocation}
              parameterName='shot_location'
              availableMetrics={filteredByParameters}
              captionTranslationKey={ITranslationKeys.shotLocation}
              hasOnlyOneColumn
              hasSmallGap
            />
            <MetricParameterToggle
              selected={puckGainType}
              options={puckGainTypeOptions}
              setFunction={setDataSettingsPuckGainType}
              parameterName='puck_gain_type'
              availableMetrics={filteredByParameters}
              captionTranslationKey={ITranslationKeys.puckGainType}
              hasOnlyOneColumn
              hasSmallGap
            />
            <MetricParameterToggle
              selected={entryType}
              options={entryTypeOptions}
              setFunction={setDataSettingsEntryType}
              parameterName='entry_type'
              availableMetrics={filteredByParameters}
              captionTranslationKey={ITranslationKeys.entryType}
              hasOnlyOneColumn
              hasSmallGap
            />
            <MetricParameterToggle
              selected={exitType}
              options={exitTypeOptions}
              setFunction={setDataSettingsExitType}
              parameterName='exit_type'
              availableMetrics={filteredByParameters}
              captionTranslationKey={ITranslationKeys.exitType}
              hasOnlyOneColumn
              hasSmallGap
            />
            <MetricParameterToggle
              selected={enterExitLocation}
              options={enterExitLocationOptions}
              setFunction={setDataSettingsEnterExitLocation}
              parameterName='enter_exit_location'
              availableMetrics={filteredByParameters}
              captionTranslationKey={ITranslationKeys.enterExitLocation}
              hasOnlyOneColumn
              hasSmallGap
            />
            {displayNetZones && (
              <GoalNet
                onZoneChange={newValue => handleChangeNetZone(newValue, netZone)}
                activeZones={netZone ? [netZone] : undefined}
                isClickable
                variant='blue'
              />
            )}
          </GridLayout>
        </div>
        <DataSettingsSections
          onChange={newValue => dispatch(setDataSettingsSelectedMetrics(newValue))}
          selected={selectedMetrics}
          options={filteredMetricsOptions}
          filteredMetricsSections={filteredMetricsSectionsToDisplay}
          {...{ individualName, onIceName }}
        />
      </div>
      <div className={classNames('selection-side-bar')}>
        <SelectionSidebar
          metrics={metrics}
          dataTemplateOptions={dataTemplateOptions}
          onClose={onClose}
          setSaveDatasetOpen={setSaveDatasetOpen}
          setEditDatasetOpen={setEditDatasetOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
        />
      </div>
      <Modal open={saveDatasetOpen} size='small'>
        <NewDataset entity={entity} onClose={() => setSaveDatasetOpen(false)} />
      </Modal>
      <Modal open={editDatasetOpen} size='small'>
        <ChangeDataset entity={entity} onClose={() => setEditDatasetOpen(false)} />
      </Modal>
      <Modal open={deleteDialogOpen} size='small'>
        <ConfirmDialog
          onConfirm={handleConfirmDeleteDialog}
          onCancel={handleCloseDeleteDialog}
          message={t(ITranslationKeys.areYouSureDeleteUser)}
          type='danger'
        />
      </Modal>
    </div>
  );
};
