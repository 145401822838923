import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectMainFilter, selectTrend } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IEntity,
  IMetricWithDatasetMetric,
  ISelectOption,
  ITimePeriodPlayerStatsRecord,
  ITrendGoalkeeperStatsTableData,
} from '../../../../types';
import { getMetricsWithValue } from '../../../../utils';

const getTableData = (
  tableTimePeriodPlayerStats: ITimePeriodPlayerStatsRecord,
  metrics: IMetricWithDatasetMetric[],
  selectedGoalkeeper: ISelectOption,
  selectedTeam: ISelectOption,
) => {
  const tableData = Object.values(tableTimePeriodPlayerStats).map<ITrendGoalkeeperStatsTableData>(
    (item, index) => {
      const metricsWithValue = getMetricsWithValue(metrics, item.stats);

      return {
        playerId: selectedGoalkeeper.value,
        teamId: selectedTeam.value,
        gameIds: item.gameIds || [],
        dateFrom: item.dateFrom,
        dateTo: item.dateTo,
        rowIndex: index,
        toi: item.stats.toi,
        stats: metricsWithValue,
      };
    },
  );

  return tableData;
};

export const useDataForTable = () => {
  const { tableTimePeriodPlayerStats } = useAppSelector(selectTrend);
  const { selectedGoalkeeper, selectedTeam } = useAppSelector(selectMainFilter);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: ITrendGoalkeeperStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.goalkeepers);

    if (filteredMetrics && selectedGoalkeeper && selectedTeam) {
      return getTableData(
        tableTimePeriodPlayerStats,
        filteredMetrics,
        selectedGoalkeeper,
        selectedTeam,
      );
    }

    return [];
  }, [tableTimePeriodPlayerStats, getFilteredMetrics, selectedGoalkeeper, selectedTeam]);

  return data;
};
