import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  CommonTable,
  DataSettingsModal,
  Loading,
  TableToolbar,
} from '../../../../components';
import {
  filteredMainFilterDataSelector,
  getGamelog,
  selectDataSettingsFilter,
  selectGamelog,
  selectMainFilter,
  selectMetrics,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useHandleOnSubmit,
  useMainSubmittedValues,
  useNormalizeMetrics,
  usePrepareBaseRequestBody,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import {
  IEntity,
  IExportRows,
  IGamelogGoalkeepersStatsTableData,
  IGamelogRequestBody,
  IMainFilterForm,
} from '../../../../types';
import {
  createClassNames,
  formatDateByLanguage,
  getScoreStateShortcut,
  handleExportToXLSX,
  roundNumberTo2Decimals,
} from '../../../../utils';
import './GamelogContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

export const useFetchGoalkeeperGamelog = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { selectedTeam, selectedGoalkeeper } = useAppSelector(selectMainFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchGoalkeeperGamelog = (data: Partial<IMainFilterForm>) => {
    const goalkeeperId = selectedGoalkeeper?.value;
    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IGamelogRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(IEntity.goalkeepers),
    };

    if (goalkeeperId) {
      const isSelectionValid = filteredParts.length > 0 && selectedTeam && selectedGoalkeeper;

      if (isSelectionValid) {
        dispatch(
          getGamelog({
            competitionsUuids,
            teamUuid: selectedTeam.value,
            goalkeeperUuid: goalkeeperId,
            body: requestBody,
          }),
        );
      }
    }
  };

  return fetchGoalkeeperGamelog;
};

const classNames = createClassNames('goalkeepers-gamelog-content');

export const GamelogContent = () => {
  const { isLoading } = useAppSelector(selectGamelog);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();
  const submittedValues = useMainSubmittedValues();

  const data = useDataForTable(submittedValues);
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const isInitialMountRef = useRef(true);

  const fetchGoalkeeperGamelog = useFetchGoalkeeperGamelog();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGoalkeeperGamelog(values);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  const commonHeader = [
    t(ITranslationKeys.homeOnes),
    t(ITranslationKeys.awayOnes),
    t(ITranslationKeys.date),
    t(ITranslationKeys.result),
    'TOI',
  ];
  const tableRowCallback = (row: IGamelogGoalkeepersStatsTableData): IExportRows => {
    const scoreState = getScoreStateShortcut(row.score.state);

    return {
      homeTeam: row.gameTeams.homeTeam.shortcut,
      awayTeam: row.gameTeams.awayTeam.shortcut,
      date: formatDateByLanguage(new Date(row.date), 'dd.MM.yyyy'),
      score: `${row.score.home}:${row.score.away}${scoreState ? t(scoreState) : ''}`,
      toi: roundNumberTo2Decimals(row.toi / 60),
    };
  };

  if (isLoading) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  return (
    <div className={classNames()} data-testid='goalkeepers-page__gamelog-content'>
      <TableToolbar
        dataTemplateEntity={IEntity.goalkeepers}
        centerTablePartRef={centerTablePartRef}
        scrollSizePx={130}
        submittedGoalkeeperOption={submittedValues.goalkeeperOption}
        disablePositionSelection
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() =>
              handleExportToXLSX(
                t(ITranslationKeys.goalkeepersGamelog),
                commonHeader,
                tableRowCallback,
                data,
              )
            }
          />
        }
      />
      <CommonTable<IGamelogGoalkeepersStatsTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.goalkeepers}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.goaliesData}
          entity={IEntity.goalkeepers}
        />
      )}
    </div>
  );
};
