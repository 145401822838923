import {
  ColumnDef,
  ColumnHelper,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnCell,
  BasicColumnHeader,
  FormColumnCell,
  ImageColumn,
  TeamColumnCell,
  TranslatedColumnHeader,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { ITranslationKeys } from '../../../../i18n/types';
import { IColumnsConfig, IStandingsTableData } from '../../../../types';
import { createClassNames } from '../../../../utils';
import './standingsColumns.styles.scss';

const columnHelper = createColumnHelper<IStandingsTableData>();
const initialSorting: SortingState = [
  {
    id: 'competitionOrder',
    desc: false,
  },
];
const columnPinning: ColumnPinningState = {
  right: ['order', 'pts', 'xpts', 'gfga', 'games'],
};

const classNames = createClassNames('standings-content-columns');

const fixedColumns: ColumnDef<IStandingsTableData, any>[] = [
  columnHelper.accessor('competitionOrder', {
    header: props => (
      <BasicColumnHeader {...props}>
        <span className={classNames('index-cell')}>P</span>
      </BasicColumnHeader>
    ),
    cell: props => (
      <BasicColumnCell {...props}>
        <span className={classNames('index-cell')}>{props.row.original.competitionOrder}.</span>
      </BasicColumnCell>
    ),
  }),
  columnHelper.accessor('teamId', {
    header: () => '',
    cell: props => (
      <div className={classNames('logo-cell')}>
        <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />
      </div>
    ),
  }),
  columnHelper.accessor('teamName', {
    header: () => (
      <span className={classNames('team-cell')}>
        <TranslatedColumnHeader label={ITranslationKeys.team} />
      </span>
    ),
    cell: props => (
      <div className={classNames('team-cell')}>
        <TeamColumnCell {...props} />
      </div>
    ),
  }),
  columnHelper.accessor('order', {
    header: props => (
      <BasicColumnHeader {...props}>
        <span className={classNames('last-5-games-rank-cell')}>
          <TranslatedColumnHeader label={ITranslationKeys.rankShortcut} />
        </span>
      </BasicColumnHeader>
    ),
    cell: props => <BasicColumnCell {...props}>{props.row.original.order}.</BasicColumnCell>,
  }),
  columnHelper.accessor('games', {
    header: props => (
      <span className={classNames('form-cell')}>
        <TranslatedColumnHeader {...props} label={ITranslationKeys.form} />
      </span>
    ),
    cell: props => (
      <FormColumnCell {...props} games={props.getValue()} teamId={props.row.original.teamId} />
    ),
  }),
];

export const useShortTableColumnsConfig = (
  data: IStandingsTableData[],
): IColumnsConfig<IStandingsTableData> => {
  const columns = useMemo(() => {
    if (!data.length) return [];
    const dynamicColumns = createDynamicShortTableStatsColumnsDef(data, columnHelper);
    return fixedColumns.concat(dynamicColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const createDynamicShortTableStatsColumnsDef = (
  tableData: IStandingsTableData[],
  columnHelper: ColumnHelper<IStandingsTableData>,
) =>
  Object.keys(tableData[0]).reduce<ColumnDef<IStandingsTableData, any>[]>((acc, stat) => {
    const key = stat as keyof IStandingsTableData;
    if (
      key === 'order' ||
      key === 'teamId' ||
      key === 'teamName' ||
      key === 'xGF' ||
      key === 'xGA' ||
      key === 'competitionOrder' ||
      key === 'competitionL' ||
      key === 'competitionW' ||
      key === 'competitionLOT' ||
      key === 'competitionWOT' ||
      key === 'competitionxGA' ||
      key === 'competitionxGF' ||
      key === 'GP' ||
      key === 'games' ||
      key.includes('%')
    ) {
      return acc;
    }

    const label = key.includes('competition') ? key.replace('competition', '') : key;
    const id = key.toLocaleLowerCase().replace(':', '');
    acc.push(
      columnHelper.accessor(row => row[key], {
        id,
        header: props => <BasicColumnHeader {...props}>{label}</BasicColumnHeader>,
        cell: props => (
          <span className={classNames('metric-cell', { id })}>
            {typeof props.getValue() === 'number' ? Math.round(props.getValue()) : props.getValue()}
          </span>
        ),
      }),
    );
    return acc;
  }, []);
