import { FC } from 'react';

import { logosPathsTcms, logosPathsWithExtraliga } from '../../constants';
import { ILogoCardSharedProps } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import './LogoCard.styles.scss';

export interface ILogoCardProps extends ILogoCardSharedProps {
  /** Id of the team */
  teamId: string;
  /** Is the item disabled? */
  disabled?: boolean;
  /** Variant of the logo card hover effect (only when onClick is passed) */
  hoverEffect?: 'default' | 'borderless';
  /** Is the item active? */
  isActive?: boolean;
  /** Function to be called on component click */
  onClick?: () => void;
}

const classNames = createClassNames('logo-card');

export const LogoCard: FC<ILogoCardProps> = ({
  teamId,
  isActive = false,
  hoverEffect = 'default',
  shape = 'circle',
  variant = 'normal',
  disabled = false,
  onClick,
}) => (
  <div
    className={classNames({
      ...transformObjectKeysToKebabCase({
        disabled,
        hoverEffect,
        isActive,
        isClickable: !!onClick,
        shape,
        teamId,
      }),
    })}
    onClick={disabled ? undefined : onClick}
    data-testid='logo-card'
  >
    <img
      src={variant === 'normal' ? logosPathsWithExtraliga[teamId] : logosPathsTcms[teamId]}
      alt='team-logo'
      className={classNames('logo')}
    />
  </div>
);
