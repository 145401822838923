import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Loading, NoData } from '../../../../components';
import {
  selectDashboard,
  selectNavigation,
  selectTeams,
  setSelectedAwayTeam,
} from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { ISelectOption } from '../../../../types';
import { createClassNames } from '../../../../utils';
import { useDashboardContent } from '../../hooks';
import './DashboardContent.styles.scss';
import { DashboardTeamComparison, DashboardTeamSide } from './components';

const classNames = createClassNames('dashboard-content');

export const DashboardContent = () => {
  const dispatch = useAppDispatch();
  const { isLoadingMinimumInitialData } = useAppSelector(selectNavigation);
  const teams = useAppSelector(selectTeams);
  const {
    // Other
    selectedTeamIds,
    // Data
    areScheduledMatchesLoading,
    // Loading
    isShortTableLoading,
    // Errors
    scheduledMatchesError,
    teamStatsError,
    shortTableError,
  } = useAppSelector(selectDashboard);

  const { competitionIds, teamOptions } = useDashboardContent();

  if (
    isLoadingMinimumInitialData ||
    areScheduledMatchesLoading ||
    isShortTableLoading ||
    !teams.allIds.length
  ) {
    return (
      <div className={classNames()} data-testid='dashboard-content'>
        <Loading />
      </div>
    );
  }

  if (teamStatsError || shortTableError || scheduledMatchesError || !competitionIds) {
    return (
      <div className={classNames()} data-testid='dashboard-content'>
        <NoData message={ITranslationKeys.defaultErrorMessage} />
      </div>
    );
  }

  if (!selectedTeamIds.homeTeam || !selectedTeamIds.awayTeam) {
    return <NoData message={ITranslationKeys.pleaseSelectTeam} />;
  }

  const handleRightTeamChange = (team?: ISelectOption) => {
    if (!team) return;
    dispatch(setSelectedAwayTeam(team.value));
  };

  return (
    <div className={classNames()} data-testid='dashboard-content'>
      <DashboardTeamComparison
        rightTeamId={selectedTeamIds.awayTeam}
        leftTeamId={selectedTeamIds.homeTeam}
        teamOptions={teamOptions}
        onRightTeamChange={handleRightTeamChange}
      />
      <div className={classNames('team-rest')}>
        <DashboardTeamSide teamId={selectedTeamIds.homeTeam} {...{ competitionIds }} />
        <DashboardTeamSide teamId={selectedTeamIds.awayTeam} {...{ competitionIds }} />
      </div>
    </div>
  );
};
