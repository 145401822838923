export interface INavigationState {
  /** Change of value handles form submit. Useful for submit form button. */
  triggerContentReloadValue: number;
  /** Indicates loading of minimum initial data. Minimum initial data is last season complete info. */
  isLoadingMinimumInitialData?: boolean;
  /** Indicates loading of all initial data. All initial data are all seasons complete infos. */
  isLoadingAllInitialData?: boolean;
  /** Is click-through from table metrics to video disabled? */
  disableMetricVideo?: boolean;
}

export interface INavigationNotPersistedState {
  /** Is data loaded and filter initialized? */
  isInitialized?: boolean;
}

/** Page keys with main data. Also used for logs in tracking. */
export const INavigationDataPageKeys = {
  players: 'players',
  formations: 'formations',
  videomaps: 'videomaps',
  goalkeepers: 'goalkeepers',
  games: 'games',
  teams: 'teams',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type INavigationDataPageKeys =
  (typeof INavigationDataPageKeys)[keyof typeof INavigationDataPageKeys];

export const INavigationPageKeys = {
  dashboard: 'dashboard',
  standings: 'standings',
  ...INavigationDataPageKeys,
  accountManagement: 'account-management',
  tracking: 'tracking',
  cms: 'cms',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type INavigationPageKeys = (typeof INavigationPageKeys)[keyof typeof INavigationPageKeys];

export const INavigationContentKeys = {
  players: 'players',
  formations: 'formations',
  goalkeepers: 'goalkeepers',
  tabs: 'tabs',
  gamelog: 'gamelog',
  trend: 'trend',
  playerCard: 'player-card',
  comparePlayers: 'compare-players',
  similarityPlayers: 'similarity-players',
  pairs: 'pairs',
  playerCombinations: 'player-combinations',
  shots: 'shots',
  heatmap: 'heatmap',
  passes: 'passes',
  faceoffs: 'faceoffs',
  zoneEntries: 'entries',
  zoneExits: 'exits',
  shootouts: 'shootouts',
  netZones: 'net-zones',
  shotmap: 'shotmap',
  goalkeeperCard: 'goalkeeper-card',
  compareGoalkeepers: 'compare-coalkeepers',
  graphicOverview: 'graphic-overview',
  report: 'report',
  headToHead: 'h2h',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type INavigationContentKeys =
  (typeof INavigationContentKeys)[keyof typeof INavigationContentKeys];

export const INavigationTrackingContentKeys = {
  overview: 'overview',
  hokejlogic: 'hokejlogic',
  hokejcoach: 'hokejcoach',
  hokejplayer: 'hokejplayer',
  users: 'users',
  logins: 'logins',
  eventLog: 'event-log',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type INavigationTrackingContentKeys =
  (typeof INavigationTrackingContentKeys)[keyof typeof INavigationTrackingContentKeys];

export const INavigationCmsContentKeys = {
  help: 'help',
  metrics: 'metrics',
  templates: 'templates',
  users: 'users',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type INavigationCmsContentKeys =
  (typeof INavigationCmsContentKeys)[keyof typeof INavigationCmsContentKeys];
