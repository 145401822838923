import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../../../../i18n/types';
import { TcmsSaveIcon } from '../../../../../../icons';
import {
  IDatasetMetric,
  ISelectOptionWithParam,
  ISubgroupsMetricsRecord,
} from '../../../../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../../../../utils';
import { mapSectionedSubgroupMetrics } from '../../utils';
import { DefinedTemplatesCheckboxSection } from '../DefinedTemplatesCheckboxSection';
import './DefinedTemplatesCheckboxes.styles.scss';

export interface IDefinedTemplatesCheckboxesProps {
  activeLanguage: string;
  filteredMetricsOptions: ISelectOptionWithParam<IDatasetMetric>[];
  subgroupMetrics: ISubgroupsMetricsRecord;
  selectedMetrics: ISelectOptionWithParam<IDatasetMetric>[];
  isDirty: boolean;
  onCheckboxItemChange: (option: ISelectOptionWithParam<IDatasetMetric>) => void;
  onSaveButtonClick: () => void;
}

const classNames = createClassNames('defined-templates-checkboxes');

export const DefinedTemplatesCheckboxes: FC<IDefinedTemplatesCheckboxesProps> = ({
  activeLanguage,
  filteredMetricsOptions,
  subgroupMetrics,
  selectedMetrics,
  isDirty,
  onCheckboxItemChange,
  onSaveButtonClick,
}) => {
  const { t } = useTranslation();

  const definedTemplates = mapSectionedSubgroupMetrics(
    activeLanguage,
    subgroupMetrics,
    filteredMetricsOptions,
    selectedMetrics,
    ITranslationKeys.playerData,
    ITranslationKeys.playerOnIceData,
  );

  return (
    <div className={classNames()}>
      <h3>{t(ITranslationKeys.templateMetricTypes)}</h3>
      {definedTemplates.map(section => (
        <DefinedTemplatesCheckboxSection
          key={section.label}
          {...section}
          selectedMetrics={selectedMetrics}
          onItemChange={onCheckboxItemChange}
        />
      ))}
      <hr />
      <div
        className={classNames('save-button', {
          ...transformObjectKeysToKebabCase({
            isDirty,
          }),
        })}
        onClick={isDirty ? onSaveButtonClick : undefined}
      >
        <TcmsSaveIcon />
        {t(ITranslationKeys.save)}
      </div>
    </div>
  );
};
