import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  DataSettingsModal,
  Loading,
  SubrowsTable,
  TableToolbar,
} from '../../../../components';
import {
  selectDataSettingsFilter,
  selectMetrics,
  selectWowy,
  setIsWowyPercentilesActive,
  setOpenDataSettings,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useFetchPairs,
  useHandleOnSubmit,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import { IWowyTableData } from '../../../../types';
import { createClassNames, handleExportToXLSX } from '../../../../utils';
import './PairsContent.styles.scss';
import { WowyCheckboxRowButtons } from './components';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';
import { useDataToExport } from './useDataToExport';
import { useExtraRowHeaderRender } from './useExtraRowHeaderRender';

const getSubRows = (row: IWowyTableData): IWowyTableData[] => row.subRows;

const classNames = createClassNames('pairs-content');

export const PairsContent = () => {
  const { t } = useTranslation();
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { isLoading, activeRows, isWowyPercentilesActive, isWowyPercentilesLoading } =
    useAppSelector(selectWowy);
  const { metrics } = useAppSelector(selectMetrics);
  const dispatch = useAppDispatch();

  const [selectedFilterTeamId, setSelectedFilterTeamId] = useState<string | undefined>();

  const isInitialMountRef = useRef(true);

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const { selectedPlayerData, data } = useDataForTable(selectedFilterTeamId);
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const extraRowHeaderRender = useExtraRowHeaderRender(selectedPlayerData, selectedFilterTeamId);
  const getDataToExport = useDataToExport();

  const fetchPairs = useFetchPairs();
  const handleOnSubmit = useHandleOnSubmit((values, config) => {
    fetchPairs(values, config?.isPercentiles);
    setSelectedFilterTeamId(values.selectedTeam?.value);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading });

  const handleToggleLeaguePercentile = () => {
    dispatch(setIsWowyPercentilesActive(!isWowyPercentilesActive));
    if (!isWowyPercentilesActive) {
      handleOnSubmit({ isPercentiles: true });
    }
  };

  const renderTableOrInfoBox = () => {
    if (isLoading) {
      return <Loading />;
    }

    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) {
      return errorInfoBox;
    }

    return (
      <SubrowsTable<IWowyTableData>
        centerPartRef={centerTablePartRef}
        hideFirstRowInMainRow={!activeRows.together}
        getSubRows={getSubRows}
        highlightMainRow
        disableFirstCellBorder
        isAlwaysExpanded
        {...{ data, columns, columnPinning, initialSorting, extraRowHeaderRender }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='formations-page__pairs-content'>
      <TableToolbar
        centerTablePartRef={centerTablePartRef}
        isPercentilesActive={isWowyPercentilesActive}
        isPercentilesLoading={isWowyPercentilesLoading}
        onClickLeaguePercentile={handleToggleLeaguePercentile}
        scrollSizePx={130}
        isLoading={isLoading}
        breakElements
        exportButton={
          <Button
            label={ITranslationKeys.exportData}
            iconComponent={<DownloadIcon />}
            iconPosition='right'
            onClick={() => {
              const { dataToExport, commonHeader, tableRowCallback } = getDataToExport(
                data,
                selectedPlayerData,
              );
              handleExportToXLSX(
                t(ITranslationKeys.pairsTable),
                commonHeader,
                tableRowCallback,
                dataToExport,
              );
            }}
          />
        }
        extraElement={<WowyCheckboxRowButtons />}
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.players}
          open={open}
          onClose={() => dispatch(setOpenDataSettings(false))}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.teamsData}
        />
      )}
    </div>
  );
};
