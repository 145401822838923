import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../app/hooks';
import { selectPlayers } from '../../../../features';
import { useGetPlayersLabelByWowyType } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { IExportRows, IWowyTableData } from '../../../../types';
import {
  getPlayerPositionShortcut,
  prepareWowyTableDataToExport,
  roundNumberTo2Decimals,
} from '../../../../utils';

export const useDataToExport = () => {
  const players = useAppSelector(selectPlayers);
  const { t } = useTranslation();
  const getPlayersLabelByWowyType = useGetPlayersLabelByWowyType();

  const getDataToExport = (data: IWowyTableData[], selectedPlayer: IWowyTableData | undefined) => {
    const commonHeader = [
      t(ITranslationKeys.playerName),
      t(ITranslationKeys.position),
      'GP',
      'TOI',
    ];
    const tableRowCallback = (row: IWowyTableData): IExportRows => {
      const player = players.byId[row.player.id];

      return {
        playerName: getPlayersLabelByWowyType(row.player.displayName, row.wowyType),
        position: t(getPlayerPositionShortcut(player.position)).toString(),
        gp: row.gp,
        toi: roundNumberTo2Decimals(row.toi / 60),
      };
    };
    const dataToExport = prepareWowyTableDataToExport(data);

    if (selectedPlayer) {
      dataToExport.unshift(selectedPlayer);
    }

    return { dataToExport, commonHeader, tableRowCallback };
  };

  return getDataToExport;
};
