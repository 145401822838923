import {
  ColumnHelper,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnCell,
  BasicColumnHeader,
  DashboardColumnCell,
  TranslatedColumnHeader,
} from '../../../../components';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IAlertColors,
  IColumnsConfig,
  IDashboardMinigameLabelColor,
  IGameTeamsTableData,
} from '../../../../types';
import { createClassNames } from '../../../../utils';

const columnHelper = createColumnHelper<IGameTeamsTableData>();
const initialSorting: SortingState = [];
const columnPinning: ColumnPinningState = {
  right: ['season', 'game', 'gfga', 'minigame'],
};

const classNames = createClassNames('dashboard-content-columns');

const fixedColumns = [
  columnHelper.display({
    id: 'specialTeams',
    header: props => (
      <span className={classNames('special-teams-cell', { header: true })}>
        <TranslatedColumnHeader {...props} label={ITranslationKeys.specialTeams} />
      </span>
    ),
    cell: props => (
      <strong className={classNames('special-teams-cell')}>
        {props.row.index === 0 ? 'PP xGF/60' : 'SH xGA/60'}
      </strong>
    ),
  }),
  columnHelper.display({
    id: 'season',
    header: props => <TranslatedColumnHeader {...props} label={ITranslationKeys.season} />,
    cell: props => (
      <BasicColumnCell {...props}>
        <span className={classNames('season-cell')}>
          {
            props.row.original.stats?.['seasonal_' + (props.row.index === 0 ? 'xgf60' : 'xga60')]
              ?.value
          }
        </span>
      </BasicColumnCell>
    ),
  }),
  columnHelper.display({
    id: 'game',
    header: props => <TranslatedColumnHeader {...props} label={ITranslationKeys.match} />,
    cell: props => (
      <BasicColumnCell {...props}>
        <span className={classNames('game-cell')}>
          {props.row.original.stats?.[props.row.index === 0 ? 'xgf60' : 'xga60']?.value}
        </span>
      </BasicColumnCell>
    ),
  }),
  columnHelper.display({
    id: 'gfga',
    header: props => <BasicColumnHeader {...props}>GF:GA</BasicColumnHeader>,
    cell: props => {
      const ga = props.row.original.stats['ga'].value;
      const gf = props.row.original.stats['gf'].value;
      const color = ga > gf ? IAlertColors.danger : gf > ga ? IAlertColors.success : false;
      return <span className={classNames('gfga-cell', { color })}>{`${gf}:${ga}`}</span>;
    },
  }),
];

export const useSpecialColumnsConfig = (): IColumnsConfig<IGameTeamsTableData> => {
  const columns = useMemo(() => {
    const minigameColumn = createDashboardMinigameColumnsDefSpecial(columnHelper);
    return fixedColumns.concat(minigameColumn);
  }, []);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const createDashboardMinigameColumnsDefSpecial = <T extends IGameTeamsTableData>(
  columnHelper: ColumnHelper<T>,
) =>
  columnHelper.display({
    id: 'minigame',
    header: props => (
      <span className={classNames('minigame-cell')}>
        <TranslatedColumnHeader {...props} label={ITranslationKeys.minigame} />
      </span>
    ),
    cell: props => {
      const isPP = props.row.index === 0;
      const metricCode = isPP ? 'xgf60' : 'xga60';
      const seasonalMetric = props.row.original.stats['seasonal_' + metricCode].value;
      const gameMetric = props.row.original.stats[metricCode].value;
      const { color, label } = getSpecialMinigameConfig(gameMetric, seasonalMetric, isPP);

      return (
        <div className={classNames('minigame-cell')}>
          <DashboardColumnCell {...props} {...{ color, label }} />
        </div>
      );
    },
  });

const getSpecialMinigameConfig = (
  gameMetric: number,
  seasonalMetric: number,
  isPP: boolean,
): IDashboardMinigameLabelColor => {
  if (isPP) {
    if (gameMetric - seasonalMetric > 1) {
      return {
        label: ITranslationKeys.aboveAveragePerformance,
        color: IAlertColors.success,
      };
    }
    if (gameMetric - seasonalMetric < -1) {
      return {
        label: ITranslationKeys.belowAveragePerformance,
        color: IAlertColors.danger,
      };
    }
    return {
      label: ITranslationKeys.averagePerformance,
      color: IAlertColors.default,
    };
  }

  if (seasonalMetric - gameMetric > 1) {
    return {
      label: ITranslationKeys.aboveAveragePerformance,
      color: IAlertColors.success,
    };
  }
  if (seasonalMetric - gameMetric < -1) {
    return {
      label: ITranslationKeys.belowAveragePerformance,
      color: IAlertColors.danger,
    };
  }
  return {
    label: ITranslationKeys.averagePerformance,
    color: IAlertColors.default,
  };
};
