import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../i18n/types';
import { IDashboardGame } from '../../types';
import { createClassNames } from '../../utils';
import { LogoCard } from '../LogoCard';
import { RatioBar } from '../RatioBar';
import './DashboardTeamOverview.styles.scss';

export interface IDashboardTeamOverviewProps {
  /** Selected game (match) */
  dashboardGame: IDashboardGame;
  /** Displays content relative to the team id */
  displayForTeamId: string;
}

const classNames = createClassNames('dashboard-team-overview');

/** UI component of the dashboard team overview */
export const DashboardTeamOverview: FC<IDashboardTeamOverviewProps> = ({
  dashboardGame,
  displayForTeamId,
}) => {
  const { t } = useTranslation();
  const { homeTeam, awayTeam } = dashboardGame;
  const actualTeam = homeTeam.logo === displayForTeamId ? homeTeam : awayTeam;
  const opponentTeam = actualTeam.logo === homeTeam.logo ? awayTeam : homeTeam;

  const getWinTitle = () => {
    if (actualTeam.xW < 55 && actualTeam.xW > 45) {
      return t(ITranslationKeys.tightGame);
    }

    if (actualTeam.score > opponentTeam.score) {
      return actualTeam.xW > 55
        ? t(ITranslationKeys.expectedWin)
        : t(ITranslationKeys.unexpectedWin);
    }

    return actualTeam.xW > 55
      ? t(ITranslationKeys.unexpectedLoss)
      : t(ITranslationKeys.expectedLoss);
  };

  return (
    <div className={classNames()}>
      <div className={classNames('header')}>
        <div className={classNames('team', { homeTeam: true })}>
          <div className={classNames('stats')}>
            <p>{homeTeam.score}</p>
            <p>
              xG: <span>{Number(homeTeam.xG.toFixed(2))}</span>
            </p>
          </div>
          <LogoCard teamId={homeTeam.logo} />
        </div>
        <div className={classNames('team', { awayTeam: true })}>
          <LogoCard teamId={awayTeam.logo} />
          <div className={classNames('stats')}>
            <p className={classNames('score')}>{awayTeam.score}</p>
            <p>
              xG: <span>{Number(awayTeam.xG.toFixed(2))}</span>
            </p>
          </div>
        </div>
      </div>
      <div className={classNames('bars')}>
        <RatioBar
          label={getWinTitle()}
          valueLeft={homeTeam.xW}
          valueRight={awayTeam.xW}
          showPercent={true}
        />
        <RatioBar
          label={t(ITranslationKeys.shots) ?? ''}
          valueLeft={homeTeam.shots}
          valueRight={awayTeam.shots}
          showPercent={false}
        />
      </div>
    </div>
  );
};
