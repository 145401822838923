import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortEndHandler } from 'react-sortable-hoc';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import {
  ConfirmDialog,
  Modal,
  TcmsItemActions,
  TcmsSortableList,
} from '../../../../../../components';
import {
  createDataset,
  deleteDataset,
  selectDefinedDatasets,
  selectMetrics,
  setDataSettingsSelectedMetrics,
  updateDataset,
  updateDatasetDisable,
  updateDatasetOrder,
} from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { TcmsPlusIcon } from '../../../../../../icons';
import {
  IDataset,
  IEntity,
  ILanguageType,
  IMetricCategory,
  ISelectOption,
} from '../../../../../../types';
import {
  createClassNames,
  moveArrayItem,
  transformObjectKeysToKebabCase,
} from '../../../../../../utils';
import { mapDatasetGroupOptions, transformSelectedMetricsToSelectOptions } from '../../utils';
import { EditTemplateModal } from '../EditTemplateModal';
import './DefinedTemplatesSection.styles.scss';

export interface IDefinedTemplatesSectionProps {
  activeSection: IEntity | null;
  activeLanguage: keyof ILanguageType<string>;
  size: 'big' | 'medium' | 'small';
  metricsBySection: IMetricCategory;
  onSelectedListOptionChange: (newOption: ISelectOption) => void;
  setDatasetGroupOptions: Dispatch<SetStateAction<ISelectOption[]>>;
  onLeaveEdited: (callback: () => void) => void;
  activeTemplate: ISelectOption | undefined;
}

const classNames = createClassNames('defined-templates-section');

export const DefinedTemplatesSection: FC<IDefinedTemplatesSectionProps> = ({
  activeSection,
  activeLanguage,
  size,
  metricsBySection,
  onSelectedListOptionChange,
  setDatasetGroupOptions,
  onLeaveEdited,
  activeTemplate,
}) => {
  const { definedDatasets, defaultTemplates } = useAppSelector(selectDefinedDatasets);
  const { metrics } = useAppSelector(selectMetrics);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [optionToDelete, setOptionToDelete] = useState<ISelectOption>();
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [templateToEdit, setTemplateToEdit] = useState<IDataset>();
  const [templatesOptions, setTemplatesOptions] = useState<ISelectOption[]>([]);

  const sectionCategoryDataset =
    definedDatasets && activeSection ? definedDatasets[activeSection] : undefined;

  useEffect(() => {
    if (sectionCategoryDataset) {
      const listOptions = sectionCategoryDataset.allIds.map(id => ({
        value: id,
        label: sectionCategoryDataset.byId[id].name[activeLanguage] as ITranslationKeys,
      }));
      setTemplatesOptions(listOptions);

      if (!activeSection) return;

      setDatasetGroupOptions(
        mapDatasetGroupOptions(activeLanguage, activeSection, definedDatasets, metrics),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguage, definedDatasets, activeSection]);

  useEffect(() => {
    if (sectionCategoryDataset) {
      const selectedMetrics = transformSelectedMetricsToSelectOptions(
        sectionCategoryDataset.byId,
        metricsBySection,
        activeTemplate,
      );
      dispatch(setDataSettingsSelectedMetrics(selectedMetrics));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTemplate, definedDatasets]);

  const handleOnListSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const sortEndCallback = () => {
      if (!definedDatasets || !defaultTemplates || !activeSection) {
        return;
      }

      if (oldIndex !== newIndex) {
        const newArray = [...templatesOptions];
        moveArrayItem(newArray, oldIndex, newIndex);
        setTemplatesOptions(newArray);

        dispatch(
          updateDatasetOrder({
            newAllIds: newArray.map(item => item.value),
            category: activeSection,
            definedDatasets,
            defaultTemplates,
          }),
        );
      }
    };

    onLeaveEdited(sortEndCallback);
  };

  const handleAddTemplate = (templateName: ILanguageType<string>) => {
    if (definedDatasets && defaultTemplates && activeSection) {
      dispatch(
        createDataset({
          category: activeSection,
          ...templateName,
          definedDatasets,
          defaultTemplates,
        }),
      );
      setOpenAddModal(false);
    }
  };

  const handleConfirmDeleteDialog = () => {
    if (!optionToDelete || !activeSection || !definedDatasets || !defaultTemplates) return;

    dispatch(
      deleteDataset({
        id: optionToDelete.value,
        category: activeSection,
        definedDatasets,
        defaultTemplates,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(t(ITranslationKeys.itemDeletedSuccessfully), {
          toastId: ITranslationKeys.itemDeletedSuccessfully,
        });
        handleCloseDeleteDialog();
      })
      .catch(error => {
        toast.error(t(ITranslationKeys.itemDeleteFailed), {
          toastId: ITranslationKeys.itemDeleteFailed,
        });
        handleCloseDeleteDialog();
        console.error('[DefinedTemplatesSection]: Delete template item error:', error);
      });
  };

  const handleOnItemDisableClick = (option: ISelectOption) => {
    const onDisableClick = () => {
      const dataset = sectionCategoryDataset?.byId?.[option.value];

      if (!dataset || !definedDatasets || !defaultTemplates || !activeSection) {
        return;
      }

      dispatch(
        updateDatasetDisable({
          id: dataset.id,
          category: activeSection,
          disabled: !dataset.disabled,
          definedDatasets,
          defaultTemplates,
        }),
      );
    };

    onLeaveEdited(onDisableClick);
  };

  const handleEditTemplate = (templateName: ILanguageType<string>, id: string) => {
    if (definedDatasets && defaultTemplates && activeSection) {
      dispatch(
        updateDataset({
          id,
          category: activeSection,
          ...templateName,
          definedDatasets,
          defaultTemplates,
        }),
      );
      setTemplateToEdit(undefined);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOptionToDelete(undefined);
  };

  const handleOpenDeleteDialog = (option: ISelectOption) => {
    onLeaveEdited(() => setOptionToDelete(option));
  };

  const handleOpenEditDialog = (option: ISelectOption) => {
    onLeaveEdited(() => {
      const dataset = sectionCategoryDataset?.byId?.[option.value];
      setTemplateToEdit(dataset);
    });
  };

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({ size }),
      })}
    >
      <h3>{activeSection ? t(activeSection) : ''}</h3>
      <TcmsSortableList
        selected={templatesOptions.find(option => option.value === activeTemplate?.value)}
        options={templatesOptions}
        onItemClick={onSelectedListOptionChange}
        onSortEnd={handleOnListSortEnd}
        itemChildrenRenderer={option => (
          <TcmsItemActions
            item={option}
            isItemChecked={!sectionCategoryDataset?.byId?.[option.value]?.disabled}
            onItemEditClick={handleOpenEditDialog}
            onItemRemoveClick={handleOpenDeleteDialog}
            onItemDisableClick={handleOnItemDisableClick}
          />
        )}
        helperClass='sortable-helper'
        lockAxis='y'
        useDragHandle
        {...{ size }}
      />
      <div
        className={classNames('add-button')}
        onClick={() => onLeaveEdited(() => setOpenAddModal(true))}
        role='button'
      >
        <TcmsPlusIcon />
        {t(ITranslationKeys.addAnotherItem)}
      </div>
      <Modal open={!!optionToDelete} size='small'>
        <ConfirmDialog
          onConfirm={handleConfirmDeleteDialog}
          onCancel={handleCloseDeleteDialog}
          message={t(ITranslationKeys.areYouSureDeleteItem)}
          type='danger'
        />
      </Modal>
      {templateToEdit && (
        <EditTemplateModal
          openModal={!!templateToEdit}
          initialTemplateName={templateToEdit.name}
          onClose={() => setTemplateToEdit(undefined)}
          onSave={templateName => handleEditTemplate(templateName, templateToEdit.id)}
        />
      )}
      {openAddModal && (
        <EditTemplateModal
          openModal
          onClose={() => setOpenAddModal(false)}
          onSave={templateName => handleAddTemplate(templateName)}
        />
      )}
    </div>
  );
};
