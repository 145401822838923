import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  filteredGamesTablesGoalkeepersSelector,
  selectGames,
  selectGoalkeepers,
  selectMainFilter,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IEntity,
  IGamesTablesPlayerTableData,
  IGoalkeeperRecord,
  IMetricWithDatasetMetric,
  IPlayerWithTeamIdAndStats,
  ISelectOption,
} from '../../../../types';
import {
  filteredPlayersOfGameTablesByTeamId,
  getMetricsWithValue,
  getPlayerShortName,
} from '../../../../utils';

const getTableData = (
  playersWithStatsFiltered: IPlayerWithTeamIdAndStats[],
  goalkeeperRecord: IGoalkeeperRecord,
  metrics: IMetricWithDatasetMetric[],
  selectedGames: ISelectOption[],
) => {
  const gameIds = selectedGames.map(game => game.value);
  const tableData = playersWithStatsFiltered
    .reduce<IGamesTablesPlayerTableData[]>((acc, playersWithTeamIdAndStats) => {
      const player = goalkeeperRecord[playersWithTeamIdAndStats.id];
      const stats = getMetricsWithValue(metrics, playersWithTeamIdAndStats.stats);

      if (player) {
        acc.push({
          playerId: player.id,
          teamId: playersWithTeamIdAndStats.teamId,
          gameIds,
          player: {
            id: player.id,
            displayName: getPlayerShortName(player),
          },
          gp: playersWithTeamIdAndStats.stats.gp,
          toi: playersWithTeamIdAndStats.stats.toi,
          stats,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = (selectedFilterTeamId?: string) => {
  const { gamesTables } = useAppSelector(selectGames);
  const { goalkeepersById } = gamesTables;
  const goalkeepers = useAppSelector(selectGoalkeepers);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const filteredGamesTablesData = useAppSelector(state =>
    filteredGamesTablesGoalkeepersSelector(state, goalkeepersById),
  );

  const getFilteredMetrics = useFilteredMetricsForTable();

  const playersWithTeamIdAndStatsFiltered = useMemo(
    () =>
      filteredPlayersOfGameTablesByTeamId(
        filteredGamesTablesData.filter(player => player.id),
        selectedFilterTeamId,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goalkeepersById, selectedFilterTeamId],
  );

  const data: IGamesTablesPlayerTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.goalkeepers);

    if (filteredMetrics) {
      return getTableData(
        playersWithTeamIdAndStatsFiltered,
        goalkeepers.byId,
        filteredMetrics,
        selectedGames,
      );
    }

    return [];
  }, [playersWithTeamIdAndStatsFiltered, goalkeepers.byId, getFilteredMetrics, selectedGames]);

  return data;
};
