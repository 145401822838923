import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectDashboard, selectGoalkeepers } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IEntity,
  IGoalkeeperRecord,
  IGoalkeeperStatsTableData,
  IMetricWithDatasetMetric,
  IMetricWithValueRecord,
  IPlayerWithTeamIdAndStats,
} from '../../../../types';
import { createDatasetMetricFromMetricCode, getPlayerShortName } from '../../../../utils';
import { dashboardGoalkeeperTableMetrics } from '../../constants';

const getTableData = (
  goalkeepers: IPlayerWithTeamIdAndStats[],
  goalkeepersRecord: IGoalkeeperRecord,
  metrics: IMetricWithDatasetMetric[],
  teamId: string,
) => {
  const tableData = goalkeepers
    .reduce<IGoalkeeperStatsTableData[]>((acc, goalkeeper) => {
      const goalie = goalkeepersRecord[goalkeeper.id];
      if (
        !goalie.teamIds.includes(teamId) ||
        (goalie.currentTeam && goalie.currentTeam !== teamId)
      ) {
        return acc;
      }

      acc.push({
        playerId: goalkeeper.id,
        teamId: teamId,
        player: {
          id: goalkeeper.id,
          displayName: getPlayerShortName(goalie),
        },
        gp: goalkeeper.stats.gp,
        toi: goalkeeper.stats.toi,
        stats: metrics.reduce<IMetricWithValueRecord>((acc, metric) => {
          acc[metric.id] = {
            ...metric,
            value: goalkeeper.stats[metric.id],
          };
          return acc;
        }, {}),
      });
      return acc;
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useGoalkeeperDataForTable = (teamId: string) => {
  const { byId } = useAppSelector(selectGoalkeepers);
  const { table } = useAppSelector(selectDashboard);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IGoalkeeperStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(
      IEntity.goalkeepers,
      dashboardGoalkeeperTableMetrics.map(metric => createDatasetMetricFromMetricCode(metric)),
    );

    if (!table?.[teamId]?.goalkeepers || !filteredMetrics) return [];

    const { goalkeepers: tableGoalkeepers } = table[teamId];
    return getTableData(tableGoalkeepers, byId, filteredMetrics, teamId);
  }, [table, byId, teamId, getFilteredMetrics]);

  return data;
};
