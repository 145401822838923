import { TcmsEditIcon, TrashIcon } from '../../../icons';
import { createClassNames } from '../../../utils';
import { Switch } from '../Switch';
import './TcmsItemActions.styles.scss';

export interface ITcmsItemActionsProps<T> {
  item: T;
  isItemChecked: boolean;
  onItemEditClick?: (item: T) => void;
  onItemRemoveClick?: (item: T) => void;
  onItemDisableClick?: (item: T) => void;
}

const classNames = createClassNames('tcms-item-actions');

export const TcmsItemActions = <T,>({
  item,
  isItemChecked,
  onItemDisableClick,
  onItemEditClick,
  onItemRemoveClick,
}: ITcmsItemActionsProps<T>) => (
  <div className={classNames()}>
    {onItemDisableClick && (
      <div className={classNames('switch')}>
        <Switch checked={isItemChecked} onClick={() => onItemDisableClick(item)} />
      </div>
    )}
    {onItemEditClick && (
      <div className={classNames('edit')} onClick={() => onItemEditClick(item)}>
        <TcmsEditIcon />
      </div>
    )}
    {onItemRemoveClick && (
      <div className={classNames('delete')} onClick={() => onItemRemoveClick(item)}>
        <TrashIcon />
      </div>
    )}
  </div>
);
