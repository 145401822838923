import { useCallback } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { Sticker, SummaryPercentileColumnCell } from '../../../../components';
import { selectTeamsFormation } from '../../../../features';
import { ITablePart, ITeamsFormationSelectedPlayer } from '../../../../types';
import { secondsToMinutesAndSeconds } from '../../../../utils';
import { SelectedPlayerRowCenterPart } from '../shared';

export const useExtraRowHeaderRender = (
  selectedPlayer: ITeamsFormationSelectedPlayer | undefined,
  selectedTeamId: string | undefined,
) => {
  const { isTeamsFormationPercentilesActive, isTeamsFormationPercentilesLoading } =
    useAppSelector(selectTeamsFormation);

  const extraRowHeaderRender = useCallback(
    (tablePart: ITablePart = 'left') => {
      if (selectedPlayer) {
        if (tablePart === 'right') {
          if (isTeamsFormationPercentilesActive && !isTeamsFormationPercentilesLoading) {
            return (
              <tr key={selectedPlayer.player.id}>
                <th className='extra-header-cell'>
                  {selectedPlayer.summaryPercentile && (
                    <SummaryPercentileColumnCell value={selectedPlayer.summaryPercentile} />
                  )}
                </th>
              </tr>
            );
          }

          return undefined;
        }

        if (tablePart === 'center') {
          return <SelectedPlayerRowCenterPart {...{ selectedPlayer, selectedTeamId }} />;
        }

        return (
          <tr key={selectedPlayer.player.id}>
            <th className='extra-header-cell'></th>
            <th className='extra-header-cell'>
              <span style={{ display: 'flex' }}>
                <Sticker
                  label={selectedPlayer.player.displayName}
                  fontWeight='bold'
                  color='white'
                />
              </span>
            </th>
            <th className='extra-header-cell'></th>
            <th className='extra-header-cell'>{selectedPlayer.gp}</th>
            <th className='extra-header-cell'>{secondsToMinutesAndSeconds(selectedPlayer.toi)}</th>
          </tr>
        );
      }

      return null;
    },
    [
      selectedPlayer,
      selectedTeamId,
      isTeamsFormationPercentilesActive,
      isTeamsFormationPercentilesLoading,
    ],
  );

  return extraRowHeaderRender;
};
