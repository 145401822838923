export const COLORS = {
  white: {
    base: '#fff',
  },
  black: {
    base: '#000',
  },
  red: {
    base: '#d91e28',
    100: '#2d0709',
    90: '#520408',
    80: '#750e13',
    70: '#a1191f',
    60: '#d91e28',
    50: '#f94d56',
    40: '#fe8389',
    30: '#ffb3b8',
    20: '#ffd7d9',
    10: '#fff1f1',
  },
  magenta: {
    base: '#d02471',
    100: '#2a0a18',
    90: '#510124',
    80: '#740737',
    70: '#9f1653',
    60: '#d02471',
    50: '#ee4f96',
    40: '#ff7db6',
    30: '#ffaed2',
    20: '#ffd6e8',
    10: '#fff0f7',
  },
  purple: {
    base: '#8b32fc',
    100: '#1c0e30',
    90: '#310e5e',
    80: '#4a158b',
    70: '#6a1dc4',
    60: '#8b32fc',
    50: '#a668ff',
    40: '#bf92ff',
    30: '#d4b9ff',
    20: '#e8d9ff',
    10: '#f6f2ff',
  },
  blue: {
    base: '#1d5cff',
    500: '#ef4444',
    200: '#fecaca',
    100: '#010f41',
    90: '#04196c',
    80: '#08279c',
    70: '#0e3cce',
    60: '#1d5cff',
    50: '#4985ff',
    40: '#7aa7ff',
    30: '#a7c7ff',
    20: '#d0e1ff',
    10: '#edf5ff',
  },
  cyan: {
    base: '#0d70c3',
    100: '#061727',
    90: '#032649',
    80: '#04386d',
    70: '#08519a',
    60: '#0d70c3',
    50: '#1c90e8',
    40: '#38afff',
    30: '#84ceff',
    20: '#bbe5ff',
    10: '#e5f6ff',
  },
  teal: {
    base: '#057d79',
    90: '#032b30',
    80: '#014144',
    70: '#035d5d',
    60: '#057d79',
    50: '#089d9a',
    40: '#12bdba',
    30: '#40dbd9',
    20: '#9ff0f0',
    10: '#d9fbfb',
  },
  green: {
    base: '#198138',
    100: '#071908',
    90: '#022d0d',
    80: '#044417',
    70: '#0e6127',
    60: '#198138',
    50: '#24a248',
    40: '#42bf65',
    30: '#6fdd8c',
    20: '#a7f1ba',
    10: '#defbe6',
  },
  coolGray: {
    base: '#697077',
    100: '#121619',
    90: '#21272a',
    80: '#343a3f',
    70: '#4d5358',
    60: '#697077',
    50: '#878d96',
    40: '#a2a9b0',
    30: '#c1c7cd',
    20: '#dde1e6',
    10: '#f2f4f8',
  },
  gray: {
    base: '#6f6f6f',
    100: '#161616',
    90: '#262626',
    80: '#393939',
    70: '#525252',
    60: '#6f6f6f',
    50: '#8d8d8d',
    40: '#a8a8a8',
    30: '#c6c6c6',
    20: '#e0e0e0',
    10: '#f4f4f4',
  },
  warmGray: {
    base: '#736f6f',
    100: '#171414',
    90: '#272525',
    80: '#3c3838',
    70: '#565151',
    60: '#736f6f',
    50: '#8f8b8b',
    40: '#ada8a8',
    30: '#cac5c4',
    20: '#e5e0df',
    10: '#f7f3f2',
  },
  orange: {
    base: '#f46601',
    esports: '#ff6600',
    70: '#cb5501',
    60: '#f46601',
    50: '#f97419',
    40: '#fe842b',
    30: '#ff9f4b',
  },
  yellow: {
    20: '#f0c41b',
  },
};
