import { useEffect, useRef } from 'react';
import simpleheat from 'simpleheat';

import { IHeatmapGradient } from '../types';

type IUseSimpleHeatmap = (
  data: [number, number, number][],
  radius?: number,
  blur?: number,
  grandient?: IHeatmapGradient,
) => React.MutableRefObject<HTMLCanvasElement | null>;

/**
 * Hook that uses simpleheat library to draw heatmap.
 * @param data Data to draw heatmap
 * @param radius Size of the circle for one data point
 * @param blur Blur of the circle for one data point
 * @param gradient Gradient of the heatmap
 * @returns Canvas element reference
 */
export const useSimpleHeatmap: IUseSimpleHeatmap = (data, radius = 29, blur = 40, gradient) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const heat = simpleheat(canvasRef.current);
      if (gradient) {
        heat.gradient(gradient);
      }
      heat.radius(radius, blur);
      heat.data(data);
      heat.draw();
    }
  }, [data, radius, blur, gradient]);

  return canvasRef;
};
