import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { useAppSelector } from '../app/hooks';
import { selectAuth } from '../features';
import { useLocationPaths } from '../hooks';
import {
  INavigationCmsContentKeys,
  INavigationContentKeys,
  INavigationDataPageKeys,
  INavigationPageKeys,
  INavigationTrackingContentKeys,
  IRole,
} from '../types';
import { logScreen } from '../utils';
import { AccountManagementPage } from './AccountManagementPage';
import { DashboardPage } from './DashboardPage';
import { FormationsPage } from './FormationsPage';
import { GamesPage } from './GamesPage';
import { GoalkeepersPage } from './GoalkeepersPage';
import { LoginPage } from './LoginPage';
import { NavigationBar } from './NavigationBar';
import { PlayersPage } from './PlayersPage';
import { TeamsPage } from './TeamsPage';
import { VideomapsPage } from './VideomapsPage';
import {
  AccessLogPage,
  DefinedTemplatesPage,
  EmptyPage,
  EventLogPage,
  ExtraCoachPage,
  HelpManagementPage,
  HockeyLogicPage,
  HokejPlayerPage,
  NavigationBarTcms,
  OverviewPage,
  ScreenLogTrackingFormWrapper,
  UserManagementPage,
  UserPage,
} from './tcms';
import { DataPagesWrapper } from './wrappers';

const ProtectedClientRoute = () => {
  const { user } = useAppSelector(selectAuth);

  if (!user || (user.role !== IRole.admin && user.role !== IRole.client)) {
    return <Navigate to='/' replace />;
  }

  return <Outlet />;
};

const ProtectedAdminRoute = () => {
  const { user } = useAppSelector(selectAuth);

  if (!user || user.role !== IRole.admin) {
    return <Navigate to='/' replace />;
  }

  return <Outlet />;
};

const ProtectedRoute = () => {
  const { token } = useAppSelector(selectAuth);

  if (!token?.access_token) {
    return <Navigate to='/' replace />;
  }

  return <Outlet />;
};

const LoggedInRoute = () => {
  const { token } = useAppSelector(selectAuth);

  if (!token?.access_token) {
    return <LoginPage />;
  }

  return <Navigate to={INavigationPageKeys.dashboard} replace />;
};

const dataDashboardPagesRoutes = (
  <>
    <Route path={`${INavigationPageKeys.dashboard}`} element={<DashboardPage />} />
    <Route path={`${INavigationPageKeys.standings}`} element={<DashboardPage />} />
  </>
);

export const dataPagesRoutes = (
  <>
    <Route path={`${INavigationPageKeys.players}/*`} element={<PlayersPage />} />
    <Route path={`${INavigationPageKeys.formations}/*`} element={<FormationsPage />} />
    <Route path={`${INavigationPageKeys.videomaps}/*`} element={<VideomapsPage />} />
    <Route path={`${INavigationPageKeys.goalkeepers}/*`} element={<GoalkeepersPage />} />
    <Route path={`${INavigationPageKeys.games}/*`} element={<GamesPage />} />
    <Route path={`${INavigationPageKeys.teams}/*`} element={<TeamsPage />} />
  </>
);

export const otherRoutes = (
  <>
    {dataDashboardPagesRoutes}
    <Route path={INavigationPageKeys.accountManagement} element={<AccountManagementPage />} />
    <Route path='*' element={<Navigate to={INavigationPageKeys.dashboard} />} />
  </>
);

export const trackingScreenLogRoutes = (
  <>
    <Route path={INavigationTrackingContentKeys.hokejlogic} element={<HockeyLogicPage />} />
    <Route path={INavigationTrackingContentKeys.hokejcoach} element={<ExtraCoachPage />} />
  </>
);

export const trackingOtherRoutes = (
  <>
    <Route path={INavigationTrackingContentKeys.overview} element={<OverviewPage />} />
    <Route path={INavigationTrackingContentKeys.hokejplayer} element={<HokejPlayerPage />} />
    <Route path={INavigationTrackingContentKeys.users} element={<UserPage />} />
    <Route path={INavigationTrackingContentKeys.logins} element={<AccessLogPage />} />
    <Route path={INavigationTrackingContentKeys.eventLog} element={<EventLogPage />} />
    <Route path='/tracking' element={<Navigate to={INavigationTrackingContentKeys.overview} />} />
    <Route path='/tracking/*' element={<Navigate to={INavigationTrackingContentKeys.overview} />} />
  </>
);

export const cmsRoutes = (
  <>
    <Route path={INavigationCmsContentKeys.help} element={<HelpManagementPage />} />
    <Route path={INavigationCmsContentKeys.metrics} element={<EmptyPage />} />
    <Route path={INavigationCmsContentKeys.templates} element={<DefinedTemplatesPage />} />
    <Route path={INavigationCmsContentKeys.users} element={<UserManagementPage />} />
    <Route path='/cms' element={<Navigate to={INavigationCmsContentKeys.help} />} />
    <Route path='/cms/*' element={<Navigate to={INavigationCmsContentKeys.help} />} />
  </>
);

export const RoutesContainer = () => {
  const { activePage, activeTab } = useLocationPaths();

  useEffect(() => {
    if (activeTab) {
      const shouldLogPage = Object.values(INavigationDataPageKeys).some(key => key === activePage);
      const shouldLogTab = Object.values(INavigationContentKeys).some(key => key === activeTab);

      if (shouldLogPage && shouldLogTab) {
        logScreen(activePage, activeTab).catch(err => {
          console.error('[RoutesContainer] Log screen failed', err);
        });
      }
    }
  }, [activePage, activeTab]);

  return (
    <Routes>
      <Route path='/' element={<LoggedInRoute />} />
      <Route path='/' element={<NavigationBar />}>
        <Route element={<ProtectedRoute />}>
          <Route element={<DataPagesWrapper />}>{dataPagesRoutes}</Route>
          {otherRoutes}
        </Route>
      </Route>
      <Route element={<ProtectedClientRoute />}>
        <Route path={INavigationPageKeys.tracking} element={<NavigationBarTcms />}>
          <Route element={<ScreenLogTrackingFormWrapper />}>{trackingScreenLogRoutes}</Route>
          {trackingOtherRoutes}
        </Route>
      </Route>
      <Route element={<ProtectedAdminRoute />}>
        <Route path={INavigationPageKeys.cms} element={<NavigationBarTcms />}>
          {cmsRoutes}
        </Route>
      </Route>
    </Routes>
  );
};
