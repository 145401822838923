import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../../services/axiosInstance';
import {
  IBaseRequestBodyWithFormation,
  IGame,
  IGameGraphicOverview,
  IGameToiDTO,
  IGamesRequestBody,
  IGetGamePreviewAndSummaryParams,
  IHeadToHead,
} from '../../../types';
import { defaultCatchErrorCallback, parseHeadToHeadData } from '../../../utils';
import { parseGamePreviewData, parseGamesData } from './parseGames';

interface IBaseGamesParams {
  competitionsUuids: string;
}

interface IGetGamesParams extends IBaseGamesParams {
  teamUuid?: string;
  secondTeamUuid?: string;
  body?: IGamesRequestBody;
  // TODO: Dashboard - remove after dashboard endpoints are created
  dontRemovePreviousGames?: boolean;
}

interface IGetToiParams extends IBaseGamesParams {
  teamUuid: string;
  bodyToi?: IBaseRequestBodyWithFormation;
}

interface IGetHeadToHeadParams {
  competitionsUuids: string;
  matchUuid: string;
  teamUuid: string;
  body?: IBaseRequestBodyWithFormation;
}

const getGamesRequest = async ({
  competitionsUuids,
  teamUuid,
  secondTeamUuid,
  body,
}: IGetGamesParams) => {
  if (!teamUuid) {
    const games: IGame[] = await axiosInstance
      .get(`/match/${competitionsUuids}`)
      .then(response => parseGamesData(response.data))
      .catch(defaultCatchErrorCallback);

    return games;
  }

  const requestPath = secondTeamUuid
    ? `/match/${competitionsUuids}/${teamUuid}/${secondTeamUuid}`
    : `/match/${competitionsUuids}/${teamUuid}`;

  const games: IGame[] = await axiosInstance
    .post(requestPath, body ?? {})
    .then(response => parseGamesData(response.data))
    .catch(defaultCatchErrorCallback);

  return games;
};

const getToiRequest = async ({ competitionsUuids, teamUuid, bodyToi }: IGetToiParams) => {
  const requestPath = `/toi/${competitionsUuids}/${teamUuid}`;

  const gameToi: IGameToiDTO[] = await axiosInstance
    .post(requestPath, bodyToi ?? {})
    .then(response => response.data)
    .catch(defaultCatchErrorCallback);

  return gameToi;
};

export const getGames = createAsyncThunk<IGame[], IGetGamesParams>(
  'games/getGames',
  getGamesRequest,
);

export const getGamePreview = createAsyncThunk<
  IGameGraphicOverview,
  IGetGamePreviewAndSummaryParams
>('games/getGamePreview', async ({ competitionsUuids, matchUuid, teamUuid, gameInfo }) => {
  const gamePreview: IGameGraphicOverview = await axiosInstance
    .get(`/match/${competitionsUuids}/${matchUuid}/${teamUuid}/preview`)
    .then(response => parseGamePreviewData(response.data, gameInfo))
    .catch(defaultCatchErrorCallback);

  return gamePreview;
});

export const getToi = createAsyncThunk<IGameToiDTO[], IGetToiParams>('games/getToi', getToiRequest);

export const getHeadToHead = createAsyncThunk<IHeadToHead, IGetHeadToHeadParams>(
  'games/getHeadToHead',
  async ({ competitionsUuids, matchUuid, teamUuid, body }) => {
    const requestPath = `/match/${competitionsUuids}/${matchUuid}/${teamUuid}/headToHead`;

    const headToHead: IHeadToHead = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseHeadToHeadData(response.data))
      .catch(defaultCatchErrorCallback);

    return headToHead;
  },
);

export const getGamesWithToi = createAsyncThunk<IGame[], IGetToiParams & IGetGamesParams>(
  'games/getGamesWithToi',
  async ({ competitionsUuids, teamUuid, secondTeamUuid, body, bodyToi }) => {
    const games = await getGamesRequest({ competitionsUuids, teamUuid, secondTeamUuid, body });
    const gameTOIs = await getToiRequest({ competitionsUuids, teamUuid, bodyToi });

    return games.map(game => ({
      ...game,
      toi: gameTOIs.find(toiItem => toiItem.matchUuid === game.id)?.toi,
    }));
  },
);
