import { FC } from 'react';

import { ILogoCardSharedProps, ISelectOption } from '../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../utils';
import { LogoCard } from '../LogoCard';
import './LogoCardList.styles.scss';

export interface ILogoCardListProps extends ILogoCardSharedProps {
  /** Array of LogoCard components */
  options: ISelectOption[];
  /** Function to be called when some child component is clicked */
  onChange: (option: ISelectOption) => void;
  /** Selected option item */
  selected?: ISelectOption;
  /** Options that are disabled */
  disabledOptions?: ISelectOption[];
  /** If true, the content will be centered */
  centerContent?: boolean;
}

const classNames = createClassNames('logo-card-list');

export const LogoCardList: FC<ILogoCardListProps> = ({
  disabledOptions,
  options,
  selected,
  shape,
  variant,
  centerContent = false,
  onChange,
}) => (
  <div
    className={classNames({ ...transformObjectKeysToKebabCase({ centerContent }) })}
    data-testid='logo-card-list'
  >
    {options.map(option => (
      <LogoCard
        key={option.value}
        teamId={option.value}
        disabled={disabledOptions?.some(disabledOption => option.value === disabledOption.value)}
        isActive={selected?.value === option.value}
        onClick={() => onChange(option)}
        {...{ shape, variant }}
      />
    ))}
  </div>
);
