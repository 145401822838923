import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectDashboard, selectGames, selectTeams } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IEntity,
  IGameReportTeams,
  IGameTeamsTableData,
  IMetricWithDatasetMetric,
  IMetricWithValue,
  IStats,
  IStatsWithId,
  ITeam,
  ITeamRecord,
  ITeamStats,
} from '../../../../types';
import { createDatasetMetricFromMetricCode, getMetricsWithValue } from '../../../../utils';
import { dashboardSpecialTableMetrics } from '../../constants';

const getGameTeam = (
  team: ITeam,
  stats: IStats,
  metrics: IMetricWithDatasetMetric[],
  specialStats: ITeamStats,
  metricCode: 'xgf60' | 'xga60',
): IGameTeamsTableData => {
  const metricsWithValue = getMetricsWithValue(metrics, stats);
  const seasonalMetric: IMetricWithValue = {
    ...metricsWithValue[metricCode],
    value: specialStats.stats[metricCode],
  };
  metricsWithValue['seasonal_' + metricCode] = seasonalMetric;

  return {
    teamId: team.id,
    teamName: team.name,
    toi: stats.toi,
    stats: metricsWithValue,
    homeAway: 'home',
  };
};

const getStatsWithId = (gameReportTeams: IGameReportTeams, teamId: string): IStatsWithId =>
  Object.values(gameReportTeams).reduce<IStatsWithId>((acc, team) => {
    if (team.id === teamId) {
      return acc;
    }

    acc = team;
    return acc;
  }, {} as IStatsWithId);

const getTableData = (
  powerplay: IGameReportTeams,
  shorthanded: IGameReportTeams,
  teamStatsPP: ITeamStats,
  teamStatsSH: ITeamStats,
  teamsRecord: ITeamRecord,
  metrics: IMetricWithDatasetMetric[],
  teamId: string,
): IGameTeamsTableData[] => {
  const team = teamsRecord[teamId];

  return [
    getGameTeam(team, getStatsWithId(powerplay, teamId).stats, metrics, teamStatsPP, 'xgf60'),
    getGameTeam(team, getStatsWithId(shorthanded, teamId).stats, metrics, teamStatsSH, 'xga60'),
  ];
};

export const useSpecialDataForTable = (teamId: string) => {
  const teamsState = useAppSelector(selectTeams);
  const { gamesReport } = useAppSelector(selectGames);
  const { table } = useAppSelector(selectDashboard);
  const { teams } = gamesReport;

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IGameTeamsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(
      IEntity.teams,
      dashboardSpecialTableMetrics.map(metric => createDatasetMetricFromMetricCode(metric)),
    );

    if (!table[teamId]?.powerplay || !table[teamId]?.shorthanded || !filteredMetrics || !teams) {
      return [];
    }

    const { powerplay, shorthanded, teamStatsPP, teamStatsSH } = table[teamId];
    if (!powerplay || !shorthanded || !teamStatsPP || !teamStatsSH) {
      return [];
    }

    return getTableData(
      powerplay,
      shorthanded,
      teamStatsPP,
      teamStatsSH,
      teamsState.byId,
      filteredMetrics,
      teamId,
    );
  }, [table, teamId, teams, teamsState.byId, getFilteredMetrics]);

  return data;
};
