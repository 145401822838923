import {
  IBaseRequestBodyWithFormation,
  ICoordinates,
  IScore,
  IVideoIdAndTime,
  IVideoInfo,
} from './common.types';

export const INetZone = {
  br: 'br',
  ce: 'ce',
  ur: 'ur',
  fh: 'fh',
  ul: 'ul',
  bl: 'bl',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type INetZone = (typeof INetZone)[keyof typeof INetZone];

export type INetZoneUnion = INetZone | '';

export interface IShotsAndPassesStatus {
  download_type?: string;
}

export interface IShotAssist extends ICoordinates {
  player: string;
  time: number;
}

export interface IPassesShot extends ICoordinates {
  afterFO: boolean;
  crossIce: boolean;
  forecheck: boolean;
  inSlot: boolean;
  innerSlot: boolean;
  long: boolean;
  oddManRush: boolean;
  oneTimer: boolean;
  player: string;
  rebounds: boolean;
  reboundsCreated: boolean;
  rush: boolean;
  shotDanger: string;
  type: string;
  xG: number;
  emptyNet?: boolean;
  netZone?: INetZoneUnion;
}

export interface ISharedShotsAndPassesInfo extends IVideoIdAndTime {
  matchDate: string;
  score: IScore;
  realTime: string;
}

// type of pass / shot info request
export interface IPassAndShotInfoRequestParams {
  match: string;
  player: string;
  time: number;
}

// type of Formation and Team passes / shots / heatmaps request
export interface IPassesAndShotsRequestParams {
  competitionsUuids: string[];
  teamUuid: string;
}

export interface IPassesRequestBody extends IBaseRequestBodyWithFormation {}

interface ISharedShotsAndPassesBase {
  gameState: string;
  gameType: string;
  afterFO: boolean;
  forecheck: boolean;
  hasGoalie: boolean;
  long: boolean;
  rush: boolean;
  oddManRush: boolean;
}

export interface ISharedShotsAndPasses
  extends ICoordinates,
    Required<IVideoInfo>,
    ISharedShotsAndPassesBase {}

export interface IPass extends ISharedShotsAndPasses {
  completedSlot: boolean;
  nonCompletedSlot: boolean;
  behindTheNet: boolean;
  blocked: boolean;
  blockedToSlot: boolean;
  shot: IPassesShot;
  status?: IShotsAndPassesStatus; // TODO: vyřešit v rámci úpravy exportu dat
}

// response type of Formation and Team passes requests
export interface IPassesState {
  passes: IPass[];
  passesAgainst: IPass[];
  isLoading?: boolean;
  error?: string;
  isLoadedFirstTime?: boolean;
}

export interface ISharedShotsAndPassesDTO
  extends ICoordinates,
    ISharedShotsAndPassesInfo,
    ISharedShotsAndPassesBase {
  match: string;
  player: string;
  homeTeam: string;
  awayTeam: string;
  time: number;
}

export interface IPassDTO extends ISharedShotsAndPassesDTO {
  completedSlot: boolean;
  nonCompletedSlot: boolean;
  behindTheNet: boolean;
  blocked: boolean;
  blockedToSlot: boolean;
  shot: IPassesShot;
  status?: IShotsAndPassesStatus;
}
