import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosInstance } from '../../services/axiosInstance';
import {
  IBaseFormationParams,
  ICompetitionTable,
  IGameGraphicOverview,
  IGamesRequestBody,
  IGamesTablesFormations,
  IGetGamePreviewAndSummaryParams,
  IMinigraphBatteryTeam,
  IMinigraphBatteryTeamMetric,
  IPlayerWithTeamIdAndStats,
  IScheduledMatch,
  IShortTable,
  ITeamsFormationRequestBody,
} from '../../types';
import { buildPath, defaultCatchErrorCallback, urlBooleanParamResolver } from '../../utils';
import { parseGamePreviewData } from '../games/games/parseGames';
import {
  parseGamesTablesFormationData,
  parseGamesTablesPlayersData,
} from '../games/gamesTables/parseGamesTables';
import {
  parseCompetitionTableData,
  parseDashboardFormationsData,
  parseGoalkeepersTeamData,
  parseScheduledMatchesData,
  parseShortTableData,
} from './parseDashboard';

interface IBaseGamesParams {
  competitionsUuids: string[];
}

interface ILeagueRankingParams {
  isLeagueRanking?: boolean;
}

export interface IGetFormationsParams extends IBaseFormationParams, ILeagueRankingParams {
  isLast?: boolean;
}

interface IGetDashboardBaseParams {
  competitionsUuids: string;
}

interface IGetPlayersParams extends IBaseGamesParams {
  teamUuid: string;
  matchUuid: string;
  body: IGamesRequestBody;
}

interface IGetScheduledMatchesParams extends IGetDashboardBaseParams {}
interface IGetShortTableParams extends IGetDashboardBaseParams {}
interface IGetCompetitionTableParams extends IGetDashboardBaseParams {}
interface IGetGoalkeeperTeamStatsParams extends IGetDashboardBaseParams, ILeagueRankingParams {
  teamUuid: string;
  body: ITeamsFormationRequestBody;
}

export const getScheduledMatches = createAsyncThunk<IScheduledMatch[], IGetScheduledMatchesParams>(
  'dashboard/getScheduledMatches',
  async ({ competitionsUuids }) => {
    const requestPath = `/competition/${competitionsUuids}/scheduled-matches`;
    const ranks: IScheduledMatch[] = await axiosInstance
      .get(requestPath)
      .then(response => parseScheduledMatchesData(response.data))
      .catch(defaultCatchErrorCallback);

    return ranks;
  },
);

export const getShortTable = createAsyncThunk<IShortTable[], IGetShortTableParams>(
  'dashboard/getShortTable',
  async ({ competitionsUuids }) => {
    const requestPath = `/short-table/${competitionsUuids}`;
    const shortTable: IShortTable[] = await axiosInstance
      .get(requestPath)
      .then(response => parseShortTableData(response.data))
      .catch(defaultCatchErrorCallback);

    return shortTable;
  },
);

export const getCompetitionTable = createAsyncThunk<
  ICompetitionTable[],
  IGetCompetitionTableParams
>('dashboard/getCompetitionTable', async ({ competitionsUuids }) => {
  const requestPath = `/table/${competitionsUuids}`;
  const shortTable: ICompetitionTable[] = await axiosInstance
    .get(requestPath)
    .then(response => parseCompetitionTableData(response.data))
    .catch(defaultCatchErrorCallback);

  return shortTable;
});

// TODO: Remove all the code below after unified EP is created
const getGoalkeeperTeamStatsRequestUrl = (
  params: Omit<IGetGoalkeeperTeamStatsParams, 'body'>,
): string => {
  const { competitionsUuids, teamUuid, isLeagueRanking } = params;
  const base = `/goalkeeper/${competitionsUuids}/${teamUuid}/team`;

  return buildPath(base, [urlBooleanParamResolver(isLeagueRanking, 'index')]);
};

export const getGoalkeeperTeamStats = createAsyncThunk<
  IMinigraphBatteryTeamMetric,
  IGetGoalkeeperTeamStatsParams
>(
  'dashboard/getGoalkeeperTeamStats',
  async ({ competitionsUuids, teamUuid, body, isLeagueRanking }) => {
    const requestPath = getGoalkeeperTeamStatsRequestUrl({
      competitionsUuids,
      teamUuid,
      isLeagueRanking,
    });
    const minigraphBatteryTeamMetric: IMinigraphBatteryTeamMetric = await axiosInstance
      .post(requestPath, body)
      .then(response => parseGoalkeepersTeamData(response.data))
      .catch(defaultCatchErrorCallback);

    return minigraphBatteryTeamMetric;
  },
);

const getFormationsRequestUrl = (params: Omit<IGetFormationsParams, 'body'>): string => {
  const { competitionsUuids, teamUuid, isLeagueRanking } = params;
  const base = `/formation/${competitionsUuids}/${teamUuid}`;

  return buildPath(base, [urlBooleanParamResolver(isLeagueRanking, 'index')]);
};

export const getDashboardFormations = createAsyncThunk<IMinigraphBatteryTeam, IGetFormationsParams>(
  'dashboard/getDashboardFormations',
  async ({ competitionsUuids, teamUuid, body, isLeagueRanking }) => {
    const requestPath = getFormationsRequestUrl({
      competitionsUuids,
      teamUuid,
      isLeagueRanking,
    });

    const formation: IMinigraphBatteryTeam = await axiosInstance
      .post(requestPath, body ?? {})
      .then(response => parseDashboardFormationsData(response.data, teamUuid))
      .catch(defaultCatchErrorCallback);

    return formation;
  },
);

export const getDashboardGamePreview = createAsyncThunk<
  IGameGraphicOverview,
  IGetGamePreviewAndSummaryParams
>(
  'dashboard/getDashboardGamePreview',
  async ({ competitionsUuids, matchUuid, teamUuid, gameInfo }) => {
    const gamePreview: IGameGraphicOverview = await axiosInstance
      .get(`/match/${competitionsUuids}/${matchUuid}/${teamUuid}/preview`)
      .then(response => parseGamePreviewData(response.data, gameInfo))
      .catch(defaultCatchErrorCallback);

    return gamePreview;
  },
);

// Původně getGamesTablesGoalkeepers
export const getDashboardGoalkeepersStats = createAsyncThunk<
  IPlayerWithTeamIdAndStats[],
  IGetPlayersParams
>(
  'gamesTables/getGamesTablesGoalkeepers',
  async ({ competitionsUuids, teamUuid, matchUuid, body }) => {
    const data: IPlayerWithTeamIdAndStats[] = await axiosInstance
      .post(`/match/${competitionsUuids}/${matchUuid}/${teamUuid}/goalkeeper`, body)
      .then(response => parseGamesTablesPlayersData(response.data));

    return data;
  },
);

export const getDashboardTeamsFormation = createAsyncThunk<
  IGamesTablesFormations[],
  IGetPlayersParams
>(
  'dashboard/getDashboardTeamsFormation',
  async ({ competitionsUuids, matchUuid, teamUuid, body }) => {
    const teamsFormation: IGamesTablesFormations[] = await axiosInstance
      .post(`/match/${competitionsUuids}/${matchUuid}/${teamUuid}/formation`, body)
      .then(response => parseGamesTablesFormationData(response.data))
      .catch(defaultCatchErrorCallback);

    return teamsFormation;
  },
);
