import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IGamesState } from '../../../types';
import { getGamePreview, getGames, getGamesWithToi, getHeadToHead, getToi } from './gamesActions';

const initialState: IGamesState = {
  byId: {},
  allIds: [],
  isLoading: false,
  error: undefined,
  isAllGamesLoaded: undefined,
  graphicOverview: undefined,
  headToHead: undefined,
  isHeadToHeadLoading: false,
  isToiLoading: false,
  errorHeadToHead: undefined,
  errorToi: undefined,
};

export const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getGamePreview.pending, state => {
        state.graphicOverview = undefined;
        state.error = undefined;
      })
      .addCase(getGamePreview.fulfilled, (state, action) => {
        state.graphicOverview = action.payload;
      })
      .addCase(getGamePreview.rejected, (state, action) => {
        state.error = action.error.message;
      })
      // Next action case
      .addCase(getToi.pending, state => {
        state.isToiLoading = true;
        state.errorToi = undefined;
      })
      .addCase(getToi.fulfilled, (state, action) => {
        action.payload.forEach(gameToi => {
          if (state.byId[gameToi.matchUuid]) {
            state.byId[gameToi.matchUuid].toi = gameToi.toi;
          }
        });
        state.isToiLoading = false;
      })
      .addCase(getToi.rejected, (state, action) => {
        state.isToiLoading = false;
        state.errorToi = action.error.message;
      })
      // Next action case
      .addCase(getHeadToHead.pending, state => {
        state.isHeadToHeadLoading = true;
        state.errorHeadToHead = undefined;
      })
      .addCase(getHeadToHead.fulfilled, (state, action) => {
        state.headToHead = action.payload;
        state.isHeadToHeadLoading = false;
      })
      .addCase(getHeadToHead.rejected, (state, action) => {
        state.isHeadToHeadLoading = false;
        state.errorHeadToHead = action.error.message;
      })
      // Next action case
      .addMatcher(isAnyOf(getGames.pending, getGamesWithToi.pending), state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addMatcher(isAnyOf(getGames.fulfilled, getGamesWithToi.fulfilled), (state, action) => {
        if (!action.meta.arg.dontRemovePreviousGames) {
          state.byId = {};
          state.allIds = [];
        }
        action.payload.forEach(game => {
          state.byId[game.id] = game;
          state.allIds.push(game.id);
        });
        state.isLoading = false;
        state.isAllGamesLoaded = true;
      })
      .addMatcher(isAnyOf(getGames.rejected, getGamesWithToi.rejected), (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
