import { useAppSelector } from '../../../../app/hooks';
import { DashboardTable, Loading } from '../../../../components';
import { selectDashboard } from '../../../../features';
import { ITranslationKeys } from '../../../../i18n/types';
import { IStandingsTableData } from '../../../../types';
import { createClassNames } from '../../../../utils';
import {
  useShortTableColumnsConfig,
  useStandingsContent,
  useStandingsDataForTable,
} from '../../hooks';
import './StandingsContent.styles.scss';

const classNames = createClassNames('standings-content');

export const StandingsContent = () => {
  useStandingsContent();
  const { competitionTable, shortTable } = useAppSelector(selectDashboard);
  const data = useStandingsDataForTable();
  const {
    columns: shortTableColumns,
    columnPinning: shortTableColumnPinning,
    initialSorting: shortTableInitialSorting,
  } = useShortTableColumnsConfig(data);

  if (!shortTable.length || !competitionTable.length) {
    return (
      <div className={classNames()} data-testid='standings-content'>
        <Loading />
      </div>
    );
  }

  return (
    <div className={classNames()} data-testid='standings-content'>
      <div className={classNames('table')}>
        <DashboardTable<IStandingsTableData>
          data={data}
          columns={shortTableColumns}
          columnPinning={shortTableColumnPinning}
          initialSorting={shortTableInitialSorting}
          disableBottomBorder
          disableIsSmall
          showRightPartLeftBorder
          centerPartTitle={ITranslationKeys.competitionTable}
          rightPartTitle={ITranslationKeys.last5Games}
          isStandings
        />
      </div>
    </div>
  );
};
