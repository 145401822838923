import {
  ColumnDef,
  ColumnHelper,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  BasicColumnHeader,
  DashboardColumnCell,
  FormationColumnCell,
  TranslatedColumnHeader,
} from '../../../../components';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IAlertColors,
  IColumnsConfig,
  IDashboardMinigameLabelColor,
  ITeamsFormationTableData,
} from '../../../../types';
import { createClassNames, createIndexColumn } from '../../../../utils';
import './columns.styles.scss';

const columnHelper = createColumnHelper<ITeamsFormationTableData>();
const initialSorting: SortingState = [];
const columnPinning: ColumnPinningState = {
  right: ['gfga', 'xgf-percent', 'minigame'],
};

const classNames = createClassNames('dashboard-content-columns');

const offenseColumn = columnHelper.display({
  id: 'index',
  header: props => (
    <span className={classNames('index-cell', { header: true })}>
      <TranslatedColumnHeader {...props} label={ITranslationKeys.fiveVFiveOffense} />
    </span>
  ),
  cell: props => <strong className={classNames('index-cell')}>{createIndexColumn(props)}</strong>,
});

const defenseColumn = columnHelper.display({
  id: 'index',
  header: props => (
    <span className={classNames('index-cell', { header: true })}>
      <TranslatedColumnHeader {...props} label={ITranslationKeys.fiveVFiveDefense} />
    </span>
  ),
  cell: props => <strong className={classNames('index-cell')}>{createIndexColumn(props)}</strong>,
});

const fixedColumn: ColumnDef<ITeamsFormationTableData, any> = columnHelper.accessor('players', {
  header: () => <span className={classNames('formation-cell')}></span>,
  cell: props => <FormationColumnCell {...props} />,
});

const rightColumns: ColumnDef<ITeamsFormationTableData, any>[] = [
  columnHelper.display({
    id: 'gfga',
    header: props => <BasicColumnHeader {...props}>GF:GA</BasicColumnHeader>,
    cell: props => {
      const ga = props.row.original.stats['ga'].value;
      const gf = props.row.original.stats['gf'].value;
      const color = ga > gf ? IAlertColors.danger : gf > ga ? IAlertColors.success : false;
      return <span className={classNames('gfga-cell', { color })}>{`${gf}:${ga}`}</span>;
    },
  }),
  columnHelper.display({
    id: 'xgf-percent',
    header: props => <BasicColumnHeader {...props}>xGF%</BasicColumnHeader>,
    cell: props => {
      const xgf = Math.round(props.row.original.stats['xgf_percent'].value);
      const color = xgf > 59 ? IAlertColors.success : xgf < 41 ? IAlertColors.danger : false;
      return <span className={classNames('xgf-percent-cell', { color })}>{xgf}%</span>;
    },
  }),
];

export const useFormationColumnsConfig = (
  isOffense: boolean,
): IColumnsConfig<ITeamsFormationTableData> => {
  const columns = useMemo(() => {
    const minigameColumn = createDashboardMinigameColumnsDefFormations(columnHelper);
    return [isOffense ? offenseColumn : defenseColumn]
      .concat(fixedColumn)
      .concat(rightColumns)
      .concat(minigameColumn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const createDashboardMinigameColumnsDefFormations = (
  columnHelper: ColumnHelper<ITeamsFormationTableData>,
) =>
  columnHelper.display({
    id: 'minigame',
    header: props => (
      <span className={classNames('minigame-cell')}>
        <TranslatedColumnHeader {...props} label={ITranslationKeys.minigame} />
      </span>
    ),
    cell: props => {
      const xgf = Math.round(props.row.original.stats['xgf_percent'].value);
      const { color, label } = getPlayersMinigameConfig(xgf);
      return (
        <div className={classNames('minigame-cell')}>
          <DashboardColumnCell {...props} {...{ color, label }} />
        </div>
      );
    },
  });

const getPlayersMinigameConfig = (xgf: number): IDashboardMinigameLabelColor => {
  if (xgf < 41) {
    return {
      color: IAlertColors.danger,
      label: ITranslationKeys.lesserChances,
    };
  }
  if (xgf > 59) {
    return {
      color: IAlertColors.success,
      label: ITranslationKeys.greaterChances,
    };
  }
  return {
    color: IAlertColors.default,
    label: ITranslationKeys.evenChances,
  };
};
