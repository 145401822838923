import { createSlice } from '@reduxjs/toolkit';

import { gamePartOptions } from '../../../constants';
import { IGamesTablesState } from '../../../types';
import {
  getGamesTablesFormations,
  getGamesTablesGoalkeepers,
  getGamesTablesPlayers,
} from './gamesTablesActions';

const initialState: IGamesTablesState = {
  goalkeepersById: {},
  goalkeepersAllIds: [],
  formationsById: {},
  formationsAllIds: [],
  playersById: {},
  playersAllIds: [],
  formationsLoading: false,
  playersLoading: false,
  errorFormations: undefined,
  errorPlayers: undefined,
  playerPosition: undefined,
  gamePart: gamePartOptions[0],
  selectedTeam: gamePartOptions[0],
};

export const gamesTablesSlice = createSlice({
  name: 'gamesTables',
  initialState,
  reducers: {
    setPlayersSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getGamesTablesPlayers.pending, state => {
        state.playersLoading = true;
        state.errorPlayers = undefined;
      })
      .addCase(getGamesTablesPlayers.fulfilled, (state, action) => {
        // TODO: Dashboard - Upravit zpět po nasazení nových EP pro dashboard
        const { meta, payload } = action;
        const isFromDashboard = meta.arg.isFromDashboard;

        if (!isFromDashboard) {
          state.playersById = {};
          state.playersAllIds = [];
        }

        payload.forEach(player => {
          state.playersById[player.id] = player;
          state.playersAllIds.push(player.id);
        });
        state.playersLoading = false;
      })
      .addCase(getGamesTablesPlayers.rejected, (state, action) => {
        state.playersLoading = false;
        state.errorPlayers = action.error.message;
      })
      // Next action case
      .addCase(getGamesTablesFormations.pending, state => {
        state.formationsLoading = true;
        state.errorFormations = undefined;
      })
      .addCase(getGamesTablesFormations.fulfilled, (state, action) => {
        state.formationsById = {};
        state.formationsAllIds = [];
        action.payload.forEach(formation => {
          const id = formation.playerIds.join(',');
          state.formationsById[id] = formation;
          state.formationsAllIds.push(id);
        });
        state.formationsLoading = false;
      })
      .addCase(getGamesTablesFormations.rejected, (state, action) => {
        state.formationsLoading = false;
        state.errorFormations = action.error.message;
      })
      // Next action case
      .addCase(getGamesTablesGoalkeepers.pending, state => {
        state.goalkeepersLoading = true;
        state.errorGoalkeepers = undefined;
      })
      .addCase(getGamesTablesGoalkeepers.fulfilled, (state, action) => {
        state.goalkeepersById = {};
        state.goalkeepersAllIds = [];
        action.payload.forEach(player => {
          state.goalkeepersById[player.id] = player;
          state.goalkeepersAllIds.push(player.id);
        });
        state.goalkeepersLoading = false;
      })
      .addCase(getGamesTablesGoalkeepers.rejected, (state, action) => {
        state.goalkeepersLoading = false;
        state.errorGoalkeepers = action.error.message;
      });
  },
});

export const { setPlayersSelectedTeam } = gamesTablesSlice.actions;
