import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LogoCardList } from '../../components';
import { teamAllOption } from '../../constants';
import { selectDashboard, setSelectedHomeTeam } from '../../features';
import { useBugReportSidebar, useLoadInitialData, useLocationPaths } from '../../hooks';
import { INavigationPageKeys, ISelectOption } from '../../types';
import { createClassNames } from '../../utils';
import './DashboardPage.styles.scss';
import { DashboardContent, StandingsContent } from './contents';
import { useDashboardPage } from './hooks';

const classNames = createClassNames('dashboard-page');

export const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const { selectedTeamIds } = useAppSelector(selectDashboard);
  useLoadInitialData();

  const getBugReportSidebar = useBugReportSidebar();
  const navigate = useNavigate();
  const { activePage } = useLocationPaths();
  const { teamOptions } = useDashboardPage();

  const selected = selectedTeamIds.homeTeam
    ? {
        label: selectedTeamIds.homeTeam,
        value: selectedTeamIds.homeTeam,
      }
    : undefined;
  const selectedAwayTeam = selectedTeamIds.awayTeam
    ? [
        {
          label: selectedTeamIds.awayTeam,
          value: selectedTeamIds.awayTeam,
        },
      ]
    : undefined;

  useEffect(() => {
    if (!activePage) {
      navigate('/' + INavigationPageKeys.dashboard);
    }
  }, [activePage, navigate]);

  const handleLogoCardList = (option: ISelectOption) => {
    dispatch(setSelectedHomeTeam(option.value));
    if (option.value === teamAllOption.value) {
      navigate('/' + INavigationPageKeys.standings);
      return;
    }

    navigate('/' + INavigationPageKeys.dashboard);
  };

  return (
    <div className='grow-and-flex-column'>
      <div className={classNames('team-select-bar')}>
        <LogoCardList
          onChange={handleLogoCardList}
          disabledOptions={selectedAwayTeam}
          options={[teamAllOption, ...teamOptions]}
          {...{ selected }}
        />
      </div>
      {contentsConfig.map(
        ({ Component, path }) =>
          activePage === path && (
            <div key={path} className={classNames('content-container')}>
              <Component />
            </div>
          ),
      )}
      {getBugReportSidebar(
        activePage,
        activePage === INavigationPageKeys.dashboard ? 'dashboard' : 'standings',
      )}
    </div>
  );
};

const contentsConfig = [
  {
    path: INavigationPageKeys.dashboard,
    Component: DashboardContent,
  },
  {
    path: INavigationPageKeys.standings,
    Component: StandingsContent,
  },
];
