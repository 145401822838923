import { Fragment, useCallback } from 'react';

import {
  ImageColumn,
  MetricColumnCell,
  PositionCircle,
  StickGripCircle,
  Sticker,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { ILeftRight, ISelectedPlayerSimilarData, ITablePart } from '../../../../types';
import { secondsToMinutesAndSeconds } from '../../../../utils';

export const useExtraRowHeaderRender = (
  selectedPlayer: ISelectedPlayerSimilarData | undefined,
  selectedTeamId: string | undefined,
) => {
  const extraRowHeaderRender = useCallback(
    (tablePart: ITablePart = 'left') => {
      if (selectedPlayer) {
        if (tablePart === 'right') {
          return (
            <tr key={selectedPlayer.player.id}>
              <th className='extra-header-cell-similarity'></th>
              <th className='extra-header-cell-similarity'></th>
            </tr>
          );
        }

        if (tablePart === 'center') {
          return (
            <tr key={selectedPlayer.player.id}>
              {Object.values(selectedPlayer.stats).map(stat => {
                return (
                  <Fragment key={stat.id}>
                    <th key={stat.id} className='extra-header-cell-similarity with-arrow-top'>
                      <MetricColumnCell
                        value={stat.value}
                        stat={stat}
                        playerId={selectedPlayer.player.id}
                        teamId={selectedTeamId || ''}
                      />
                    </th>
                    <th key={`rank_${stat.id}`} className='extra-header-cell-similarity'></th>
                  </Fragment>
                );
              })}
            </tr>
          );
        }

        return (
          <tr key={selectedPlayer.player.id}>
            <th className='extra-header-cell-similarity'></th>
            <th className='extra-header-cell-similarity'>
              <ImageColumn src={logosPaths[selectedPlayer.teamId]} alt='team-logo' />
            </th>
            <th className='extra-header-cell-similarity'>
              <span style={{ display: 'flex' }}>
                <Sticker
                  label={selectedPlayer.player.displayName}
                  fontWeight='bold'
                  color='white'
                />
              </span>
            </th>
            <th className='extra-header-cell-similarity'>
              <PositionCircle
                position={selectedPlayer.position === 'DE' ? 'DE' : 'FO'}
                hasWhiteBackground
              />
            </th>
            <th className='extra-header-cell-similarity'>
              <StickGripCircle
                stick={selectedPlayer.stick as ILeftRight | undefined}
                hasWhiteBackground
              />
            </th>
            <th className='extra-header-cell-similarity'>
              {secondsToMinutesAndSeconds(selectedPlayer.toi)}
            </th>
          </tr>
        );
      }

      return null;
    },
    [selectedPlayer, selectedTeamId],
  );

  return extraRowHeaderRender;
};
