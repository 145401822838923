import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  ConfirmDialog,
  LanguageController,
  Loading,
  Modal,
  TcmsButton,
} from '../../../../components';
import {
  dataSettingsFilterAllDataSelector,
  selectDataSettingsFilter,
  selectDefinedDatasets,
  selectMetrics,
  setDataSettingsSelectedMetrics,
  updateDatasetMetrics,
  updateDefaultTemplateMetrics,
} from '../../../../features';
import { useOnLeaveEdited, useWindowSize } from '../../../../hooks';
import i18n from '../../../../i18n/i18n';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IDatasetMetric,
  IDatasetMetricRecord,
  IEntity,
  ILanguageType,
  ISelectOption,
} from '../../../../types';
import { createClassNames, getMetricsOptions } from '../../../../utils';
import './DefinedTemplatesPage.styles.scss';
import {
  DefaultTemplates,
  DefinedTemplatesCheckboxes,
  DefinedTemplatesDataTypes,
  DefinedTemplatesSection,
  TemplateMetricOverview,
} from './components';
import {
  getMetricsByActiveSection,
  mapDatasetGroupOptions,
  transformSelectedMetricsToSelectOptions,
} from './utils';

const IDefault = {
  defaultTemplates: 'defaultTemplates',
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type IDefault = (typeof IDefault)[keyof typeof IDefault];

export const IDefinedDatasetEntity = {
  ...IEntity,
  ...IDefault,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type IDefinedDatasetEntity = IEntity | IDefault;

const definedTemplatesSectionKeys: ISelectOption[] = [
  {
    label: ITranslationKeys.playerTemplates,
    value: IEntity.players,
  },
  {
    label: ITranslationKeys.teamTemplates,
    value: IEntity.teams,
  },
  {
    label: ITranslationKeys.goalkeeperTemplates,
    value: IEntity.goalkeepers,
  },
  {
    label: ITranslationKeys.defaultTemplates,
    value: 'defaultTemplates',
  },
];

const classNames = createClassNames('defined-templates-page');

export const DefinedTemplatesPage = () => {
  const { definedDatasets, defaultTemplates } = useAppSelector(selectDefinedDatasets);
  const { selectedMetrics } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const dispatch = useAppDispatch();

  const [activeLanguage, setActiveLanguage] = useState<keyof ILanguageType<string>>(
    i18n.language as keyof ILanguageType<string>,
  );
  const [activeSection, setActiveSection] = useState<IDefinedDatasetEntity>(
    definedTemplatesSectionKeys[0].value as IDefinedDatasetEntity,
  );
  const [activeTemplate, setActiveTemplate] = useState<ISelectOption>();
  const [originalSelectedMetrics, setOriginalSelectedMetrics] = useState<
    ISelectOption<IDatasetMetric>[]
  >([]);
  const [datasetGroupOptions, setDatasetGroupOptions] = useState<ISelectOption[]>([]);

  const isDefaultTemplate = activeSection === 'defaultTemplates';
  const entity = isDefaultTemplate ? (activeTemplate?.additionalValue as IEntity) : activeSection;

  const metricsBySection = getMetricsByActiveSection(entity, metrics);
  const { filteredBySectionedSubgroups, filteredByParameters, metricsPrefix } = useAppSelector(
    state => dataSettingsFilterAllDataSelector(state, metricsBySection),
  );
  const filteredMetricsOptions = useMemo(
    () => getMetricsOptions(filteredByParameters, metricsPrefix, null),
    [filteredByParameters, metricsPrefix],
  );

  const { t } = useTranslation();
  const { width } = useWindowSize();

  useEffect(() => {
    if (definedDatasets && defaultTemplates) {
      setOriginalSelectedMetrics(selectedMetrics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTemplate, definedDatasets, defaultTemplates]);

  useEffect(() => {
    if (activeTemplate) {
      setActiveTemplate(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  const isDirty = useMemo(
    () => !!activeTemplate && !areArraysEqual(selectedMetrics, originalSelectedMetrics),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTemplate, selectedMetrics, originalSelectedMetrics],
  );

  const { leaveMethod, setLeaveMethod, onLeaveEdited } = useOnLeaveEdited(isDirty);

  if (!activeSection || !definedDatasets || !defaultTemplates || !metricsBySection || !metrics) {
    return <Loading />;
  }

  const handleCheckboxItemChange = (option: ISelectOption<IDatasetMetric>) => {
    if (selectedMetrics.find(metric => metric.additionalInfo.code === option.value)) {
      dispatch(
        setDataSettingsSelectedMetrics(
          selectedMetrics.filter(metric => metric.additionalInfo.code !== option.value),
        ),
      );
      return;
    }

    dispatch(setDataSettingsSelectedMetrics([...selectedMetrics, option]));
  };

  const handleTcmsItemClick = (option: ISelectOption) => {
    const datasetRecord = !isDefaultTemplate ? definedDatasets[entity].byId : defaultTemplates.byId;

    setActiveTemplate(option);
    setDatasetGroupOptions(
      mapDatasetGroupOptions(
        activeLanguage,
        entity,
        !isDefaultTemplate ? definedDatasets : defaultTemplates,
        metrics,
      ),
    );

    const selectedMetrics = transformSelectedMetricsToSelectOptions(
      datasetRecord,
      !isDefaultTemplate ? metricsBySection : metrics[option.additionalValue as IEntity],
      option,
    );
    dispatch(setDataSettingsSelectedMetrics(selectedMetrics));
  };

  const handleTemplateSaveButtonClick = () => {
    if (!activeTemplate) return;

    const metricsRecord = selectedMetrics.reduce<IDatasetMetricRecord>((acc, metric) => {
      acc[metric.additionalInfo.code] = metric.additionalInfo;
      return acc;
    }, {});

    if (!isDefaultTemplate) {
      dispatch(
        updateDatasetMetrics({
          id: activeTemplate.value,
          category: entity,
          metricsRecord,
          definedDatasets,
          defaultTemplates,
        }),
      );
    } else {
      dispatch(
        updateDefaultTemplateMetrics({
          id: activeTemplate.value,
          metricsRecord,
          definedDatasets,
          defaultTemplates,
        }),
      );
    }
  };

  const handleConfirmLeaveDialog = () => {
    leaveMethod?.();
    setLeaveMethod(null);
  };

  const handleCloseLeaveDialog = () => {
    setLeaveMethod(null);
  };

  const handleChangeSection = (option: ISelectOption) => {
    setActiveSection(option.value as IEntity);
  };

  const displayMetricOverview = !Object.values(filteredBySectionedSubgroups).length;
  const size = width > 1400 || width < 1201 ? 'medium' : 'small';

  return (
    <div className={classNames()}>
      <div className={classNames('controls')}>
        <div className={classNames('controls__header')}>
          <h2>{t(ITranslationKeys.templateCategory)}</h2>
          <div className={classNames('controls__header__flags')}>
            <LanguageController
              activeLanguage={activeLanguage}
              onClick={() => setActiveLanguage(activeLanguage === 'cz' ? 'en' : 'cz')}
            />
          </div>
        </div>
        <div className={classNames('controls__buttons')}>
          {definedTemplatesSectionKeys.map(option => (
            <TcmsButton
              key={option.value}
              label={option.label}
              variant='filled'
              onClick={() => onLeaveEdited(() => handleChangeSection(option))}
              color={activeSection === option.value ? 'blue' : 'default'}
            />
          ))}
        </div>
      </div>
      <div className={classNames('content')}>
        {!isDefaultTemplate ? (
          <DefinedTemplatesSection
            onSelectedListOptionChange={option => onLeaveEdited(() => handleTcmsItemClick(option))}
            activeSection={entity}
            {...{
              activeTemplate,
              activeLanguage,
              metricsBySection,
              setDatasetGroupOptions,
              size,
              onLeaveEdited,
            }}
          />
        ) : (
          <DefaultTemplates
            onSelectedListOptionChange={option => onLeaveEdited(() => handleTcmsItemClick(option))}
            activeSection={entity}
            {...{
              activeTemplate,
              activeLanguage,
              metricsBySection,
              setDatasetGroupOptions,
              size,
            }}
          />
        )}
        <DefinedTemplatesDataTypes
          options={datasetGroupOptions}
          metricsBySection={metricsBySection}
        />
        {activeTemplate &&
          (displayMetricOverview ? (
            <TemplateMetricOverview
              onSaveButtonClick={handleTemplateSaveButtonClick}
              activeSection={entity}
              {...{ isDirty }}
            />
          ) : (
            <DefinedTemplatesCheckboxes
              subgroupMetrics={filteredBySectionedSubgroups}
              onCheckboxItemChange={handleCheckboxItemChange}
              onSaveButtonClick={handleTemplateSaveButtonClick}
              {...{ activeLanguage, filteredMetricsOptions, selectedMetrics, isDirty }}
            />
          ))}
      </div>
      <Modal open={!!leaveMethod} size='small'>
        <ConfirmDialog
          onConfirm={handleConfirmLeaveDialog}
          onCancel={handleCloseLeaveDialog}
          message={t(ITranslationKeys.continueWithoutSave)}
          type='danger'
        />
      </Modal>
    </div>
  );
};

const areArraysEqual = (a: ISelectOption[], b: ISelectOption[]) => {
  if (a.length !== b.length) return false;

  return a.every((element, index) => element.value === b[index].value);
};
