import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectMainFilter, selectTrend } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IMetricWithDatasetMetric,
  ISelectOption,
  ISelectedPlayerItem,
  ITimePeriodPlayerStatsRecord,
  ITrendPlayerStatsTableData,
} from '../../../../types';
import { createFormationFromSelectedPlayers, getMetricsWithValue } from '../../../../utils';

const getTableData = (
  tableTimePeriodPlayerStats: ITimePeriodPlayerStatsRecord,
  metrics: IMetricWithDatasetMetric[],
  selectedPlayerItems: ISelectedPlayerItem[],
  selectedTeam: ISelectOption,
): ITrendPlayerStatsTableData[] => {
  const formations = createFormationFromSelectedPlayers(selectedPlayerItems).filter(
    item => !!item.player,
  );

  const tableData = Object.values(tableTimePeriodPlayerStats).map<ITrendPlayerStatsTableData>(
    (item, index) => {
      const metricsWithValue = getMetricsWithValue(metrics, item.stats);

      return {
        teamId: selectedTeam.value,
        gameIds: item.gameIds || [],
        dateFrom: item.dateFrom,
        dateTo: item.dateTo,
        formations,
        rowIndex: index,
        toi: item.stats.toi,
        stats: metricsWithValue,
      };
    },
  );

  return tableData;
};

export const useDataForTable = () => {
  const { tableTimePeriodPlayerStats } = useAppSelector(selectTrend);
  const { selectedPlayerItems, selectedTeam } = useAppSelector(selectMainFilter);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: ITrendPlayerStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics && selectedTeam) {
      return getTableData(
        tableTimePeriodPlayerStats,
        filteredMetrics,
        selectedPlayerItems,
        selectedTeam,
      );
    }

    return [];
  }, [tableTimePeriodPlayerStats, getFilteredMetrics, selectedPlayerItems, selectedTeam]);

  return data;
};
