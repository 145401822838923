import { useMemo } from 'react';

import { useAppSelector } from '../app/hooks';
import { defaultTemplate } from '../constants';
import { selectDefinedDatasets, selectUserDatasets } from '../features';
import i18n from '../i18n/i18n';
import { IEntity, ISelectOption } from '../types';
import { createUserDatasetOptions } from '../utils';

export const useDataTemplateOptions = (entity: IEntity = IEntity.players) => {
  const { definedDatasets } = useAppSelector(selectDefinedDatasets);
  const userDatasets = useAppSelector(selectUserDatasets);

  const dataTemplateOptions: ISelectOption[] = useMemo(() => {
    if (definedDatasets) {
      const definedCategoryDataset = definedDatasets[entity];
      const datasetOptions = definedCategoryDataset?.allIds.reduce<ISelectOption[]>((acc, id) => {
        const dataset = definedCategoryDataset.byId[id];

        if (!dataset || dataset.disabled) return acc;

        acc.push({
          value: dataset.id,
          label: i18n.language === 'cz' ? dataset.name.cz : dataset.name.en,
        });

        return acc;
      }, []);

      const userDatasetOptions = createUserDatasetOptions(userDatasets.byId, entity);

      const allDatasetOptions = userDatasetOptions.concat(datasetOptions);
      allDatasetOptions.unshift(defaultTemplate);
      return allDatasetOptions;
    }

    return [];
  }, [definedDatasets, userDatasets.byId, entity]);

  return dataTemplateOptions;
};
