import { FC } from 'react';

import { IGameResult } from '../../../types';
import { createClassNames } from '../../../utils';
import { LogoCard } from '../../LogoCard';
import { GameScore } from '../GameScore';
import './GameResult.styles.scss';

const classNames = createClassNames('game-result');

/**
 * GameResult UI component.
 */
export const GameResult: FC<IGameResult> = ({ home, away, scoreState }) => {
  return (
    <div className={classNames()}>
      <div className={classNames('team', { home: true })}>
        <h3>{home.name}</h3>
        <LogoCard teamId={home.uuid} />
      </div>
      <GameScore
        home={home.goals}
        away={away.goals}
        scoreState={scoreState}
        homeThirds={home.thirds}
        awayThirds={away.thirds}
      />
      <div className={classNames('team', { away: true })}>
        <LogoCard teamId={away.uuid} />
        <h3>{away.name}</h3>
      </div>
    </div>
  );
};
