import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectGamelog, selectTeams } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IGamelog,
  IGamelogPlayerStatsTableData,
  IMainSubmittedValues,
  IMetricWithDatasetMetric,
  ITeamRecord,
} from '../../../../types';
import {
  createFormationFromSelectedPlayers,
  getMetricsWithValue,
  mapPlayerLineups,
} from '../../../../utils';

const getTableData = (
  gamelog: IGamelog[],
  teamRecord: ITeamRecord,
  metrics: IMetricWithDatasetMetric[],
  submittedValues: IMainSubmittedValues,
) => {
  const tableData = gamelog.map<IGamelogPlayerStatsTableData>(game => {
    const homeTeam = teamRecord[game.info.homeTeamId];
    const awayTeam = teamRecord[game.info.awayTeamId];

    const formations = submittedValues.selectedPlayerItems
      ? createFormationFromSelectedPlayers(submittedValues.selectedPlayerItems).filter(
          item => !!item.player,
        )
      : [];

    return {
      teamId: submittedValues.teamOption?.value || '',
      formations,
      gameIds: [game.id],
      homeTeamId: game.info.homeTeamId,
      gameTeams: {
        homeTeam,
        awayTeam,
      },
      awayTeamId: game.info.awayTeamId,
      date: game.info.date,
      score: game.info.score,
      toi: game.stats.toi,
      lineup: mapPlayerLineups(game.lineup),
      stats: getMetricsWithValue(metrics, game.stats),
    };
  });

  return tableData;
};

export const useDataForTable = (submittedValues: IMainSubmittedValues) => {
  const gamelog = useAppSelector(selectGamelog);
  const teams = useAppSelector(selectTeams);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IGamelogPlayerStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics) {
      return getTableData(
        Object.values(gamelog.byId),
        teams.byId,
        filteredMetrics,
        submittedValues,
      );
    }

    return [];
  }, [gamelog.byId, teams.byId, submittedValues, getFilteredMetrics]);

  return data;
};
