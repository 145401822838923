import { FC } from 'react';

import { PlayIcon } from '../../../icons';
import {
  IDefaultPlayerColorNames,
  IGameEntity,
  ISelectedPlayerItem,
  IVideoInfo,
} from '../../../types';
import {
  createClassNames,
  createFormationFromSelectedPlayers,
  transformObjectKeysToKebabCase,
} from '../../../utils';
import './GamesSelectItemActions.styles.scss';

export interface IGamesSelectItemActionsProps {
  /** Show circles? */
  isSelected: boolean;
  /** Count of entities (shots, passes, etc.) filtered for this game. */
  filteredEntities?: IGameEntity;
  /** Array of selected players to be separated from filtered entities */
  selectedPlayerItems?: ISelectedPlayerItem[];
  /** Event triggered after click on action part of content (play icon, count circle). */
  onActionClick?: () => void;
}

const classNames = createClassNames('games-select-item-actions');
const colors: IDefaultPlayerColorNames[] = ['red', 'purple', 'cyan', 'teal', 'orange'];

/** UI component for displaying action content in CheckboxButton in GamesSelectListItem component. */
export const GamesSelectItemActions: FC<IGamesSelectItemActionsProps> = ({
  filteredEntities,
  isSelected,
  selectedPlayerItems,
  onActionClick,
}) => {
  if (!isSelected || !filteredEntities) return defaultRender(isSelected, onActionClick);

  const filteredSelectedPlayers = selectedPlayerItems
    ? Object.values(selectedPlayerItems).filter(item => item.selectedPlayer)
    : [];
  const formation = createFormationFromSelectedPlayers(filteredSelectedPlayers);
  const onPlayerIds = formation?.reduce<string[]>((acc, player) => {
    if (player.on) acc.push(player.player);
    return acc;
  }, []);
  const shouldSeparate = onPlayerIds.length > 0;
  const entities = getEntities(filteredEntities, onPlayerIds, shouldSeparate)?.filter(
    entity => entity.length > 0,
  );

  if (!entities || entities.length === 0) return defaultRender(isSelected, onActionClick);

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isSelected,
          hasMoreCircles: shouldSeparate && entities ? entities.length > 1 : false,
        }),
      })}
      onClick={isSelected && onActionClick ? () => onActionClick() : undefined}
      data-testid='games-select-item-actions'
    >
      <div
        className={classNames('circle-wrapper')}
        style={{
          transform: filteredEntities
            ? `translateX(-${(shouldSeparate && entities ? entities.length - 1 : 0) * 8}px)`
            : 'unset',
        }}
      >
        {entities.map((entity, index) => {
          if (entity.length === 0) return null;

          const playerId = entity[0]?.playerId;
          const colorIndex = selectedPlayerItems
            ? Object.values(selectedPlayerItems).find(
                player => player.selectedPlayer?.value === playerId,
              )?.id
            : undefined;
          const color = shouldSeparate && colorIndex ? colors[Number(colorIndex)] : 'default';

          return (
            <div
              key={index}
              className={classNames('circle-wrapper__circle', {
                ...transformObjectKeysToKebabCase({
                  index,
                  isOnlyOne: shouldSeparate && entities.length === 1,
                  color,
                }),
              })}
            >
              {entity.length}
            </div>
          );
        })}
      </div>
      <PlayIcon />
    </div>
  );
};

const defaultRender = (isSelected: boolean, onActionClick?: () => void) => {
  return (
    <div
      className={classNames()}
      onClick={isSelected && onActionClick ? () => onActionClick() : undefined}
      data-testid='games-select-item-actions'
    >
      <PlayIcon />
    </div>
  );
};

const getEntities = (
  filteredEntities: IGameEntity,
  onPlayerIds: string[],
  shouldSeparate: boolean,
): IVideoInfo[][] | undefined => {
  if (!filteredEntities) return undefined;

  if (!shouldSeparate) {
    const entities = filteredEntities?.entities;
    return entities?.length > 0 ? [entities] : undefined;
  }

  const entities = onPlayerIds.reduce<IVideoInfo[][]>((acc, playerId) => {
    const playerEntities = filteredEntities.entities.filter(entity => entity.playerId === playerId);
    if (playerEntities) acc.push(playerEntities);

    return acc;
  }, []);

  return entities;
};
