import { ITranslationKeys } from '../../../i18n/types';
import {
  IDashboardGame,
  IDashboardGamePreviewRecord,
  IGame,
  IMinigraphBattery,
  IMinigraphBatteryTeam,
  INavigationContentKeys,
  INavigationPageKeys,
  ISeasonRecord,
  ISelectOption,
  ISelectOptionWithParam,
  IShortTable,
  ITeamRecord,
  ITeamsState,
} from '../../../types';
import { getSeasonsOptions } from '../../../utils';

export const generateDashboardGames = (
  games: IGame[],
  teamId: string,
  competitionIds: string[],
  gamePreviewsById: Record<string, IDashboardGamePreviewRecord>,
  teams: ITeamRecord,
) => {
  if (games.length === 0 || !gamePreviewsById?.[teamId]) return [];

  const filteredGames = games.filter(
    game =>
      (game.homeTeamId === teamId || game.awayTeamId === teamId) &&
      competitionIds.includes(game.competitionId),
  );

  const dashboardGames = Object.entries(gamePreviewsById[teamId])
    .reduce<IDashboardGame[]>((acc, [_, game]) => {
      const gameInfo = filteredGames.find(filteredGame => filteredGame.id === game.matchId);
      const homeTeam = teams[game.homeTeam.teamId];
      const awayTeam = teams[game.awayTeam.teamId];

      if (!gameInfo || !homeTeam || !awayTeam) return acc;

      const homeTeamXG = game.homeTeam.shots.reduce((acc, shots) => acc + shots.xG, 0);
      const awayTeamXG = game.awayTeam.shots.reduce((acc, shots) => acc + shots.xG, 0);

      const dashboardGame: IDashboardGame = {
        id: game.matchId,
        date: gameInfo.date,
        score: gameInfo.score,
        homeTeam: {
          logo: game.homeTeam.teamId,
          score: gameInfo.score.home,
          xG: homeTeamXG,
          xW: game.homeTeam.expectedWin,
          shots: game.homeTeam.shots.length,
          shortcut: homeTeam.shortcut,
        },
        awayTeam: {
          logo: game.awayTeam.teamId,
          score: gameInfo.score.away,
          xG: awayTeamXG,
          xW: game.awayTeam.expectedWin,
          shots: game.awayTeam.shots.length,
          shortcut: awayTeam.shortcut,
        },
      };
      acc.push(dashboardGame);
      return acc;
    }, [])
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  return dashboardGames;
};

export const perpareBatteryOptions = (
  teamStats: IMinigraphBatteryTeam,
  shortTable: IShortTable | undefined,
  total: number,
  teamId: string,
  games: IGame[],
): ISelectOptionWithParam<IMinigraphBattery>[] => {
  const mappedGames = games.map(game => game.id).join(',');

  return [
    {
      label: ITranslationKeys.form,
      value: 'order',
      additionalInfo: {
        metricValue: shortTable?.order ?? -1,
        order: shortTable?.order ?? -1,
        tooltipLabel: ITranslationKeys.standingsInTheLast5Games,
        total,
        // TODO: Přidat proklik na ligový dashboard posledních 5 zápasů (/standings)
        pageKey: INavigationPageKeys.dashboard,
      },
    },
    {
      label: ITranslationKeys.offense,
      value: 'gf60',
      additionalInfo: {
        metricName: 'GF/60',
        tooltipLabel: ITranslationKeys.fiveVFiveGoalsFor,
        total,
        pageKey: INavigationPageKeys.videomaps,
        tabKey: INavigationContentKeys.shots,
        filterParams: [
          {
            name: 'selectedTeam',
            value: teamId,
          },
          {
            name: 'shotCategory',
            value: 'G',
          },
          {
            name: 'selectedGames',
            value: mappedGames,
          },
        ],
        ...generateMetricRest(teamStats, 'gf60', games),
      },
    },
    {
      label: ITranslationKeys.defense,
      value: 'ga60',
      additionalInfo: {
        metricName: 'GA/60',
        tooltipLabel: ITranslationKeys.fiveVFiveGoalsAgainst,
        total,
        pageKey: INavigationPageKeys.videomaps,
        tabKey: INavigationContentKeys.shots,
        filterParams: [
          {
            name: 'selectedTeam',
            value: teamId,
          },
          {
            name: 'entity',
            value: 'opponent',
          },
          {
            name: 'shotCategory',
            value: 'G',
          },
          {
            name: 'selectedGames',
            value: mappedGames,
          },
        ],
        ...generateMetricRest(teamStats, 'ga60', games),
      },
    },
    {
      label: ITranslationKeys.goalkeepers,
      value: 'gsax',
      additionalInfo: {
        metricName: 'GSAx',
        tooltipLabel: ITranslationKeys.goalsSavedAboveExpected,
        total,
        pageKey: INavigationPageKeys.goalkeepers,
        tabKey: INavigationContentKeys.tabs,
        filterParams: [
          {
            name: 'selectedTeam',
            value: teamId,
          },
          {
            name: 'countOfPlayers',
            value: 'all',
          },
        ],
        ...generateMetricRest(teamStats, 'gsax', games),
      },
    },
    {
      label: ITranslationKeys.powerPlaysShort,
      value: 'pp_gf60',
      additionalInfo: {
        metricName: 'PP GF60',
        tooltipLabel: ITranslationKeys.pPGoalsFor,
        total,
        pageKey: INavigationPageKeys.videomaps,
        tabKey: INavigationContentKeys.shots,
        filterParams: [
          {
            name: 'selectedTeam',
            value: teamId,
          },
          {
            name: 'countOfPlayers',
            value: 'PP',
          },
          {
            name: 'shotCategory',
            value: 'G',
          },
          {
            name: 'selectedGames',
            value: mappedGames,
          },
        ],
        ...generateMetricRest(teamStats, 'pp_gf60', games),
      },
    },
    {
      label: ITranslationKeys.penaltyKill,
      value: 'sh_ga60',
      additionalInfo: {
        metricName: 'SH GA60',
        tooltipLabel: ITranslationKeys.pKGoalsAgainst,
        total,
        pageKey: INavigationPageKeys.videomaps,
        tabKey: INavigationContentKeys.shots,
        filterParams: [
          {
            name: 'selectedTeam',
            value: teamId,
          },
          {
            name: 'countOfPlayers',
            value: 'SH',
          },
          {
            name: 'entity',
            value: 'opponent',
          },
          {
            name: 'shotCategory',
            value: 'G',
          },
          {
            name: 'selectedGames',
            value: mappedGames,
          },
        ],
        ...generateMetricRest(teamStats, 'sh_ga60', games),
      },
    },
  ];
};

/**
 * Generates rest of the metric data for the battery
 * When value of metricValue or order is -1, then the skeleton is loaded in the Minigraph component
 */
const generateMetricRest = (
  teamStats: IMinigraphBatteryTeam,
  metricName: string,
  games: IGame[],
) => {
  const defaultValue = {
    metricValue: -1,
    order: -1,
  };

  if (!games?.length) {
    return defaultValue;
  }

  return teamStats?.metricRecord?.[metricName] ? teamStats.metricRecord[metricName] : defaultValue;
};

export const getAllSeasonPartsFromSeasonRecord = (seasonRecord: ISeasonRecord) => {
  const seasonOptions = getSeasonsOptions(seasonRecord);
  if (!seasonOptions.length) {
    return;
  }

  // TODO: After all endpoints are done, change index 1 to 0
  const lastSeasonOption = seasonOptions[1];
  const lastSeason = seasonRecord[lastSeasonOption.value];
  return lastSeason;
};

export const getTeamOptionsFromSeason = (teamsState: ITeamsState, competitionIds: string[]) =>
  teamsState.allIds.reduce<ISelectOption[]>((acc, teamId) => {
    if (
      competitionIds.some(competitionId =>
        teamsState.byId[teamId].competitionIds.includes(competitionId),
      )
    ) {
      acc.push({
        value: teamsState.byId[teamId].id,
        label: teamsState.byId[teamId].name,
      });
    }

    return acc;
  }, []);
