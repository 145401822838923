import { AxiosError } from 'axios';

import { ITranslationKeys } from '../../../i18n/types';
import { axiosInstance } from '../../../services/axiosInstance';
import { IDefinedDatasetsWithDefault } from '../../../types';

export const putDefinedDatasetsRequest = async (
  newDefinedDatasets: IDefinedDatasetsWithDefault,
) => {
  const status = await axiosInstance
    .put(`/setting/template`, newDefinedDatasets)
    .then(response => response.status)
    .catch((error: AxiosError) => {
      const errorMessage = error.message ?? ITranslationKeys.saveDataFailedMessage;
      throw new Error(errorMessage);
    });

  return status === 204 ? newDefinedDatasets : undefined;
};
