import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  ConfirmDialog,
  LanguageController,
  Loading,
  Modal,
  TcmsButton,
} from '../../../../components';
import {
  DATA_PAGE_KEYS_OPTIONS,
  EMPTY_HELP_ITEM_BASE,
  helpContentTypeOptions,
  helpManagementSubsectionKeys,
  helpSectionsWithSubsectionsRecord,
} from '../../../../constants';
import { putHelpCategoryItem, selectHelp } from '../../../../features';
import { useOnLeaveEdited } from '../../../../hooks';
import i18n from '../../../../i18n/i18n';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IHelp,
  IHelpCategory,
  IHelpItem,
  IHelpTextItem,
  ILanguageType,
  INavigationDataPageKeys,
  ISelectOption,
} from '../../../../types';
import {
  createClassNames,
  getTranslationKeyForNavigationKey,
  transformObjectKeysToKebabCase,
} from '../../../../utils';
import './HelpManagementPage.styles.scss';
import {
  HelpItemEdit,
  HelpManagementSectionParts,
  HelpManagementSectionPicker,
} from './components';

const classNames = createClassNames('help-management-page');

export const HelpManagementPage = () => {
  const { help, isLoading } = useAppSelector(selectHelp);
  const dispatch = useAppDispatch();

  const [activeLanguage, setActiveLanguage] = useState<keyof ILanguageType<string>>(
    i18n.language as keyof ILanguageType<string>,
  );
  const [activeSection, setActiveSection] = useState<ISelectOption>(DATA_PAGE_KEYS_OPTIONS[0]);
  const [activeSubsection, setActiveSubsection] = useState<ISelectOption>(
    helpManagementSubsectionKeys[0],
  );
  const [activeHelpCategoryType, setActiveHelpCategoryType] = useState<ISelectOption>(
    helpContentTypeOptions[0],
  );
  const [selectedListOption, setSelectedListOption] = useState<ISelectOption | null>(null);
  const [newItemId, setNewItemId] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { t } = useTranslation();

  const { leaveMethod, setLeaveMethod, onLeaveEdited } = useOnLeaveEdited(isDirty);

  const subsectionOptions = useMemo(() => {
    const navigationContentKeys =
      helpSectionsWithSubsectionsRecord[activeSection.value as INavigationDataPageKeys];

    const filteredOptions = navigationContentKeys.map<ISelectOption>(key => ({
      value: key,
      label:
        key !== 'data-settings'
          ? t(getTranslationKeyForNavigationKey(key))
          : t(ITranslationKeys.dataSelection),
    }));

    setActiveSubsection(filteredOptions[0]);

    return filteredOptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection]);

  if (isLoading) {
    return <Loading />;
  }

  const screenId = `${activeSection.value}_${activeSubsection.value}`;
  const helpItems = help[activeHelpCategoryType.value as IHelpCategory] || [];
  const isDescription = activeHelpCategoryType.value === 'descriptions';
  const editHelpItem = getEditHelpItem(isDescription, screenId, helpItems, selectedListOption);

  const handleHelpManagementSectionClick = (option: ISelectOption) => {
    setActiveSection(option);
  };

  const handleSetActiveLanguage = () => setActiveLanguage(activeLanguage === 'cz' ? 'en' : 'cz');

  const handleOnSelectedListOptionChange = (newOption: ISelectOption) => {
    if (newItemId) {
      setNewItemId(null);
    }

    if (selectedListOption?.value === newOption.value) {
      setSelectedListOption(null);
    } else {
      setSelectedListOption(newOption);
    }
  };

  const handleUpdateItem = (item: IHelpTextItem) => {
    if (editHelpItem === undefined || !selectedListOption) return;
    const { id, ...restItem } = editHelpItem;

    dispatch(
      putHelpCategoryItem({
        id: selectedListOption.value,
        category: activeHelpCategoryType.value as keyof IHelp,
        body: {
          ...restItem,
          [activeLanguage]: item,
        },
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(t(ITranslationKeys.itemEditedSuccessfully), {
          toastId: ITranslationKeys.itemEditedSuccessfully,
        });
      })
      .catch(error => {
        toast.error(t(ITranslationKeys.itemEditFailed), {
          toastId: ITranslationKeys.itemEditFailed,
        });
        console.error('[HelpManagementPage]: Edit help content item error:', error);
      });
  };

  const handleOnAddItemClick = () => {
    if (selectedListOption) {
      setSelectedListOption(null);
    }

    const uniqueId = Math.floor(Date.now() * Math.random());
    const newId = `${uniqueId}-${Date.now()}`;
    setNewItemId(newId);
  };

  const handleAddItem = (item: IHelpTextItem) => {
    if (!newItemId) return;

    dispatch(
      putHelpCategoryItem({
        id: newItemId,
        category: activeHelpCategoryType.value as keyof IHelp,
        body: {
          ...EMPTY_HELP_ITEM_BASE,
          [activeLanguage]: item,
        },
        isNew: true,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(t(ITranslationKeys.itemAddedSuccessfully), {
          toastId: ITranslationKeys.itemAddedSuccessfully,
        });
        setNewItemId(null);
        setIsDirty(false);
      })
      .catch(error => {
        toast.error(t(ITranslationKeys.itemAddFailed), {
          toastId: ITranslationKeys.itemAddFailed,
        });
        console.error('[HelpManagementPage]: Add help content item error:', error);
      });
  };

  const handleOnHelpCategoryTypeClick = (option: ISelectOption) => {
    setActiveHelpCategoryType(option);

    if (editHelpItem) {
      setSelectedListOption(null);
    }

    if (newItemId) {
      setNewItemId(null);
    }
  };

  const handleConfirmLeaveDialog = () => {
    leaveMethod?.();
    setLeaveMethod(null);
    setIsDirty(false);
  };

  const handleCloseLeaveDialog = () => {
    setLeaveMethod(null);
  };

  return (
    <div className={classNames()}>
      <div className={classNames('controls')}>
        <div className={classNames('controls__header')}>
          <h2>{t(ITranslationKeys.helpCategory)}</h2>
          <div className={classNames('controls__header__flags')}>
            <LanguageController
              activeLanguage={activeLanguage}
              onClick={() => onLeaveEdited(handleSetActiveLanguage)}
            />
          </div>
        </div>
        <div className={classNames('controls__buttons')}>
          {DATA_PAGE_KEYS_OPTIONS.map((option, index) => (
            <TcmsButton
              key={index}
              label={option.label}
              variant='filled'
              onClick={() => onLeaveEdited(() => handleHelpManagementSectionClick(option))}
              color={activeSection === option ? 'blue' : 'default'}
            />
          ))}
        </div>
      </div>
      <div
        className={classNames('content', {
          ...transformObjectKeysToKebabCase({
            isDescription,
          }),
        })}
      >
        <div>
          <HelpManagementSectionPicker
            onActiveSubsectionChange={option => onLeaveEdited(() => setActiveSubsection(option))}
            onHelpCategoryTypeClick={option =>
              onLeaveEdited(() => handleOnHelpCategoryTypeClick(option))
            }
            {...{
              activeSection,
              activeSubsection,
              activeHelpCategoryType,
              helpContentTypeOptions,
              subsectionOptions,
            }}
          />
        </div>
        {!isDescription && (
          <HelpManagementSectionParts
            onSelectedListOptionChange={option =>
              onLeaveEdited(() => handleOnSelectedListOptionChange(option))
            }
            onAddItemClick={() => onLeaveEdited(handleOnAddItemClick)}
            {...{
              screenId,
              activeSubsection,
              activeHelpCategoryType,
              activeLanguage,
              selectedListOption,
            }}
          />
        )}
        {editHelpItem && (
          <HelpItemEdit
            item={editHelpItem[activeLanguage]}
            updateItem={handleUpdateItem}
            {...{ isDirty, setIsDirty }}
          />
        )}
        {newItemId && (
          <HelpItemEdit
            item={EMPTY_HELP_ITEM_BASE.cz}
            updateItem={handleAddItem}
            isNew
            {...{ isDirty, setIsDirty }}
          />
        )}
      </div>
      <Modal open={!!leaveMethod} size='small'>
        <ConfirmDialog
          onConfirm={handleConfirmLeaveDialog}
          onCancel={handleCloseLeaveDialog}
          message={t(ITranslationKeys.continueWithoutSave)}
          type='danger'
        />
      </Modal>
    </div>
  );
};

const getEditHelpItem = (
  isDescription: boolean,
  screenId: string,
  helpItems: IHelpItem[],
  selectedListOption: ISelectOption | null,
) => {
  if (isDescription) {
    return helpItems?.find(item => item.id === screenId);
  }

  if (!selectedListOption?.value) return undefined;

  return helpItems?.find(item => item.id === selectedListOption.value);
};
