import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { filteredPlayersByPositionSelector, selectPlayers, selectWowy } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IFormation,
  IGenericWowyType,
  IMetricWithDatasetMetric,
  IPlayerRecord,
  ISimilarPlayerNameRecord,
  IWowyPlayer,
  IWowyTableData,
  IWowyTableWithHeaderData,
  IWowyType,
} from '../../../../types';
import { getMetricsWithValue, getPlayerShortName } from '../../../../utils';

const getTableData = (
  wowyPlayers: IWowyPlayer[],
  playersRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  activeRows: IGenericWowyType<boolean>,
  similarPlayerNames: ISimilarPlayerNameRecord,
  selectedPlayerId: string,
  selectedTeamId: string,
  wowyPlayersPercentiles?: IWowyPlayer[],
) => {
  const tableData = wowyPlayers
    .map<IWowyTableData>(wowyPlayer => {
      const player = playersRecord[wowyPlayer.id];
      const wowyPlayerPercentiles = wowyPlayersPercentiles?.find(
        player => player.id === wowyPlayer.id,
      );

      const subRows: IWowyTableData[] = [];
      const nestedSubRows: IWowyTableData[] = [];

      if (activeRows.teammateSeparately) {
        const row: IWowyTableData = {
          playerId: player.id,
          teamId: selectedTeamId,
          formations: createFromations(selectedPlayerId, player.id, IWowyType.teammateSeparately),
          player: {
            id: player.id,
            displayName: getPlayerShortName(player, similarPlayerNames),
          },
          gp: wowyPlayer.wowyStats.teammateSeparately.gp,
          toi: wowyPlayer.wowyStats.teammateSeparately.toi,
          stats: getMetricsWithValue(metrics, wowyPlayer.wowyStats.teammateSeparately.stats),
          subRows: [],
          wowyType: IWowyType.teammateSeparately,
          percentiles: wowyPlayerPercentiles
            ? getMetricsWithValue(metrics, wowyPlayerPercentiles.wowyStats.teammateSeparately.stats)
            : undefined,
          summaryPercentile: wowyPlayerPercentiles
            ? wowyPlayerPercentiles.wowyStats.teammateSeparately.stats['summaryPercentile']
            : undefined,
        };

        if (activeRows.separately) {
          nestedSubRows.push(row);
        } else {
          subRows.push(row);
        }
      }

      if (activeRows.separately) {
        subRows.push({
          playerId: player.id,
          teamId: selectedTeamId,
          formations: createFromations(selectedPlayerId, player.id, IWowyType.separately),
          player: {
            id: player.id,
            displayName: getPlayerShortName(player, similarPlayerNames),
          },
          gp: wowyPlayer.wowyStats.separately.gp,
          toi: wowyPlayer.wowyStats.separately.toi,
          stats: getMetricsWithValue(metrics, wowyPlayer.wowyStats.separately.stats),
          subRows: nestedSubRows,
          wowyType: IWowyType.separately,
          percentiles: wowyPlayerPercentiles
            ? getMetricsWithValue(metrics, wowyPlayerPercentiles.wowyStats.separately.stats)
            : undefined,
          summaryPercentile: wowyPlayerPercentiles
            ? wowyPlayerPercentiles.wowyStats.separately.stats['summaryPercentile']
            : undefined,
        });
      }

      return {
        playerId: selectedPlayerId,
        teamId: selectedTeamId,
        formations: createFromations(selectedPlayerId, player.id, IWowyType.together),
        player: {
          id: player.id,
          displayName: getPlayerShortName(player, similarPlayerNames),
        },
        gp: wowyPlayer.wowyStats.together.gp,
        toi: wowyPlayer.wowyStats.together.toi,
        stats: getMetricsWithValue(metrics, wowyPlayer.wowyStats.together.stats),
        subRows,
        wowyType: IWowyType.together,
        percentiles: wowyPlayerPercentiles
          ? getMetricsWithValue(metrics, wowyPlayerPercentiles.wowyStats.together.stats)
          : undefined,
        summaryPercentile: wowyPlayerPercentiles
          ? wowyPlayerPercentiles.wowyStats.together.stats['summaryPercentile']
          : undefined,
      };
    })
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = (selectedTeamId: string | undefined) => {
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);
  const filteredPlayers = useAppSelector(filteredPlayersByPositionSelector);
  const { wowy, activeRows, wowyPercentiles } = useAppSelector(selectWowy);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const filteredWowyPlayers = useMemo(
    () =>
      wowy?.wowyPlayers.filter(player =>
        filteredPlayers.map(player => player?.id).includes(player?.id),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [wowy],
  );

  const dataWithHeader: IWowyTableWithHeaderData = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics && wowy && filteredWowyPlayers && selectedTeamId) {
      const data = getTableData(
        filteredWowyPlayers,
        byId,
        filteredMetrics,
        activeRows,
        similarPlayerNames,
        wowy.selectedPlayer.id,
        selectedTeamId,
        wowyPercentiles?.wowyPlayers,
      );

      const player = byId[wowy.selectedPlayer.id];

      const selectedPlayerData: IWowyTableData = {
        playerId: player.id,
        teamId: selectedTeamId,
        player: {
          id: player.id,
          displayName: getPlayerShortName(player, similarPlayerNames),
        },
        gp: wowy.selectedPlayer.gp,
        toi: wowy.selectedPlayer.toi,
        stats: getMetricsWithValue(filteredMetrics, wowy.selectedPlayer.stats),
        subRows: [],
        percentiles: wowyPercentiles
          ? getMetricsWithValue(filteredMetrics, wowyPercentiles.selectedPlayer.stats)
          : undefined,
        summaryPercentile: wowyPercentiles
          ? wowyPercentiles.selectedPlayer.stats['summaryPercentile']
          : undefined,
      };

      return {
        selectedPlayerData,
        data,
      };
    }

    return {
      selectedPlayerData: undefined,
      data: [],
    };
  }, [
    wowy,
    wowyPercentiles,
    filteredWowyPlayers,
    activeRows,
    byId,
    similarPlayerNames,
    getFilteredMetrics,
    selectedTeamId,
  ]);

  return dataWithHeader;
};

const createFromations = (
  selectedPlayerId: string,
  playerId: string,
  type: IWowyType,
): IFormation[] => {
  const selectedPlayerOn = type !== IWowyType.teammateSeparately;
  const playerOn = type !== IWowyType.separately;

  return [
    { player: selectedPlayerId, on: selectedPlayerOn },
    { player: playerId, on: playerOn },
  ];
};
