import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../app/hooks';
import { Button, MinigraphList, NoData } from '../../../../../../components';
import { selectDashboard, selectGames, selectTeams } from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import {
  IMinigraphBattery,
  INavigationContentKeys,
  INavigationPageKeys,
  ISelectOptionWithParam,
  IUrlFilterParams,
} from '../../../../../../types';
import {
  combineSearchAndFilterParams,
  createClassNames,
  normalizeUrlWithFilterParams,
} from '../../../../../../utils';
import { useDashboardTeamSide } from '../../../../hooks';
import { perpareBatteryOptions } from '../../../../utils';
import { DashboardTeamGameListWithSummary } from '../DashboardTeamGameListWithSummary';
import { DashboardTeamTables } from '../DashboardTeamTables';
import './DashboardTeamSide.styles.scss';

interface IDashboardTeamSideProps {
  teamId: string;
  competitionIds: string[];
}

const className = createClassNames('dashboard-team-side');

export const DashboardTeamSide: FC<IDashboardTeamSideProps> = ({ competitionIds, teamId }) => {
  const { shortTable, teamBatteryStatsById } = useAppSelector(selectDashboard);
  const teams = useAppSelector(selectTeams);
  const games = useAppSelector(selectGames);

  const [searchParams] = useSearchParams();
  const { dashboardGames, selectedDashboardGame, width, setSelectedDashboardGame } =
    useDashboardTeamSide(competitionIds, teamId);

  const teamShortTable = shortTable.filter(team => team.teamId === teamId);
  const leagueTeams = Object.values(teams.byId).filter(team =>
    team.competitionIds.some(competitionId => competitionIds.includes(competitionId)),
  );
  const filteredGames = Object.values(games.games.byId).filter(
    game =>
      (game.homeTeamId === teamId || game.awayTeamId === teamId) &&
      competitionIds.includes(game.competitionId),
  );

  const minigraphOptions = perpareBatteryOptions(
    teamBatteryStatsById[teamId],
    teamShortTable[0],
    leagueTeams.length,
    teamId,
    filteredGames,
  );

  if (!shortTable.length) {
    return (
      <div className={className()} data-testid='dashboard-team-side'>
        <div className={className('error')}>
          <NoData />
        </div>
      </div>
    );
  }

  const handleTeamButtonClick = (
    teamId: string,
    pageKey: INavigationPageKeys,
    tabKey: INavigationContentKeys,
  ) => {
    const pageUrl = `/${pageKey}/${tabKey}`;
    const filterParams: IUrlFilterParams[] = [
      {
        name: 'selectedTeam',
        value: teamId,
      },
    ];

    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, filterParams);
    const urlToOpen = normalizeUrlWithFilterParams(pageUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };

  const handleMinigraphClick = (option: ISelectOptionWithParam<IMinigraphBattery>) => {
    const { tabKey, pageKey, filterParams } = option.additionalInfo;
    const pagesUrl = tabKey ? `/${pageKey}/${tabKey}` : `/${pageKey}`;

    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, filterParams);
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };

  const handleGraphicOverviewClick = () => {
    if (!selectedDashboardGame?.id) {
      return;
    }

    const filterParams: IUrlFilterParams[] = [
      {
        name: 'selectedTeam',
        value: teamId,
      },
      {
        name: 'selectedGames',
        value: selectedDashboardGame.id,
      },
    ];
    const pagesUrl = `/${INavigationPageKeys.games}/${INavigationContentKeys.graphicOverview}`;
    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, filterParams);
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };

  const handlePlayersClick = () => {
    if (!selectedDashboardGame?.id) {
      return;
    }

    const filterParams: IUrlFilterParams[] = [
      {
        name: 'selectedTeam',
        value: teamId,
      },
      {
        name: 'selectedGames',
        value: selectedDashboardGame.id,
      },
    ];
    const pagesUrl = `/${INavigationPageKeys.games}/${INavigationContentKeys.players}`;
    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, filterParams);
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };
  const handleVideomapsClick = () => {
    if (!selectedDashboardGame?.id) {
      return;
    }

    const filterParams: IUrlFilterParams[] = [
      {
        name: 'selectedTeam',
        value: teamId,
      },
      {
        name: 'selectedGames',
        value: selectedDashboardGame.id,
      },
    ];
    const pagesUrl = `/${INavigationPageKeys.videomaps}/${INavigationContentKeys.shots}`;
    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, filterParams);
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };

  return (
    <div className={className()} data-testid='dashboard-team-side'>
      <MinigraphList options={minigraphOptions} onClick={handleMinigraphClick} />
      <div className={className('list')}>
        <Button
          label={ITranslationKeys.players}
          onClick={() =>
            handleTeamButtonClick(teamId, INavigationPageKeys.players, INavigationContentKeys.tabs)
          }
        />
        <Button
          label={ITranslationKeys.formations}
          onClick={() =>
            handleTeamButtonClick(
              teamId,
              INavigationPageKeys.formations,
              INavigationContentKeys.formations,
            )
          }
        />
      </div>
      <DashboardTeamGameListWithSummary
        {...{ dashboardGames, selectedDashboardGame, setSelectedDashboardGame, teamId, width }}
      />
      <DashboardTeamTables {...{ selectedDashboardGame, teamId }} />
      <div className={className('list')}>
        <Button label={ITranslationKeys.graphicOverview} onClick={handleGraphicOverviewClick} />
        <Button label={ITranslationKeys.players} onClick={handlePlayersClick} />
        <Button label={ITranslationKeys.videomaps} onClick={handleVideomapsClick} />
      </div>
    </div>
  );
};
