import { ITranslationKeys } from '../../../../i18n/types';

export interface IGoalkeeperCardConfig {
  metricId: string;
  label: ITranslationKeys;
}

export const seasonStatisticsConfig: IGoalkeeperCardConfig[] = [
  { metricId: 'gsax', label: ITranslationKeys.goalsSavedAboveExpected },
  { metricId: 'sg', label: ITranslationKeys.stolenGames },
  { metricId: 'shutout', label: ITranslationKeys.shutouts },
  { metricId: 'gr', label: ITranslationKeys.goalieReliability },
  { metricId: 'cn.sv_pct', label: ITranslationKeys.controlledSaves },
  { metricId: 'reb.gsax', label: ITranslationKeys.rebounds },
  { metricId: 'cr.sa.gsax', label: ITranslationKeys.afterCrossIceShotAssists },
  { metricId: 'so.sv_percent', label: ITranslationKeys.shootoutAttempts },
];

export const actualFormConfig: IGoalkeeperCardConfig[] = [
  { metricId: 'gsax', label: ITranslationKeys.goalsSavedAboveExpected },
  { metricId: 'ga60', label: ITranslationKeys.receivedGoals },
];
