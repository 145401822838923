import {
  ColumnDef,
  ColumnPinningState,
  Row,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  BasicColumnCell,
  BasicColumnHeader,
  EyeDropdown,
  IEyeDropdownTooltipItem,
  SummaryPercentileColumnCell,
  SummaryPercentileColumnHeader,
  WowyPlayerColumnCell,
} from '../../../../components';
import { filteredPlayersOptionsSelector, selectPlayers, selectWowy } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IColumnsConfig,
  IGenericWowyType,
  INavigationContentKeys,
  INavigationPageKeys,
  IPlayerRecord,
  ISelectOption,
  IUrlFilterParams,
  IWowyTableData,
  IWowyType,
} from '../../../../types';
import { createDynamicStatsColumnsDef, secondsToMinutesAndSeconds } from '../../../../utils';

const columnHelper = createColumnHelper<IWowyTableData>();

const columnPinning: ColumnPinningState = {
  left: ['actions', 'player', 'gp', 'toi'],
  right: ['summaryPercentile'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns: ColumnDef<IWowyTableData, any>[] = [
  columnHelper.accessor('player', {
    header: () => <span style={{ display: 'block', width: '230px' }}></span>,
    cell: props => <WowyPlayerColumnCell {...props} />,
  }),
  columnHelper.accessor('gp', {
    header: props => <BasicColumnHeader {...props}>GP</BasicColumnHeader>,
    cell: props => <BasicColumnCell {...props}>{props.getValue()}</BasicColumnCell>,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<IWowyTableData> => {
  const { activeRows, isWowyPercentilesActive, isWowyPercentilesLoading } =
    useAppSelector(selectWowy);
  const { wowy } = useAppSelector(selectWowy);
  const playersOptions = useAppSelector(filteredPlayersOptionsSelector);
  const players = useAppSelector(selectPlayers);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const actionsColumn = columnHelper.display({
      id: 'actions',
      cell: props =>
        wowy
          ? displayActionsIcon(
              props.row,
              activeRows,
              wowy.selectedPlayer.id,
              playersOptions,
              players.byId,
            )
          : null,
    });

    const filteredMetrics = getFilteredMetrics();
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      {
        isPercentilesActive: isWowyPercentilesActive,
        isPercentilesLoading: isWowyPercentilesLoading,
      },
      centerPartWidth,
    );

    const conditionalColumns: ColumnDef<IWowyTableData, any>[] =
      isWowyPercentilesActive && !isWowyPercentilesLoading
        ? [
            columnHelper.accessor('summaryPercentile', {
              header: props => (
                <BasicColumnHeader {...props}>
                  <SummaryPercentileColumnHeader isLastInTable />
                </BasicColumnHeader>
              ),
              cell: props => (
                <BasicColumnCell {...props}>
                  <SummaryPercentileColumnCell value={props.getValue()} />
                </BasicColumnCell>
              ),
            }),
          ]
        : [];

    return fixedColumns.concat(dynamicColumns).concat(conditionalColumns).concat(actionsColumn);
  }, [
    isWowyPercentilesActive,
    isWowyPercentilesLoading,
    activeRows,
    wowy,
    playersOptions,
    players.byId,
    centerPartWidth,
    getFilteredMetrics,
  ]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const displayActionsIcon = (
  row: Row<IWowyTableData>,
  activeRows: IGenericWowyType<boolean>,
  wowyPlayerId: string,
  playersOptions: ISelectOption[],
  players: IPlayerRecord,
) => {
  const wowyPlayer = players[wowyPlayerId];

  const playerOne = playersOptions.find(option => option.value === wowyPlayerId)?.value;
  const playerTwo = row.original.player.id;

  const filterParams: IUrlFilterParams[] = [
    {
      name: 'selectedTeam',
      value: wowyPlayer.currentTeam,
    },
    {
      name: 'selectedPlayerItems',
      value: [playerOne, playerTwo].join(','),
    },
  ];

  const eyeDropdownTooltipItems: IEyeDropdownTooltipItem[] = [
    {
      pageTranslationLabel: ITranslationKeys.gamelog,
      navigationPageKey: INavigationPageKeys.players,
      navigationTabKey: INavigationContentKeys.gamelog,
      filterParams,
    },
    {
      pageTranslationLabel: ITranslationKeys.trend,
      navigationPageKey: INavigationPageKeys.players,
      navigationTabKey: INavigationContentKeys.trend,
      filterParams,
    },
    {
      pageTranslationLabel: ITranslationKeys.combinations,
      navigationPageKey: INavigationPageKeys.formations,
      navigationTabKey: INavigationContentKeys.playerCombinations,
      filterParams,
    },
  ];

  if (row.original.wowyType === IWowyType.separately) {
    return <EyeDropdown eyeDropdownTooltipItems={eyeDropdownTooltipItems} />;
  }

  if (row.original.wowyType === IWowyType.together) {
    return !activeRows.separately ? (
      <EyeDropdown eyeDropdownTooltipItems={eyeDropdownTooltipItems} />
    ) : undefined;
  }

  if (row.original.wowyType === IWowyType.teammateSeparately) {
    return !activeRows.separately && !activeRows.together ? (
      <EyeDropdown eyeDropdownTooltipItems={eyeDropdownTooltipItems} />
    ) : undefined;
  }

  return undefined;
};
