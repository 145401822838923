import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  getShootouts,
  selectMainFilter,
  setShootoutPage,
} from '../../features';
import { IBaseRequestBody, IFetchDataConfig, IMainFilterForm } from '../../types';
import { normalizeDateFromTo } from '../../utils';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchShootouts = () => {
  const dispatch = useAppDispatch();
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam } = useAppSelector(selectMainFilter);

  const fetchShootouts = (
    data: Partial<IMainFilterForm>,
    shootoutPage: 'videomaps' | 'goalkeepers',
    config?: IFetchDataConfig,
  ) => {
    const dateRange = config?.customDateFromTo
      ? normalizeDateFromTo(config?.customDateFromTo)
      : undefined;

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IBaseRequestBody = {
      ...requestBodyBase,
      gameState: undefined,
      dateFrom: dateRange?.from || requestBodyBase.dateFrom,
      dateTo: dateRange?.to || requestBodyBase.dateTo,
    };

    const competitionUuids = filteredParts.map(part => part.id);
    const selectedTeamUuid = selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : '';
    const teamUuid = config?.customSelectedTeamId || selectedTeamUuid;

    dispatch(setShootoutPage(shootoutPage));

    dispatch(
      getShootouts({
        competitionUuids,
        teamUuid,
        body: requestBody,
      }),
    );
  };

  return {
    fetchShootouts,
  };
};
