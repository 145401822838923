import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { teamAllOption } from '../../../constants';
import {
  getCompetitionTable,
  getGames,
  getScheduledMatches,
  getShortTable,
  selectAuth,
  selectDashboard,
  selectGames,
  selectSeasons,
  selectTeams,
  setSelectedAwayTeam,
  setSelectedHomeTeam,
} from '../../../features';
import { useWindowSize } from '../../../hooks';
import { IDashboardGame } from '../../../types';
import {
  generateDashboardGames,
  getAllSeasonPartsFromSeasonRecord,
  getTeamOptionsFromSeason,
} from '../utils';
import { useFetchGamePreviews, useFetchMetrics, useFetchTable } from './fetch';

export const useDashboardContent = () => {
  const teams = useAppSelector(selectTeams);
  const {
    // Other
    selectedTeamIds,
    // Data
    scheduledMatches,
    shortTable,
    teamStatsAllIds,
    teamBatteryStatsById,
    gamePreviewsById,
    // Loading
    areScheduledMatchesLoading,
    isShortTableLoading,
    areTeamStatsLoading,
    // Errors
    scheduledMatchesError,
    shortTableError,
    teamStatsError,
  } = useAppSelector(selectDashboard);
  const dispatch = useAppDispatch();

  const { competitionIds } = useDashboardPage();

  const { fetchGoalkeeperMetrics, fetchTeamMetrics } = useFetchMetrics();
  const fetchGamePreview = useFetchGamePreviews();
  const teamOptions = useMemo(() => {
    if (!teams.allIds.length || !competitionIds) {
      return [];
    }

    return getTeamOptionsFromSeason(teams, competitionIds);
  }, [teams, competitionIds]);

  useEffect(() => {
    if (
      areScheduledMatchesLoading ||
      selectedTeamIds.awayTeam ||
      scheduledMatchesError ||
      !scheduledMatches.length ||
      !teams.allIds.length
    ) {
      return;
    }

    const teamShortcut = teamOptions.find(team =>
      scheduledMatches.find(match => match.id === teams.byId[team.value].shortcut),
    );
    if (teamShortcut) dispatch(setSelectedAwayTeam(teamShortcut.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledMatches, areScheduledMatchesLoading, teams, teamOptions]);

  useEffect(() => {
    if (
      !competitionIds ||
      selectedTeamIds.homeTeam === teamAllOption.value ||
      scheduledMatchesError ||
      shortTableError
    ) {
      return;
    }

    if (!scheduledMatches.length && !areScheduledMatchesLoading) {
      dispatch(
        getScheduledMatches({
          competitionsUuids: competitionIds.join(','),
        }),
      );
    }

    if (
      (scheduledMatches.length || areScheduledMatchesLoading) &&
      !shortTable.length &&
      !isShortTableLoading
    ) {
      dispatch(
        getShortTable({
          competitionsUuids: competitionIds.join(','),
        }),
      );
    }

    if (
      selectedTeamIds.homeTeam &&
      selectedTeamIds.awayTeam &&
      !areTeamStatsLoading &&
      !teamStatsError
    ) {
      [selectedTeamIds.homeTeam, selectedTeamIds.awayTeam].forEach(teamId => {
        if (!Object.keys(gamePreviewsById).includes(teamId)) {
          dispatch(
            getGames({
              competitionsUuids: competitionIds.join(','),
              teamUuid: teamId,
              dontRemovePreviousGames: true,
            }),
          )
            .unwrap()
            .then(games => {
              if (!games.length) {
                return;
              }

              const firstFiveGames = games.slice(0, 5);
              Promise.allSettled(fetchGamePreview(competitionIds, firstFiveGames, teamId));
            });
        }

        if (teamStatsAllIds.includes(teamId)) {
          return;
        }

        fetchTeamMetrics(competitionIds, teamId);
        fetchGoalkeeperMetrics(competitionIds, teamId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    competitionIds,
    scheduledMatches,
    shortTable,
    teamStatsAllIds,
    teamBatteryStatsById,
    areScheduledMatchesLoading,
    isShortTableLoading,
    areTeamStatsLoading,
    selectedTeamIds,
  ]);

  return {
    competitionIds,
    teamOptions,
  };
};

export const useDashboardTeamSide = (competitionIds: string[], teamId: string) => {
  const { gamePreviewsById } = useAppSelector(selectDashboard);
  const games = useAppSelector(selectGames);
  const teams = useAppSelector(selectTeams);
  const { width } = useWindowSize();

  const [selectedDashboardGame, setSelectedDashboardGame] = useState<IDashboardGame>();

  const fetchTable = useFetchTable();

  const dashboardGames = generateDashboardGames(
    Object.values(games.games.byId),
    teamId,
    competitionIds,
    gamePreviewsById,
    teams.byId,
  );

  useEffect(() => {
    if (
      (!selectedDashboardGame && gamePreviewsById[teamId]) ||
      (selectedDashboardGame?.awayTeam.logo !== teamId &&
        selectedDashboardGame?.homeTeam.logo !== teamId)
    ) {
      setSelectedDashboardGame(dashboardGames[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardGame, gamePreviewsById, dashboardGames, teamId]);

  useEffect(() => {
    if (!selectedDashboardGame) {
      return;
    }

    fetchTable(competitionIds, teamId, selectedDashboardGame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardGame, competitionIds, teamId]);

  return {
    dashboardGames,
    selectedDashboardGame,
    width,
    setSelectedDashboardGame,
  };
};

export const useDashboardPage = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  const { selectedTeamIds } = useAppSelector(selectDashboard);
  const teams = useAppSelector(selectTeams);
  const seasons = useAppSelector(selectSeasons);

  const [competitionIds, setCompetitionIds] = useState<string[] | undefined>();

  const teamOptions = useMemo(() => {
    if (!teams.allIds.length || !competitionIds) {
      return [];
    }

    return getTeamOptionsFromSeason(teams, competitionIds);
  }, [teams, competitionIds]);

  useEffect(() => {
    const lastSeasons = getAllSeasonPartsFromSeasonRecord(seasons.byId);
    if (!lastSeasons) {
      return;
    }

    setCompetitionIds([lastSeasons.competitionIds[3]]);
  }, [seasons]);

  useEffect(() => {
    if (!user || !teams.allIds.length || selectedTeamIds.homeTeam) {
      return;
    }

    dispatch(setSelectedHomeTeam(user.teamId ? user.teamId : teamAllOption.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, teams]);

  return {
    teamOptions,
    competitionIds,
  };
};

export const useStandingsContent = () => {
  const dispatch = useAppDispatch();
  const {
    competitionTable,
    shortTable,
    isCompetitionTableLoading,
    isShortTableLoading,
    competitionTableError,
    shortTableError,
  } = useAppSelector(selectDashboard);
  const teams = useAppSelector(selectTeams);
  const { competitionIds } = useDashboardPage();

  useEffect(() => {
    if (competitionTableError || shortTableError || !competitionIds) {
      return;
    }

    if (!shortTable.length && !isShortTableLoading) {
      dispatch(
        getShortTable({
          competitionsUuids: competitionIds.join(','),
        }),
      );
    }

    if (!competitionTable.length && !isCompetitionTableLoading) {
      dispatch(
        getCompetitionTable({
          competitionsUuids: competitionIds.join(','),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    competitionTable,
    shortTable,
    isCompetitionTableLoading,
    isShortTableLoading,
    competitionTableError,
    shortTableError,
    competitionIds,
  ]);

  useEffect(() => {
    if (!teams.allIds.length || !competitionIds) {
      return;
    }

    teams.allIds.forEach(teamId =>
      dispatch(
        getGames({
          competitionsUuids: competitionIds.join(','),
          teamUuid: teamId,
          dontRemovePreviousGames: true,
        }),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams, competitionIds]);
};
