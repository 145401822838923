import { FC } from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import { ISelectOptionBase, ITcmsItemWithDropdownProps } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { TcmsSortableItemWithDropdown } from '../TcmsItemWithDropdown';
import './TcmsDropdownList.styles.scss';

export interface ITcmsDropdownListProps {
  /** TCMS sortable items to be mapped for user interaction */
  options: ITcmsItemWithDropdownProps[];
  /** The currently selected item */
  selectedOption?: ISelectOptionBase;
  /**
   * Whether the component should be rendered in a small size
   * @default false
   */
  isSmall?: boolean;
  /** Disable sorting */
  disableSorting?: boolean;
  /** Callback function that is called when the user clicks on the dropdown icon */
  onDropdownItemExpansionClick?: (option: ITcmsItemWithDropdownProps) => void;
}

const classNames = createClassNames('tcms-dropdown-list');

export const TcmsDropdownList: FC<ITcmsDropdownListProps> = ({
  options,
  selectedOption,
  isSmall = false,
  disableSorting = false,
  onDropdownItemExpansionClick,
}) => (
  <div
    className={classNames({ ...transformObjectKeysToKebabCase({ isSmall }) })}
    data-testid='tcms-dropdown-list'
  >
    {options.map((option, index) => (
      <TcmsSortableItemWithDropdown
        key={index}
        index={index}
        selected={option.label === selectedOption?.label}
        onClick={() => onDropdownItemExpansionClick?.(option)}
        disabled={disableSorting}
        {...option}
      />
    ))}
  </div>
);

export const TcmsSortableDropdownList = SortableContainer<ITcmsDropdownListProps>(
  (props: ITcmsDropdownListProps) => <TcmsDropdownList {...props} />,
);
