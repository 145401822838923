import { CellContext } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { IAlertColors } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import './DashboardColumn.styles.scss';

export interface IDashboardColumnCellProps<TData, TValue> extends CellContext<TData, TValue> {
  color: IAlertColors;
  label: ITranslationKeys;
}

const classNamesCell = createClassNames('dashboard-column-cell');

export const DashboardColumnCell = <TData, TValue>({
  column,
  color,
  label,
}: IDashboardColumnCellProps<TData, TValue>) => {
  const { t } = useTranslation();
  const isSortable = column.getCanSort();
  const isSorted = !!column.getIsSorted();

  return (
    <div
      className={classNamesCell({
        ...transformObjectKeysToKebabCase({
          isSorted: isSortable && isSorted,
          color,
        }),
      })}
    >
      <div className={classNamesCell('state')}>{t(label)}</div>
    </div>
  );
};
