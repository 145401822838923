import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SortEndHandler } from 'react-sortable-hoc';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { TcmsItemActions, TcmsSortableList } from '../../../../../../components';
import {
  selectDataSettingsFilter,
  selectMetrics,
  setDataSettingsSelectedMetrics,
} from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { TcmsSaveIcon } from '../../../../../../icons';
import { IDatasetMetric, IEntity, ISelectOption } from '../../../../../../types';
import {
  createClassNames,
  getMetricLabel,
  moveArrayItem,
  transformObjectKeysToKebabCase,
} from '../../../../../../utils';
import './TemplateMetricOverview.styles.scss';

export interface ITemplateMetricOverviewProps {
  activeSection: IEntity;
  isDirty: boolean;
  onSaveButtonClick: () => void;
}

const classNames = createClassNames('template-metric-overview');

export const TemplateMetricOverview: FC<ITemplateMetricOverviewProps> = ({
  activeSection,
  isDirty,
  onSaveButtonClick,
}) => {
  const { selectedMetrics } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleRemoveMetric = (option: ISelectOption<IDatasetMetric>) => {
    if (selectedMetrics.find(metric => metric.additionalInfo.code === option.value)) {
      dispatch(
        setDataSettingsSelectedMetrics(
          selectedMetrics.filter(metric => metric.additionalInfo.code !== option.value),
        ),
      );
    }
  };

  const handleOnListSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newArray = [...selectedMetrics];
      moveArrayItem(newArray, oldIndex, newIndex);

      dispatch(setDataSettingsSelectedMetrics(newArray));
    }
  };

  if (!metrics) return null;

  const options = selectedMetrics.map(metric => ({
    value: metric.value,
    label: getMetricLabel(
      metrics[activeSection]?.byId[metric.additionalInfo.origin],
      metric.additionalInfo,
    ),
  }));

  return (
    <div className={classNames()}>
      <h3>{t(ITranslationKeys.selectedMetrics)}</h3>
      <TcmsSortableList
        selected={undefined}
        options={options}
        onItemClick={undefined}
        onSortEnd={handleOnListSortEnd}
        itemChildrenRenderer={option => (
          <TcmsItemActions
            item={option}
            isItemChecked={false}
            onItemRemoveClick={option =>
              handleRemoveMetric(option as ISelectOption<IDatasetMetric>)
            }
          />
        )}
        helperClass='sortable-helper'
        lockAxis='y'
        useDragHandle
        size='medium'
      />
      <div
        className={classNames('save-button', {
          ...transformObjectKeysToKebabCase({
            isDirty,
          }),
        })}
        onClick={isDirty ? onSaveButtonClick : undefined}
      >
        <TcmsSaveIcon />
        {t(ITranslationKeys.save)}
      </div>
    </div>
  );
};
