import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '../../../../../../app/hooks';
import { LogoCard, LogoCardList, Tooltip } from '../../../../../../components';
import { selectTeams } from '../../../../../../features';
import { useClickOutside } from '../../../../../../hooks';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { ChangeCircleIcon } from '../../../../../../icons';
import {
  INavigationContentKeys,
  INavigationPageKeys,
  ISelectOption,
  IUrlFilterParams,
} from '../../../../../../types';
import {
  combineSearchAndFilterParams,
  createClassNames,
  normalizeUrlWithFilterParams,
} from '../../../../../../utils';
import './DashboardTeamComparison.styles.scss';

const classNames = createClassNames('dashboard-team-comparison');

interface IDashboardTeamComparisonProps {
  leftTeamId: string;
  rightTeamId: string;
  teamOptions: ISelectOption[];
  onRightTeamChange: (option: ISelectOption | undefined) => void;
}

export const DashboardTeamComparison: FC<IDashboardTeamComparisonProps> = ({
  leftTeamId,
  rightTeamId,
  teamOptions,
  onRightTeamChange,
}) => {
  const { t } = useTranslation();
  const teams = useAppSelector(selectTeams);

  const [searchParams] = useSearchParams();

  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const ref = useClickOutside<HTMLDivElement>(() => setIsTooltipShown(false), [setIsTooltipShown]);

  if (leftTeamId === 'all') {
    return (
      <div className={classNames()} data-testid='dashboard-team-comparison'>
        Pro tuto možnost ještě není Dashboard připravená
      </div>
    );
  }

  const leftTeam = teams.byId[leftTeamId];
  const rightTeam = teams.byId[rightTeamId];
  const selectedLeftTeamOption = teamOptions.find(option => option.value === leftTeamId);
  const selectedRightTeamOption = teamOptions.find(option => option.value === rightTeamId);

  const handleRightTeamChange = (option: ISelectOption | undefined) => {
    onRightTeamChange(option);
    setIsTooltipShown(false);
  };

  const handleLogoCardClick = (teamId: string) => {
    const filterParams: IUrlFilterParams[] = [
      {
        name: 'selectedTeam',
        value: teamId,
      },
    ];
    const pagesUrl = `/${INavigationPageKeys.teams}/${INavigationContentKeys.tabs}`;

    const urlParamsToOpen = combineSearchAndFilterParams(searchParams, filterParams);
    const urlToOpen = normalizeUrlWithFilterParams(pagesUrl, urlParamsToOpen);
    window.open(urlToOpen, '_blank');
  };

  return (
    <div className={classNames()} data-testid='dashboard-team-comparison'>
      <div className={classNames('team')}>
        <div className={classNames('team__header')}>
          {t(ITranslationKeys.selectedTeam)}: {leftTeam.name}
        </div>
        <div className={classNames('team__logo')}>
          <LogoCard
            teamId={leftTeamId}
            onClick={() => handleLogoCardClick(leftTeamId)}
            hoverEffect='borderless'
          />
        </div>
      </div>
      {/* // TODO: Dodělat až bude hotová obrazovka pro srovnání týmů
      <div className={classNames('button')}>
        <Button label={ITranslationKeys.teamComparison} />
      </div> */}
      <div className={classNames('team')}>
        <div className={classNames('team__header')}>
          {t(ITranslationKeys.nextOpponent)}: {rightTeam.name}{' '}
          <div className={classNames('team__header__switch')}>
            <ChangeCircleIcon onClick={() => setIsTooltipShown(!isTooltipShown)} />
            <Tooltip isShown={isTooltipShown} variant='white' arrowDirection='up' effect='fade'>
              <div ref={ref}>
                <LogoCardList
                  options={teamOptions}
                  onChange={handleRightTeamChange}
                  selected={selectedRightTeamOption}
                  disabledOptions={selectedLeftTeamOption ? [selectedLeftTeamOption] : []}
                  centerContent
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={classNames('team__logo')}>
          <LogoCard
            teamId={rightTeamId}
            onClick={() => handleLogoCardClick(rightTeamId)}
            hoverEffect='borderless'
          />
        </div>
      </div>
    </div>
  );
};
