import { useState } from 'react';

export const useOnLeaveEdited = (isDirty: boolean) => {
  const [leaveMethod, setLeaveMethod] = useState<(() => void) | null>(null);

  const onLeaveEdited = (callback: () => void) => {
    if (isDirty) {
      setLeaveMethod(() => callback);
    } else {
      callback();
    }
  };

  return {
    leaveMethod,
    setLeaveMethod,
    onLeaveEdited,
  };
};
