import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';

import { useAppSelector } from '../../../../app/hooks';
import {
  BasicColumnCell,
  BasicColumnHeader,
  HeadCellBase,
  TimePeriodColumnCell,
  TimePeriodColumnHeader,
} from '../../../../components';
import { filteredTrendSelector, selectTrend } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IColumnsConfig,
  IEntity,
  ITimePeriodTableForm,
  ITrendGoalkeeperStatsTableData,
} from '../../../../types';
import { createDynamicStatsColumnsDef, secondsToMinutesAndSeconds } from '../../../../utils';

const columnHelper = createColumnHelper<ITrendGoalkeeperStatsTableData>();

const columnPinning: ColumnPinningState = {
  left: ['rowIndex', 'toi'],
};

const initialSorting: SortingState = [];

const fixedColumns: ColumnDef<ITrendGoalkeeperStatsTableData, any>[] = [
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>
        {props.getValue() ? secondsToMinutesAndSeconds(props.getValue()) : ''}
      </BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  control: Control<ITimePeriodTableForm, any>,
  centerPartWidth: number | undefined,
  entity?: IEntity,
): IColumnsConfig<ITrendGoalkeeperStatsTableData> => {
  const { tableDataBy, tableTimePeriodType } = useAppSelector(selectTrend);
  const filteredTrend = useAppSelector(filteredTrendSelector);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(entity);
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      undefined,
      centerPartWidth,
    );

    const timePeriodColumn = columnHelper.accessor('rowIndex', {
      header: () => (
        <HeadCellBase>
          <TimePeriodColumnHeader dataBy={tableDataBy} entity={IEntity.goalkeepers} />
        </HeadCellBase>
      ),
      cell: props => (
        <TimePeriodColumnCell
          {...props}
          control={control}
          dataLength={filteredTrend.length}
          dataBy={tableDataBy}
          timePeriodType={tableTimePeriodType}
        />
      ),
    });

    return [timePeriodColumn].concat(fixedColumns).concat(dynamicColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableDataBy, centerPartWidth, getFilteredMetrics]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};
