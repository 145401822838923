import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks';
import { TcmsItemActions, TcmsSortableList } from '../../../../../../components';
import {
  selectDefinedDatasets,
  selectMetrics,
  setDataSettingsSelectedMetrics,
} from '../../../../../../features';
import { ITranslationKeys } from '../../../../../../i18n/types';
import { IEntity, ILanguageType, IMetricCategory, ISelectOption } from '../../../../../../types';
import {
  createClassNames,
  getTranslationKeyForNavigationKey,
  transformObjectKeysToKebabCase,
} from '../../../../../../utils';
import { mapDatasetGroupOptions, transformSelectedMetricsToSelectOptions } from '../../utils';
import './DefaultTemplates.styles.scss';

interface IDefaultTemplatesProps {
  activeSection: IEntity | null;
  activeLanguage: keyof ILanguageType<string>;
  size: 'big' | 'medium' | 'small';
  metricsBySection: IMetricCategory;
  onSelectedListOptionChange: (newOption: ISelectOption) => void;
  setDatasetGroupOptions: Dispatch<SetStateAction<ISelectOption[]>>;
  activeTemplate: ISelectOption | undefined;
}

const classNames = createClassNames('default-templates');

export const DefaultTemplates: FC<IDefaultTemplatesProps> = ({
  activeSection,
  activeLanguage,
  size,
  metricsBySection,
  onSelectedListOptionChange,
  setDatasetGroupOptions,
  activeTemplate,
}) => {
  const { defaultTemplates, definedDatasets } = useAppSelector(selectDefinedDatasets);
  const { metrics } = useAppSelector(selectMetrics);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [templatesOptions, setTemplatesOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (defaultTemplates) {
      const listOptions = defaultTemplates.allIds.map<ISelectOption>(id => {
        const [page, subPage] = defaultTemplates.byId[id].id.split('_');

        return {
          value: id,
          label: `${t(page)} - ${t(getTranslationKeyForNavigationKey(subPage))}`,
          additionalValue: defaultTemplates.byId[id].entity,
        };
      });
      setTemplatesOptions(listOptions);

      if (!activeSection) return;

      setDatasetGroupOptions(
        mapDatasetGroupOptions(activeLanguage, activeSection, definedDatasets, metrics),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguage, defaultTemplates, definedDatasets, activeSection]);

  useEffect(() => {
    if (defaultTemplates) {
      const selectedMetrics = transformSelectedMetricsToSelectOptions(
        defaultTemplates.byId,
        metricsBySection,
        activeTemplate,
      );
      dispatch(setDataSettingsSelectedMetrics(selectedMetrics));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTemplate]);

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({ size }),
      })}
    >
      <h3>{t(ITranslationKeys.defaultTemplates)}</h3>
      <TcmsSortableList
        selected={templatesOptions.find(option => option.value === activeTemplate?.value)}
        options={templatesOptions}
        onItemClick={onSelectedListOptionChange}
        itemChildrenRenderer={option => <TcmsItemActions item={option} isItemChecked={true} />}
        useDragHandle
        disableSorting
        {...{ size }}
      />
    </div>
  );
};
