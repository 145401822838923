import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { usePrepareBaseRequestBody } from '../';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  getGamelog,
  getGamesWithToi,
  getHeatmap,
  getPasses,
  getShots,
  selectGames,
  selectMainFilter,
  selectVideomapsFilter,
} from '../../features';
import { ITranslationKeys } from '../../i18n/types';
import {
  IFetchDataConfig,
  IFetchDataGoalkeeperConfig,
  IGame,
  IHeatmapsRequestBody,
  IMainFilterForm,
  IPassesRequestBody,
} from '../../types';
import { createFormationFromSelectedPlayers, normalizeDateFromTo } from '../../utils';

export interface IFetchGamesAndToiDataConfig extends IFetchDataConfig {
  onGamesLoad?: (games: IGame[]) => void;
}

export const useFetchShotsOrPasses = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam, selectedGoalkeeper, selectedGames, selectedPlayerItems } =
    useAppSelector(selectMainFilter);
  const { entity, comparisonType } = useAppSelector(selectVideomapsFilter);
  const { games } = useAppSelector(selectGames);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const competitionsUuids = filteredParts.map(part => part.id);
  const filteredSelected = Object.values(selectedPlayerItems).filter(item => item.selectedPlayer);
  const selectedTeamUuid = selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : '';

  const matches = selectedGames.map(game => game.value);

  const fetchShots = (data: Partial<IMainFilterForm>, config?: IFetchDataConfig) => {
    const selectedPlayers = config?.customSelectedPlayerItems || filteredSelected;
    const teamUuid = config?.customSelectedTeamId || selectedTeamUuid;
    const dateRange = config?.customDateFromTo
      ? normalizeDateFromTo(config?.customDateFromTo)
      : undefined;

    const formation = createFormationFromSelectedPlayers(selectedPlayers);
    const isValidFormation = formation.length > 0;

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestShotsBody: IPassesRequestBody = {
      ...requestBodyBase,
      formation: isValidFormation ? formation : undefined,
      dateFrom: dateRange?.from || requestBodyBase.dateFrom,
      dateTo: dateRange?.to || requestBodyBase.dateTo,
    };

    dispatch(
      getShots({
        competitionsUuids,
        body: requestShotsBody,
        teamUuid,
        isFormation: isValidFormation,
      }),
    );

    dispatch(
      getShots({
        competitionsUuids,
        body: requestShotsBody,
        teamUuid,
        isFormation: isValidFormation,
        isAgainst: true,
      }),
    );
  };

  const fetchPasses = (data: Partial<IMainFilterForm>, config?: IFetchDataConfig) => {
    const selectedPlayers = config?.customSelectedPlayerItems || filteredSelected;
    const teamUuid = config?.customSelectedTeamId || selectedTeamUuid;
    const dateRange = config?.customDateFromTo
      ? normalizeDateFromTo(config?.customDateFromTo)
      : undefined;

    const formation = createFormationFromSelectedPlayers(selectedPlayers);
    const isValidFormation = formation.length > 0;

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestShotsBody: IPassesRequestBody = {
      ...requestBodyBase,
      formation: isValidFormation ? formation : undefined,
      dateFrom: dateRange?.from || requestBodyBase.dateFrom,
      dateTo: dateRange?.to || requestBodyBase.dateTo,
    };

    dispatch(
      getPasses({
        competitionsUuids,
        body: requestShotsBody,
        teamUuid,
        isFormation: isValidFormation,
      }),
    );

    dispatch(
      getPasses({
        competitionsUuids,
        body: requestShotsBody,
        teamUuid,
        isFormation: isValidFormation,
        isAgainst: true,
      }),
    );
  };

  const fetchHeatmap = (data: Partial<IMainFilterForm>, isInitHeatmap?: boolean) => {
    const formation = createFormationFromSelectedPlayers(filteredSelected);
    const isValidFormation = formation.length > 0;

    const isPlayerEntitySelected = entity.value === 'player';
    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestShotsBody: IHeatmapsRequestBody = {
      ...requestBodyBase,
      formation: isValidFormation && !isPlayerEntitySelected ? formation : undefined,
      matches: isInitHeatmap ? games.allIds : matches,
    };

    if (isPlayerEntitySelected) {
      const activeSelectedPlayers = filteredSelected.filter(item => item.isActive);
      const isOnlyOnePlayerSelected = activeSelectedPlayers.length === 1;

      if (isOnlyOnePlayerSelected) {
        dispatch(
          getHeatmap({
            competitionsUuids,
            body: requestShotsBody,
            teamUuid: selectedTeamUuid,
            isFormation: isValidFormation,
            isRelativeToLeague: true,
            playerUuid: activeSelectedPlayers[0].selectedPlayer?.value,
          }),
        );

        return;
      }

      if (activeSelectedPlayers.length > 1 && comparisonType?.value !== 'without') {
        toast.warn(t(ITranslationKeys.onlyOnePlayerRequired), {
          toastId: 'onlyOnePlayerRequired',
        });
      }

      return;
    }

    dispatch(
      getHeatmap({
        competitionsUuids,
        body: requestShotsBody,
        teamUuid: selectedTeamUuid,
        isFormation: isValidFormation,
        isRelativeToLeague: true,
      }),
    );
  };

  const fetchGamesAndToiForShotsOrPasses = (
    data: Partial<IMainFilterForm>,
    config?: IFetchGamesAndToiDataConfig,
  ) => {
    const selectedPlayers = config?.customSelectedPlayerItems || filteredSelected;
    const teamUuid = config?.customSelectedTeamId || selectedTeamUuid;
    const dateRange = config?.customDateFromTo
      ? normalizeDateFromTo(config?.customDateFromTo)
      : undefined;

    const formation = createFormationFromSelectedPlayers(selectedPlayers);
    const isValidFormation = formation.length > 0;

    const { requestBodyBase, requestBodyGames } = prepareBaseRequestBody(data);
    const requestShotsBody: IPassesRequestBody = {
      ...requestBodyBase,
      formation: isValidFormation ? formation : undefined,
      dateFrom: dateRange?.from || requestBodyBase.dateFrom,
      dateTo: dateRange?.to || requestBodyBase.dateTo,
    };

    dispatch(
      getGamesWithToi({
        competitionsUuids: competitionsUuids.join(','),
        body: requestBodyGames,
        bodyToi: requestShotsBody,
        teamUuid,
      }),
    )
      .unwrap()
      .then(games => config?.onGamesLoad?.(games));
  };

  const fetchGoalkeepersShots = (
    data: Partial<IMainFilterForm>,
    config?: IFetchDataGoalkeeperConfig,
  ) => {
    const selectedGoalkeeperOption = config?.customSelectedGoalkeeper || selectedGoalkeeper;
    const teamUuid = config?.customSelectedTeamId || selectedTeamUuid;
    const dateRange = config?.customDateFromTo
      ? normalizeDateFromTo(config?.customDateFromTo)
      : undefined;

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestShotsBody: IPassesRequestBody = {
      ...requestBodyBase,
      dateFrom: dateRange?.from || requestBodyBase.dateFrom,
      dateTo: dateRange?.to || requestBodyBase.dateTo,
    };

    dispatch(
      getGamelog({
        competitionsUuids,
        goalkeeperUuid: selectedGoalkeeperOption?.value,
        body: requestShotsBody,
        teamUuid,
      }),
    );

    dispatch(
      getShots({
        competitionsUuids,
        body: requestShotsBody,
        teamUuid,
        playerUuid: selectedGoalkeeperOption?.value,
      }),
    );
  };

  return {
    fetchShots,
    fetchPasses,
    fetchHeatmap,
    fetchGamesAndToiForShotsOrPasses,
    fetchGoalkeepersShots,
  };
};
