import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  filteredGamesTablesDataSelector,
  selectGames,
  selectMainFilter,
  selectPlayers,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IGamesTablesPlayerTableDataWithLineup,
  IMetricWithDatasetMetric,
  IPlayerRecord,
  IPlayerWithTeamIdAndStatsAndLineup,
  ISelectOption,
  ISimilarPlayerNameRecord,
} from '../../../../types';
import {
  filteredPlayersOfGameTablesByTeamId,
  getMetricsWithValue,
  getPlayerShortName,
} from '../../../../utils';

const getTableData = (
  playersWithStatsFiltered: IPlayerWithTeamIdAndStatsAndLineup[],
  playerRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  similarPlayerNames: ISimilarPlayerNameRecord,
  selectedGames: ISelectOption[],
) => {
  const gameIds = selectedGames.map(game => game.value);
  const tableData = playersWithStatsFiltered
    .reduce<IGamesTablesPlayerTableDataWithLineup[]>((acc, playersWithTeamIdAndStats) => {
      const player = playerRecord[playersWithTeamIdAndStats.id];
      const stats = getMetricsWithValue(metrics, playersWithTeamIdAndStats.stats);

      if (player) {
        acc.push({
          playerId: player.id,
          teamId: playersWithTeamIdAndStats.teamId,
          gameIds,
          player: {
            id: player.id,
            displayName: getPlayerShortName(player, similarPlayerNames),
          },
          gp: playersWithTeamIdAndStats.stats.gp,
          toi: playersWithTeamIdAndStats.stats.toi,
          stats,
          lineup: playersWithTeamIdAndStats.line + playersWithTeamIdAndStats.position,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = (selectedFilterTeamId?: string) => {
  const { gamesTables } = useAppSelector(selectGames);
  const { playersById } = gamesTables;
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const filteredGamesTablesData = useAppSelector(state =>
    filteredGamesTablesDataSelector(state, playersById),
  );

  const getFilteredMetrics = useFilteredMetricsForTable();

  const playersWithTeamIdAndStatsFiltered = useMemo(
    () => {
      const filteredPlayers = filteredPlayersOfGameTablesByTeamId(
        filteredGamesTablesData.filter(player => player.id),
        selectedFilterTeamId,
      );
      const filteredPlayersWithLineups: IPlayerWithTeamIdAndStatsAndLineup[] = filteredPlayers.map(
        player => ({
          ...player,
          line: playersById[player.id].line,
          position: playersById[player.id].position,
        }),
      );
      return filteredPlayersWithLineups;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playersById, filteredGamesTablesData, selectedFilterTeamId],
  );

  const data: IGamesTablesPlayerTableDataWithLineup[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics) {
      return getTableData(
        playersWithTeamIdAndStatsFiltered,
        byId,
        filteredMetrics,
        similarPlayerNames,
        selectedGames,
      );
    }

    return [];
  }, [
    playersWithTeamIdAndStatsFiltered,
    byId,
    similarPlayerNames,
    getFilteredMetrics,
    selectedGames,
  ]);

  return data;
};
