import { useCallback } from 'react';

import { useAppSelector } from '../app/hooks';
import {
  selectDefinedDatasets,
  selectMetrics,
  selectTableFilter,
  selectUserDatasets,
} from '../features';
import { IDatasetMetric, IEntity, IMetricWithDatasetMetric } from '../types';
import { getMetricLabel, getMetricsFromDataTemplate } from '../utils';
import { useLocationPaths } from './useLocationPaths';

export const useFilteredMetricsForTable = () => {
  const { metrics } = useAppSelector(selectMetrics);
  const { definedDatasets, defaultTemplates } = useAppSelector(selectDefinedDatasets);
  const userDatasets = useAppSelector(selectUserDatasets);
  const { dataTemplate, customMetrics } = useAppSelector(selectTableFilter);

  const { activePage, activeTab } = useLocationPaths();

  const getFilteredMetrics = useCallback(
    (entity: IEntity = IEntity.players, customDataTemplateMetrics?: IDatasetMetric[]) => {
      if (definedDatasets && defaultTemplates && metrics) {
        const screenId = `${activePage}_${activeTab}`;

        const customDatasetMetrics = customMetrics?.map(metric => metric.additionalInfo);
        const dataTemplateMetrics =
          customDatasetMetrics ??
          getMetricsFromDataTemplate(
            definedDatasets,
            defaultTemplates,
            userDatasets.byId,
            dataTemplate,
            screenId,
            entity,
          );
        const templateMetrics = customDataTemplateMetrics ?? dataTemplateMetrics;

        const filteredMetrics = templateMetrics.map<IMetricWithDatasetMetric>(datasetMetric => {
          const metricRecord = metrics[entity].byId;
          const metric = datasetMetric.parameter
            ? metricRecord[datasetMetric.origin]
            : metricRecord[datasetMetric.code];

          const label = getMetricLabel(metric, datasetMetric);

          return {
            id: datasetMetric.code,
            label,
            metric,
            datasetMetric,
          };
        });

        return filteredMetrics;
      }

      return undefined;
    },
    [
      metrics,
      definedDatasets,
      defaultTemplates,
      userDatasets.byId,
      dataTemplate,
      customMetrics,
      activePage,
      activeTab,
    ],
  );

  return getFilteredMetrics;
};
