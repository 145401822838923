import { useAppDispatch } from '../../app/hooks';
import {
  setEnterExitLocation,
  setEntryExitType,
  setGameActionType,
  setNetZones,
  setPuckGainType,
  setShotDanger,
  setShotLocation,
  setShotType,
} from '../../features';
import { ITranslationKeys } from '../../i18n/types';
import {
  IDatasetMetric,
  IEntity,
  IMetricVideoLinkKeys,
  IMetricWithDatasetMetric,
  INetZoneUnion,
} from '../../types';
import { getMetricParametersInfo } from '../../utils';
import {
  useSetEntityFilter,
  useSetFaceoffsFilter,
  useSetPassesFilter,
  useSetShootoutsFilter,
  useSetShotmapFilter,
  useSetShotsFilter,
  useSetZonesFilter,
} from './filterSetters';

export const useSetFilterByMetricParams = () => {
  const dispatch = useAppDispatch();

  const { setEntityFilterSetter } = useSetEntityFilter();
  const { setShotsFilterSetter } = useSetShotsFilter();
  const { setPassesFilterSetter } = useSetPassesFilter();
  const { setZonesFilterSetter } = useSetZonesFilter();
  const { setFaceoffsFilterSetter } = useSetFaceoffsFilter();
  const { setShootoutsFilterSetter } = useSetShootoutsFilter();
  const { setShotmapFilterSetter } = useSetShotmapFilter();

  const setFilterByMetricParams = (datasetMetric: IDatasetMetric) => {
    const parametersInfo = getMetricParametersInfo(datasetMetric);

    parametersInfo?.forEach(({ id, paramGroupLabel, label }) => {
      if (paramGroupLabel === ITranslationKeys.shotLocation) {
        dispatch(setShotLocation({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.shotTypes) {
        dispatch(setShotType({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.shotsDanger) {
        dispatch(setShotDanger([{ value: id, label }]));
      }

      if (paramGroupLabel === ITranslationKeys.attackType) {
        dispatch(setGameActionType({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.netZones) {
        dispatch(setNetZones([id as INetZoneUnion]));
      }

      if (
        paramGroupLabel === ITranslationKeys.entryExitType ||
        paramGroupLabel === ITranslationKeys.exitType
      ) {
        dispatch(setEntryExitType([{ value: id, label }]));
      }

      if (paramGroupLabel === ITranslationKeys.enterExitLocation) {
        dispatch(setEnterExitLocation({ value: id, label }));
      }

      if (paramGroupLabel === ITranslationKeys.puckGainType) {
        dispatch(setPuckGainType({ value: id, label }));
      }
    });
  };

  const setFilterByMetricSubgroup = (
    stat: IMetricWithDatasetMetric,
    playerId?: string,
    entity?: IEntity,
  ) => {
    setEntityFilterSetter(stat, playerId === undefined, entity);
    if (stat.metric.metricVideoLinkId === IMetricVideoLinkKeys.shots) setShotsFilterSetter(stat);
    if (stat.metric.metricVideoLinkId === IMetricVideoLinkKeys.passes) setPassesFilterSetter(stat);
    if (stat.metric.metricVideoLinkId === IMetricVideoLinkKeys.entries)
      setZonesFilterSetter(stat, playerId);
    if (stat.metric.metricVideoLinkId === IMetricVideoLinkKeys.exits)
      setZonesFilterSetter(stat, playerId);
    if (stat.metric.metricVideoLinkId === IMetricVideoLinkKeys.faceoffs)
      setFaceoffsFilterSetter(stat);
    if (
      stat.metric.metricVideoLinkId === IMetricVideoLinkKeys.shootouts ||
      stat.metric.metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts
    )
      setShootoutsFilterSetter(stat);
    if (stat.metric.metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShots)
      setShotmapFilterSetter(stat);
  };

  return {
    setFilterByMetricParams,
    setFilterByMetricSubgroup,
  };
};
