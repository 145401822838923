import { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Button,
  Caption,
  GamesSelectList,
  Loading,
  Playground,
  PlaygroundZoneContent,
  ToggleSelect,
} from '../../../../components';
import {
  DISABLED_TABS_ON_OFF,
  dumpInOutResultOptions,
  entityOptions,
  shotDangerOptions,
  zoneExitCategoryOptions,
  zoneExitResultOptions,
  zoneExitTypeOptions,
  zoneFinishOptions,
  zoneSuccessOptions,
} from '../../../../constants';
import {
  filteredVideomapsFilterDataSelector,
  filteredZoneExitsFilterDataSelector,
  filteredZoneGameEntitiesSelector,
  selectGames,
  selectMainFilter,
  selectMetricParamsFilter,
  selectPlayers,
  selectTeams,
  selectVideomapsFilter,
  selectZones,
  selectZonesFilter,
  setSelectedGames,
  setZoneExitResult,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useExportAsImage,
  useFetchShotsOrPasses,
  useFetchZones,
  useHandleOnChange,
  useHandleOnSubmit,
  useLocationPaths,
  useSetUrlSearchParams,
  useVideoCenter,
  useZoneEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import { DownloadIcon } from '../../../../icons';
import {
  IFormation,
  ISelectOption,
  IUrlParam,
  IZoneEntityOptions,
  IZonesFetchDataMode,
} from '../../../../types';
import {
  changeUrlSearchParamsMultiselectInput,
  computePlaygroundTotalShots,
  computePlaygroundTotalXg,
  createClassNames,
  createFormationFromSelectedPlayers,
  filterDumpInOutGoals,
  getGamesOptions,
  getPlayerPlaygroundBoxProps,
  getZoneDangerBoxes,
  handleToggleMultiselectChange,
  isAtLeastOnePlayerOn,
  isPlayerSelected,
} from '../../../../utils';
import './ZoneExitsContent.styles.scss';

const classNames = createClassNames('videomaps-zone-exits-content');

export const ZoneExitsContent = () => {
  const teams = useAppSelector(selectTeams);
  const players = useAppSelector(selectPlayers);
  const { games } = useAppSelector(selectGames);
  const { selectedGames, selectedPlayerItems } = useAppSelector(selectMainFilter);
  const { entity } = useAppSelector(selectVideomapsFilter);
  const { dumpInOutResult, zoneCategory, zoneSuccess, zoneFinish, zoneExitResult } =
    useAppSelector(selectZonesFilter);
  const { shotDanger, entryExitType } = useAppSelector(selectMetricParamsFilter);
  const { filteredGamesTotalToi } = useAppSelector(filteredVideomapsFilterDataSelector);
  const { areZonesLoading } = useAppSelector(selectZones);
  const zoneExits = useAppSelector(filteredZoneExitsFilterDataSelector);
  const zoneEntities = useAppSelector(state => filteredZoneGameEntitiesSelector(state, zoneExits));
  const dispatch = useAppDispatch();

  const { activeTab } = useLocationPaths();
  const [, setSearchParams] = useSearchParams();

  const { setUrlSearchParams } = useSetUrlSearchParams();
  const {
    onChangeEntity,
    onChangeZoneCategory,
    onChangeShotDanger,
    onChangeZoneSuccess,
    onChangeZoneFinish,
    onChangeEntryExitType,
    onChangeDumpInOutResult,
  } = useHandleOnChange();
  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();
  const { fetchGamesAndToiForShotsOrPasses } = useFetchShotsOrPasses();
  const { fetchZoneExits } = useFetchZones();
  const { playSelectedGamesVideos, playGameVideos, playVideos } = useVideoCenter(
    zoneEntities,
    ITranslationKeys.zoneExits,
  );

  const [showPlaygroundBoxes, setShowPlaygroundBoxes] = useState(false);

  /* Export dat */
  const exportRef = useRef<HTMLDivElement>(null);
  const { isExporting, exportImage } = useExportAsImage(exportRef, `hokejLogic-karta-hráče`, 'png');

  const isInitialMountRef = useRef(true);

  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchZoneExits(values, {
      mode:
        zoneCategory.value === 'dumpInOut'
          ? IZonesFetchDataMode.dumpInOut
          : IZonesFetchDataMode.entry,
    });
    fetchGamesAndToiForShotsOrPasses(values);
  }, isInitialMountRef);

  const handleChangeGame = (options: ISelectOption[]) => {
    dispatch(setSelectedGames(options));
    changeUrlSearchParamsMultiselectInput(options, 'selectedGames', setSearchParams);
  };

  const handleChangeZoneExitResult = (option: ISelectOption) => {
    dispatch(setZoneExitResult(option));
    setUrlSearchParams(IUrlParam.zoneExitResult, option.value);
  };

  const formation: IFormation[] = useMemo(() => {
    const filteredSelectedPlayers = Object.values(selectedPlayerItems).filter(
      item => item.selectedPlayer,
    );
    return createFormationFromSelectedPlayers(filteredSelectedPlayers);
  }, [selectedPlayerItems]);

  useZoneEffects(handleOnSubmit, isInitialMountRef, formation, games.isLoading);

  const gamesOptions = useMemo(() => getGamesOptions(games.byId), [games.byId]);

  if (games.isLoading && !games.isAllGamesLoaded) {
    return <Loading />;
  }

  const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, 1);
  if (errorInfoBox) {
    return errorInfoBox;
  }

  const selectedEntity: IZoneEntityOptions = entity.value === 'player' ? 'formation' : 'team';
  const disabledOnOff = DISABLED_TABS_ON_OFF.includes(activeTab);
  const isSomePlayerOn = isAtLeastOnePlayerOn(selectedPlayerItems, disabledOnOff);
  const isValidFormation = formation.length > 0 && isSomePlayerOn;
  const filteredPlayers = Object.values(selectedPlayerItems).filter(player =>
    player.selectedPlayer?.value
      ? isPlayerSelected(selectedPlayerItems, player.selectedPlayer.value, disabledOnOff)
      : false,
  );
  const { leftBox, centerBox, rightBox, summaryBox } = getPlayerPlaygroundBoxProps(
    zoneExits,
    formation,
    selectedEntity,
    selectedPlayerItems,
    filteredPlayers,
    entity.value,
    players.byId,
  );

  return (
    <div ref={exportRef} className={classNames()} data-testid='videomaps-page__passes-content'>
      <div className={classNames('main')}>
        <GamesSelectList
          selected={selectedGames}
          options={gamesOptions}
          gameRecord={games.byId}
          teamRecord={teams.byId}
          shouldCheckFullBody={false}
          gameEntities={zoneEntities}
          selectedPlayerItems={selectedPlayerItems}
          onChange={newValue => handleChangeGame(newValue)}
          onItemContentActionClick={gameId => playGameVideos(gameId)}
          onPlaySelectedGamesVideos={playSelectedGamesVideos}
          hasSelectAllOption
        />
        <div className={classNames('main__playground-wrapper')}>
          <Playground
            toi={filteredGamesTotalToi}
            c={computePlaygroundTotalShots(zoneExits, formation, entity.value)}
            xG={computePlaygroundTotalXg(zoneExits, formation, entity.value)}
            g={filterDumpInOutGoals(zoneExits, formation, entity.value)}
            boxes={getZoneDangerBoxes(zoneExits, formation, entity.value)}
            boxListHeading={ITranslationKeys.exitDanger}
            isReversed
            renderContent={() => {
              if (areZonesLoading) {
                return <Loading />;
              }

              return (
                <PlaygroundZoneContent
                  leftBox={leftBox}
                  centerBox={centerBox}
                  rightBox={rightBox}
                  summaryBox={summaryBox}
                  showBoxes={showPlaygroundBoxes}
                  onClick={playVideos}
                />
              );
            }}
            onPrintIconClick={exportImage}
            onBoxesAppearanceChange={setShowPlaygroundBoxes}
          />
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption label={ITranslationKeys.whoExitsFromDefensiveZone} />
            <ToggleSelect
              selected={entity}
              options={entityOptions}
              onChange={newOption => onChangeEntity(newOption)}
              disabledOptions={!isValidFormation ? [entityOptions[0]] : undefined}
              twoColumns
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.exitCategory} />
            <ToggleSelect
              selected={zoneCategory}
              options={zoneExitCategoryOptions}
              onChange={newOption => onChangeZoneCategory(newOption)}
              hasOnlyOneColumn
            />
          </div>
          {zoneCategory.value !== 'dumpInOut' ? (
            <>
              <div>
                <Caption label={ITranslationKeys.bySuccess} />
                <ToggleSelect
                  selected={zoneSuccess}
                  options={zoneSuccessOptions}
                  onChange={newOption => onChangeZoneSuccess(newOption)}
                  twoColumns
                />
              </div>
              <div>
                <Caption label={ITranslationKeys.exitType} />
                <ToggleSelect
                  selected={entryExitType}
                  options={zoneExitTypeOptions}
                  hasOnlyOneColumn
                  onChange={option =>
                    onChangeEntryExitType(
                      handleToggleMultiselectChange(option, zoneExitTypeOptions, entryExitType),
                    )
                  }
                />
              </div>
            </>
          ) : (
            <div>
              <Caption label={ITranslationKeys.dumpoutResult} />
              <ToggleSelect
                selected={dumpInOutResult}
                options={dumpInOutResultOptions}
                onChange={newOption => onChangeDumpInOutResult(newOption)}
                hasOnlyOneColumn
              />
            </div>
          )}
        </div>
        <div className={classNames('main__form-box')}>
          <div>
            <Caption
              label={
                zoneCategory.value !== 'dumpInOut'
                  ? ITranslationKeys.exitDanger
                  : ITranslationKeys.dumpoutDanger
              }
            />
            <ToggleSelect
              options={shotDangerOptions}
              selected={shotDanger}
              hasOnlyOneColumn
              onChange={option =>
                onChangeShotDanger(
                  handleToggleMultiselectChange(option, shotDangerOptions, shotDanger),
                )
              }
            />
          </div>
          <div>
            <Caption label={ITranslationKeys.finish} />
            <ToggleSelect
              selected={zoneFinish}
              options={zoneFinishOptions}
              onChange={newOption => onChangeZoneFinish(newOption)}
              hasOnlyOneColumn
            />
          </div>
          {zoneCategory.value !== 'dumpInOut' && (
            <div>
              <Caption label={ITranslationKeys.exitZoneResult} />
              <ToggleSelect
                selected={zoneExitResult}
                options={zoneExitResultOptions}
                onChange={newOption => handleChangeZoneExitResult(newOption)}
                hasOnlyOneColumn
              />
            </div>
          )}
          <div className={classNames('main__form-box__save-button')}>
            <Button
              label={ITranslationKeys.saveMap}
              iconComponent={<DownloadIcon />}
              onClick={exportImage}
              disabled={isExporting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
