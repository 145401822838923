import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  Caption,
  CommonTable,
  DataSettingsModal,
  Loading,
  SelectInput,
  TableToolbar,
} from '../../../../components';
import { defaultTemplate, gamePartDefaultOption } from '../../../../constants';
import {
  filteredMainFilterDataSelector,
  getGamesTablesGoalkeepers,
  resetDataSettingsFilterState,
  selectDataSettingsFilter,
  selectGames,
  selectMainFilter,
  selectMetrics,
  selectTableFilter,
  selectTeams,
  selectedGameSelector,
  setCustomMetrics,
  setDataTemplate,
  setGamePart,
} from '../../../../features';
import {
  useContentErrorInfoBox,
  useHandleOnSubmit,
  useNormalizeMetrics,
  usePrepareBaseRequestBody,
  useRefetchContent,
  useTableCenterPartWidth,
  useTableCommonEffects,
} from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IEntity,
  IGamesTablesPlayerTableData,
  IGamesTablesStatsRequestBody,
  IMainFilterForm,
  ISelectOption,
} from '../../../../types';
import { createClassNames, createTeamSortingOptions, getTimeFromGamePart } from '../../../../utils';
import './GameGoalkeepersContent.styles.scss';
import { useColumnsConfig } from './useColumnsConfig';
import { useDataForTable } from './useDataForTable';

export const useFetchGameGoalkeepersStats = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { gamePart } = useAppSelector(selectTableFilter);
  const { filteredParts, selectedGame } = useAppSelector(filteredMainFilterDataSelector);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchGameGoalkeepers = (data: Partial<IMainFilterForm>) => {
    if (!selectedGame) return;

    const competitionsUuids = filteredParts.map(part => part.id);
    const timeFromTo = getTimeFromGamePart(gamePart);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IGamesTablesStatsRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(IEntity.goalkeepers),
      timeFrom: timeFromTo?.timeFrom,
      timeTo: timeFromTo?.timeTo,
    };
    dispatch(
      getGamesTablesGoalkeepers({
        competitionsUuids,
        matchUuid: selectedGame.id,
        teamUuid: selectedGame.homeTeamId,
        body: requestBody,
      }),
    );
  };

  return fetchGameGoalkeepers;
};

const classNames = createClassNames('game-goalkeepers-content');

export const GameGoalkeepersContent = () => {
  const selectedGame = useAppSelector(selectedGameSelector);
  const { countOfPlayers } = useAppSelector(selectMainFilter);
  const { gamePart, dataTemplate } = useAppSelector(selectTableFilter);
  const { gamesTables, games } = useAppSelector(selectGames);
  const { byId } = useAppSelector(selectTeams);
  const { open } = useAppSelector(selectDataSettingsFilter);
  const { metrics } = useAppSelector(selectMetrics);
  const { goalkeepersLoading } = gamesTables;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const filteredTeamOptions: ISelectOption<any>[] = createTeamSortingOptions(byId, selectedGame);
  const [selectedFilterTeamId, setSelectedFilterTeamId] = useState<
    ISelectOption<any> | undefined
  >();

  const { centerTablePartRef, centerPartWidth } = useTableCenterPartWidth();

  const data = useDataForTable(selectedFilterTeamId?.value);
  const { columns, columnPinning, initialSorting } = useColumnsConfig(centerPartWidth);

  const shouldDisplayErrorInfoBox = useContentErrorInfoBox();

  const isInitialMountRef = useRef(true);

  const fetchGameGoalkeepersStats = useFetchGameGoalkeepersStats();
  const handleOnSubmit = useHandleOnSubmit(values => {
    fetchGameGoalkeepersStats(values);
  }, isInitialMountRef);

  useTableCommonEffects({
    handleOnSubmit,
    isInitialMountRef,
  });

  useRefetchContent({ handleOnSubmit, isLoading: goalkeepersLoading, disableInitialRefetch: true });

  useEffect(() => {
    setSelectedFilterTeamId(filteredTeamOptions[0]);
    dispatch(setGamePart(gamePartDefaultOption));

    if (selectedGame) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGame]);

  useEffect(() => {
    handleOnSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countOfPlayers, gamePart, dataTemplate]);

  useEffect(() => {
    return () => {
      dispatch(setDataTemplate(defaultTemplate));
      dispatch(setCustomMetrics(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (goalkeepersLoading || !games.isAllGamesLoaded) {
    return <Loading />;
  }

  if (!selectedGame) {
    return <div className='content-info-box'>{t(ITranslationKeys.pleaseSelectGame)}</div>;
  }

  const renderTableOrInfoBox = () => {
    const errorInfoBox = shouldDisplayErrorInfoBox(isInitialMountRef, data.length);
    if (errorInfoBox) return errorInfoBox;

    return (
      <CommonTable<IGamesTablesPlayerTableData>
        centerPartRef={centerTablePartRef}
        {...{ data, columns, columnPinning, initialSorting }}
      />
    );
  };

  return (
    <div className={classNames()} data-testid='games-page__tabs-content'>
      <TableToolbar
        dataTemplateEntity={IEntity.goalkeepers}
        centerTablePartRef={centerTablePartRef}
        enableCountOfPlayers
        enableGamePartField
        disablePositionSelection
        extraElement={
          <div>
            <Caption label={ITranslationKeys.filterTeam} />
            <SelectInput
              variant='filter'
              options={filteredTeamOptions}
              selected={selectedFilterTeamId}
              onChange={setSelectedFilterTeamId}
            />
          </div>
        }
        scrollSizePx={130}
      />
      {renderTableOrInfoBox()}
      {open && metrics && (
        <DataSettingsModal
          metrics={metrics.goalkeepers}
          open={open}
          onClose={() => dispatch(resetDataSettingsFilterState())}
          individualName={ITranslationKeys.playerData}
          onIceName={ITranslationKeys.teamsData}
          entity={IEntity.goalkeepers}
        />
      )}
    </div>
  );
};
