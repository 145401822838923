import { useAppDispatch } from '../../../../app/hooks';
import { getDashboardGamePreview } from '../../../../features';
import { IGame } from '../../../../types';

export const useFetchGamePreviews = () => {
  const dispatch = useAppDispatch();

  const fetchGamePreview = (competitionIds: string[], games: IGame[], teamId: string) =>
    games.reduce<Promise<any>[]>((acc, game) => {
      acc.push(
        dispatch(
          getDashboardGamePreview({
            competitionsUuids: competitionIds.join(','),
            matchUuid: game.id,
            teamUuid: teamId,
            gameInfo: game,
          }),
        ),
      );
      return acc;
    }, []);

  return fetchGamePreview;
};
