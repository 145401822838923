import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ITranslationKeys } from '../../../i18n/types';
import { ITcmsModalButtonConfig } from '../../../types';
import { createClassNames, transformObjectKeysToKebabCase } from '../../../utils';
import { Modal, ModalButton } from '../../modalComponents';
import './TcmsModal.styles.scss';

export interface ITcmsModalProps {
  /** Content of the modal. */
  children?: ReactNode;
  /** Configuration of the confirm button. */
  confirmButtonConfig?: ITcmsModalButtonConfig;
  /** Configuration of the close button. */
  closeButtonConfig?: ITcmsModalButtonConfig;
  /** Icon of the modal. */
  icon?: ReactNode;
  /** Whether the modal is open or not. */
  open: boolean;
  /** Whether the modal should have overflow visible. */
  overflowVisible?: boolean;
  /** Title of the modal. */
  title?: ITranslationKeys;
}

const classNames = createClassNames('tcms-modal');

export const TcmsModal: FC<ITcmsModalProps> = ({
  open,
  children,
  title,
  icon,
  overflowVisible,
  closeButtonConfig,
  confirmButtonConfig,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames({
        ...transformObjectKeysToKebabCase({
          isEmpty: !children,
        }),
      })}
    >
      <Modal
        size='small'
        modalButtons={
          <>
            {closeButtonConfig && (
              <ModalButton
                label={closeButtonConfig.label}
                fontWeight='normal'
                onClick={closeButtonConfig.onClick}
                disabled={closeButtonConfig.disabled}
              />
            )}
            {confirmButtonConfig && (
              <ModalButton
                label={confirmButtonConfig.label}
                fontWeight='bold'
                onClick={confirmButtonConfig.onClick}
                disabled={confirmButtonConfig.disabled}
              />
            )}
          </>
        }
        {...{ open, overflowVisible }}
      >
        <div className={classNames('header')}>
          {icon && <div className={classNames('header__icon')}>{icon}</div>}
          {title && <span className={classNames('header__title')}>{t(title)}</span>}
        </div>
        {children && <div className={classNames('content')}>{children}</div>}
      </Modal>
    </div>
  );
};
