import {
  ITranslationKeys,
  ITranslationKeysAccountManagement,
  ITranslationKeysAllWordNominativ,
  ITranslationKeysBase,
  ITranslationKeysBugReport,
  ITranslationKeysCSVExports,
  ITranslationKeysCms,
  ITranslationKeysCountOfPlayers,
  ITranslationKeysDashboard,
  ITranslationKeysEcContent,
  ITranslationKeysErrors,
  ITranslationKeysFilters,
  ITranslationKeysGameState,
  ITranslationKeysGames,
  ITranslationKeysGoalkeeperInfo,
  ITranslationKeysHomeOrAway,
  ITranslationKeysMetrics,
  ITranslationKeysModal,
  ITranslationKeysNavigation,
  ITranslationKeysNetZones,
  ITranslationKeysPagesWithCards,
  ITranslationKeysPeriods,
  ITranslationKeysPlayerInfo,
  ITranslationKeysSeasonParts,
  ITranslationKeysShootout,
  ITranslationKeysTables,
  ITranslationKeysTopPlayers,
  ITranslationKeysTracking,
  ITranslationKeysVideoCenter,
  ITranslationKeysVideomaps,
  ITranslationKeysWidgets,
  ITranslationKeysWidgetsDescriptions,
  ITypeForTranslation,
} from './types';

const translationMetrics: Pick<ITypeForTranslation, ITranslationKeysMetrics> = {
  [ITranslationKeys.eventParameters]: 'Event parameters',
  [ITranslationKeys.playerData]: 'Player data',
  [ITranslationKeys.playerOnIceData]: 'Player on-ice data',
  [ITranslationKeys.relativeToTeam]: 'Relative to team',
  [ITranslationKeys.goaliesData]: 'Goalies data',
  [ITranslationKeys.teamsData]: 'Teams data',
  [ITranslationKeys.dataGroups]: 'Data groups',
};

const translationBase: Pick<ITypeForTranslation, ITranslationKeysBase> = {
  [ITranslationKeys.password]: 'Password',
  [ITranslationKeys.login]: 'Login',
  [ITranslationKeys.userSuccessfullyLoggedIn]: 'User has successfully logged in.',
  [ITranslationKeys.fillAllInputs]: 'Please fill in all input fields.',
  [ITranslationKeys.eSports]: 'eSports.com (EN)',
  [ITranslationKeys.eSportsUrl]: 'https://www.esportsmedia.com/',
  [ITranslationKeys.bpaSportMarketing]: 'BPA sport marketing a.s.',
  [ITranslationKeys.bpaUrl]: 'https://www.bpa.cz',
  [ITranslationKeys.goToApp]: 'Go to application',
  [ITranslationKeys.loadingData]: 'Loading data',
  [ITranslationKeys.loadData]: 'Load data',
  [ITranslationKeys.sessionExpired]: 'The login session has expired. Log in again.',
  [ITranslationKeys.selectAll]: 'Select all',
  [ITranslationKeys.removeAll]: 'Remove all',
  [ITranslationKeys.defaultSelectPlaceholder]: 'Select option',
  [ITranslationKeys.allTeams]: 'All teams',
  [ITranslationKeys.player]: 'Player',
  [ITranslationKeys.goalkeeper]: 'Goalkeeper',
  [ITranslationKeys.team]: 'Team',
  [ITranslationKeys.teamComparison]: 'Team comparison',
  [ITranslationKeys.selectedTeam]: 'Chosen team',
  [ITranslationKeys.opponent]: 'Opponent',
  [ITranslationKeys.confirm]: 'Confirm',
  [ITranslationKeys.delete]: 'Delete',
  [ITranslationKeys.from]: 'From',
  [ITranslationKeys.to]: 'To',
  [ITranslationKeys.insertTime]: 'Insert time',
  [ITranslationKeys.select]: 'Select',
  [ITranslationKeys.date]: 'Date',
  [ITranslationKeys.match]: 'Match',
  [ITranslationKeys.defaultTemplate]: 'Default template',
  [ITranslationKeys.defaultTemplates]: 'Default templates',
  [ITranslationKeys.exportData]: 'Export data',
  [ITranslationKeys.deleteSelected]: 'Delete selected',
  [ITranslationKeys.close]: 'Close',
  [ITranslationKeys.with]: 'with',
  [ITranslationKeys.without]: 'without',
  [ITranslationKeys.selectedPlayer]: 'Selected player',
  [ITranslationKeys.withTeammate]: 'With teammate',
  [ITranslationKeys.withoutTeammate]: 'Without teammate',
  [ITranslationKeys.teammateWithout]: 'Teammate without',
  [ITranslationKeys.formationAll]: '5/5 - all',
  [ITranslationKeys.formationForwards]: '5/5 - forwards',
  [ITranslationKeys.formationDefensemen]: '5/5 - defensemen',
  [ITranslationKeys.opponents]: 'Opponents',
  [ITranslationKeys.playersInChart]: 'Players in the chart',
  [ITranslationKeys.stickGrip]: 'Stick grip',
  [ITranslationKeys.positiveDeviation]: 'Positive deviation',
  [ITranslationKeys.overtime]: 'Overtime',
  [ITranslationKeys.gameCourseExpectedGoals]: 'Match course: expected goals',
  [ITranslationKeys.gamePeriod]: 'Game period',
  [ITranslationKeys.average]: 'Average',
  [ITranslationKeys.inLeague]: 'In league',
  [ITranslationKeys.inSeason]: 'In season',
  [ITranslationKeys.inMatch]: 'In match',
  [ITranslationKeys.print]: 'Print',
  [ITranslationKeys.name]: 'Name',
  [ITranslationKeys.surname]: 'Surname',
  [ITranslationKeys.role]: 'Role',
  [ITranslationKeys.requiredArray]: 'Required array',
  [ITranslationKeys.cancel]: 'Cancel',
  [ITranslationKeys.datasetSavedSuccessfully]: 'Data template successfully saved.',
  [ITranslationKeys.datasetSaveFailed]: 'Failed to save template. Please try again.',
  [ITranslationKeys.datasetChangedSuccessfully]: 'Data template successfully changed.',
  [ITranslationKeys.datasetDeletedSuccessfully]: 'Data template successfully deleted.',
  [ITranslationKeys.datasetDeleteFailed]: 'Failed to delete template. Please try again.',
  [ITranslationKeys.saveDataset]: 'Save data-template',
  [ITranslationKeys.editDataset]: 'Edit data-template',
  [ITranslationKeys.deleteDataset]: 'Delete data-template',
  [ITranslationKeys.newDatasetName]: 'New name for dataset',
  [ITranslationKeys.rename]: 'Rename',
  [ITranslationKeys.nameYourDataset]: 'Name the group of selected data please',
  [ITranslationKeys.saveNewDatasetMetrics]: 'Save the group of selected data to this template',
  [ITranslationKeys.selectDatasetToEdit]: 'Select the template you want to edit please',
  [ITranslationKeys.defensiveZone]: 'Defensive zone',
  [ITranslationKeys.neutralZone]: 'Neutral zone',
  [ITranslationKeys.offensiveZone]: 'Offensive zone',
  [ITranslationKeys.pass]: 'Pass',
  [ITranslationKeys.showEvents]: 'Events Filter',
  [ITranslationKeys.displayInGraph]: 'Diplay in graph',
  [ITranslationKeys.movingAverage]: 'Moving average',
  [ITranslationKeys.pointer]: 'Pointer',
  [ITranslationKeys.save]: 'Save',
  [ITranslationKeys.combinations]: 'Combinations',
  [ITranslationKeys.others]: 'Others',
  [ITranslationKeys.version]: 'Version',
  [ITranslationKeys.selectSeason]: 'Select season',
  [ITranslationKeys.tab]: 'Tab',
  [ITranslationKeys.turnOff]: 'Turn off',
  [ITranslationKeys.turnOn]: 'Turn on',
  [ITranslationKeys.teammates]: 'Teammates',
  [ITranslationKeys.yes]: 'Yes',
  [ITranslationKeys.no]: 'No',
  [ITranslationKeys.id]: 'ID',
  [ITranslationKeys.fromPass]: 'From pass',
  [ITranslationKeys.withoutPass]: 'Without pass',
  [ITranslationKeys.enterText]: 'Enter text',
  [ITranslationKeys.newsAtPage]: 'News at page',
  [ITranslationKeys.dataSelection]: 'Data selection',
  [ITranslationKeys.changeLanguage]: 'Change language',
  [ITranslationKeys.goalsSavedAboveExpected]: 'Goals saved above expected',
  [ITranslationKeys.penaltyKill]: 'Penalty kill',
};

const translationDashboard: Pick<ITypeForTranslation, ITranslationKeysDashboard> = {
  [ITranslationKeys.nextOpponent]: 'Next opponent',
  [ITranslationKeys.form]: 'Form',
  [ITranslationKeys.tightGame]: 'Tight game',
  [ITranslationKeys.expectedWin]: 'Expected win',
  [ITranslationKeys.unexpectedWin]: 'Unexpected win',
  [ITranslationKeys.expectedLoss]: 'Expected loss',
  [ITranslationKeys.unexpectedLoss]: 'Unexpected loss',
  [ITranslationKeys.offense]: 'Offense',
  [ITranslationKeys.defense]: 'Defense',
  [ITranslationKeys.standingsInTheLast5Games]: 'Standings in the last 5 games',
  [ITranslationKeys.fiveVFiveGoalsFor]: '5v5 Goals for',
  [ITranslationKeys.fiveVFiveGoalsAgainst]: '5v5 Goals against',
  [ITranslationKeys.fiveVFiveOffense]: '5/5 - Offense',
  [ITranslationKeys.fiveVFiveDefense]: '5/5 - Defense',
  [ITranslationKeys.pPGoalsFor]: 'PP Goals for',
  [ITranslationKeys.pKGoalsAgainst]: 'SH Goals against',
  [ITranslationKeys.specialTeams]: 'Special teams',
  [ITranslationKeys.minigame]: 'Minigame',
  [ITranslationKeys.lesserChances]: 'Lesser chances',
  [ITranslationKeys.evenChances]: 'Even chances',
  [ITranslationKeys.greaterChances]: 'Greater chances',
  [ITranslationKeys.stolenGame]: 'Stolen game',
  [ITranslationKeys.poorGame]: 'Poor game',
  [ITranslationKeys.unreliableGame]: 'Unreliable game',
  [ITranslationKeys.shutout]: 'Shutout',
  [ITranslationKeys.aboveAveragePerformance]: 'Above-average performance',
  [ITranslationKeys.belowAveragePerformance]: 'Below-average performance',
  [ITranslationKeys.averagePerformance]: 'Average performance',
  [ITranslationKeys.winShortcut]: 'W',
  [ITranslationKeys.lossShortcut]: 'L',
  [ITranslationKeys.competitionTable]: 'Competition table',
  [ITranslationKeys.rankShortcut]: 'Ord.',
};

const translationSeasonParts: Pick<ITypeForTranslation, ITranslationKeysSeasonParts> = {
  [ITranslationKeys.base]: 'Regular season',
  [ITranslationKeys.playoff]: 'Play-off',
  [ITranslationKeys.playout]: 'Play-out',
  [ITranslationKeys.relegation]: 'Relegation',
  [ITranslationKeys.preliminary]: 'Preliminary round',
};

const translationCountOfPlayers: Pick<ITypeForTranslation, ITranslationKeysCountOfPlayers> = {
  [ITranslationKeys.evenStrength]: 'Even strength',
  [ITranslationKeys.powerPlaysShort]: 'Power plays',
  [ITranslationKeys.allSituations]: 'All situations',
};

const translationHomeOrAway: Pick<ITypeForTranslation, ITranslationKeysHomeOrAway> = {
  [ITranslationKeys.homeAndAway]: 'Home + away',
  [ITranslationKeys.home]: 'Home',
  [ITranslationKeys.away]: 'Away',
};

const translationCSVExports: Pick<ITypeForTranslation, ITranslationKeysCSVExports> = {
  [ITranslationKeys.awayOnes]: 'Away',
  [ITranslationKeys.homeOnes]: 'Home',
  [ITranslationKeys.result]: 'Result',
  [ITranslationKeys.playerName]: 'Player name',
  [ITranslationKeys.goalkeeperName]: 'Goalkeeper name',
  [ITranslationKeys.position]: 'Position',
  [ITranslationKeys.user]: 'User',
  [ITranslationKeys.hlTotalLoginCount]: 'Total login count + 30 days',
  [ITranslationKeys.value]: 'Value',
  [ITranslationKeys.openLinks]: 'Open links',
  [ITranslationKeys.totalLinks]: 'Total links',
  [ITranslationKeys.teamName]: 'Team name',
  [ITranslationKeys.teamShortName]: 'Team short name',
  [ITranslationKeys.hlLastLogin]: 'HL Last login',
  [ITranslationKeys.ecTotalLoginCount]: 'Total login count',
  [ITranslationKeys.ecLastLogin]: 'EC Last login',
  [ITranslationKeys.goalkeepersGamelog]: 'goalkeepers-gamelog',
  [ITranslationKeys.playersGamelog]: 'players-gamelog',
  [ITranslationKeys.teamsGamelog]: 'teams-gamelog',
  [ITranslationKeys.formationTable]: 'formation-table',
  [ITranslationKeys.pairsTable]: 'pairs-table',
  [ITranslationKeys.playerCombinationsTable]: 'player-combinations-table',
  [ITranslationKeys.playersTable]: 'players-table',
  [ITranslationKeys.goalkeepersTable]: 'goalkeepers-table',
  [ITranslationKeys.appUsers]: 'app-users',
  [ITranslationKeys.teamsTable]: 'teams-table',
};

const translationGameState: Pick<ITypeForTranslation, ITranslationKeysGameState> = {
  [ITranslationKeys.allGameStates]: 'All states',
  [ITranslationKeys.leading]: 'Leading',
  [ITranslationKeys.losing]: 'Losing',
  [ITranslationKeys.tied]: 'Tied',
};

const translationErrors: Pick<ITypeForTranslation, ITranslationKeysErrors> = {
  [ITranslationKeys.defaultErrorMessage]:
    'An error occurred while loading data. Please contact us at podpora@esports.cz.',
  [ITranslationKeys.networkErrorMessage]: 'Network Error.',
  [ITranslationKeys.invalidCredentialsErrorMessage]: 'Invalid credentials.',
  [ITranslationKeys.defaultLoginErrorMessage]: 'Error! Login failed.',
  [ITranslationKeys.noAccessRightsErrorMessage]:
    'You do not have the appropriate permissions to log in.',
  [ITranslationKeys.cantChangePasswordErrorMessage]: 'Password change failed. Please, try again.',
  [ITranslationKeys.cantChangePlayerEmailErrorMessage]:
    "User's email change failed. Please, try again.",
  [ITranslationKeys.cantDeletePlayerEmailErrorMessage]:
    "User's email deletion failed. Please, try again.",
  [ITranslationKeys.cantChangePlayerLanguageErrorMessage]:
    "User's language change failed. Please, try again.",
  [ITranslationKeys.saveDataFailedMessage]:
    'An error occurred while saving data. Please, try again.',
  [ITranslationKeys.areYouSureDeleteUser]:
    'Are you sure you want to delete this user? This operation is irreversible.',
  [ITranslationKeys.areYouSureDeleteItem]:
    'Are you sure you want to delete this item? This operation is irreversible.',
  [ITranslationKeys.continueWithoutSave]:
    'Data was not saved. Do you want to continue without saving? Continuing will delete the changes made.',
  [ITranslationKeys.teamOrMediaNotExistsErrorMessage]:
    'Team or media does not exist. Please, try again.',
};

const translationNavigation: Pick<ITypeForTranslation, ITranslationKeysNavigation> = {
  [ITranslationKeys.dashboard]: 'Dashboard',
  [ITranslationKeys.players]: 'Players',
  [ITranslationKeys.formations]: 'Formations',
  [ITranslationKeys.videomaps]: 'Videomaps',
  [ITranslationKeys.goalkeepers]: 'Goalies',
  [ITranslationKeys.games]: 'Games',
  [ITranslationKeys.teams]: 'Teams',
  [ITranslationKeys.accountManagement]: 'Account management',
  [ITranslationKeys.logout]: 'Log out',
  [ITranslationKeys.help]: 'Help',
};

const translationModal: Pick<ITypeForTranslation, ITranslationKeysModal> = {
  [ITranslationKeys.nextTimeDontShow]: "Don't show next time",
  [ITranslationKeys.understand]: 'I understand',
  [ITranslationKeys.closeHelp]: 'Close help',
  [ITranslationKeys.chosenTips]: 'Chosen tips',
};

const translationWidgets: Pick<ITypeForTranslation, ITranslationKeysWidgets> = {
  [ITranslationKeys.tabs]: 'Tabs',
  [ITranslationKeys.gamelog]: 'Gamelog',
  [ITranslationKeys.trend]: 'Trend',
  [ITranslationKeys.playerCard]: 'Player card',
  [ITranslationKeys.comparePlayers]: 'Players compare',
  [ITranslationKeys.similarityPlayers]: 'Find similar player',
  [ITranslationKeys.pairs]: 'Teammates in pairs',
  [ITranslationKeys.playerCombinations]: 'Player combinations',
  [ITranslationKeys.shots]: 'Shots',
  [ITranslationKeys.heatmap]: 'Heatmap',
  [ITranslationKeys.passes]: 'Passes',
  [ITranslationKeys.faceoffs]: 'Faceoffs',
  [ITranslationKeys.zoneEntries]: 'Zone entries',
  [ITranslationKeys.zoneExits]: 'Zone exits',
  [ITranslationKeys.shootouts]: 'Shootouts',
  [ITranslationKeys.netZones]: 'Net zones',
  [ITranslationKeys.shotmap]: 'Shot map',
  [ITranslationKeys.goalkeeperCard]: 'Goalkeeper card',
  [ITranslationKeys.compareGoalkeepers]: 'Goalkeepers compare',
  [ITranslationKeys.graphicOverview]: 'Graphic overview',
  [ITranslationKeys.report]: 'Report',
  [ITranslationKeys.headToHead]: 'Head-to-head',
};

const translationWidgetsDescriptions: Pick<
  ITypeForTranslation,
  ITranslationKeysWidgetsDescriptions
> = {
  [ITranslationKeys.tabsPlayersDescription]: '',
  [ITranslationKeys.tabsGoalkeepersDescription]: '',
  [ITranslationKeys.tabsTeamsDescription]: '',
  [ITranslationKeys.gamelogPlayersDescription]: '',
  [ITranslationKeys.gamelogGoalkeepersDescription]: '',
  [ITranslationKeys.gamelogTeamsDescription]: '',
  [ITranslationKeys.trendPlayersDescription]: '',
  [ITranslationKeys.trendGoalkeepersDescription]: '',
  [ITranslationKeys.trendTeamsDescription]: '',
  [ITranslationKeys.playerCardDescription]: '',
  [ITranslationKeys.comparePlayersDescription]: '',
  [ITranslationKeys.similarityPlayersDescription]: '',
  [ITranslationKeys.pairsDescription]: '',
  [ITranslationKeys.playerCombinationsDescription]: '',
  [ITranslationKeys.shotsDescription]: '',
  [ITranslationKeys.heatmapDescription]: '',
  [ITranslationKeys.passesDescription]: '',
  [ITranslationKeys.faceoffsDescription]: '',
  [ITranslationKeys.zoneEntriesDescription]: '',
  [ITranslationKeys.zoneExitsDescription]: '',
  [ITranslationKeys.shootoutsPlayersDescription]: '',
  [ITranslationKeys.shootoutsGoalkeepersDescription]: '',
  [ITranslationKeys.netZonesDescription]: '',
  [ITranslationKeys.shotmapDescription]: '',
  [ITranslationKeys.goalkeeperCardDescription]: '',
  [ITranslationKeys.compareGoalkeepersDescription]: '',
  [ITranslationKeys.graphicOverviewDescription]: '',
  [ITranslationKeys.reportDescription]: '',
  [ITranslationKeys.headToHeadDescription]: '',
  [ITranslationKeys.playersDescription]: '',
  [ITranslationKeys.formationsDescription]: '',
  [ITranslationKeys.formationsGamesDescription]: '',
  [ITranslationKeys.goalkeepersDescription]: '',
};

const translationFilters: Pick<ITypeForTranslation, ITranslationKeysFilters> = {
  [ITranslationKeys.moreFilters]: 'More filters',
  [ITranslationKeys.lessFilters]: 'Less filters',
  [ITranslationKeys.season]: 'Season',
  [ITranslationKeys.partOfSeason]: 'Game type',
  [ITranslationKeys.selectTeam]: 'Select team',
  [ITranslationKeys.countOfPlayers]: 'On-ice state',
  [ITranslationKeys.selectOpponent]: 'Select opponent',
  [ITranslationKeys.selectPlayer]: 'Select player',
  [ITranslationKeys.selectGoalkeeper]: 'Select goalkeeper',
  [ITranslationKeys.lastPlayedMatches]: 'Last games played',
  [ITranslationKeys.dateFromTo]: 'Date from - to',
  [ITranslationKeys.homeOrAway]: 'Home / Away',
  [ITranslationKeys.gameState]: 'Game state',
  [ITranslationKeys.gameTime]: 'Game time',
  [ITranslationKeys.minimumTOI]: 'Minimum of TOI',
  [ITranslationKeys.chooseFormationType]: 'Choose formation type',
  [ITranslationKeys.formationType]: 'Formation type',
  [ITranslationKeys.playerInLines]: 'Player in lines',
  [ITranslationKeys.insertCount]: 'Insert number',
};

const translationPagesWithCards: Pick<ITypeForTranslation, ITranslationKeysPagesWithCards> = {
  [ITranslationKeys.pleaseSelectTeam]: 'Please select a team',
  [ITranslationKeys.pleaseSelectTeamAndPlayer]: 'Please select a team and a player',
  [ITranslationKeys.pleaseSelectSeason]: 'Please select season',
  [ITranslationKeys.pleaseSelectPart]: 'Please select part of season',
  [ITranslationKeys.pleaseSelectOneSeasonAndOnePart]:
    'Please select one season and then one part of season',
  [ITranslationKeys.pleaseSelectOnlyOneSeason]: 'Please select only one season',
  [ITranslationKeys.pleaseSelectGame]: 'Please select match',
  [ITranslationKeys.pleaseSelectTeamAndGoalkeeper]: 'Please select a team and a goalkeeper',
  [ITranslationKeys.selectPlayerToCompare]: 'Select player to compare',
  [ITranslationKeys.selectGoalkeeperToCompare]: 'Select goalkeeper to compare',
  [ITranslationKeys.youCanLoadData]: 'You can load data now',
};

const translationTables: Pick<ITypeForTranslation, ITranslationKeysTables> = {
  [ITranslationKeys.selectOwnData]: 'Select data',
  [ITranslationKeys.dataTemplates]: 'Data templates',
  [ITranslationKeys.filterPositions]: 'Positions',
  [ITranslationKeys.filterTeam]: 'Filter team',
  [ITranslationKeys.allPositions]: 'All',
  [ITranslationKeys.onlyForwards]: 'Forwards',
  [ITranslationKeys.onlyDefensemen]: 'Defensemen',
  [ITranslationKeys.leaguePercentile]: 'League percentile',
  [ITranslationKeys.leagueOrder]: 'League order',
  [ITranslationKeys.leagueAverage]: 'League average',
  [ITranslationKeys.displayTimePeriod]: 'Display time period',
  [ITranslationKeys.nGames]: '{{count}} matches',
  [ITranslationKeys.months]: 'Months',
  [ITranslationKeys.quarter]: 'Quarter of regular season',
  [ITranslationKeys.custom]: 'Custom',
  [ITranslationKeys.dataSelectionBy]: 'Data selection by',
  [ITranslationKeys.playerMatches]: `Player's matches`,
  [ITranslationKeys.goalkeeperMatches]: `Goalkeeper's matches`,
  [ITranslationKeys.teamMatches]: `Team's matches`,
  [ITranslationKeys.calendar]: 'Calendar',
  [ITranslationKeys.addNextTimePeriod]: 'Add next time period',
  [ITranslationKeys.deleteTimePeriod]: 'Delete time period',
  [ITranslationKeys.timePeriodByPlayerMatches]: `Time period by player's matches`,
  [ITranslationKeys.timePeriodByGoalkeeperMatches]: `Time period by goalkeeper's matches`,
  [ITranslationKeys.timePeriodByTeamMatches]: `Time period by team's matches`,
  [ITranslationKeys.timePeriodByCalendar]: 'Time period by calendar',
};

const translationPlayerInfo: Pick<ITypeForTranslation, ITranslationKeysPlayerInfo> = {
  [ITranslationKeys.born]: 'Born',
  [ITranslationKeys.age]: 'Age',
  [ITranslationKeys.height]: 'Height',
  [ITranslationKeys.weight]: 'Weight',
  [ITranslationKeys.stick]: 'Stick',
  [ITranslationKeys.years]: 'years',
  [ITranslationKeys.leftStick]: 'Left',
  [ITranslationKeys.rightStick]: 'Right',
  [ITranslationKeys.leftAndRightStick]: 'Left & right',
  [ITranslationKeys.notAvailable]: 'Not available',
  [ITranslationKeys.insufficientMinutes]: 'Insufficient minutes',
  [ITranslationKeys.compareWithOtherPlayer]: 'Compare with other player',
  [ITranslationKeys.compareWithOtherGoalkeeper]: 'Compare with other goalkeeper',
  [ITranslationKeys.downloadPlayerCard]: 'Download player card',
  [ITranslationKeys.downloadGoalkeeperCard]: 'Download goalkeeper card',
  [ITranslationKeys.attack]: 'Attack',
  [ITranslationKeys.defend]: 'Defend',
  [ITranslationKeys.transition]: 'Transition',
  [ITranslationKeys.powerPlays]: 'Power plays',
  [ITranslationKeys.goals]: 'Goals',
  [ITranslationKeys.expectedGoals]: 'Expected goals',
  [ITranslationKeys.productivity]: 'Productivity',
  [ITranslationKeys.shotAssists]: 'Shot Assists',
  [ITranslationKeys.expectedGoalsFromAssists]: 'Expected goals from assists',
  [ITranslationKeys.influenceOnScoredTeamGoals]: 'Influence on scored team goals',
  [ITranslationKeys.influenceOnExpectedTeamGoals]: 'Influence on expected team goals',
  [ITranslationKeys.enemyBlocksOrShots]: 'Enemy blocks / shots',
  [ITranslationKeys.avoidedEntryIntoZone]: 'Avoided entry into the zone',
  [ITranslationKeys.bluelineDefending]: 'Blueline Defending',
  [ITranslationKeys.zoneExitDenials]: 'Zone Exit Denials',
  [ITranslationKeys.influenceOnSuccessOfFightsAfterDumpins]:
    'Influence on the success of fights after dumpins',
  [ITranslationKeys.influenceOnRecivedGoalsOfTeam]: 'Influence on recived goals of team',
  [ITranslationKeys.influenceOnExpectedGoalsOfEnemy]: 'Influence on expected goals of enemy',
  [ITranslationKeys.controlledZoneEntries]: 'Controlled Zone Entries',
  [ITranslationKeys.controlledZoneExits]: 'Controlled Zone Exits',
  [ITranslationKeys.preferenceOfControlledEntries]: 'Preference of controlled entries',
  [ITranslationKeys.zoneEntryPasses]: 'Zone Entry Passes',
  [ITranslationKeys.dumpinsWithFight]: 'Dumpins with fight',
  [ITranslationKeys.transitionsFromDefensiveToOffensiveZone]:
    'Transitions from defensive to offensive zone',
  [ITranslationKeys.stretchPassZoneExits]: 'Stretch Pass Zone Exits',
  [ITranslationKeys.slotShotAssists]: 'Slot Shot Assists',
  [ITranslationKeys.dumpouts]: 'Dumpouts',
  [ITranslationKeys.defenderShortcut]: 'DE',
  [ITranslationKeys.attackerShortcut]: 'FO',
  [ITranslationKeys.goalkeeperShortcut]: 'GK',
};

const translationGoalkeeperInfo: Pick<ITypeForTranslation, ITranslationKeysGoalkeeperInfo> = {
  [ITranslationKeys.seasonStatistics]: 'Season statistics',
  [ITranslationKeys.successOfInterventions]: 'Success of interventions',
  [ITranslationKeys.receivedGoals]: 'Recieved goals',
  [ITranslationKeys.actualForm]: 'Actual form',
  [ITranslationKeys.stolenGames]: 'Stolen games',
  [ITranslationKeys.shutouts]: 'Shutouts',
  [ITranslationKeys.goalieReliability]: 'Goalie reliability',
  [ITranslationKeys.controlledSaves]: 'Controlled saves',
  [ITranslationKeys.saves]: 'Saves',
  [ITranslationKeys.opponentsStick]: "Opponent's stick",
  [ITranslationKeys.controlled]: 'Controlled',
  [ITranslationKeys.uncontrolled]: 'Uncontrolled',
  [ITranslationKeys.screeningPlayer]: 'Screening player',
  [ITranslationKeys.deflected]: 'Deflected',
  [ITranslationKeys.afterCrossIceShotAssists]: 'Shots after cross-ice shot assists',
  [ITranslationKeys.shootoutAttempts]: 'Shootout attempts',
  [ITranslationKeys.moreGoalies]: 'More goalies',
  [ITranslationKeys.noGoalkeeperDataMessage]:
    'There are no data for this goalkeeper based on the selected filters.',
  [ITranslationKeys.noDataForSelected]: 'Unfortunately there are no data for the selected',
};

const translationGames: Pick<ITypeForTranslation, ITranslationKeysGames> = {
  [ITranslationKeysGames.overtimeShortcut]: 'ot',
  [ITranslationKeysGames.shootingShortcut]: 'pn',
  [ITranslationKeysGames.danger]: 'danger',
  [ITranslationKeysGames.high]: 'High',
  [ITranslationKeysGames.medium]: 'Medium',
  [ITranslationKeysGames.low]: 'Low',
  [ITranslationKeysGames.score]: 'Score',
  [ITranslationKeysGames.lineup]: 'Lineup',
  [ITranslationKeysGames.teamShots]: 'Team shots',
  [ITranslationKeysGames.shotsAgainst]: 'Shots against',
  [ITranslationKeysGames.negative]: 'Negative',
  [ITranslationKeysGames.positive]: 'Positive',
  [ITranslationKeysGames.shotsHeatmap]: 'Shots heatmap',
  [ITranslationKeysGames.winExpectation]: 'Win expectation',
  [ITranslationKeysGames.defenseFirstLetter]: 'D',
  [ITranslationKeysGames.forwardFirstLetter]: 'F',
  [ITranslationKeysGames.stickLetter]: 'S',
  [ITranslationKeysGames.leftLetter]: 'L',
  [ITranslationKeysGames.rightLetter]: 'R',
  [ITranslationKeysGames.allShotAttemptsFromSlot]: 'All shot attempts from slot',
  [ITranslationKeysGames.allPlayers]: 'All players',
  [ITranslationKeysGames.shotsOnGoal]: 'Shots on goal',
  [ITranslationKeysGames.playGoals]: 'Play goals',
  [ITranslationKeysGames.playAllGoals]: 'Play all goals',
  [ITranslationKeysGames.gameReport]: 'Game report',
  [ITranslationKeysGames.statsInGame5on5]: 'Statistics in game 5 on 5',
  [ITranslationKeysGames.statsInPowerPlay]: 'Statistics in power plays',
  [ITranslationKeysGames.showAllPlayers]: 'Show all players',
  [ITranslationKeysGames.noGamesToDisplay]: 'No games to display',
  [ITranslationKeysGames.shootingsSuperiority]: 'Shootings superiority',
};

const translationVideomaps: Pick<ITypeForTranslation, ITranslationKeysVideomaps> = {
  [ITranslationKeysVideomaps.count]: 'Count',
  [ITranslationKeysVideomaps.shotsDanger]: 'Shots danger',
  [ITranslationKeysVideomaps.entryDanger]: 'Entry danger',
  [ITranslationKeysVideomaps.exitDanger]: 'Exit danger',
  [ITranslationKeysVideomaps.last5Games]: 'Last 5 games',
  [ITranslationKeysVideomaps.filterBy]: 'Filter By',
  [ITranslationKeysVideomaps.withTeammate]: 'With teammate',
  [ITranslationKeysVideomaps.play]: 'Play',
  [ITranslationKeysVideomaps.saveMap]: 'Save map',
  [ITranslationKeysVideomaps.saveMaps]: 'Save maps',
  [ITranslationKeysVideomaps.hide]: 'Hide',
  [ITranslationKeysVideomaps.total]: 'Total',
  [ITranslationKeysVideomaps.totalValuesPerTeam]: 'Total values per team',
  [ITranslationKeysVideomaps.left]: 'Left',
  [ITranslationKeysVideomaps.center]: 'Center',
  [ITranslationKeysVideomaps.right]: 'Right',
  [ITranslationKeysVideomaps.playSelectedMatches]: 'Play selected matches',
  [ITranslationKeysVideomaps.selectAllGames]: 'Select all games',
  [ITranslationKeysVideomaps.deselectAllGames]: 'Deselect all games',
  [ITranslationKeysVideomaps.allShotAttempts]: 'All shot attempts',
  [ITranslationKeysVideomaps.onNet]: 'On net',
  [ITranslationKeysVideomaps.inSlot]: 'Slot',
  [ITranslationKeysVideomaps.slot]: 'Slot',
  [ITranslationKeysVideomaps.innerSlot]: 'Inner slot',
  [ITranslationKeysVideomaps.oneTimer]: 'One-timer',
  [ITranslationKeysVideomaps.rebound]: 'Rebound',
  [ITranslationKeysVideomaps.rebounds]: 'Rebounds',
  [ITranslationKeysVideomaps.forecheck]: 'Forecheck',
  [ITranslationKeysVideomaps.rush]: 'Rush',
  [ITranslationKeysVideomaps.cycle]: 'Cycle',
  [ITranslationKeysVideomaps.oddManRush]: 'Odd-man rush',
  [ITranslationKeysVideomaps.fromFaceoff]: 'From faceoff',
  [ITranslationKeysVideomaps.whoShoots]: 'Who shoots',
  [ITranslationKeysVideomaps.selectAtLeastTwoPlayers]: 'Please select at least 2 players.',
  [ITranslationKeysVideomaps.shotCategory]: 'Shot category',
  [ITranslationKeysVideomaps.shotLocation]: 'Shot location',
  [ITranslationKeysVideomaps.shotTypes]: 'Shot types',
  [ITranslationKeysVideomaps.attackType]: 'Attack type',
  [ITranslationKeysVideomaps.timeRecalculatedValues]: 'Time-recalculated values',
  [ITranslationKeysVideomaps.equityAndDerivedValues]: 'Equity and derived values',
  [ITranslationKeysVideomaps.relativeToTeam]: 'Relative to team',
  [ITranslationKeysVideomaps.trajectories]: 'Trajectories',
  [ITranslationKeysVideomaps.showAllTrajectories]: 'Show all trajectories',
  [ITranslationKeysVideomaps.whoPasses]: 'Who passes to shot',
  [ITranslationKeysVideomaps.passCategory]: 'Passes resulting in',
  [ITranslationKeysVideomaps.zoneEntryPass]: 'Zone entry pass',
  [ITranslationKeysVideomaps.zoneExitPass]: 'Zone exit pass',
  [ITranslationKeysVideomaps.shotAfterPassLocation]: 'Shot location',
  [ITranslationKeysVideomaps.passTypes]: 'Pass types',
  [ITranslationKeysVideomaps.behindTheNet]: 'Behind the net',
  [ITranslationKeysVideomaps.crossIce]: 'Cross-ice',
  [ITranslationKeysVideomaps.passOneTimer]: 'One-timer',
  [ITranslationKeysVideomaps.compareWithLeague]: 'Compare with the League average',
  [ITranslationKeysVideomaps.shotsProduction]: 'Shots production',
  [ITranslationKeysVideomaps.useOfOpportunities]: 'Use of opportunities',
  [ITranslationKeysVideomaps.withoutComparison]: 'Without comparison',
  [ITranslationKeysVideomaps.loadHeatmap]: 'Load heatmap',
  [ITranslationKeysVideomaps.onlyOnePlayerRequired]:
    'Only one player needs to be selected for the single player shot heatmap.',
  [ITranslationKeysVideomaps.faceoffResult]: 'Faceoff result',
  [ITranslationKeysVideomaps.faceoffFollowedBy]: 'Faceoffs followed by',
  [ITranslationKeysVideomaps.won]: 'Won',
  [ITranslationKeysVideomaps.lost]: 'Lost',
  [ITranslationKeysVideomaps.shotAttempt]: 'Shot attempt',
  [ITranslationKeysVideomaps.shotFromSlot]: 'Shot from slot',
  [ITranslationKeysVideomaps.goal]: 'Goal',
  [ITranslationKeysVideomaps.allFaceoffs]: 'All faceoffs',
  [ITranslationKeysVideomaps.leftSideFaceoffs]: 'Left side faceoffs',
  [ITranslationKeysVideomaps.rightSideFaceoffs]: 'Right side faceoffs',
  [ITranslationKeysVideomaps.puckGainType]: 'Puck gain type',
  [ITranslationKeysVideomaps.entryType]: 'Entry type',
  [ITranslationKeysVideomaps.exitType]: 'Exit type',
  [ITranslationKeysVideomaps.enterExitLocation]: 'Enter/Exit location',
  [ITranslationKeysVideomaps.captureOrBlock]: 'Capture/block',
  [ITranslationKeysVideomaps.inFight]: 'In fight',
  [ITranslationKeysVideomaps.carryIn]: 'Carry-in',
  [ITranslationKeysVideomaps.withPass]: 'Pass',
  [ITranslationKeysVideomaps.carryOut]: 'Carry-out',
  [ITranslationKeysVideomaps.passBehindRedLine]: 'Pass behind the red line',
  [ITranslationKeysVideomaps.middle]: 'Middle',
  [ITranslationKeysVideomaps.entryExitType]: 'Entry/exit type',
  [ITranslationKeysVideomaps.puckGain]: 'Puck gain',
  [ITranslationKeysVideomaps.dumpins]: 'Dumpins',
  [ITranslationKeysVideomaps.shifts]: 'Shifts',
  [ITranslationKeysVideomaps.dumpout]: 'Dumpout',
  [ITranslationKeysVideomaps.successfully]: 'Successfully',
  [ITranslationKeysVideomaps.unsuccessfully]: 'Unsuccessfully',
  [ITranslationKeysVideomaps.offensiveZoneGain]: 'Offensive zone gain',
  [ITranslationKeysVideomaps.losingPuckInMiddleZone]: 'Losing the puck in the middle zone',
  [ITranslationKeysVideomaps.entryCategory]: 'Enter category',
  [ITranslationKeysVideomaps.bySuccess]: 'By success',
  [ITranslationKeysVideomaps.finish]: 'Finish',
  [ITranslationKeysVideomaps.whoExitsFromDefensiveZone]: 'Who exits from defending zone',
  [ITranslationKeysVideomaps.whoEntersToOffensiveZone]: 'Who enters to offensive zone',
  [ITranslationKeysVideomaps.exitCategory]: 'Exit category',
  [ITranslationKeysVideomaps.exitZoneResult]: 'Zone exit result',
  [ITranslationKeysVideomaps.dumpoutResult]: 'Dumpout result',
  [ITranslationKeysVideomaps.dumpinResult]: 'Dumpin result',
  [ITranslationKeysVideomaps.dumpoutDanger]: 'Dumpout danger',
  [ITranslationKeysVideomaps.dumpinDanger]: 'Dumpin danger',
  [ITranslationKeysVideomaps.puckBattle]: 'Puck battle',
  [ITranslationKeysVideomaps.battleWonByTeam]: 'Battle won by team',
  [ITranslationKeysVideomaps.teamRecoveryWithoutBattle]: 'Team recovery w/o battle',
  [ITranslationKeysVideomaps.opponentRecoveryWithoutBattle]: 'Opponent recovery w/o battle',
  [ITranslationKeysVideomaps.battleWonByOpponent]: 'Battle won by opponent',
  [ITranslationKeysVideomaps.teamRanking]: 'Team ranking',
  [ITranslationKeysVideomaps.telhRanking]: 'TELH ranking',
  [ITranslationKeysVideomaps.blocked]: 'Blocked',
  [ITranslationKeysVideomaps.missedShots]: 'Missed shots',
  [ITranslationKeysVideomaps.posts]: 'Posts',
};

const translationAccountManagement: Pick<ITypeForTranslation, ITranslationKeysAccountManagement> = {
  [ITranslationKeysAccountManagement.changeOfPassword]: 'Change of password',
  [ITranslationKeysAccountManagement.hokejPlayerManagement]: 'hokej.PLAYER management',
  [ITranslationKeysAccountManagement.downloadsManagement]: 'Downloads management',
  [ITranslationKeysAccountManagement.newPassword]: 'New password',
  [ITranslationKeysAccountManagement.newPasswordAgain]: 'New password again',
  [ITranslationKeysAccountManagement.changePassword]: 'Change password',
  [ITranslationKeysAccountManagement.downloadVideo]: 'Download video',
  [ITranslationKeysAccountManagement.deleteVideo]: 'Delete video',
  [ITranslationKeysAccountManagement.multipleFiles]: 'multiple files',
  [ITranslationKeysAccountManagement.category]: 'Category',
  [ITranslationKeysAccountManagement.match]: 'Match',
  [ITranslationKeysAccountManagement.time]: 'Time',
  [ITranslationKeysAccountManagement.downloadAll]: 'Download all',
  [ITranslationKeysAccountManagement.deleteAll]: 'Delete all',
  [ITranslationKeysAccountManagement.previous]: 'Previous',
  [ITranslationKeysAccountManagement.next]: 'Next',
  [ITranslationKeysAccountManagement.enterEmail]: 'Enter e-mail',
  [ITranslationKeysAccountManagement.sentClips]: 'Sent clips',
  [ITranslationKeysAccountManagement.playerPosition]: 'Position',
  [ITranslationKeysAccountManagement.email]: 'E-mail',
  [ITranslationKeysAccountManagement.videocoachClips]: 'Videocoach clips',
  [ITranslationKeysAccountManagement.passwordsDontMatch]: 'Entered passwors do not match.',
  [ITranslationKeysAccountManagement.passwordTooShort]:
    'Password must be at least 6 characters long.',
  [ITranslationKeysAccountManagement.passwordChanged]: 'Password has been changed.',
  [ITranslationKeysAccountManagement.passwordChangedLoginAgain]:
    'Password has been changed, please login again.',
  [ITranslationKeysAccountManagement.language]: 'Language',
  [ITranslationKeysAccountManagement.emailEdited]: 'E-mail was succesfuly edited.',
  [ITranslationKeysAccountManagement.emailDeleted]: 'E-mail was succesfuly deleted.',
  [ITranslationKeysAccountManagement.enterValidEmail]: 'Enter valid e-mail please.',
  [ITranslationKeysAccountManagement.languageChanged]: 'Language was succesfuly changed.',
  [ITranslationKeysAccountManagement.clipDeleteError]: 'Error while deleting the clip.',
  [ITranslationKeysAccountManagement.clipDeleted]: 'The clip was succesfuly deleted.',
};

const translationShootout: Pick<ITypeForTranslation, ITranslationKeysShootout> = {
  [ITranslationKeysShootout.allAttempts]: 'All attempts',
  [ITranslationKeysShootout.shot]: 'Shot',
  [ITranslationKeysShootout.feint]: 'Feint',
  [ITranslationKeysShootout.forehand]: 'Forehand',
  [ITranslationKeysShootout.backhand]: 'Backhand',
  [ITranslationKeysShootout.sideOfEnding]: 'Side of ending',
  [ITranslationKeysShootout.typeOfEnding]: 'Type of ending',
  [ITranslationKeysShootout.resultOfEnding]: 'Result of ending',
  [ITranslationKeysShootout.goalkeepersShootouts]: 'Shootouts on goalkeeper',
};

const translationAllWordNominativ: Pick<ITypeForTranslation, ITranslationKeysAllWordNominativ> = {
  [ITranslationKeysAllWordNominativ.all]: 'All',
  [ITranslationKeysAllWordNominativ.all_male]: 'All',
  [ITranslationKeysAllWordNominativ.all_female]: 'All',
  [ITranslationKeysAllWordNominativ.all_one]: 'All',
  [ITranslationKeysAllWordNominativ.all_male_one]: 'All',
  [ITranslationKeysAllWordNominativ.all_female_one]: 'All',
};

const translationPeriods: Pick<ITypeForTranslation, ITranslationKeysPeriods> = {
  [ITranslationKeysPeriods.period]: 'Period',
  [ITranslationKeysPeriods.periodWithOrder]: '{{count}}. period',
};

const translationNetZones: Pick<ITypeForTranslation, ITranslationKeysNetZones> = {
  [ITranslationKeysNetZones.ur]: 'Upper right',
  [ITranslationKeysNetZones.ce]: 'Center',
  [ITranslationKeysNetZones.ul]: 'Upper left',
  [ITranslationKeysNetZones.br]: 'Bottom right',
  [ITranslationKeysNetZones.fh]: 'Five hole',
  [ITranslationKeysNetZones.bl]: 'Bottom left',
};

const translationVideoCenter: Pick<ITypeForTranslation, ITranslationKeysVideoCenter> = {
  [ITranslationKeysVideoCenter.downloadSelectedVideos]: 'Download selected videos',
  [ITranslationKeysVideoCenter.videoCenter]: 'Video center',
  [ITranslationKeysVideoCenter.cutBefore]: 'Cut before',
  [ITranslationKeysVideoCenter.cutAfter]: 'Cut after',
  [ITranslationKeysVideoCenter.videoTime]: 'Video time',
  [ITranslationKeysVideoCenter.videocoachNotes]: 'Videocoach notes',
  [ITranslationKeysVideoCenter.clipName]: 'Clip name',
  [ITranslationKeysVideoCenter.actionRating]: 'Action rating',
  [ITranslationKeysVideoCenter.situationNote]: 'Match situation note',
  [ITranslationKeysVideoCenter.noteReceivers]: 'Note receivers',
  [ITranslationKeysVideoCenter.forwards]: 'Forwards',
  [ITranslationKeysVideoCenter.defensemen]: 'Defensemen',
  [ITranslationKeysVideoCenter.sendToPlayers]: 'Send to player / players',
  [ITranslationKeysVideoCenter.downloadingVideos]: 'Videos are starting to download.',
  [ITranslationKeysVideoCenter.downloadingVideosError]:
    'The video download could not be completed.',
  [ITranslationKeysVideoCenter.downloadingVideosSuccesful]:
    'The videos have been downloaded successfully, you can find them in the download manager.',
  [ITranslationKeysVideoCenter.clipNameEmpty]: 'Clip name must be filled.',
  [ITranslationKeysVideoCenter.zeroPlayersSelected]: 'You must select at least one player.',
  [ITranslationKeysVideoCenter.noteSend]: 'Note sent successfully.',
  [ITranslationKeysVideoCenter.noteNotSend]: 'There was an error sending the note.',
  [ITranslationKeysVideoCenter.navigateToVideomap]: 'Navigate to videomap',
  [ITranslationKeysVideoCenter.videoLinks]: 'Video links',
  [ITranslationKeysVideoCenter.videoLoadFailed]: 'Video load failed.',
};

const translationTopPlayers: Pick<ITypeForTranslation, ITranslationKeysTopPlayers> = {
  [ITranslationKeysTopPlayers.topPlayers]: 'Top players',
  [ITranslationKeysTopPlayers.topPlayers_one]: 'Top player',
  [ITranslationKeysTopPlayers.topPlayers_two]: 'Top {{count}} players',
  [ITranslationKeysTopPlayers.topPlayers_few]: 'Top {{count}} players',
  [ITranslationKeysTopPlayers.topPlayers_other]: 'Top {{count}} players',
};

const translationTracking: Pick<ITypeForTranslation, ITranslationKeysTracking> = {
  [ITranslationKeysTracking.backToApp]: 'Back to application',
  [ITranslationKeysTracking.analyticsOverview]: 'Analytics overview',
  [ITranslationKeysTracking.users]: 'Users',
  [ITranslationKeysTracking.logins]: 'Logins',
  [ITranslationKeysTracking.eventLog]: 'Event log',
  [ITranslationKeysTracking.admin]: 'Admin',
  [ITranslationKeysTracking.media]: 'Media',
  [ITranslationKeysTracking.user]: 'User',
  [ITranslationKeysTracking.client]: 'Client',
  [ITranslationKeysTracking.userRole]: `User's role`,
  [ITranslationKeysTracking.analyticsType]: 'Analytics type',
  [ITranslationKeysTracking.interval]: 'Interval',
  [ITranslationKeysTracking.displayData]: 'Display data',
  [ITranslationKeysTracking.activeUser]: 'Active user',
  [ITranslationKeysTracking.teamMedia]: 'Team / Media',
  [ITranslationKeysTracking.pleaseSelectTeamOrMedia]: 'Please select a team or media',
  [ITranslationKeysTracking.visits]: 'Visits',
  [ITranslationKeysTracking.uniqueUsers]: 'Unique users',
  [ITranslationKeysTracking.openScreensCount]: 'Open screens count',
  [ITranslationKeysTracking.screens]: 'Screens',
  [ITranslationKeysTracking.allUsers]: 'All users',
  [ITranslationKeysTracking.pickUser]: 'Pick user',
  [ITranslationKeysTracking.roleChanged]: 'Role was changed succesfuly.',
  [ITranslationKeysTracking.videocoachChanged]: "Videocoach's team was changed succesfuly.",
  [ITranslationKeysTracking.removeVideocoachNote]:
    'Do you really want to remove this videocoach note?',
  [ITranslationKeysTracking.userActivated]: 'User was succesfuly activated.',
  [ITranslationKeysTracking.userDeactivated]: 'User was succesfuly deactivated.',
  [ITranslationKeysTracking.changeInProgress]: 'The change is in progress, please wait',
  [ITranslationKeysTracking.openedPage]: 'Opened page',
  [ITranslationKeysTracking.event]: 'Event',
  [ITranslationKeysTracking.lastLogin]: 'Last login',
  [ITranslationKeysTracking.loginCountTotalAndLast30Days]: 'Total login count + last 30 days',
  [ITranslationKeysTracking.loginList]: 'Login list',
  [ITranslationKeysTracking.registeredPlayers]: 'Registered players',
  [ITranslationKeysTracking.sentLinks]: 'Sent links',
  [ITranslationKeysTracking.clickthroughRate]: 'Clickthrough rate',
  [ITranslationKeysTracking.playersWithAttendance]: 'Players with attendance',
  [ITranslationKeysTracking.totalPlayersWithSavedClips]: 'Total players with saved clips',
  [ITranslationKeysTracking.totalNotesSent]: 'Total notes sent',
  [ITranslationKeysTracking.videocoachNotesSent]: 'Videocoach notes sent',
  [ITranslationKeysTracking.numberOfClipsInPlaylist]: 'Number of clips in playlist',
  [ITranslationKeysTracking.searchPlayer]: 'Search for player',
  [ITranslationKeysTracking.teamSelection]: 'Team selection',
  [ITranslationKeysTracking.attendance]: 'Attendance',
  [ITranslationKeysTracking.dataByTimePeriod]: 'Data by time period',
  [ITranslationKeysTracking.higherThan]: 'Higher than {{context}}',
  [ITranslationKeysTracking.equalTo]: 'Equal to {{context}}',
  [ITranslationKeysTracking.doesNotMatter]: 'Does not matter',
  [ITranslationKeysTracking.tracking]: 'Tracking',
  [ITranslationKeysTracking.overview]: 'Overview',
  [ITranslationKeysTracking.inputSearchedEmail]: 'Input searched email',
  [ITranslationKeysTracking.downloadUsersList]: 'Download users list',
  [ITranslationKeysTracking.dateAndTime]: 'Date and time',
  [ITranslationKeysTracking.ipAdress]: 'IP adress',
  [ITranslationKeysTracking.eventType]: 'Event type',
  [ITranslationKeysTracking.allEvents]: 'All events',
};

const translationCms: Pick<ITypeForTranslation, ITranslationKeysCms> = {
  [ITranslationKeysCms.helpManagement]: 'Help management',
  [ITranslationKeysCms.helpCategory]: 'Help category',
  [ITranslationKeysCms.metricsManagement]: 'Metrics management',
  [ITranslationKeysCms.templatesManagement]: 'Templates management',
  [ITranslationKeysCms.definedTemplatesManagement]: 'Predefined templates',
  [ITranslationKeysCms.userManagement]: 'User management',
  [ITranslationKeysCms.notFilledOut]: 'Not filled out',
  [ITranslationKeysCms.addUser]: 'Add user',
  [ITranslationKeysCms.userAdded]: 'User was succesfuly added.',
  [ITranslationKeysCms.helpContentEditation]: 'Help content editation',
  [ITranslationKeysCms.editFunctions]: 'Edit functions',
  [ITranslationKeysCms.editTips]: 'Edit tips',
  [ITranslationKeysCms.editNews]: 'Edit news',
  [ITranslationKeysCms.editOpeningText]: 'Edit opening text',
  [ITranslationKeysCms.caption]: 'Caption',
  [ITranslationKeysCms.addAnotherItem]: 'Add another item',
  [ITranslationKeysCms.textEditation]: 'Text editation',
  [ITranslationKeysCms.itemEditation]: 'Item editation',
  [ITranslationKeysCms.itemAddition]: 'Item addition',
  [ITranslationKeysCms.sectionEditation]: 'Section editation',
  [ITranslationKeysCms.playerTemplates]: 'Player templates',
  [ITranslationKeysCms.teamTemplates]: 'Team templates',
  [ITranslationKeysCms.goalkeeperTemplates]: 'Goalkeeper templates',
  [ITranslationKeysCms.dataTypes]: 'Data types',
  [ITranslationKeysCms.templateMetricTypes]: 'Template metric types',
  [ITranslationKeysCms.removeTemplate]: 'Do you really want to remove this template?',
  [ITranslationKeysCms.enterNameOfTemplate]: 'Enter name of the template',
  [ITranslationKeysCms.itemDeletedSuccessfully]: 'Item was successfully deleted.',
  [ITranslationKeysCms.itemDeleteFailed]: 'Failed to delete item. Please try again.',
  [ITranslationKeysCms.itemAddedSuccessfully]: 'Item was successfully added.',
  [ITranslationKeysCms.itemAddFailed]: 'Failed to add item. Please try again.',
  [ITranslationKeysCms.itemEditedSuccessfully]: 'Item was successfully edited.',
  [ITranslationKeysCms.itemEditFailed]: 'Failed to edit item. Please try again.',
  [ITranslationKeysCms.enterTitleOfItem]: 'Enter title of the item',
  [ITranslationKeysCms.templateCategory]: 'Template category',
  [ITranslationKeysCms.selectedMetrics]: 'Selected metrics',
};

const translationEcContent: Pick<ITypeForTranslation, ITranslationKeysEcContent> = {
  [ITranslationKeysEcContent.formationsCompare]: 'Formations',
  [ITranslationKeysEcContent.iceTime]: 'IceTime',
  [ITranslationKeysEcContent.momentum]: 'Momentum',
};

const translationBugReport: Pick<ITypeForTranslation, ITranslationKeysBugReport> = {
  [ITranslationKeysBugReport.reportBug]: 'Report bug',
  [ITranslationKeysBugReport.metricsInaccuracy]: 'Inaccuracy in metrics',
  [ITranslationKeysBugReport.featureNotWorking]: 'Feature not working',
  [ITranslationKeysBugReport.otherProblem]: 'Other problem',
  [ITranslationKeysBugReport.describeProblem]: 'Tell us more about the error you are reporting.',
  [ITranslationKeysBugReport.send]: 'Send',
  [ITranslationKeysBugReport.wrongTime]: 'Video starts at the wrong time',
  [ITranslationKeysBugReport.wrongPlayer]: 'Wrong player',
  [ITranslationKeysBugReport.wrongEvent]: 'Wrong event',
  [ITranslationKeysBugReport.reportSent]: 'Report sent successfully.',
  [ITranslationKeysBugReport.reportSentError]: 'There was an error while sending report.',
};

export const TRANSLATION_EN: ITypeForTranslation = {
  ...translationMetrics,
  ...translationBase,
  ...translationSeasonParts,
  ...translationCountOfPlayers,
  ...translationHomeOrAway,
  ...translationGameState,
  ...translationErrors,
  ...translationNavigation,
  ...translationModal,
  ...translationWidgets,
  ...translationWidgetsDescriptions,
  ...translationFilters,
  ...translationPagesWithCards,
  ...translationTables,
  ...translationPlayerInfo,
  ...translationGames,
  ...translationGoalkeeperInfo,
  ...translationAccountManagement,
  ...translationVideomaps,
  ...translationShootout,
  ...translationAllWordNominativ,
  ...translationPeriods,
  ...translationNetZones,
  ...translationVideoCenter,
  ...translationTopPlayers,
  ...translationTracking,
  ...translationCms,
  ...translationEcContent,
  ...translationCSVExports,
  ...translationBugReport,
  ...translationDashboard,
};
