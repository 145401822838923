import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredMainFilterDataSelector,
  getPlayersWithStats,
  getSimilarPlayers,
  selectMainFilter,
  selectTableFilter,
} from '../../features';
import {
  IIndividualStatsRequestBody,
  IMainFilterForm,
  ISimilarPlayersRequestBody,
} from '../../types';
import { normalizeSelectWithAllValues } from '../../utils';
import { useNormalizeMetrics } from '../useNormalizeMetrics';
import { usePrepareBaseRequestBody } from '../usePrepareBaseRequestBody';

export const useFetchSimilarityPlayers = () => {
  const prepareBaseRequestBody = usePrepareBaseRequestBody();
  const { stickGrip } = useAppSelector(selectTableFilter);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);
  const { selectedTeam, selectedPlayer } = useAppSelector(selectMainFilter);
  const dispatch = useAppDispatch();

  const normalizeMetrics = useNormalizeMetrics();

  const fetchSimilarityPlayers = (data: Partial<IMainFilterForm>) => {
    const competitionsUuids = filteredParts.map(part => part.id);

    const { requestBodyBase } = prepareBaseRequestBody(data);
    const requestBody: IIndividualStatsRequestBody = {
      ...requestBodyBase,
      metrics: normalizeMetrics(),
    };
    const requestBodySimilarPlayers: ISimilarPlayersRequestBody = {
      ...requestBody,
      stick: normalizeSelectWithAllValues(stickGrip),
    };

    const teamUuid = selectedTeam && selectedTeam.value !== 'all' ? selectedTeam.value : undefined;

    dispatch(
      getPlayersWithStats({
        competitionsUuids,
        teamUuid,
        body: requestBody,
      }),
    );

    if (selectedPlayer) {
      dispatch(
        getSimilarPlayers({
          competitionsUuids,
          playerUuid: selectedPlayer.value,
          teamUuid,
          body: requestBodySimilarPlayers,
          isByPlayer: true,
        }),
      );
    }
  };

  return fetchSimilarityPlayers;
};
