import {
  ColumnDef,
  ColumnPinningState,
  SortingState,
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  BasicColumnCell,
  BasicColumnHeader,
  EyeDropdown,
  FormationColumnCell,
  ImageColumn,
  SummaryPercentileColumnCell,
  SummaryPercentileColumnHeader,
} from '../../../../components';
import { logosPaths } from '../../../../constants';
import { selectTeamsFormation } from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import { ITranslationKeys } from '../../../../i18n/types';
import {
  IColumnsConfig,
  INavigationContentKeys,
  INavigationPageKeys,
  ITeamsFormationTableData,
  IUrlFilterParams,
} from '../../../../types';
import { createDynamicStatsColumnsDef, secondsToMinutesAndSeconds } from '../../../../utils';

const columnHelper = createColumnHelper<ITeamsFormationTableData>();

const columnPinning: ColumnPinningState = {
  left: ['actions', 'players', 'teamId', 'gp', 'toi'],
  right: ['summaryPercentile'],
};

const initialSorting: SortingState = [
  {
    id: 'toi',
    desc: true,
  },
];

const fixedColumns: ColumnDef<ITeamsFormationTableData, any>[] = [
  columnHelper.accessor('players', {
    header: () => <span style={{ display: 'block', width: '230px' }}></span>,
    cell: props => <FormationColumnCell {...props} />,
  }),
  columnHelper.accessor('teamId', {
    header: '',
    cell: props => <ImageColumn src={logosPaths[props.getValue()]} alt='team-logo' />,
  }),
  columnHelper.accessor('gp', {
    header: props => <BasicColumnHeader {...props}>GP</BasicColumnHeader>,
    cell: props => <BasicColumnCell {...props}>{props.getValue()}</BasicColumnCell>,
  }),
  columnHelper.accessor('toi', {
    header: props => <BasicColumnHeader {...props}>TOI</BasicColumnHeader>,
    cell: props => (
      <BasicColumnCell {...props}>{secondsToMinutesAndSeconds(props.getValue())}</BasicColumnCell>
    ),
  }),
];

export const useColumnsConfig = (
  centerPartWidth: number | undefined,
): IColumnsConfig<ITeamsFormationTableData> => {
  const { isTeamsFormationPercentilesActive, isTeamsFormationPercentilesLoading } =
    useAppSelector(selectTeamsFormation);
  const getFilteredMetrics = useFilteredMetricsForTable();

  const columns = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();
    const dynamicColumns = createDynamicStatsColumnsDef(
      filteredMetrics,
      columnHelper,
      {
        isPercentilesActive: isTeamsFormationPercentilesActive,
        isPercentilesLoading: isTeamsFormationPercentilesLoading,
      },
      centerPartWidth,
    );

    const conditionalColumns: ColumnDef<ITeamsFormationTableData, any>[] =
      isTeamsFormationPercentilesActive && !isTeamsFormationPercentilesLoading
        ? [
            columnHelper.accessor('summaryPercentile', {
              header: props => (
                <BasicColumnHeader {...props}>
                  <SummaryPercentileColumnHeader isLastInTable />
                </BasicColumnHeader>
              ),
              cell: props => (
                <BasicColumnCell {...props}>
                  <SummaryPercentileColumnCell value={props.getValue()} />
                </BasicColumnCell>
              ),
            }),
          ]
        : [];

    const actionsColumn = createActionsColumn();

    return [actionsColumn].concat(fixedColumns).concat(dynamicColumns).concat(conditionalColumns);
  }, [
    isTeamsFormationPercentilesActive,
    isTeamsFormationPercentilesLoading,
    centerPartWidth,
    getFilteredMetrics,
  ]);

  return {
    columns,
    columnPinning,
    initialSorting,
  };
};

const createActionsColumn = () =>
  columnHelper.display({
    id: 'actions',
    cell: props => {
      const { players, countOfPlayers } = props.row.original;
      const playersRows = [...players.firstRow, ...players.secondRow];
      const playerIds = playersRows.map(player => player.id).join(',');

      const filterParams: IUrlFilterParams[] = [
        {
          name: 'selectedTeam',
          value: props.row.original.teamId,
        },
        {
          name: 'selectedPlayerItems',
          value: playerIds,
        },
      ];

      if (countOfPlayers) {
        filterParams.push({
          name: 'countOfPlayers',
          value:
            countOfPlayers === '5:5-FO' || countOfPlayers === '5:5-DE' ? '5:5' : countOfPlayers,
        });
      }

      return (
        <EyeDropdown
          eyeDropdownTooltipItems={[
            {
              pageTranslationLabel: ITranslationKeys.gamelog,
              navigationPageKey: INavigationPageKeys.players,
              navigationTabKey: INavigationContentKeys.gamelog,
              filterParams,
            },
            {
              pageTranslationLabel: ITranslationKeys.trend,
              navigationPageKey: INavigationPageKeys.players,
              navigationTabKey: INavigationContentKeys.trend,
              filterParams,
            },
            {
              pageTranslationLabel: ITranslationKeys.combinations,
              navigationPageKey: INavigationPageKeys.formations,
              navigationTabKey: INavigationContentKeys.playerCombinations,
              filterParams,
            },
            {
              pageTranslationLabel: 'Video',
              navigationPageKey: INavigationPageKeys.videomaps,
              navigationTabKey: INavigationContentKeys.shots,
              filterParams,
            },
          ]}
        />
      );
    },
  });
