import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IDatasetMetricRecord,
  IDefaultDataset,
  IDefaultDefinedDatasets,
  IDefinedDatasetsWithDefault,
} from '../../../../types';
import { putDefinedDatasetsRequest } from '../utils';
import { IRemoveAfterAPIChange } from './types';

export interface IUpdateDefaultTemplateMetrics extends IRemoveAfterAPIChange {
  id: string;
  metricsRecord: IDatasetMetricRecord;
}

export const updateDefaultTemplateMetrics = createAsyncThunk<
  IDefinedDatasetsWithDefault | undefined,
  IUpdateDefaultTemplateMetrics
>(
  'settings/definedDatasets/updateDataset',
  async ({ id, metricsRecord, definedDatasets, defaultTemplates }) => {
    const updatedDataset: IDefaultDataset = {
      ...defaultTemplates.byId[id],
      datasetMetrics: metricsRecord,
    };
    const updatedDefaultTemplate: IDefaultDefinedDatasets = {
      byId: {
        ...defaultTemplates.byId,
        [id]: updatedDataset,
      },
      allIds: defaultTemplates.allIds,
    };

    return await putDefinedDatasetsRequest({
      ...definedDatasets,
      defaultTemplates: updatedDefaultTemplate,
    });
  },
);
