import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  filteredFaceoffsSelector,
  filteredGoalkeepersShotsVideoMetricSelector,
  filteredPassesVideoMetricSelector,
  filteredShootoutsSelector,
  filteredShotsVideoMetricSelector,
  filteredZoneEntriesFilterDataSelector,
  filteredZoneExitsFilterDataSelector,
  selectMainFilter,
  selectMetricParamsFilter,
  selectVideoCenter,
  selectVideomapsFilter,
  setMetricVideoGameIds,
  setVideoRecord,
} from '../../features';
import {
  IFaceoff,
  IFormation,
  IMetricVideoLinkKeys,
  INavigationContentKeys,
  INavigationPageKeys,
  IPass,
  ISelectOption,
  IShot,
  IVideoFormationsConfig,
  IWowyType,
  IZoneEntity,
} from '../../types';
import {
  createVideoRecordFromArray,
  filterUniqueGameIds,
  mapShootoutToVideoInfo,
} from '../../utils';
import { useLocationPaths } from '../useLocationPaths';

export const useSetVideoRecordAtMetricDataLoad = () => {
  const filteredShots = useAppSelector(filteredShotsVideoMetricSelector);
  const filteredPasses = useAppSelector(filteredPassesVideoMetricSelector);
  const filteredFaceoffs = useAppSelector(filteredFaceoffsSelector);
  const zoneEntries = useAppSelector(filteredZoneEntriesFilterDataSelector);
  const zoneExits = useAppSelector(filteredZoneExitsFilterDataSelector);
  const filteredShootouts = useAppSelector(filteredShootoutsSelector);
  const filteredGoalkeepersShots = useAppSelector(filteredGoalkeepersShotsVideoMetricSelector);
  const { selectedGames } = useAppSelector(selectMainFilter);
  const { entity, playerId, formations, filterByProp } = useAppSelector(selectVideomapsFilter);
  const { metricVideoLinkId, formationsConfig } = useAppSelector(selectVideoCenter);
  const { entryExitType } = useAppSelector(selectMetricParamsFilter);
  const dispatch = useAppDispatch();

  const { activePage, activeTab } = useLocationPaths();

  const hasFormation =
    (activePage === INavigationPageKeys.players &&
      (activeTab === INavigationContentKeys.gamelog ||
        activeTab === INavigationContentKeys.trend)) ||
    (activePage === INavigationPageKeys.formations &&
      activeTab !== INavigationContentKeys.playerCombinations) ||
    activeTab === INavigationContentKeys.formations;

  const hasSelectedGames =
    activeTab === INavigationContentKeys.gamelog ||
    activeTab === INavigationContentKeys.trend ||
    activePage === INavigationPageKeys.games;

  useEffect(() => {
    if (metricVideoLinkId === IMetricVideoLinkKeys.shots) {
      const shots = filterShotsOrEntries(
        filteredShots,
        entity,
        filterByProp,
        playerId,
        formations,
        selectedGames,
        hasSelectedGames,
        hasFormation,
        formationsConfig,
      );
      const gameIds = filterUniqueGameIds(shots);
      console.log('shots', shots);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(shots)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.passes) {
      const passes = filterPassesOrExits(
        filteredPasses,
        entity,
        playerId,
        formations,
        selectedGames,
        hasSelectedGames,
        hasFormation,
        formationsConfig,
      );
      const gameIds = filterUniqueGameIds(passes);
      console.log('passes', passes);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(passes)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.faceoffs) {
      const faceoffs = filterFaceoffs(
        filteredFaceoffs,
        formations,
        selectedGames,
        hasSelectedGames,
        hasFormation,
      );
      const gameIds = filterUniqueGameIds(faceoffs);
      console.log('faceoffs', faceoffs);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(faceoffs)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.entries) {
      const entriesWithPassType = filterShotsOrEntries(
        zoneEntries,
        entity,
        filterByProp,
        playerId,
        formations,
        selectedGames,
        hasSelectedGames,
        hasFormation,
        formationsConfig,
      );
      const entries = entryExitType.some(option => option.value === 'enp')
        ? entriesWithPassType
        : entriesWithPassType.filter(entry => entry.type !== 'pass');
      const gameIds = filterUniqueGameIds(entries);
      console.log('entries', entries);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(entries)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.exits) {
      const exitsWithPassType = filterPassesOrExits(
        zoneExits,
        entity,
        playerId,
        formations,
        selectedGames,
        hasSelectedGames,
        hasFormation,
        formationsConfig,
      );
      const exits = entryExitType.some(option => option.value === 'exp' || option.value === 'stp')
        ? exitsWithPassType
        : exitsWithPassType.filter(exit => exit.type !== 'pass' && exit.type !== 'longPass');
      const gameIds = filterUniqueGameIds(exits);
      console.log('exits', exits);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(exits)));
    }

    if (
      metricVideoLinkId === IMetricVideoLinkKeys.shootouts ||
      metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts
    ) {
      const isTeam = entity.value === 'team';
      const isGoalkeeper = metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShootouts;
      const filteredShootoutsByPlayer = isTeam
        ? filteredShootouts
        : filteredShootouts.filter(shootout =>
            isGoalkeeper
              ? shootout.goalkeeper.playerId === playerId
              : shootout.skater.playerId === playerId,
          );
      const filteredShootoutsByGames =
        hasSelectedGames && selectedGames.length > 0
          ? filteredShootoutsByPlayer.filter(shot =>
              selectedGames.some(game => game.value === shot.matchId),
            )
          : filteredShootoutsByPlayer;
      const shootouts = filteredShootoutsByGames.map(shootout => mapShootoutToVideoInfo(shootout));

      const gameIds = filterUniqueGameIds(filteredShootoutsByGames);
      console.log('filteredShootouts', filteredShootoutsByGames);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(shootouts)));
    }

    if (metricVideoLinkId === IMetricVideoLinkKeys.goalkeepersShots) {
      const goalkeeperShots =
        hasSelectedGames && selectedGames.length > 0
          ? filteredGoalkeepersShots.filter(shot =>
              selectedGames.some(game => game.value === shot.matchId),
            )
          : filteredGoalkeepersShots;
      const gameIds = filterUniqueGameIds(goalkeeperShots);
      console.log('goalkeeperShots', goalkeeperShots);
      console.log('gameIds', gameIds);
      dispatch(setMetricVideoGameIds(gameIds));
      dispatch(setVideoRecord(createVideoRecordFromArray(goalkeeperShots)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredShots,
    filteredPasses,
    filteredFaceoffs,
    filteredShootouts,
    zoneEntries,
    zoneExits,
    filteredGoalkeepersShots,
    formationsConfig,
  ]);
};

const filterShotsOrEntries = <T extends IShot | IZoneEntity>(
  items: T[],
  entity: ISelectOption,
  filterByProp: string | undefined,
  playerId: string | undefined,
  formations: IFormation[] | undefined,
  selectedGames: ISelectOption[],
  hasSelectedGames: boolean,
  hasFormation: boolean,
  formationsConfig?: IVideoFormationsConfig,
) => {
  const filteredByGames = hasSelectedGames ? filterBySelectedGames(items, selectedGames) : items;

  if (
    (hasFormation || formationsConfig?.isCombinationExtraRow) &&
    formations &&
    formations.length > 0
  ) {
    const filteredByFormations = filterShotsOrEntriesByFormations(
      filteredByGames,
      formations,
      filterByProp,
    );

    if (formationsConfig?.wowyType === IWowyType.together) {
      return filteredByFormations.filter(item => item[filterByProp as keyof T] === playerId);
    }

    return filteredByFormations;
  }

  const filteredByPlayerId = filteredByGames.filter(
    item => item[filterByProp as keyof T] === playerId,
  );

  if (entity.value === 'player') {
    return filteredByPlayerId;
  }

  if (filterByProp === 'blocker') {
    if (entity.value === 'opponent') {
      return filteredByPlayerId;
    }

    return filteredByGames.filter(item => !!item.blocker);
  }

  return filteredByGames;
};

const filterPassesOrExits = <T extends IPass | IZoneEntity>(
  items: T[],
  entity: ISelectOption,
  playerId: string | undefined,
  formations: IFormation[] | undefined,
  selectedGames: ISelectOption[],
  hasSelectedGames: boolean,
  hasFormation: boolean,
  formationsConfig?: IVideoFormationsConfig,
) => {
  const filteredByGames = hasSelectedGames ? filterBySelectedGames(items, selectedGames) : items;

  if (
    (hasFormation || formationsConfig?.isCombinationExtraRow) &&
    formations &&
    formations.length > 0
  ) {
    const filteredByFormations = filterByFormations(filteredByGames, formations);

    if (formationsConfig?.wowyType === IWowyType.together) {
      return filteredByFormations.filter(item => item.playerId === playerId);
    }

    return filteredByFormations;
  }

  if (entity.value === 'player') {
    return filteredByGames.filter(item => item.playerId === playerId);
  }

  return filteredByGames;
};

const filterFaceoffs = (
  faceoffs: IFaceoff[],
  formations: IFormation[] | undefined,
  selectedGames: ISelectOption[],
  hasSelectedGames: boolean,
  hasFormation: boolean,
) => {
  const filteredByGames = hasSelectedGames
    ? filterBySelectedGames(faceoffs, selectedGames)
    : faceoffs;

  if (hasFormation && formations && formations.length > 0) {
    return filterByFormations(filteredByGames, formations);
  }

  return filteredByGames;
};

const filterShotsOrEntriesByFormations = <T extends IShot | IZoneEntity>(
  items: T[],
  formations: IFormation[],
  filterByProp: string | undefined,
) => {
  if (filterByProp === 'blocker') {
    return items.filter(
      item =>
        item.blocker &&
        formations.some(formation => item.blocker === formation.player && formation.on),
    );
  }
  return filterByFormations(items, formations);
};

const filterByFormations = <T extends IPass | IZoneEntity | IFaceoff | IShot>(
  items: T[],
  formations: IFormation[],
) =>
  items.filter(item =>
    formations.some(formation => item.playerId === formation.player && formation.on),
  );

const filterBySelectedGames = <T extends IShot | IPass | IZoneEntity | IFaceoff>(
  items: T[],
  selectedGames: ISelectOption[],
) => {
  if (selectedGames.length > 0) {
    return items.filter(item => selectedGames.some(game => game.value === item.matchId));
  }

  return items;
};
