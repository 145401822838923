import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  selectMainFilter,
  selectPlayers,
  selectTeamsFormation,
  selectWowy,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IFormation,
  IMetricWithDatasetMetric,
  IPlayer,
  IPlayerRecord,
  ISelectOption,
  ISimilarPlayerNameRecord,
  ITeamFormation,
  ITeamsFormationSelectedPlayer,
  ITeamsFormationTableData,
  ITeamsFormationTableWithHeaderData,
} from '../../../../types';
import { getMetricsWithValue, getPlayerShortName, getPlayersTwoRows } from '../../../../utils';

const getTableData = (
  teamsFormations: ITeamFormation[],
  playerRecord: IPlayerRecord,
  metrics: IMetricWithDatasetMetric[],
  similarPlayerNames: ISimilarPlayerNameRecord,
  teamsFormationsPercentiles?: ITeamFormation[],
  formationType?: ISelectOption,
) => {
  const tableData = teamsFormations
    .map<ITeamsFormationTableData>((teamsFormation, index) => {
      const players = teamsFormation.playerIds.map<IPlayer>(playerId => {
        const player = playerRecord[playerId];

        // Data are unexpectedly missing
        if (!player) {
          console.warn('[Data are unexpectedly missing]: Player is missing - id:', playerId);
        }

        return playerRecord[playerId];
      });
      const teamsFormationPercentiles = teamsFormationsPercentiles
        ? teamsFormationsPercentiles[index]
        : undefined;

      // Data are unexpectedly missing
      const filteredPlayers = players.filter(player => player !== undefined);

      const { firstRow, secondRow } = getPlayersTwoRows(
        filteredPlayers,
        similarPlayerNames,
        formationType,
      );

      return {
        players: {
          firstRow,
          secondRow,
        },
        teamId: teamsFormation.teamId,
        formations: teamsFormation.playerIds.map<IFormation>(playerId => ({
          player: playerId,
          on: true,
        })),
        countOfPlayers: formationType?.value,
        gp: teamsFormation.stats.gp,
        toi: teamsFormation.stats.toi,
        stats: getMetricsWithValue(metrics, teamsFormation.stats),
        percentiles: teamsFormationPercentiles
          ? getMetricsWithValue(metrics, teamsFormationPercentiles.stats)
          : undefined,
        summaryPercentile: teamsFormationPercentiles
          ? teamsFormationPercentiles.stats['summaryPercentile']
          : undefined,
      };
    })
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = () => {
  const { byId, similarPlayerNames } = useAppSelector(selectPlayers);
  const teamsFormation = useAppSelector(selectTeamsFormation);
  const { wowy, wowyPercentiles } = useAppSelector(selectWowy);
  const { formationType } = useAppSelector(selectMainFilter);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const dataWithHeader: ITeamsFormationTableWithHeaderData = useMemo(() => {
    const filteredMetrics = getFilteredMetrics();

    if (filteredMetrics) {
      const allTeamsFormations = Object.values(teamsFormation.byId).reduce<ITeamFormation[]>(
        (acc, allTeamsFormations) => acc.concat(allTeamsFormations.teamsFormation),
        [],
      );

      const allTeamsFormationsPercentiles = teamsFormation.isTeamsFormationPercentilesActive
        ? Object.values(teamsFormation.byId).reduce<ITeamFormation[]>(
            (acc, allTeamsFormations) =>
              acc.concat(allTeamsFormations.teamsFormationPercentiles ?? []),
            [],
          )
        : undefined;

      const filteredAllTeamsFormations = wowy
        ? allTeamsFormations.filter(teamsFormation =>
            teamsFormation.playerIds.includes(wowy.selectedPlayer.id),
          )
        : allTeamsFormations;

      const filteredAllTeamsFormationsPercentiles =
        wowy && allTeamsFormationsPercentiles
          ? allTeamsFormationsPercentiles.filter(teamsFormation =>
              teamsFormation.playerIds.includes(wowy.selectedPlayer.id),
            )
          : allTeamsFormationsPercentiles;

      const data = getTableData(
        filteredAllTeamsFormations,
        byId,
        filteredMetrics,
        similarPlayerNames,
        filteredAllTeamsFormationsPercentiles,
        formationType,
      );

      if (!wowy) return { selectedPlayerData: undefined, data };

      const player = byId[wowy.selectedPlayer.id];

      const selectedPlayerData: ITeamsFormationSelectedPlayer = {
        playerId: player.id,
        player: {
          id: player.id,
          displayName: getPlayerShortName(player, similarPlayerNames),
        },
        gp: wowy.selectedPlayer.gp,
        toi: wowy.selectedPlayer.toi,
        stats: getMetricsWithValue(filteredMetrics, wowy.selectedPlayer.stats),
        percentiles: wowyPercentiles
          ? getMetricsWithValue(filteredMetrics, wowyPercentiles.selectedPlayer.stats)
          : undefined,
        summaryPercentile: wowyPercentiles
          ? wowyPercentiles.selectedPlayer.stats['summaryPercentile']
          : undefined,
      };

      return {
        selectedPlayerData,
        data,
      };
    }

    return {
      selectedPlayerData: undefined,
      data: [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wowy, wowyPercentiles, teamsFormation, byId, similarPlayerNames, getFilteredMetrics]);

  return dataWithHeader;
};
