import { FC, SetStateAction } from 'react';

import { useAppSelector } from '../../../../../../app/hooks';
import { DashboardGameList, DashboardTeamOverview, Skeleton } from '../../../../../../components';
import { selectDashboard } from '../../../../../../features';
import { IDashboardGame } from '../../../../../../types';
import { createClassNames } from '../../../../../../utils';
import './DashboardGameListWithSummary.styles.scss';

interface IDashboardTeamGameListWithSummaryProps {
  dashboardGames: IDashboardGame[];
  selectedDashboardGame: IDashboardGame | undefined;
  teamId: string;
  width: number;
  setSelectedDashboardGame: (value: SetStateAction<IDashboardGame | undefined>) => void;
}

const className = createClassNames('dashboard-team-game-list-with-summary');

export const DashboardTeamGameListWithSummary: FC<IDashboardTeamGameListWithSummaryProps> = ({
  dashboardGames,
  selectedDashboardGame,
  setSelectedDashboardGame,
  teamId,
  width,
}) => {
  const { gamePreviewsAllIds, gamePreviewsById } = useAppSelector(selectDashboard);

  const renderDashboardTeamOverview = () => {
    if (!selectedDashboardGame?.homeTeam) {
      return null;
    }

    return (
      <DashboardTeamOverview dashboardGame={selectedDashboardGame} displayForTeamId={teamId} />
    );
  };

  if (!gamePreviewsAllIds.length || !gamePreviewsById[teamId]) {
    return (
      <div className={className('skeleton-list')}>
        {Array.from(Array(5)).map((_, index) => (
          <Skeleton
            key={'dashboard-game-list-skeleton' + index}
            width={width > 1600 || width < 1400 ? '14.68rem' : '12.28rem'}
            height={126}
            borderRadius={8}
          />
        ))}
      </div>
    );
  }

  return (
    <>
      <DashboardGameList
        displayForTeamId={teamId}
        games={dashboardGames}
        selectedGameId={selectedDashboardGame?.id}
        onChange={selectedGameId =>
          setSelectedDashboardGame(dashboardGames.find(game => game.id === selectedGameId))
        }
      />
      <div className={className('overview')}>{renderDashboardTeamOverview()}</div>
    </>
  );
};
