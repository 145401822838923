import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { IDefinedDatasetsState } from '../../../types';
import {
  createDataset,
  deleteDataset,
  getDefinedDatasets,
  putDefinedDatasets,
  updateDataset,
  updateDatasetDisable,
  updateDatasetMetrics,
  updateDatasetOrder,
  updateDefaultTemplateMetrics,
} from './definedDatasetsActions';

const initialState: IDefinedDatasetsState = {
  definedDatasets: undefined,
  defaultTemplates: undefined,
  isLoading: false,
  isDefinedDatasetsLoaded: false,
  error: undefined,
};

export const definedDatasetsSlice = createSlice({
  name: 'settings/definedDatasets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDefinedDatasets.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getDefinedDatasets.fulfilled, (state, action) => {
        const { defaultTemplates, ...definedDatasets } = action.payload;
        state.definedDatasets = definedDatasets;
        state.defaultTemplates = defaultTemplates;
        state.isLoading = false;
        state.isDefinedDatasetsLoaded = true;
      })
      .addCase(getDefinedDatasets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Next action case
      .addCase(putDefinedDatasets.pending, state => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(putDefinedDatasets.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          const { defaultTemplates, ...definedDatasets } = action.payload;
          state.definedDatasets = definedDatasets;
          state.defaultTemplates = defaultTemplates;
        }
        state.isLoading = false;
      })
      .addCase(putDefinedDatasets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      // Matchers
      .addMatcher(
        isAnyOf(
          createDataset.pending,
          updateDataset.pending,
          deleteDataset.pending,
          updateDatasetDisable.pending,
          updateDatasetOrder.pending,
          updateDatasetMetrics.pending,
        ),
        state => {
          state.error = undefined;
        },
      )
      .addMatcher(
        isAnyOf(
          createDataset.fulfilled,
          updateDataset.fulfilled,
          deleteDataset.fulfilled,
          updateDatasetDisable.fulfilled,
          updateDatasetOrder.fulfilled,
          updateDatasetMetrics.fulfilled,
        ),
        (state, action) => {
          if (action.payload !== undefined) {
            const { defaultTemplates, ...definedDatasets } = action.payload;
            state.definedDatasets = definedDatasets;
          }
        },
      )
      .addMatcher(
        isAnyOf(
          createDataset.rejected,
          updateDataset.rejected,
          deleteDataset.rejected,
          updateDatasetDisable.rejected,
          updateDatasetOrder.rejected,
          updateDatasetMetrics.rejected,
        ),
        (state, action) => {
          state.error = action.error.message;
        },
      )
      // Next case
      .addMatcher(isAnyOf(updateDefaultTemplateMetrics.pending), state => {
        state.error = undefined;
      })
      .addMatcher(isAnyOf(updateDefaultTemplateMetrics.fulfilled), (state, action) => {
        if (action.payload !== undefined) {
          state.defaultTemplates = action.payload.defaultTemplates;
        }
      })
      .addMatcher(isAnyOf(updateDefaultTemplateMetrics.rejected), (state, action) => {
        state.error = action.error.message;
      });
  },
});
