import { FC } from 'react';

import { ITranslationKeys } from '../../i18n/types';
import { IMinigraphBattery, ISelectOptionWithParam } from '../../types';
import { createClassNames } from '../../utils';
import { Minigraph } from '../Minigraph';
import './MinigraphList.styles.scss';

export interface IMinigraphListProps {
  /** Minigraph options */
  options: ISelectOptionWithParam<IMinigraphBattery>[];
  /** Callback function when the minigraph option is clicked */
  onClick: (option: ISelectOptionWithParam<IMinigraphBattery>) => void;
}

const classNames = createClassNames('minigraph-list');

export const MinigraphList: FC<IMinigraphListProps> = ({ options, onClick }) => (
  <div className={classNames()} data-testid='minigraph-list'>
    {options.map(option => {
      const { value, label, additionalInfo } = option;
      const { metricName, metricValue, order, total, tooltipLabel } = additionalInfo;

      return (
        <Minigraph
          key={value + label}
          label={label as ITranslationKeys}
          order={order}
          total={total}
          tooltipLabel={tooltipLabel}
          metricName={metricName}
          metricValue={metricValue}
          onClick={() => onClick(option)}
        />
      );
    })}
  </div>
);
