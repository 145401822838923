import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import { selectDashboard, selectGames, selectTeams } from '../../../../features';
import { IStandingsTableData } from '../../../../types';

export const useStandingsDataForTable = () => {
  const { competitionTable, shortTable } = useAppSelector(selectDashboard);
  const { byId } = useAppSelector(selectTeams);
  const games = useAppSelector(selectGames);

  const data = useMemo(() => {
    if (!shortTable || !competitionTable) return [];

    return shortTable.reduce<IStandingsTableData[]>((acc, team) => {
      const competitionTeam = competitionTable.find(t => t.teamId === team.teamId);
      if (!competitionTeam) return acc;

      const filteredGames = Object.values(games.games.byId)
        .filter(game => game.homeTeamId === team.teamId || game.awayTeamId === team.teamId)
        .slice(0, 5);
      if (!filteredGames.length) return acc;
      console.log('Team:', team.teamId);
      console.log(team);
      console.log(competitionTeam);

      acc.push({
        teamName: byId[team.teamId].name,
        competitionOrder: competitionTeam.order,
        competitionGP: competitionTeam.GP,
        competitionL: competitionTeam.L,
        competitionW: competitionTeam.W,
        competitionWOT: competitionTeam.WOT,
        competitionLOT: competitionTeam.LOT,
        competitionPTS: competitionTeam.PTS,
        competitionXPTS: competitionTeam.xPTS,
        competitionxGA: competitionTeam.xGA,
        competitionxGF: competitionTeam.xGF,
        'competitionGF:GA': competitionTeam['GF:GA'],
        games: filteredGames,
        ...team,
      });
      return acc;
    }, []);
  }, [shortTable, competitionTable, byId, games.games.byId]);
  return data;
};
